
<!-- <select *ngIf="!showTextSelection" id="select_{{id}}" class="form-control form-control-sm" #t (change)="onChange(t.value)" placeholder="Etiquetas" [disabled]="!editable"> -->
    <!-- <option *ngIf="!inputOption" [selected]="showtext" disabled>Seleccione una opción</option> -->
    <!-- <option [selected]="!optionCodeSelected" disabled>Seleccione una opción</option> -->
<!--     <option disabled>Seleccione una opción</option>
    <option *ngFor="let option of options" [selected]="(optionCodeSelected && (option[atrValue] == optionCodeSelected))" [value]="option[atrValue]">{{option[atrValue]}}
    </option>
</select>
<select *ngIf="showTextSelection" id="select_{{id}}" class="form-control form-control-sm" #t (change)="onChange(t.value)" placeholder="Etiquetas" [disabled]="!editable"> -->
    <!-- <option *ngIf="!inputOption" [selected]="showtext" disabled>Seleccione una opción</option> -->
    <!-- <option selected disabled>Seleccione una opción</option>
    <option *ngFor="let option of options" [selected]="(optionCodeSelected && (option[atrValue] == optionCodeSelected))" [value]="option[atrValue]">{{option[atrValue]}}
    </option>
</select> -->

<!-- v2 -->
<!-- <div class="form-group" [ngStyle]="(displayText == 'horizontal') ? {'display': 'inline-flex', 'width': '100%'}: {'display': 'block'}"> -->
<div class="form-group" [ngClass]="(displayText == 'horizontal') ? 'div-horizontal': 'div-vertical'" [ngStyle]="marginBottom ? {'margin-bottom': '3%'}: {'margin-bottom': '0px'}">
    <label *ngIf="text" for="" style="line-height: initial; margin-bottom: 0.3rem;">
        <small [ngStyle]="(displayText == 'horizontal') ? {'width': '33%'}: {'width': '100%'}">{{text}}</small>
    </label>
    <select *ngIf="!showTextSelection" id="select_{{id}}" class="form-control form-control-sm" #t (change)="onChange(t.value)" placeholder="Etiquetas" [disabled]="!editable">
        <option *ngFor="let option of options" [selected]="(optionCodeSelected && (option[atrValue!] == optionCodeSelected))" [value]="option[atrValue!]">{{option[atrValue!]}}
        </option>
    </select>
    <select *ngIf="showTextSelection" id="select_{{id}}" class="form-control form-control-sm" #t (change)="onChange(t.value)" placeholder="Etiquetas" [disabled]="!editable">
        <option selected disabled>Seleccione una opción</option>
        <option *ngFor="let option of options" [selected]="(optionCodeSelected && (option[atrValue!] == optionCodeSelected))" [value]="option[atrValue!]">{{option[atrValue!]}}
        </option>
    </select>
</div>