<form [formGroup]="civilIdForm" (ngSubmit)="notifyEnterPressed()">
  <div class="form-group" [ngStyle]="(displayText == 'horizontal') ? {'display': 'inline-flex'}: {'display': 'block'}" style="width: 100%;">
    <div *ngIf="text">
      <label for="" style="line-height: initial; margin-bottom: 0.3rem;" [ngStyle]="colorText ? {'color': colorText}: {'color': 'inherit'}">
        <small [ngStyle]="(displayText == 'horizontal') ? {'width': '33%'}: {'width': '100%'}">{{text}}</small>
      </label>
    </div>

    <div style="display: inline-flex; width: 100%;" class="input-group">
      <!-- <app-selector-generic style="width: 20%;" [editable]=false [atrValue]="'code'" [options]="countries" [inputOption]="'AR'"
        [showTextSelection]=false [displayText]="'horizontal'" (notifyChangeOption)="changeCountry($event)"></app-selector-generic>
      <app-selector-generic style="width: 20%;" [editable]=true [atrValue]="'code'" [options]="optionsDocumentType" [inputOption]="'DNI'"
        [showTextSelection]=false [displayText]="'horizontal'" (notifyChangeOption)="changeTypeCivilId($event)"></app-selector-generic>
  
      <input *ngIf="!editable || !typeCivilId" style="width: 60%;" class="form-control form-control-sm form-control-inp" type="text" formControlName="valueCivilId" [readonly]=true>
      <input *ngIf="editable && typeCivilId" style="width: 60%;" (ngModelChange)="changeInput()" class="form-control form-control-sm" type="text" [readonly]=false
        formControlName="valueCivilId" [placeholder]="placeholder" [type]="hideValue ? 'password': 'text'" [ngClass]="civilIdForm.valid? 'inp-valid': 'inp-invalid' "> -->
      <app-selector-generic style="width: 30%; padding-right: 1%;" [editable]=false [atrValue]="'name'" [options]="countries" [inputOption]="'Argentina'"
        [showTextSelection]=false [text]="'País'" [colorText]="colorText" [displayText]="'vertical'" (notifyChangeOption)="changeCountry($event)"></app-selector-generic>
      <app-selector-generic style="width: 20%; padding-right: 1%;" [editable]=true [atrValue]="'code'" [options]="optionsDocumentType" [inputOption]="'DNI'"
        [showTextSelection]=false [text]="'Tipo'" [colorText]="colorText" [displayText]="'vertical'" (notifyChangeOption)="changeTypeCivilId($event)"></app-selector-generic>
  
      <div style="width: 50%;">
        <label for="" style="line-height: initial; margin-bottom: 0.3rem;">
          <small [ngStyle]="colorText ? {'color': colorText}: {'color': 'inherit'}">Número</small>
        </label>
        <input *ngIf="!editable || !typeCivilId" style="width: 100%;" class="form-control form-control-sm form-control-inp" type="text" formControlName="valueCivilId" [readonly]=true>
        <!-- <input *ngIf="editable && typeCivilId" style="width: 100%;" (ngModelChange)="changeInput()" class="form-control form-control-sm" type="text" [readonly]=false
          formControlName="valueCivilId" [placeholder]="placeholder" [type]="hideValue ? 'password': 'text'" [ngClass]="civilIdForm.valid? 'inp-valid': 'inp-invalid' "> -->
        <input *ngIf="editable && typeCivilId" style="width: 100%;" (ngModelChange)="changeInput()" class="form-control form-control-sm" type="text" [readonly]=false
          formControlName="valueCivilId" [placeholder]="placeholder" [type]="hideValue ? 'password': 'text'" [ngClass]="{'inp-invalid': civilIdForm.get('valueCivilId').touched && civilIdForm.get('valueCivilId').invalid }">
        <div *ngIf="showEye" class="div-eye">
            <img *ngIf="!hideValue" src="assets/images/eye_close48.svg" title="Ocultar" class="w3-hide" style="width:80%;" class="w3-hover-opacity" alt="" (click)="showValue()">
            <img *ngIf="hideValue" src="assets/images/eye_open48.svg" title="Mostrar" class="w3-hide" style="width:80%;" class="w3-hover-opacity" alt="" (click)="showValue()">
        </div>
      </div>
    </div>
  </div>
</form>