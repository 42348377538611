// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app: "web_template",
  appName: "Template Web App",
  domain: "hms",
  version: "1.0.0",
  apikey_central: "kO2Ntii7I7d8SzxnYZGsckNmTUZAdZ1b",
  /* central_server: "http://osiris.hms-tech.com:10100" */
  central_server: "https://osiris.hms-tech.com" ,    /* para https */
  envDeploy : "test"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
