import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { InfoModal } from '../../model/info.modal';
import { AuthenticationV2Service } from '../../services/authentication-v2.service';
import { ConfigService } from '../../services/config.service';
import { TokenizerService } from '../../services/tokenizer.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { MessageAppsService } from '../../services/message-apps.service';
import { AdminAppService } from '../../services/manager/admin-app.service';
import { AdminRolesService } from '../../services/manager/admin-roles.service';
import { AdminContextSassService } from '../../services/manager/admin-context-sass.service';
import { EncryptatorService } from '../../services/utils/encryptator.service';
import { UrlCore } from '../../assets/config/url-core';
import { Identifier } from '../../model/person/identifier.model';
import { ResponseLogin } from '../../model/SSO/response/login';
import { PersonLSSSO } from '../../model/SSO/context/personLSSSO';
import { Profiles } from '../../model/profiler/profiles.enum';
import { PersonStatus } from '../../model/person/personStatus';
import { FUNCTIONALITY } from '../../model/functionality-roles';
import { DataToken } from '../../model/token/data-token';
import { PersonSSO } from '../../model/SSO/person/person';

import { TYPE_METHOD, config } from '../../assets/mocks/login';
import { Identifiers } from '../../model/person/identifiers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminProfileService } from '../../services/manager/admin-profile.service';
import { AdminOptionsHmsIdService } from '../../services/manager/admin-options-hms-id.service';
import { AdminModalService } from '../../services/manager/adminModal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminMsgResponseService } from '../../services/manager/admin-msg-response.service';
import { ConfigToken, VALID_INIT_LOGIN } from '../../assets/config/config-constant.example';
import { Generic } from '../../assets/source/message';
import { ContextSass } from '../../model/context/contextSass';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();

  configForm = config;
  typeIdHms?: string;
  operatorLogged?: boolean = false;
  tokenOtherApp?: string;     // esto para safar el paso de contexto entre apps. PARCHE!1!!

  constructor(
    private authenticationService: AuthenticationV2Service,
    private configService: ConfigService,
    private tokenizerService: TokenizerService,
    private router: Router,
    private adminProfileService: AdminProfileService,
    private adminOptionsHmsId: AdminOptionsHmsIdService,
    private localStorageService: LocalStorageService,
    private messageAppsService: MessageAppsService,
    private adminAppsService: AdminAppService,
    private adminRolesService: AdminRolesService,
    private adminContextSassService: AdminContextSassService,
    private encryptatorService: EncryptatorService,
    private adminMsgResponseService: AdminMsgResponseService,
    private adminModalService: AdminModalService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.adminContextSassService.tokenVisitorContext$.subscribe(
      (resp: any) => {
        if(resp){
          console.log("[Login.comp] => Token de otra app: ", resp);
          this.loginByTokenContext(resp);
        }
      }
    )

    this.authenticationService.authenticationState$.subscribe(
      (resp: any) => {
        /* console.log("[Authv2.serv] => Operador logueado: ", resp); */
        this.operatorLogged = resp;
      }
    )
  }

  // para redireccionar desde el link en caso de ya estar logueado
  // public redirectHome(){
  //   this.authenticationService.registerUsersListActive(this.userLogged, identifier);
  //   // Como me acabo de loggear debo actualizar el estado de la variable que controla la sesion
  //   this.authenticationService.authenticationState$.next(true);
  //   this.router.navigateByUrl(UrlCore.HOME);
  // }

  public redirectRegister(){
    this.router.navigateByUrl(UrlCore.REGISTER);
  }

  public login(typeMethod: any, identifier: Identifier, password: string) {
    console.log("ENTRO AL Login con typeMethod: ", typeMethod); 
    let userLogged = this.authenticationService.isLoggedUser(identifier);

    if (!userLogged) {
        this.authenticationService.authenticateByIdentifierHashEncrypt(identifier, password)
            .subscribe(
              (resp: any) => {
                /* if(resp.error){
                  console.warn("[Auth.serv] => No puede entrar!!");
                  return;  
                } */
                resp = resp as ResponseLogin;
                 console.log("[Auth.serv] => Resp Login: ", resp); 
                 if (resp.person) {
                  this.closeModal.emit('success'); // Emitir evento de éxito si se recibe la persona
                 } else {
                  //this.showLoginErrorModal(); // Mostrar modal de error si no se recibe la persona
                 }
                let operatorDecrypt = this.encryptatorService.getIdPersonDecrypt(resp.person);
                 console.log("[Auth.serv] => Operador desencriptado: ", operatorDecrypt); 
                if(operatorDecrypt){
                  this.loginOperator(operatorDecrypt, identifier, undefined);
                }
                else{
                  //this.adminModalService.showModalGlobal(this.configService.getAppName(), this.adminMsgResponseService.getMsg({status: "ERROR_LOCAL"}));
                }
              },
              (error: HttpErrorResponse) => {
                console.warn("[Login.comp] => Error al realizar la solicitud. ", error); 
                this.adminModalService.showModalGlobal('Error', 'Hubo un error al iniciar sesión.');
              }
            );
    }
    // no hace falta el ELSE ya que en el caso de que pase se le da la posibilidad de redirigirse
  }

  private persistDataOperator(operator: any, identifier: Identifier) {
    // creamos el operador para tenerlo en el contexto
    let personLS = new PersonLSSSO(operator);
    let isRegistered: boolean|null;
    personLS.registered ? isRegistered = operator.isRegistered : isRegistered = null;
    personLS.addIdentifierLogin(Profiles.OPERATOR_CC, identifier);
    personLS.updateProfile(Profiles.OPERATOR_CC, this.configService.getAppDomain(), isRegistered, PersonStatus.ACTIVE, null);
    personLS.function = FUNCTIONALITY.EDIT_OPERATOR;
    personLS.activeRoles = this.adminRolesService.getRolesByFunctionality(personLS.function);
    // agregamos a la persona en ambos lugares
    this.localStorageService.addPerson(personLS);
  }

  // Buscamos un token de control de sesion y lo guardamos localmente
  private getAccessToken(operator: any, identifier: Identifier) {
    let payload = {
      idPerson: identifier,
      domain: this.configService.getAppDomain(),
      profiles: [Profiles.OPERATOR_CC]
    };
    /* console.log("[Login.comp] => (getAccesToken) Data operador: ", operator); */
    /* this.tokenizerService.getToken(payload) */
    this.tokenizerService.getTokenV2(payload, ConfigToken.DURATION_LOGIN_DEFAULT, Number.parseInt(ConfigToken.LENGTH_LOGIN_DEFAULT), ConfigToken.TYPE_LOGIN_DEFAULT, Number.parseInt(ConfigToken.NUMBER_USSAGE_LOGIN_DEFAULT))
      .subscribe(
        (data: any) => {
          /* console.log("[Login.comp] => Token recibido: ", data); */
          // TODO: ver una manera elegante de determinar que lo recibido sea correcto
          if(data["long_token"]){
            let dataToken: DataToken = data as DataToken;
            let personLS = new PersonLSSSO(operator);
            this.localStorageService.updateDataTokenPerson(personLS, dataToken);
            this.localStorageService.setTokenCurrentOperator(dataToken);
            this.informLogin();
          }
          else{
            console.warn("[Login.comp] => Ha ocurrido un error al recuperar el token.");
          }
        },
        error => {
          console.log("[Login.comp] => No se pudo generar el token. Error: ", error);
        }
      )
  }

  private async loginOperator(operator: PersonSSO, identifier: Identifier, operatorToken: string|undefined){
    console.log("[loginOperator] => operatorToken: ", operatorToken);
    
    let respUserAllowed = await this.adminRolesService.userAllowed(operator, FUNCTIONALITY.AUTH_OPERATOR);
    if(respUserAllowed){
      this.localStorageService.setItem(VALID_INIT_LOGIN, "true");
      // guardamos los datos del operador en el contexto por medio de su token
      if(operatorToken){
        await this.updateWhitUserEntered(operatorToken);
        /* this.updateWhitUserEntered(operatorToken); */
      }
      else{
        await this.saveOperatorInContext(operator);
        /* this.saveOperatorInContext(operator); */
      }
      // guardamos los datos del operador en el LS como en el contexto
      this.persistDataOperator(operator, identifier);
      // Agregamos al usuario a la lista de usuarios activos
      this.authenticationService.registerUsersListActive(operator, identifier);
      // Como me acabo de loggear debo actualizar el estado de la variable que controla la sesion
      this.authenticationService.authenticationState$.next(true);
      this.getAccessToken(operator, identifier);
      this.router.navigateByUrl(UrlCore.HOME);
    }
    else{
      //this.adminModalService.showModalGlobal(this.configService.getAppName(), Generic.DENIED_PROFILE)
    }
  }

  // Actualizamos los datos del token de contexto
  private updateTokenContext() {
    let tokenContext = this.adminContextSassService.getTokenContext();
    let context = this.adminContextSassService.getContext();
    if(tokenContext){
      /* this.tokenizerService.updatePayloadLongToken(tokenContext, {context: context}).subscribe( */
      // CUIDADO: el update por long short token no funciona con ambos
      this.tokenizerService.updatePayloadShortToken(tokenContext, {context: context}).subscribe(
        (resp: any) => {
          console.log("[Login.comp] => Contexto actualizado por token =>  ", this.adminContextSassService.getTokenContext());
        },
        (err: any) => {
          console.warn("[Login.comp] => No se pudo actualizar el contexto!");
        }
      )
    }
    else{                 // si no hay token de contexto
      /* console.log("[Login.comp] => Se actualizo el contexto y GENERO un token de contexto."); */
      this.adminContextSassService.generateTokenContext();
    }
  }

  private async updateWhitUserEntered(userToken: string){
    /* this.adminContextSassService.addUserLogged(userToken); */
    /* await this.adminContextSassService.updateWithUserLogged(userToken); */
    /* console.log("[updateWhitUserEntered] => va a actualizar el contexto!!"); */
    await this.adminContextSassService.updateWithUserLogged(userToken);
  }

  private async saveOperatorInContext(operator: PersonSSO) {
    /* console.log("Datos del token a guardar: ", operator); */
    this.tokenizerService.getTokenV2(operator, ConfigToken.DURATION_LOGIN_DEFAULT, Number.parseInt(ConfigToken.LENGTH_LOGIN_DEFAULT), ConfigToken.TYPE_LOGIN_DEFAULT, Number.parseInt(ConfigToken.NUMBER_USSAGE_LOGIN_DEFAULT)).subscribe(
      async (resp: any) => {
        if(resp.short_token){
          await this.updateWhitUserEntered(resp.short_token);
        }
        else{
          console.warn("[saveOperatorInContext] => No se pudo obtener un token con los datos del operador!!!!");
        }
      },
      (error: any) => {
        console.warn("[saveOperatorInContext] => La solicitud de generacion de token del operador no se ha podido realizar.");
      }
    )
    /* console.log("[saveOperatorinContext] => Resp del getToken: ", respGetToken); */
  }

  // comunicamos a las demas apps que se realizo un login exitoso
  private informLogin(){
    //this.closeModal.emit('success');
    if(this.adminAppsService.getAppsAvailable())
      this.messageAppsService.reportLogin(this.adminAppsService.getAppsAvailable(), JSON.stringify(window.localStorage));
  }

  public reportLogin(data: any){
     console.log("[Login.comp] => Informe de login recibido: ", data); 
    switch(data.typeMethod.code){
      case TYPE_METHOD.qr:{
        this.loginByQr(data);
        break;
      }
      default:{

        this.login(data.typeMethod.code, data.identifier, data.password);
        break;
      }
    }
  }

  // recuperamos la info del token si no ha expirado(si fue asi deberiamos de mostrar mje, "Debe iniciar sesion nuevamente")
  private loginByQr(data: any){
    // TODO: descomentar esto cuando se reciba el token del operador en lugar de los datos completos
    // de momento esta recuperacion de info se realiza en BoxLogin.comp
    /* this.tokenizerService.getPayloadTokenCtd(data.identifier.value.token).subscribe(
      (resp: any) => {
        console.log("[Login.comp] => Datos recuperados del token del operador: ", resp);
        let identifier = {
          type: resp.payload.login_id.type,
          value: resp.payload.login_id.value
        }
        this.loginOperator(resp.payload.person, identifier);
      },
      (err: any) => {
        console.warn("[Login.comp] => No se han logrado recuperar los datos del token.");
      }
    ) */

    // Nueva version, dado q de momento la app informa todos los datos del afiliado en lugar del token del mismo
    this.loginOperator(data.operator, data.identifier, undefined);
  }

  // ##########################################################################################
  // ############################## LOGIN_BOX - CONTEXT_PARAM #################################
  private loginByTokenContext(token: string){
    // TODO: para evitar el multiple login del mismo usuario aca debemos revisar  si el usuario existe
    // TODO: ver si no se guarda un token en LS de sesion al loguearse por token y luego se va a buscar ese token para iniciar sesion nuevamente
    // TODO: imprimir la cant de veces q intenta loguearse!!!
    this.tokenizerService.getPayloadTokenCtd(token, true).subscribe(
      (resp: any) => {
        if(resp.error){
          console.warn("[Login.comp] => No se pudo recuperar los datos del token!");
          return;  
        }
        /* console.log("[Login.comp] => Datos del token recuperado: ", resp); */
        let tokenOperator = resp.payload.context.users_entered[0].user;
        let contexVisitor = resp.payload.context;
        if(!tokenOperator){
          console.warn("[loginByTokenContext] => No existe token de operador. No se puede iniciar con token de contexto!!");
          return; 
        }
        // vamos a buscar la info del operador
        this.tokenizerService.getPayloadTokenCtd(tokenOperator, true).subscribe(
          (resp: any) => {
            let user = resp.payload;
            if(user){
              this.updateContextFromContextVisitor(contexVisitor);
              // se pasa un identifier para poder agregar al user-popover
              let identifier = {type: Identifiers.EMAIL, value: "el_mail_de@markan.com"};
              /* console.log("[loginByTokenContext] => Datos del usuario recuperado: ", user); */
              this.loginOperator(user, identifier, tokenOperator);
              // para garantizar un unico uso del token y para no propagar el update del token q ya fue leido
              this.adminContextSassService.setTokenVisitorContext(undefined);
            }
          },
          (error: any) => {
            console.warn("[loginByTokenContext] => No se pudo obtener la info del token del operador!. Error: ", error);
          }
        )
      },
      (err: any) => {
        console.warn("[loginByTokenContext] => Ha ocurrido un error al recuperar los datos del token!");  
      }
    )
  }

  private updateContextFromContextVisitor(context: ContextSass){
    // si tenemos usuarios en contexto ingresados por el operador visitante
    if(context.users_entered){
      this.adminContextSassService.updateUsersEntered(context.users_entered);
      /* console.log("[updateContextFromContextVisitor] usuarios de contextos actualizados: ", this.adminContextSassService.getContext()); */
    }
  }
}