import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
/* import { DataToken } from '../model/token/data-token'; */
import { ConfigService } from './config.service';
import { Microservices } from '../assets/mocks/microservices';

@Injectable({
  providedIn: 'root'
})
export class TokenizerService {

  /* readonly NAME_SERVER = "tokenizer_server"; */
  private urlBaseSrv?: string;
  private apikey?: string;

  constructor(
    private httpClient: HttpClient,
    private configService:ConfigService
  )
  {
    this.configService.targetServices$.subscribe((value: any) => {      
      if(value){
        /* let dataMicroservices: any = value[Microservices.TOKENIZER_SV.namev2]; */
        let baseUrl: any = this.configService.getBaseUrlMicroservice(Microservices.TOKENIZER_SV.name);
        if(!baseUrl){
          console.warn("[Menu.serv] => No se encontraron los datos de base del tokenizer_sv.");
          return;
        }
        this.urlBaseSrv = baseUrl;
        this.apikey = this.configService.getApiKey() as string;
        /* console.log("Url SRV_TOKENIZER: "+this.urlBaseSrv); */
      }
    });
  }

  public async getSharedTokenContext(context: any, duration: string, length_token: number, type_token: string, number_used: number){
    // creamos un token con los datos de contexto para un solo uso
    // esto no funca
    /* return await this.getTokenV2(context, duration, length_token, type_token,number_used).subscribe(
      (resp: any) => {
        console.log("[getTokenSharedToken] => Token de contexto inicializado:  ", resp);
        return Promise.resolve(resp.short_token);
      },
      (err: any) => {
        console.warn("[getTokenSharedToken] => No se pudo actualizar el contexto!");
        return Promise.resolve(null);
      }
    ); */
    return await this.getTokenV2(context, duration, length_token, type_token,number_used).toPromise();
  }

  // buscamos los datos del token
  public getPayloadToken(token:string){
    let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/qr/"+token+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);

    return this.httpClient.get(url);
  }

  // buscamos los datos del token del tipo ctd
  public getPayloadTokenCtd(token:string, skipLoading: boolean|undefined){
    let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/ctd/"+token+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);
    if(skipLoading){
      return this.httpClient.get(url, {headers:{skip:"true"}});
    }
    return this.httpClient.get(url);
  }

  // buscamos los datos del token del tipo QR
  public getPayloadTokenQr(token:string){
    let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/qr/"+token+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);

    return this.httpClient.get(url);
  }

  // recupera un token con los datos enviados   expiration, ej: "10 m", "5 s"
  public getToken(payloadBody: any, expiration: string, length_token: number, tokenType: string, number_used: number){
    let bodyRequest = {
      domain: this.configService.getAppDomain(),
      token_type: tokenType,
      length_short_token: length_token,
      requested_duration: expiration,
      number_used: number_used,
      payload: payloadBody
    };

    let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/tokenizer/get?apikey="+this.apikey;

    return this.httpClient.post(url, bodyRequest);
  }

  // recupera un token con los datos enviados   expiration, ej: "10 m", "5 s"
  public getTokenV2(payloadBody: any, expiration: string, length_token: number, tokenType: string, number_used: number){
    let bodyRequest = {
      domain: this.configService.getAppDomain(),
      token_type: tokenType,
      length_short_token: length_token,
      requested_duration: expiration,
      number_used: number_used,
      payload: payloadBody
    };

    let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/getToken?apikey="+this.apikey;

    return this.httpClient.post(url, bodyRequest);
  }

  // Actualiza el payload del token recibido, suma los datos del payload
  public updatePayloadShortToken(token: string, addPayload: any){
    let body = {
      short_token : token,
      data: addPayload
    }
    let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/payloadUpdate?apikey="+this.apikey;

    return this.httpClient.patch(url, body);
  }

  public updatePayloadLongToken(token: string, addPayload: any){
    let body = {
      long_token : token,
      data: addPayload
    }
    let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/payloadUpdate?apikey="+this.apikey;

    return this.httpClient.patch(url, body);
  }

  public timeout(){
    return this.httpClient.get("http://localhost:10025/timeout");
  }
}
