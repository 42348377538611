<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="Política de Privacidad para la App Kirio 2.0. Aprende cómo protegemos tus datos y cómo solicitar la eliminación de tu cuenta.">
    <title>Política de Privacidad</title>
</head>
<body>
    <header class="header">
        <h1>Política de Privacidad de Datos</h1>
    </header>

    <main class="content-container">
        <section class="section">
            <h2>Introducción</h2>
            <p>
                En el Colegio de Escribanos de la Provincia de Buenos Aires, nos comprometemos a proteger la privacidad de nuestros usuarios. 
                Esta política explica cómo recopilamos, usamos y protegemos sus datos personales al utilizar la App Kirio 2.0.
            </p>
        </section>

        <section class="section">
            <h2>Datos que Recopilamos</h2>
            <p>
                Para ofrecer nuestros servicios, recopilamos los siguientes datos personales:
                <ul>
                    <li>Información de identificación personal, como nombre y CUIT.</li>
                    <li>Fotografía-retrato (selfie) para credenciales digitales.</li>
                    <li>Datos de contacto, como correo electrónico o número de teléfono.</li>
                </ul>
                Estos datos se utilizan exclusivamente para los fines descritos y no se comparten con terceros sin su consentimiento explícito.
        </section>

        <section class="section">
            <h2>Solicitud de Eliminación de Cuenta y Datos</h2>
            <p>
                Si usted desea eliminar su membresía, operando en la App Kirio 2.0, puede hacerlo en cualquier momento a través de la sección "Membresías" de la aplicación. 
                Independiente de ello, podrá contactar con el teléfono de la institución para solicitar la eliminación de sus datos personales.
                <span class="phone-number"><a href="tel:+542214121800">+54-2214121800</a></span>
            </p>
    
        </section>

        <section class="section">
            <h2>Descripción de la Aplicación</h2>
            <p>
                La App Kirio 2.0 permite a los usuarios acceder a su Credencial Digital personal y la de su grupo familiar. 
                La selfie capturada será utilizada exclusivamente para la identificación en las credenciales.
            </p>
            <p>
                Nuestra misión es garantizar la seguridad y privacidad de nuestros usuarios al proporcionar servicios de calidad. 
                Valoramos la transparencia y nos esforzamos por ofrecer una experiencia confiable y segura para nuestros usuarios.
            </p>
        </section>

        <section class="section">
            <h2>Compromiso con la Privacidad</h2>
            <p>
                En el Colegio de Escribanos de la Provincia de Buenos Aires, nos comprometemos a proteger la privacidad y seguridad de nuestros usuarios. Cumplimos con las regulaciones y políticas de privacidad establecidas por Google Play Store para garantizar una experiencia segura y confiable para todos nuestros usuarios.
            </p>
        </section>

    </main>
</body>
</html>
