<!-- <form [formGroup]="captchaForm" (ngSubmit)="onSubmit($event)"> -->
    <form [formGroup]="captchaForm">
        <div style="width: 100%; display: inline-flex;">
            <input style="width: 53%; margin-right: 1%;" class="form-control form-control-sm" formControlName="captcha" (keypress)="pressKey($event)"
                (ngModelChange)="changeInput()" [placeholder]="placeholder" [ngClass]="validToken ? 'inp-valid': 'inp-invalid' ">
            <canvas style="width: 35%; margin-right: 1%; border: solid; border-color: darkgray;
                border-width: 1px; background: white;" id="valicode"></canvas>
            <button id="toggle" style="width: 10%;" class="btn form-control form-control-sm btn-info btn-sm" (click)="refreshToken()">
                <img src="app/sesion/assets/icon/refresh_24.svg" width="23"/>
            </button>
        </div>
    </form>
    
    