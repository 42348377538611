import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './components/navigation/menu/menu.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { RouterModule } from '@angular/router';
import { CenterMenuComponent } from './components/navigation/center-menu/center-menu.component';
import { ToastrModule } from 'ngx-toastr';
import { OrderByPipe } from './pipes/order-by.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageToggleComponent } from './../shared/components/language-toggle/language-toggle.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { FloatingButtonsComponent } from './components/floating-buttons/floating-buttons.component';
import { SupportConfirmationModalComponent } from './components/support-confirmation-modal/support-confirmation-modal.component';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from '../sesion/components/loading/loading.component';
import { SesionModule } from '../sesion/sesion.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [MenuComponent, TapToTopComponent, CenterMenuComponent, OrderByPipe, LoaderComponent, LanguageToggleComponent, AppMenuComponent, FloatingButtonsComponent, SupportConfirmationModalComponent],
  exports:[
    MenuComponent,
    LoaderComponent,
    CenterMenuComponent,
    TapToTopComponent,
    OrderByPipe,
    LoaderComponent,
    LanguageToggleComponent,
    AppMenuComponent,
    FloatingButtonsComponent,
    SupportConfirmationModalComponent,
    SesionModule,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ]
})
export class SharedModule { }
