import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN_BY_TOKEN_ENABLED, TIME_SESSION_ENABLED } from '../assets/config/config-constant.example';
import { UrlCore } from '../assets/config/url-core';
import { Profiles } from '../assets/mocks/profiles';
/* import { PersonContext } from '../model/context/personContext';
import { PersonLS } from '../model/context/personLS'; */
/* import { Person } from '../model/person/person.model';
import { DataToken } from '../model/token/data-token';
import { PayloadTokenLogin } from '../model/token/payloadTokenLogin'; */
import { ConfigService } from './config.service';
import { DomainService } from './domain.service';
import { LocalStorageService } from './local-storage.service';
/* import { PeopleService } from './people.service';
import { TokenizerService } from './tokenizer.service'; */
import { TimeService } from './utils/time.service';
import { AdminContextSassService } from './manager/admin-context-sass.service';
import { TokenizerService } from './tokenizer.service';
import { PersonContextSSO } from '../model/SSO/context/personContextSSO';
import { PersonLSSSO } from '../model/SSO/context/personLSSSO';
import { AuthenticationV2Service } from './authentication-v2.service';
import { ModalService } from './utils/modal.service';
import { ModalMessage } from '../model/msg.modal';
import { InfoModal } from '../model/info.modal';
import { Info, Type } from '../assets/mocks/message';
import { AdminAppService } from './manager/admin-app.service';
import { AdminModalService } from './manager/adminModal.service';
import { MenuOptionsService } from './menu-options.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private menuOptionService: MenuOptionsService,
    //private themeService: ThemeService,
    private authenticationService: AuthenticationV2Service,
    private localStorageService: LocalStorageService,
    private router: Router,
    private domainService: DomainService,
    private timeService: TimeService,
    /* private adminAppsService: AdminAppService, */
    private configService: ConfigService,
    private adminContextSassService: AdminContextSassService,
    private adminModalService: AdminModalService
  ) { 
    this.domainService.dataDomain$.subscribe((value) => {
      // console.log("Cambia la data del domain&env: ", value);
      // cuando se consiguen los datos del domain_server se deberia recien ir a buscar los demas datos,
      // tmb se deberia de notificar a config.service para actualizar su baseUrl
      if(value && (typeof (value["domain"]) != "undefined")){
        // si tenemos un token persistido y nuestra config lo permite podemos iniciar sesion
        if(LOGIN_BY_TOKEN_ENABLED && this.existeTokenOperator()){
          if(!this.expiredToken()){
            // aca deberiamos de traer la info del operador e iniciar sesion
            this.loginByToken();
          }
          else{
            // si existe sesion abierta tenemos q renovar el token

            //sino limpiamos el localStorage
            this.localStorageService.resetData();
            console.warn("[App-init.serv] => Token expirado, NO valido para request. NO se puede iniciar sesion.");
          }
        }
        else{
          console.warn("[App-init.serv] => Token NO EXISTENTE o service deshabilitado. No se puede iniciar sesion.");
          this.localStorageService.resetData();
        }

        // vemos si tenemos un token de contexto de otro site
        let visitorToken = this.adminContextSassService.getTokenVisitorContext();
        if(visitorToken){
          this.adminContextSassService.setTokenVisitorContext(visitorToken);
        }

        // generamos el token de contexto
        /* this.adminContextSassService.generateTokenContext(); */

        this.initMenu();
        // en este caso no devuelve una paleta de colores por lo que no se actualiza la paleta, pero en otros
        // como SCIS si lo hace y rompe el style de la caja de login 
        /* this.themeService.loadTheme(value["palette"]); */
      }
    });
   }


   // Esto se ejecuta solo una vez al inicio
  async init() {
    // ################################ Incializo la paleta de colores de HMS ####################################
    //this.updateDataDomainCore();
    // ###########################################################################################################
    // actualizamos los datos del dominio&amb
    let respUpdateDomain: any = await this.domainService.updateDataDomain(this.configService.getAppDomain(), this.configService.getTypeEnvironment());
    /* console.log("[init] => update domain&env => ", respUpdateDomain); */
    if(respUpdateDomain.error){
      this.adminModalService.showModalGlobal(this.configService.getAppName(), Info.ERROR_SERVER);
    }
    /* this.adminAppsService.checkAppsAvailable(); */
    if(TIME_SESSION_ENABLED){       // seteamos el tiempo de inactividad
      this.localStorageService.setTimeLastestActivity(new Date());
    }
    /* this.initDataPages(); */
  }

  // private initDataPages() {
  //   let dataPagesLS = this.localStorageService.getDataPages();
  //   if(!dataPagesLS){
  //     let dataPages = this.adminAppsService.getAppsAvailable();
  //     for (let i = 0; i < dataPages.length; i++) {
  //       this.localStorageService.setIsLogoutPage(document.location.origin, false);
  //     }
  //   }
  // }
  
  // iniciamos sesion con el token obtenido, desde los datos del contexto => ESTO SE REEMPLAZARA POR EL SERV DEL AUTH DE NAHUEL
  private loginByToken(){
    /* console.log("[loginByToken] => (loginByToken) Existe token de sesion."); */
    this.localStorageService.cloneLocalStorageInContext();
    // recuperamos la persona desde el contexto
    let operatorPerson = this.localStorageService.getPersonByToken(this.localStorageService.getTokenOperator());
    /* console.warn("[loginByToken] => (loginByToken) Person de contexto recuperada: ",operatorPerson); */
    this.authenticationService.authenticationState$.next(true);
    // Buscamos el id utilizado para el login del operador correspondiente al token actual
    let idForLogin = (new PersonContextSSO(operatorPerson)).getIdentifierLogin(Profiles.OPERATOR_CC);
    if(!idForLogin){
      console.warn("[loginByToken] => (loginByToken) Ha ocurrido un error al buscar el id de login. idForLogin: ",idForLogin);
      return;
    }
    this.authenticationService.setAuth(new PersonLSSSO(operatorPerson), idForLogin);
    this.addOperatorsLSToUserLogged(new PersonLSSSO(operatorPerson));
    this.router.navigateByUrl(UrlCore.HOME);
  }

  // agrega los operadores del LS a la lista de usuarios logueados exceptuando el recibido
  private addOperatorsLSToUserLogged(personExclude: PersonLSSSO) {
    let operators = this.localStorageService.getAllPerson();
    for (let i = 0; i < operators.length; i++) {
      let operator = new PersonLSSSO(operators[i]);
      // para agregar solo operadores
      if((!operator.equals(personExclude)) && (operator.existIdLogin())){
        this.authenticationService.reportAddUser(operator.getPersonSSO());
      }
    }
  }

  private expiredToken(){
    let timeExpired = this.localStorageService.getMomentExpiredTokenOperator();
    /* console.log("[expiredToken] => Time tokenExpired: ", timeExpired); */
    if(!timeExpired)
      return true;
    // pasamos la fecha guardad a un Date con la zona horaria por defecto
    let dateFormat = new Date(parseInt(timeExpired));
    let segundosDif = this.timeService.compare(dateFormat, new Date());
    /* console.log("[expiredToken] => Segundos de diferencia TEST: ", segundosDif, " Date1: ", dateFormat, " - date2: ", new Date()); */
    
    if( segundosDif < 0){
      return true;
    }
    return false;
  }

  private existeTokenOperator(){
    let tokenLocal = this.localStorageService.getTokenOperator();
    /* console.log("[App-init.serv] => (existTokOp): ", tokenLocal); */
    if(!tokenLocal || (tokenLocal == "null"))
      return false;
    return true;
  }

  // Actualizamos los datos del dominio HMSpor default, update de paleta de colores e imagenes?? (por ahora)
  //private updateDataDomainCore(){
    //this.domainService.getDataDomainV3("hms").subscribe(
      //(resp: any) => {
        ///* console.log("[updateDataDomainCore] => Datos domain HMS: ", resp); */
        //this.themeService.loadVarDefault(resp.palette);
      //},
      //(error: any) => {
        //console.warn("[updateDataDomainCore] => No se pudo obtener los datos iniciales del dominio.");
      //}
    //)
  //}

  private initMenu() {
    this.menuOptionService.getMenu();
  }
  
}