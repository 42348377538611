export class Coordinate{
  latitude?: number;
  longitude?: number;

  constructor(data?: Partial<Coordinate>) {
      Object.assign(this, data);
    }
  
    public clone?(): Coordinate {
      return new Coordinate(this);
    }
}