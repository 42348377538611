import { Injectable } from '@angular/core';
/* import { sprintf } from 'sprintf-js'; */
import { vsprintf } from 'sprintf-js';

@Injectable({
  providedIn: 'root'
})
export class ParseCodeService {

  constructor() { }

  // en el caso de los civilId se necesita los valores como un array de string para poder trabajarlos, esto
  // puede cambiar dependiendo el pais por ej o el tipo de valor a mostrar
  public formatCivilId(format: string, value: string){
    let arrayValue = Array.from(value);
    return vsprintf(format, arrayValue);
  }


  private formattedValue(format: string, arrayValue: string[]){

  }
}
