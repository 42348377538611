export enum FUNCTIONALITY {
    AUTH_OPERATOR = "auhtenticateOperator",
    EDIT_OPERATOR = "editOperator",
    CREATE_OPERATOR = "createOperator",
    EDIT_AFFILIATE = "editAffiliate",
    CANCEL_TRANSACTION = "cancelTransaction",
    PRINT_TRANSACTION = "printTransaction",
    VERIFY_AFFILIATE = "verifyAffiliate",
    SEARCH_AFFILIATE = "searchAffiliate",
    SEARCH_PERSON = "searchPerson",
    SEARCH_PROVIDER = "searchProvider",
    AUTH_BENEFIT = "authBenefit",
    AUTH_BENEFIT_ODON = "authBenefitOdon",
    AUTH_AI = "authAI",
    AUTH_EI = "authEI",
    RESCUE_AUTH = "rescueAuth",
    LIST_AUTH = "ListAuth"
}

export const ROLES_FUNCTION = [
    {
        funcionality: FUNCTIONALITY.AUTH_OPERATOR,
        roles: ["AUTENTICAR_PERSONAS"]
    },
    {
        funcionality: FUNCTIONALITY.CREATE_OPERATOR,
        roles: ["AGREGAR_PERSONA"]
    },
    {
        funcionality: FUNCTIONALITY.EDIT_OPERATOR,
        roles: ["EDITAR_PERSONA"]
    },
    {
        funcionality: FUNCTIONALITY.EDIT_AFFILIATE,
        roles: ["EDITAR_AFILIADO"]
    },
    {
        funcionality: FUNCTIONALITY.CANCEL_TRANSACTION,
        roles: ["ANULAR_AUTORIZACION"]
    },
    {
        funcionality: FUNCTIONALITY.PRINT_TRANSACTION,
        roles: ["IMPRIMIR_AUTORIZACION"]
    },
    {
        funcionality: FUNCTIONALITY.VERIFY_AFFILIATE,
        roles: ["VERIFICAR_AFILIADO"]
    },
    {
        funcionality: FUNCTIONALITY.SEARCH_PERSON,
        roles: ["IDENTIFICAR_PERSONA"]
    },
    {
        funcionality: FUNCTIONALITY.SEARCH_AFFILIATE,
        roles: ["IDENTIFICAR_AFILIADO"]
    },
    {
        funcionality: FUNCTIONALITY.SEARCH_PROVIDER,
        roles: ["IDENTIFICAR_PESTADOR"]
    },
    {
        funcionality: FUNCTIONALITY.AUTH_BENEFIT,
        roles: ["AUTORIZAR_PRESTACION"]
    },
    {
        funcionality: FUNCTIONALITY.AUTH_BENEFIT_ODON,
        roles: ["AUTORIZAR_PRESTACION_ODONTOLOGICA"]
    },
    {
        funcionality: FUNCTIONALITY.AUTH_AI,
        roles: ["INGRESAR_INTERNACION"]
    },
    {
        funcionality: FUNCTIONALITY.AUTH_EI,
        roles: ["EGRESAR_INTERNACION"]
    },
    {
        funcionality: FUNCTIONALITY.RESCUE_AUTH,
        roles: ["RESCATAR_AUTORIZACION"]
    },
    {
        funcionality: FUNCTIONALITY.LIST_AUTH,
        roles: ["LISTAR_AUTORIZACIONES_REGISTRADAS"]
    }
]