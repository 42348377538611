
<!--slider css start-->
<section class="saas2 laptop-slider bg-gradient" id="our-work" [ngStyle]="{'background-image': 'url()'}">
  <div class="container">
      <div class="row">
          <div class="col-md-10 offset-md-1">
              <div class="title">
                  <div class="main-title">
                      <h2 class="text-black">{{'Our Work' | translate }}</h2>
                  </div>
                  <div class="sub-title">
                      <p class="sub-title-para text-black">{{   "Hms tech offers a variety of products aimed at organizations and individuals, focused on comprehensive identity management, community connectivity, document management and transactionality, both as a service mode (SaaS), and optionally, under perpetual licenses." | translate}}</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="container-fluid">
      <div class="slider-container">
          <owl-carousel-o [options]="screenshotscarouselOptions" class="trainers-slider">
              <ng-container *ngFor="let screenshot of screenshots">
                  <ng-template carouselSlide class="item">
                  <img [src]="screenshot.img" alt="" class="img-fluid">
                  </ng-template>
                  </ng-container>
                  </owl-carousel-o>
      </div>
  </div>
</section>
<!--slider css start-->
