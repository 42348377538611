import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ControlForm } from '../model/form/controls-form';
import { ConfigService } from './config.service';
import { Microservices } from '../assets/mocks/microservices';



@Injectable({
  providedIn: 'root'
})
export class ControlFormsService {
  private urlBaseSrv?: string;
  private apikey?: string;

  controlsRegisterForm: any;
  controlsEditOperatorForm: any;

  changeControls: boolean = false;
  changeControls$ = new BehaviorSubject<boolean>(false);

  constructor(
    private configService:ConfigService,
    private httpClient: HttpClient
  ) {
      this.configService.targetServices$.subscribe((value: any) => {      
        if(value){
          /* let dataMicroservices: any = value[Microservices.CONTROL_SV.namev2]; */
          let baseUrl: any = this.configService.getBaseUrlMicroservice(Microservices.CONTROL_SV.name);
          if(!baseUrl){
            console.warn("[Control.serv] => No se encontraron los datos de base del control_sv.");
            return;
          }
          this.urlBaseSrv = baseUrl;
          this.apikey = this.configService.getApiKey() as string;
          /* console.log("Url SRV_CONTROL: "+this.urlBaseSrv); */
        }
      });
   }

   public setControlForms() {
    this.getControlForms()
      .subscribe(
        data => {
          let controlForm: any = data as any;
          /* console.log("[Control.form.serv] => Resp de getControlForms(): ", controlForm); */
          if(!controlForm.error){
            this.controlsRegisterForm = controlForm.controls.form_register;
            this.controlsEditOperatorForm = controlForm.controls.form_edit_operator;
            // notificamos el cambio de controles para actualizar los forms
            this.changeControls = true;
            this.changeControls$.next(this.changeControls);
            /* console.log("Notificado cambio de controles: ", this.changeControls); */
          }
          else{
            console.warn("[Control.form.serv] => Datos de controles invalidos. Los forms pueden no estar configurados correctamente.");
          }
        },
        error => {
          console.warn("[Control.form.serv] => ha ocurrido un error al recuperar los controles de los forms. " +error);
        }
      );
  }

  private getControlForms(){
    let urlRequest = this.urlBaseSrv + "/domain/"+this.configService.getAppDomain()+"/app/"+this.configService.getApp()+"/version/"+this.configService.getAppVersion()+"?apikey=" + this.apikey;
    /* console.log("url controlForm: ", urlRequest); */
    return this.httpClient.get(urlRequest);
  }

  public getControlFormRegister(userProfile: string){
    // el form de registro es el mismo sin importar perfil
    let controlsForm = this.controlsRegisterForm[userProfile] as ControlForm;
    if(typeof controlsForm == "undefined")
      return null;

    return controlsForm;
  }

  public getControlFormEditOperator(userProfile: string){
    let controlsForm = this.controlsEditOperatorForm[userProfile] as ControlForm;
    if(typeof controlsForm == "undefined")
      return null;

    return controlsForm;
  }
}

