<!-- price table -->
<!-- google material -->
<head>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
</head>
<section id="plan" class="saas1 pricing" *ngIf="showPricePanel && selectedProduct.monthlyPrice" [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2-eg.png'}">
    <div class="container">
        <div class="col-md-12 text-center">
            <!-- Título y subtítulo -->
            <div class="title">
                <img src="assets/images/hms-tech/service-lines/title-logo-amarillo.png" alt="title-logo" class="img-fluid">
                <div class="main-title">
                    <h2 class="text-white">
                        {{selectedProduct.name}}
                        <!-- Icono de pregunta -->
                        <i class="material-icons question-icon" (click)="openModal()" (mouseover)="startTimer()" (mouseleave)="cancelTimer()">help_outline</i>
                    </h2>
                </div>
                
                <hr>
                <div class="sub-title">
                     <p class="p-padding text-white">{{selectedProduct.description| translate}}.</p>
                 </div>
            </div>

        </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center p-0">
        <div class="pricing ">
            <div class="pricing__switcher switcher">
                <div class="switcher__buttons">
                    <div class="switcher__button monthly" [class.switcher__button--enabled]="val" [class.switcher__button--selected]="val" (click)="monthly()">{{"Monthly" | translate}}</div>
                    <div class="switcher__button yearly" [class.switcher__button--enabled]="!val" [class.switcher__button--selected]="!val" (click)="monthly()">{{"Annual" | translate}}</div>
                    <div class="switcher__border"></div>
                </div>
            </div>            
        </div>
    </div>
    <div class="container">
        <div id="monthly" class="wrapper-full">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12" *ngFor="let data of price">
                    <div class="price-box card">
                        <h3 class="price-heading">{{data.heading| translate}}</h3>
                        <h4 class="detail-small">{{data.det1| translate}}</h4>
                        <img src="assets/images/saas1/plan-box.png" alt="pan-line">
                        <h4 class="detail-large">{{data.det2| translate}}</h4>
                        <!-- Dependiendo del estado del botón switcher, cambia el precio -->
                        <h4 class="no-weight"><span>{{val ? data.monthlyPrice.currency : data.annualPrice.currency}} {{val ? data.monthlyPrice.amount : data.annualPrice.amount}} x {{(val ? data.monthlyPrice.unit : data.annualPrice.unit) | translate}} {{(val ? data.det3 : data.det4)| translate}} <!-- Mostrar detalle según el modo --></span></h4>
                        
                        <!-- Mostrar solo las características definidas -->
                        <ul class="features-list">
                            <li *ngIf="data.features1"><span class="">{{data.features1| translate}}</span></li>
                            <li *ngIf="data.features2"><span class="feature-item">{{data.features2| translate}}</span></li>
                            <li *ngIf="data.features3"><span class="feature-item">{{data.features3| translate}}</span></li>
                            <li *ngIf="data.features4"><span class="feature-item">{{data.features4| translate}}</span></li>
                            <li *ngIf="data.features5"><span class="feature-item">{{data.features5| translate}}</span></li>
                        </ul>                                                 
                        <a class="btn btn-default btn-white" (click)="startNow(data)">{{data.btn| translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>
<!-- end price table -->

