<!-- Sección de productos -->
<section class="portfolio-creative portfolio-section p-7" id="products">
    <div class="container-fluid">
        <!-- Sección de producto: Security Framework -->
        <div id="security-framework" class="row">
            <!-- Contenido de la sección de producto: Security Framework -->
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/SECURITY-FRAMEWORK.webp" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">{{"Security Framework" | translate}}</h2>
                    <p class="head-sub-text">
                        {{"For organizations characterized with a diverse technological ecosystem, provides services and artifacts to implement single sign-on, biometrics, multiple factors and interoperability." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'Security Framework' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
        </div>
        
        <!-- Sección de producto: Kirio -->
        <div id="kirio" class="row">
            <!-- Contenido de la sección de producto: Kirio -->
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">Kirio</h2>
                    <p class="head-sub-text">
                        {{"Kirio is an innovative app for Android and iOS devices that simplifies how organizations engage with their communities of customers, suppliers, employees, and others. It provides an integrated off-the-shelf solution that allows them to manage user identities, establish communications, and improve process transactionality, while safeguarding security and reducing potential fraud." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'Kirio' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/KIRIO.webp" alt="" class="img-fluid">
            </div>
        </div>

        <!-- Sección de producto: SSAPI -->
        <div id="ssapi" class="row">
            <!-- Contenido de la sección de producto: SSAPI -->
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/SSAPI.webp" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">SSAPI</h2>
                    <p class="head-sub-text">
                        {{"WEB-API to access to health core functions → hms health." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'SSAPI' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
        </div>

        <!-- Sección de producto: Digital Credentials -->
        <div id="digital-credentials" class="row">
            <!-- Contenido de la sección de producto: Digital Credentials -->
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">{{"Digital Credentials" | translate}}</h2>
                    <p class="head-sub-text">
                        {{"A spinoff of Kirio that is integrated into the HMS “SaludSoft” transactional core and interacts with the benefit authorization Apps." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'Digital Credentials' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/CREDENCIALES-DIGITALES.webp" alt="" class="img-fluid">
            </div>
        </div>
        
        <!-- Sección de producto: HMS ABC Book -->
        <div id="hms-abc-book" class="row">
            <!-- Contenido de la sección de producto: HMS ABC Book -->
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/ABC-BOOK.webp" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">HMS ABC Book</h2>
                    <p class="head-sub-text">
                        {{"Online service for financiers and health providers to display their staff classified and ordered with different search criteria, specialty, geolocation, etc." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'HMS ABC Book' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
        </div>

        <!-- Sección de producto: HMS DAM -->
        <div id="hms-dam" class="row">
            <!-- Contenido de la sección de producto: HMS DAM -->
            
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">HMS DAM</h2>
                    <p class="head-sub-text">
                        {{"(Digital Asset Management). Organizations that handle a variety of documents and images access to this system to be managed and communicated systematically." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'HMS DAM' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/HMS-DAM.webp" alt="" class="img-fluid">
            </div>
        </div>
        
        <!-- Sección de producto: HMS Geo-Tags -->
        <div id="hms-geo-tags" class="row">
            <!-- Contenido de la sección de producto: HMS Geo-Tags -->
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/GEO-TAGS.webp" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">{{"HMS Geo-Tags" | translate}}</h2>
                    <p class="head-sub-text">
                        {{"Constitutes a database of georeferenced addresses usable for productive purposes." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'HMS Geo-Tags' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
        </div>

        <!-- Sección de producto: HMS Web / Mobile Authorization Apps -->
        <div id="hms-web" class="row">
            <!-- Contenido de la sección de producto: HMS Web / Mobile Authorization Apps -->
            
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">{{"HMS Web Mobile Authorization" | translate}}</h2>
                    <p class="head-sub-text">
                        {{"Intended for Health Providers, aimed at medical and related providers, makes it possible." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'HMS Web Mobile Authorization' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/WEB-AUTORIZACIONES.webp" alt="" class="img-fluid">
            </div>
        </div>

        <!-- Sección de producto: HMS Contact Center -->
        <div id="contact-center" class="row">
            <!-- Contenido de la sección de producto: HMS Contact Center -->
            <div class="col-md-6 p-0 isotopeSelector">
                <img src="assets/images/hms-tech/products/CONTACT-CENTER.webp" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">HMS Contact Center</h2>
                    <p class="head-sub-text">
                        {{"A solution that assists call center operators to facilitate the user management." | translate}}
                    </p>
                    <a [routerLink]="['/service-lines']" [queryParams]="{ product: 'HMS Contact Center' }" fragment="saas-solutions" class="btn btn-default">{{"View More" | translate }}</a>
                </div>
            </div>
        </div>
        
    </div>
</section>
<!-- Fin Sección de productos  -->
