import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kirio-colescba',
  templateUrl: './kirio-colescba.component.html',
  styleUrls: ['./kirio-colescba.component.scss']
})
export class KirioColescbaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
