<!-- technologies section-->
<section id="technologies" class="saas1 service">
    <div class="about-chat">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="title">
                        <img src="assets/images/hms-tech/service-lines/title-logo-amarillo.png" alt="title-logo" class="img-fluid">
                        <div class="main-title">
                            <h2>
                                <span class="theme-color">T</span>{{"echnologies"| translate}}
                            </h2>
                        </div>
                
                        <hr>
                        <div class="sub-title d-flex flex-column align-items-center">
                            <p class="p-padding text-center">{{"HMS Tech offers the total or partial development of comprehensive customized solutions, their subsystems and components, at the service of interested organizations, with a strong focus on their business processing needs. With its expertise, it manages to cover a wide spectrum of technological platforms in heterogeneous environments in integration with legacy systems and solutions located technologically at the cutting edge."| translate}}.</p>
                            <div class="img-with-filter">
                              <div class="img-container">
                                <img src="assets/images/hms-tech/icon/technologies/technologies-v2.png" alt="technologies icon" class="img-fluid mw-100 mw-lg-75 mw-md-50">
                              </div>
                            </div>
                          </div>
                    </div>
                </div>

<!-- División en 4 columnas -->
<div class="col-lg-3 col-md-6 service-container" *ngFor="let data of features">
    <div class="chat-box">
        <div class="feature-content">
            <h4 class="service-heading"> 
                {{data.title| translate}} 
                <span class="theme-color">{{data.subTitle| translate}}</span>
            </h4>
            <hr>
            <p [ngClass]="{ 'collapsed': data.isCollapsed }">
                {{data.description| translate}}
            </p>
            <button (click)="toggleCollapse(data)">
                {{ data.isCollapsed ? 'Leer más.' : 'Leer menos.' }}
            </button>
        </div>
    </div>
</div>
<!-- Fin de las 4 columnas -->


                
            </div>
        </div>
    </div>
</section>
<!-- end technologies section -->
