import { Component, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { InfoModal } from '../../model/info.modal';

@Component({
    selector: 'app-modal-register',
    templateUrl: './modal-register.component.html',
    styleUrls: ['./modal-register.component.scss']
})
export class ModalRegisterComponent {

    @ViewChild('myModalRegister') myModal: any;
    private modalRef: any;

    @Input() data?: InfoModal;

    constructor(
        config: NgbModalConfig,
        private modalService: NgbModal    
      ) {
        // customize default values of modals used by this component tree
        config.backdrop = 'static';
        config.keyboard = false;
        config.centered = true;
    }
    
    open(){
        this.modalRef = this.modalService.open(this.myModal);
    }
}
