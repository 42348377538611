import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  isLoading: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private loadingService:LoadingService
  ) { }

  // para evitar errores de control de cambios de variables utilizadas para actualizar la vista
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    console.log("Loading creado!!!!!"); 
    this.loadingService.isLoading$.subscribe(value => {
      this.isLoading = value;
    })
  }

}
