import { Agent } from "./agent";

export class PhoneSSO{
    value?: string;
    agent?: Agent;
    label?: string;
    trusted?: boolean;
    verified?: boolean;
    country_code?: string;

    constructor(data?: Partial<PhoneSSO>) {
        Object.assign(this, data);
    }

    public equals(phone: PhoneSSO): boolean{
        if(!phone)
            return false;
        return ((this.value == phone.value) && (this.country_code == phone.country_code));
    }
}
