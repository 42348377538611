<!--service css start-->
<section class="saas2 services" id="careers">
    <div class="container saas2-services">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="title">
            <div class="main-title">
              <h2>{{'Careers' | translate }}</h2>
            </div>
            <div class="sub-title">
              <p class="sub-title-para">
                {{'We are always looking for talented people to join our team.' | translate }}
                <a routerLink="/home/careers2" class="learn-more-link">{{ 'To postulate' | translate }}</a>
              </p>
              <p class="total-positions">{{'Open positions' | translate}}: {{ totalOpenPositions }}</p>
            </div>
          </div>
        </div>
  
        <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of services">
            <div class="service" [routerLink]="['/home/careers2', { category: data.title}]" style="cursor: pointer;">
            <div class="">
              <img [src]="data.img" alt="" class="service-img img-fluid">
            </div>
            <div class="service-feature career-category text-center">
              <h4 class="feature-text">{{data.title | translate }}</h4>
              <ul>
                <p>
                <li *ngFor="let item of data.description">{{ item | translate }}</li>
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--service css end-->
  