import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  // Devuelve los segundos de diferencias entre 2 fechas (segundo con decimales), null si alguno de los datos es invalido
  public compareDatesInSeconds(date1: Date|null, date2: Date){
    if(!date1 || !date2)
      return null;
    let difDate = date1.getTime() - date2.getTime();
    let difSeconds = difDate / 1000;

    return Math.abs(difSeconds);
  }

  // Compara 2 Dates o number, devuelve >0 si date1 es mayor, <0 si date es menor, =0 si son iguales
  public compare(date1: Date|number, date2: Date|number){
    let date1Numb, date2Numb;
    (typeof date1 == "number")? date1Numb = date1: date1Numb = date1.getTime();
    (typeof date2 == "number")? date2Numb = date2: date2Numb = date2.getTime();
    
    let difDate = date1Numb - date2Numb;
    // devolvemos la dif en segundos
    return (difDate / 1000);
  }

  // Devuelve un string a partir de una fecha dada, con el formato DD-MM-YYYY
  public getStringDate(date: Date){
    let dateString = "";
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if(month < 10){
      dateString = `${day}-0${month}-${year}`;
    }else{
      dateString = `${day}-${month}-${year}`;
    }

    return dateString;
  }

  // recibe un tiempo con formato del tipo "2 m", "120 s" y devuelve la cantidad de segundos total
  public getSecondsTotal(time:string){
    let arrayTime = time.split(" ");
    if(arrayTime.length != 2){
      console.warn("[Time.serv] => El dato recibido no cumple el formato esperado.");
      return;
    }
    let digit: number = +arrayTime[0];      // parseo a number
    let denominator = arrayTime[1];
    if(denominator == "s"){
      return (digit*1);
    }
    if(denominator == "m"){
      return (digit*60);
    }
    console.warn("[Time.serv] => Revisar el formato del dato recibido.");
    return null;
  }
}
