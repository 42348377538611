export enum Profiles {
    ROOT = "ADMIN",
    AFFILIATE = "AFFILIATE",
    OPERATOR_TEMP = "OPERADOR DE TEMPLATE WEB",
    OPERATOR_CC = "OPERADOR DE CONTACT CENTER",
    OPERATOR_PE = "OPERADOR DE PEOPLE EXPLORER",
    OPERATOR_WP = "OPERADOR DE WEB PRESTADORES",
    OPERATOR_PC = "OPERADOR DE PORTAL CARTILLA",
    OPERATOR_TAGS = "OPERADOR ADMIN TAGS",
    OPERATOR_LOGS = "OPERADOR MONITOR LOGS"
}

export enum ProfilesSSO {
  ROOT_SSO = "ADMIN",
  AFFILIATE_SSO = "AFFILIATE",
  OPERATOR_TEMP_SSO = "OPERATOR_TW",
  OPERATOR_CC_SSO = "OPERATOR_CC",
  OPERATOR_PE_SSO = "OPERATOR_PE",
  OPERATOR_WP_SSO = "OPERATOR_WP",
  OPERATOR_PC_SSO = "OPERATOR_PC",
  OPERATOR_TAGS_SSO = "OPERATOR_GT"
}

export const ProfilesLoginByApp = [
  {
    app: "web_template",
    profiles: [Profiles.OPERATOR_TEMP]
  },
  {
    app: "contact_center",
    profiles: [Profiles.OPERATOR_CC]
  },
  {
    app: "web_prestadores",
    profiles: [Profiles.OPERATOR_WP]
  },
  {
    app: "people_explorer",
    profiles: [Profiles.OPERATOR_PE]
  },
  {
    app: "portal_cartilla",
    profiles: [Profiles.OPERATOR_PC]
  },
  {
    app: "manager_tags",
    profiles: [Profiles.OPERATOR_TAGS]
  },
  {
    app: "monitor_logs",
    profiles: [Profiles.OPERATOR_LOGS]
  }
]

export const ProfilesLoginByAppSSO = [
  {
    app: "web_template",
    profiles: [ProfilesSSO.OPERATOR_TEMP_SSO]
  },
  {
    app: "contact_center",
    profiles: [ProfilesSSO.OPERATOR_CC_SSO]
  },
  {
    app: "web_prestadores",
    profiles: [ProfilesSSO.OPERATOR_WP_SSO]
  },
  {
    app: "people_explorer",
    profiles: [ProfilesSSO.OPERATOR_PE_SSO]
  },
  {
    app: "portal_cartilla",
    profiles: [ProfilesSSO.OPERATOR_PC_SSO]
  },
  {
    app: "manager_tags",
    profiles: [ProfilesSSO.OPERATOR_TAGS_SSO]
  }
]