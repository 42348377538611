import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HmsTechComponent } from './layouts/hms-tech/hms-tech.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HmsTechComponent,
    data: {
      breadcrumb: "Home",
      title: "Home | HMS Group"
    },

  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
