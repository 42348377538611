<section class="coming-soon coming-soon-2 p-0" [ngStyle]="{'background-image': 'url(assets/images/inner-page/maintain-soon/friend.jpg)'}">
    <div class="container">
        <div class="row text-center">
            <div class="col-md-12 p-0">
                <div class="center-container">
                    <div class="set-bg">
                        <div>
                            <img src="assets/images/logo/6.png" alt="" class="coming-logo">
                        </div>
                        <h2 class="text-white our-web-text">Website Is Almost Ready</h2>
                        <h6 class="launch-text"> Time Left Untill Launching </h6>
                        <div class="set-count">
                            <div class="counters-content">
                                <div class="count">
                                    <div class="counter-container">
                                        <h2 class="text-white">
                                            <span id="days" class="days-text">213</span><span
                                                class="days gradient-text">days</span>
                                        </h2>
                                    </div>
                                    <div class="set-border-left"></div>
                                    <div class="set-border-bottom"></div>
                                </div>
                            </div>
                            <div class="counters-content">
                                <div class="count">
                                    <div class="counter-container">
                                        <h2 class=" text-white">
                                            <span id="hours" class="days-text gradient-text">8</span><span
                                                class="days gradient-text">hours
                                            </span>
                                        </h2>
                                    </div>
                                    <div class="set-border-left"></div>
                                    <div class="set-border-bottom"></div>
                                </div>
                            </div>
                            <div class="counters-content">
                                <div class="count">
                                    <div class="counter-container">
                                        <h2 class="text-white">
                                            <span id="minutes" class="days-text gradient-text">31</span><span
                                                class="days gradient-text"> minutes</span>
                                        </h2>
                                    </div>
                                    <div class="set-border-left"></div>
                                    <div class="set-border-bottom"></div>
                                </div>
                            </div>
                            <div class="counters-content">
                                <div class="count no-border">
                                    <div class="counter-container">
                                        <h2 class=" text-white">
                                            <span id="seconds" class="days-text gradient-text">44</span><span
                                                class="days gradient-text">seconds</span>
                                        </h2>
                                    </div>
                                    <div class="set-border-left"></div>
                                    <div class="set-border-bottom"></div>
                                </div>
                            </div>
                        </div>
                        <h6 class="launch-text2">notify me when it's ready</h6>
                        <div>
                            <div class="center-content">
                                <div class="form-group">
                                    <div class="d-flex">
                                        <input type="email" class="form-control radius-0"
                                               placeholder="Email Address" name="email">
                                        <div class="button-primary">
                                            <button type="submit"
                                                    class=" btn btn-default primary-btn radius-0 no-border">
                                                subscribe
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="social m-t-20">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-instagram center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
