import { Injectable } from '@angular/core';
import { Coordinate } from '../../model/coordinate';

export class LocationService {

  supportGeolocation: boolean = false;
  readonly NOT_SUPPORT_GEOLOCATION = "El navegador no soporta el sistema de geolocalizacion";

  constructor() {
    if (navigator.geolocation){
      this.supportGeolocation = true;
    }
    else{
      console.warn("[Location.serv] =>  ERROR: ", this.NOT_SUPPORT_GEOLOCATION);
      this.supportGeolocation = false;
    }
  }

  public async getCoordsLocation(){
    if(!this.supportGeolocation){
      console.warn("[Location.serv] =>  ERROR: ", this.NOT_SUPPORT_GEOLOCATION);
      return new Coordinate();
    }
    let pos: any = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    return new Coordinate({latitude: pos.coords.latitude, longitude: pos.coords.longitude});
  };
}
