import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as emailjs from 'emailjs-com';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-candidate-form',
  templateUrl: './candidate-form.component.html',
  styleUrls: ['./candidate-form.component.scss']
})
export class CandidateFormComponent implements AfterViewInit {
  @ViewChild('candidateForm', { static: true }) candidateForm: ElementRef<HTMLFormElement>;

  form: FormGroup;
  country: string;
  position: string;
  uploadedFileUrl: string; // Variable para almacenar la URL de la imagen cargada
  isLoading = false; // Variable para controlar el estado de carga

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private http: HttpClient, private router: Router) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      position: [{ value: '', disabled: true }, Validators.required],
      country: [{ value: '', disabled: true }, Validators.required],
      linkedin: ['', Validators.required],
      cvFile: [null, Validators.required]
    });
  }

  ngAfterViewInit(): void {
    document.getElementById('cv').addEventListener('change', (e) => {
      const file = (e.target as HTMLInputElement).files[0];
      const fileName = file ? file.name : '';
      (document.querySelector('.custom-file-label') as HTMLLabelElement).innerText = fileName;
      
      if (file) {
        this.form.patchValue({
          cvFile: file
        });
      }
    });

    this.route.params.subscribe(params => {
      this.country = params['country'];
      this.position = params['position'];
      this.autocompleteForm();
    });

    emailjs.init('nRY-qhu8i2ICNo9hk');
  }

  async submitForm() {
    this.isLoading = true; // Inicia el estado de carga
    try {
      if (this.form.value.cvFile) {
        await this.onUploadSuccess(this.form.value.cvFile);
      }
  
      const response = await emailjs.send('Candidate-form', 'template_fyfgz9j', {
        form_name: this.form.value.name,
        form_phone: this.form.value.phone,
        form_position: this.position,
        form_country: this.country,
        form_linkedin: this.form.value.linkedin,
        form_cv: this.uploadedFileUrl, // Utiliza la URL de la imagen cargada
      });
  
      console.log('Formulario enviado correctamente:', response);
      Swal.fire({
        title: '¡Form Submitted Successfully!',
        text: 'Your form has been submitted successfully.',
        icon: 'success',
        confirmButtonText: 'Accept',
        didRender: () => {
            const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
            confirmButton.style.backgroundColor = '#43DBCF';
            confirmButton.style.border = '#43DBCF';
            confirmButton.style.color = '#fff';
            confirmButton.style.borderBottomColor = '#43DBCF';

          }
      }).then(() => {
        this.router.navigate(['/home']); // Redirigir a la página de inicio después de la confirmación
      });
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while submitting the form. Please try again later.',
        icon: 'error',
        confirmButtonText: 'Accept',
        didRender: () => {
            const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
            confirmButton.style.backgroundColor = '#FF0000';
            confirmButton.style.borderColor = '#FF0000';
            confirmButton.style.color = '#fff';

          }
      });
    } finally {
      this.isLoading = false; // Finaliza el estado de carga
    }
  }
  

  async onUploadSuccess(file: File) {
    console.log('Entrando en onUploadSuccess');
  
    if (!file) {
      console.error('Archivo no válido');
      return;
    }

    console.log('Archivo recibido:', file);

    const apiKey = 'cc5bf0aae1ad614a9262b5cdbc86895a'; // Reemplaza esto con tu API key de ImgBB
    const data = new FormData();
    data.append('image', file);
    data.append('key', apiKey);

    try {
      const response: any = await this.http.post('https://api.imgbb.com/1/upload', data).toPromise();
      
      if (response && response.data && response.data.url) {
        // Almacenar la URL de la imagen cargada
        this.uploadedFileUrl = response.data.url;

        // Actualizar el valor del campo cvFile en el formulario
        this.form.patchValue({
          cvFile: this.uploadedFileUrl
        });

        console.log('Archivo subido correctamente:', this.uploadedFileUrl);
      } else {
        console.error('Respuesta de carga no válida:', response);
      }
    } catch (error) {
      console.error('Error al subir el archivo:', error);
    }
  }

  autocompleteForm() {
    this.form.patchValue({
      country: this.country,
      position: this.position
    });
  }
}
