<!--Nav start-->
<header class="saas2 loding-header nav-abs custom-scroll">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <nav>
          <a href="#" class="d-inline-block m-r-auto">
            <img src="assets/images/logo/HMS_horizontal_submarca_tech.png" alt="" class="img-fluid custom-logo">
          </a>
          <app-menu></app-menu>
          <a class="language-toggle-container" > <app-language-toggle></app-language-toggle></a> 
          <a class="login-icon" >
            <a class="login-icon" (click)="openLoginModal()" *ngIf="!isLoggedIn">
              <span class="material-symbols-outlined">
                account_circle 
              </span>
              <span class="text-login">{{"Login" | translate}}</span>
          </a>
          </a>
          <div *ngIf="isLoggedIn" class="app-menu-container">
            <app-app-menu></app-app-menu>
          </div>
          <!--Menu usuario logueado START-->
          <div *ngIf="isLoggedIn" class="login-menu-container" >
            <a class="login-button" (click)="toggleLoginMenu()">
              <div class="login-icon">
                 <span class="material-symbols-outlined">account_circle</span>
                 <span class="text-login p-info-user">{{ loggedName | translate}}</span>
              </div>
           </a>

            <ul *ngIf="showLoginMenu" class="login-options" [class.light-mode]="layoutType==='light'"> 
              <li> <a  routerLink="/personal-info"> <span><i>&#x2139;</i> {{ "Información personal" | translate }} </span>
              </a></li>  
              
              <!--<li><a routerLink="/settings">{{ "Dark / Light" | translate }}</a></li> -->
 
             <!-- dark/light (ocultado)  <li class="sidebar-btn dark-light-btn" *ngIf="customize.data.settings.layout_version == 'dark'" (click)="customizeLayoutVersion('light')">
                        <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version"><i>&#9728;</i> Dark / Light</span></a>
                    </li>
                    <li class="sidebar-btn dark-light-btn"  *ngIf="customize.data.settings.layout_version == 'light'" (click)="customizeLayoutVersion('dark')">
                        <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version"><i>&#9790;</i> Dark / Light</span></a>
                    </li>
               -->           
              <!--<li><a routerLink="/settings">{{ "settings" | translate }}</a></li> -->
             <!-- <li><a (click)="openLogoutConfirmationDialog()">{{ "Sing out" | translate }} </a></li>-->
               <li><a (click)="openLogoutConfirmationDialog()" *ngIf="isLoggedIn" [class.light-mode]="layoutType==='light'"> 
                <span class="text-login"><i>&#8594;</i>{{"Logout" | translate}}</span>
              </a></li>
  
            </ul>
          </div>
          <!--Menu usuario logueado END-->

        </nav>
      </div>
    </div>
  </div>
</header>

<!--Nav end-->
