<ng-template #myModalRegister let-c="close" let-d="dismiss">
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title">Registro de usuarios</h4> -->
    <h4 class="modal-title" id="modal-basic-title">{{data.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <p>Para registrarse descarguése la app Kirio de su tienda de aplicaciones</p> -->
    <p>{{data.body}}</p>
    <div class="row">
      <div class="col-6" style="text-align: end; padding-right: 1.5%; display: grid;">
        <div style="text-align: center;">
          <small>Android</small>
        </div>
        <div style="text-align: center; cursor: pointer;">
          <img src="assets/images/sso/playAndroid.png" style="width: 10vw;" alt="">
        </div>
      </div>
      <div class="col-6" style="text-align: start; padding-left: 1.5%; display: grid;">
        <div style="text-align: center;">
          <small>IOS</small>
        </div>
        <div style="text-align: center; cursor: pointer;">
          <img src="assets/images/sso/playIOS.png" style="width: 10vw;" alt="">
        </div>
      </div>
    </div>
  </div>
</ng-template>