<div class="modal-backdrop">
  <div class="modal-container">
    <div class="modal-header">
      <h2>{{"Support Confirmation" | translate }}</h2>
      <button class="close-button" (click)="canceled.emit()">×</button>
    </div>
    <div class="modal-body">
      <p *ngIf="isWithinWorkingHours(); else outsideHours">
        {{ "You will be redirected to WhatsApp. Do you want to continue?" | translate  }}
      </p>
      <ng-template #outsideHours>
        <p>
          {{"The attention is from 9 AM to 5 PM. Currently, we are not available. You can" | translate}} <a href="https://hms-tech.odoo.com/help" target="_blank">{{"enter a support ticket here" | translate}}</a>.
        </p>
      </ng-template>
      <button class="confirm-button" 
              [disabled]="!isWithinWorkingHours()" 
              (click)="confirmed.emit()">
        {{"Confirm" | translate}}
      </button>
      <button class="cancel-button" (click)="canceled.emit()">
        {{"Cancel" | translate}}
      </button>
    </div>
  </div>
</div>
