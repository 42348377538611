<div id="sel-autsearch_{{id}}">
    <div class="input-group mb-2">
        <input *ngIf="!enable" disabled type="text" class="form-control form-control-sm no-warning"
            [(ngModel)]="valueInput">
        <input *ngIf="enable" type="text" class="form-control form-control-sm" placeholder="{{placeholder}}" (ngModelChange)="search($event)" (keyup)="setOption($event)"
            [(ngModel)]="valueInput" [ngClass]="required ? 'warning': 'no-warning'" (focus)="onFocus()" (blur)="onBlur()">
        <div *ngIf="enable" class="div-arrow">
            <img *ngIf="!viewListOptions" src="assets/icon/arrow_down_48.svg" title="Ver opciones" class="w3-hide" style="width:100%;" class="w3-hover-opacity" alt="" (click)="viewAllOptions()">
            <img *ngIf="viewListOptions" src="assets/icon/arrow_up_48.svg" title="Ocultar opciones" class="w3-hide" style="width:100%;" class="w3-hover-opacity" alt="" (click)="viewAllOptions()">
        </div>
    </div>
    <div *ngIf="viewListOptions">
        <ul *ngIf="!atrValue" class="ul-custom">
            <li *ngFor="let item of items" (click)="setValue(item)">
                {{item.value}}
            </li>
        </ul>
        <ul *ngIf="atrValue" class="ul-custom">
            <li *ngFor="let item of items" (click)="setValue(item)">
                {{item[atrValue]}}
            </li>
        </ul>
    </div>
</div>