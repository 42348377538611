import { Domain } from "../../model/domain/domain";

export const domains: Domain [] = [
    { 
        code: "hms",
        name: "HMS",
        icon: "assets/images/domains/hms.svg",
        description: "Ambiente de desarrollo y test Osiris.",
        codeMenu: "HMS Osiris",
        codeDomain: "hms",
        environment: "test"
    },
    { 
        code: "hms",
        name: "HMS",
        icon: "assets/images/domains/hms.svg",
        description: "Ambiente de desarrollo y test Osiris.",
        codeMenu: "HMS Osiris",
        codeDomain: "hms",
        environment: "prod"
    },
    { 
        code: "austral",
        name: "Austral (test)",
        icon: "assets/images/domains/austral.png",
        description:"Ambientes de test de Austral.",
        codeMenu: "AUSTRAL (Test)",
        codeDomain: "austral"
    },
    { 
        code: "austral-prod",
        name: "Austral (prod)",
        icon: "assets/images/domains/austral.png",
        description:"Ambientes de produccion de Austral.",
        codeMenu: "AUSTRAL",
        codeDomain: "austral"
    },
    { 
        code: "up",
        name: "Union Personal",
        icon: "assets/images/domains/up.png",
        description:"Ambiente de test de UP.",
        codeMenu: "UP (Test)",
        codeDomain: "up"
    },
    { 
        code: "osde",
        name: "OSDE",
        icon: "assets/images/domains/osde.png",
        description:"Ambiente de test de OSDE.",
        codeMenu: "OSDE (Demo)",
        codeDomain: "osde"
    },
    {
        code: "scis_test",
        name: "SCIS",
        icon: "assets/images/domains/scis.png",
        description:"Ambiente de test de SCIS.",
        codeMenu: "SCIS (Test)",
        /* codeMenu: "scis", */
        codeDomain: "scis",
        environment: "test",
        environtmentUrl: ""
    },
    {
        code: "scis-prod",
        name: "SCIS(prod)",
        icon: "assets/images/domains/scis.png",
        description:"Ambiente de producción de SCIS.",
        /* codeMenu: "SCIS", */
        codeDomain: "scis",
        environment: "prod",
        environtmentUrl: ""
    }
];