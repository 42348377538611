export var hmsIdentifiers: any[] = [
    { 
        code: "consulting_id",
        value: "Código de consultorio",
        /* icon: "assets/images/domains/hms.png", */
        regex: '[0-9]{3,15}',
        readOnly: false,
        selected: false
    },
    { 
        code: "provider_id",
        value: "Código de prestador",
        regex: '[0-9]{3,15}',
        /* icon: "assets/images/domains/hms.png", */
        readOnly: false,
        selected: false
    },
    { 
        code: "secretary",
        value: "Código de secretaria",
        regex: '[A-Za-z0-9]{5,10}',
        /* icon: "assets/images/domains/hms.png", */
        readOnly: false,
        selected: false
    },
    { 
        code: "affiliate_id",
        value: "Código de afiliado",
        regex: '[0-9]{3,20}',
        /* icon: "assets/images/domains/hms.png", */
        readOnly: false,
        selected: false
    },
    { 
        code: "cred_id",
        value: "Número de credencial",
        regex: '[0-9]{15,30}',
        /* icon: "assets/images/domains/hms.png", */
        readOnly: false,
        selected: false
    },
    { 
        code: "user_backoffice_id",
        value: "Usuario de backoffice",
        regex: '[0-9]{5}',
        /* icon: "assets/images/domains/hms.png", */
        readOnly: true,
        selected: false
    },
    { 
        code: "ctd_v1",
        value: "Ctd v1",
        regex: '[0-9]{4}',
        /* icon: "assets/images/domains/hms.png", */
        readOnly: true,
        selected: false
    },
    { 
        code: "ctd_v2",
        value: "Ctd v2",
        regex: '[A-Za-z0-9]{5}',
        /* icon: "assets/images/domains/hms.png", */
        readOnly: true,
        selected: false
    }
]