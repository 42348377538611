import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from './modal-price/modal-price.component';
import { ProductService } from '../../hms-tech/services/product.service';
import { TokenizerService } from 'src/app/sesion/services/tokenizer.service';
import { AdminContextSassService } from 'src/app/sesion/services/manager/admin-context-sass.service';
import { ContextSass } from 'src/app/sesion/model/context/contextSass';
import { ConfigToken } from 'src/app/sesion/assets/config/config-constant.example';

@Component({
  selector: 'app-enterprice-sass-price',
  templateUrl: './enterprice-sass-price.component.html',
  styleUrls: ['./enterprice-sass-price.component.scss']
})
export class EnterpriceSassPriceComponent implements OnInit {
  public selectedProduct: any; 
  public showPricePanel: boolean = false;

  public val:boolean=true;
  basePrice: any;
  timer: NodeJS.Timeout;
  constructor(
    private modalService: NgbModal,
    private productService: ProductService,
    private tokenizerService: TokenizerService,
    private adminContextSassService: AdminContextSassService,
  ) { }

  ngOnInit() {
    this.productService.selectedProduct.subscribe((product: any) => {
        this.selectedProduct = product;
        this.showPricePanel = !!product; // Muestra el panel de precios solo si se selecciona un producto
        if (this.selectedProduct) {
            this.updatePrices();
        }
    });

    this.productService.basePrice.subscribe((price: any) => {
        this.basePrice = price; // Establece el precio base del producto
    });
}

updatePrices() {
  if (!this.selectedProduct) return;

  // Actualizar los precios de cada plan
  this.price.forEach(item => {
    item.monthlyPrice.unit = this.selectedProduct.unit; // Unidad de precio mensual directamente del producto
    item.annualPrice.unit = this.selectedProduct.unit; // Unidad de precio anual directamente del producto
    switch (item.heading) {
      case 'Tiny':
        item.monthlyPrice.amount = this.selectedProduct.monthlyPrice.tiny; // Precio mensual directamente del producto
        item.annualPrice.amount = this.selectedProduct.annualPrice.tiny; // Precio anual directamente del producto
        // Actualizar features según el producto seleccionado
        if (this.selectedProduct.name === 'SSAPI') {
          item.features2 = item.ssapiFeatures[0];
          item.features3 = item.ssapiFeatures[1];
          item.features4 = item.ssapiFeatures[2];
          item.features5 = item.ssapiFeatures[3];
        } else if (this.selectedProduct.name === 'Kirio') {
          item.features2 = item.kirioFeatures[0];
          item.features3 = item.kirioFeatures[1];
          item.features4 = item.kirioFeatures[2];
          item.features5 = item.kirioFeatures[3];
        } else if (this.selectedProduct.name === 'HMS ABC Book') {
          item.features2 = item.hmsAbcBookFeatures[0];
          item.features3 = item.hmsAbcBookFeatures[1];
          item.features4 = item.hmsAbcBookFeatures[2];
          item.features5 = item.hmsAbcBookFeatures[3];
  
        } else if (this.selectedProduct.name === 'Digital Credentials') {
          item.monthlyPrice.unit = 'Affiliate' // Unidad de precio cambiada por "Afiliado"
          item.annualPrice.unit = 'Affiliate' // Unidad de precio cambiada por "Afiliado"
          item.features2 = item.digitalCredentialsFeatures[0];
          item.features3 = item.digitalCredentialsFeatures[1];
          item.features4 = item.digitalCredentialsFeatures[2];
          item.features5 = item.digitalCredentialsFeatures[3];
        }
        break;
      case 'Small':
        item.monthlyPrice.amount = this.selectedProduct.monthlyPrice.small // Precio mensual directamente del producto
        item.annualPrice.amount = this.selectedProduct.annualPrice.small; // Precio anual directamente del producto
        // Actualizar features según el producto seleccionado
        if (this.selectedProduct.name === 'SSAPI') {
          item.features2 = item.ssapiFeatures[0];
          item.features3 = item.ssapiFeatures[1];
          item.features4 = item.ssapiFeatures[2];
          item.features5 = item.ssapiFeatures[3];
        } else if (this.selectedProduct.name === 'Kirio') {
          item.features2 = item.kirioFeatures[0];
          item.features3 = item.kirioFeatures[1];
          item.features4 = item.kirioFeatures[2];
          item.features5 = item.kirioFeatures[3];
        } else if (this.selectedProduct.name === 'HMS ABC Book') {
          item.features2 = item.hmsAbcBookFeatures[0];
          item.features3 = item.hmsAbcBookFeatures[1];
        } else if (this.selectedProduct.name === 'Digital Credentials') {
          item.features2 = item.digitalCredentialsFeatures[0];
          item.features3 = item.digitalCredentialsFeatures[1];
          item.features4 = item.digitalCredentialsFeatures[2];
          item.features5 = item.digitalCredentialsFeatures[3];
        }
        break;
      case 'Medium':
        item.monthlyPrice.amount = this.selectedProduct.monthlyPrice.medium; // Precio mensual directamente del producto
        item.annualPrice.amount = this.selectedProduct.annualPrice.medium; // Precio anual directamente del producto
        // Actualizar features según el producto seleccionado
        if (this.selectedProduct.name === 'SSAPI') {
          item.features2 = item.ssapiFeatures[0];
          item.features3 = item.ssapiFeatures[1];
          item.features4 = item.ssapiFeatures[2];
          item.features5 = item.ssapiFeatures[3];
        } else if (this.selectedProduct.name === 'Kirio') {
          item.features2 = item.kirioFeatures[0];
          item.features3 = item.kirioFeatures[1];
          item.features4 = item.kirioFeatures[2];
          item.features5 = item.kirioFeatures[3];
        } else if (this.selectedProduct.name === 'HMS ABC Book') {
          item.features2 = item.hmsAbcBookFeatures[0];
          item.features3 = item.hmsAbcBookFeatures[1];
        } else if (this.selectedProduct.name === 'Digital Credentials') {
          item.features2 = item.digitalCredentialsFeatures[0];
          item.features3 = item.digitalCredentialsFeatures[1];
          item.features4 = item.digitalCredentialsFeatures[2];
          item.features5 = item.digitalCredentialsFeatures[3];
        }
        break;
      case 'Large':
        // No es necesario ya que large no tiene precio. Se establece como "TBD" (por determinar)
        //item.monthlyPrice.amount = this.selectedProduct.monthlyPrice.amount * 0.6; // Factor de multiplicación para Large
        //item.annualPrice.amount = this.selectedProduct.annualPrice.amount * 0.6; // Factor de multiplicación para Large
        // Actualizar features según el producto seleccionado
        // Actualizar features según el producto seleccionado
        if (this.selectedProduct.name === 'SSAPI') {
          item.features2 = item.ssapiFeatures[0];
          item.features3 = item.ssapiFeatures[1];
          item.features4 = item.ssapiFeatures[2];
          item.features5 = item.ssapiFeatures[3];
        } else if (this.selectedProduct.name === 'Kirio') {
          item.features2 = item.kirioFeatures[0];
          item.features3 = item.kirioFeatures[1];
          item.features4 = item.kirioFeatures[2];
          item.features5 = item.kirioFeatures[3];
        } else if (this.selectedProduct.name === 'HMS ABC Book') {
          item.features2 = item.hmsAbcBookFeatures[0];
          item.features3 = item.hmsAbcBookFeatures[1];
        } else if (this.selectedProduct.name === 'Digital Credentials') {
          item.features2 = item.digitalCredentialsFeatures[0];
          item.features3 = item.digitalCredentialsFeatures[1];
          item.features4 = item.digitalCredentialsFeatures[2];
          item.features5 = item.digitalCredentialsFeatures[3];
        }
        break;
      default:
        break;
    }
  });
}

// Funcion para abrir el modal de ayuda al usuario cuando el cursor se mantiene sobre el icono de pregunta
 startTimer() {
   this.timer = setTimeout(() => {
       this.openModal();
   }, 2000); // 2000 ms = 2 segundos
 }

 cancelTimer() {
   clearTimeout(this.timer);
 }
  
  openModal() {
    const modalRef = this.modalService.open(ModalContentComponent, { centered: true });
    // Puedes pasar datos al modal utilizando el método 'componentInstance'
    modalRef.componentInstance.name = 'World';
  }

closeModal() {
    // Cierra el modal cuando el cursor se retira del icono de pregunta
    this.modalService.dismissAll();
}

startNow(plan: any) {
  // Obtener la fecha de fin
  const endDate = this.calculateEndDate();
  // Obtener el contexto
  const newContext: ContextSass = this.adminContextSassService.getContext();
  // Declarar la variable de la URL
  let url = 'https://osiris.hms-tech.com:10225/';
  // Generar el token con el contexto
  this.tokenizerService.getTokenV2({context: newContext}, ConfigToken.DURATION_LOGIN_DEFAULT, Number.parseInt(ConfigToken.LENGTH_LOGIN_DEFAULT), ConfigToken.TYPE_LOGIN_DEFAULT, 50).subscribe(
      (resp: any) => {
          // Construir los query parameters con el token
          const queryParams = {
              api: this.selectedProduct.name,
              time_base: plan.heading,
              mode: this.val ? 'mensual' : 'anual',
              currency: this.val ? plan.monthlyPrice.currency : plan.annualPrice.currency,
              amount: this.val ? plan.monthlyPrice.amount : plan.annualPrice.amount,
              qtyxunit: this.val ? plan.monthlyPrice.qtyxunit : plan.annualPrice.qtyxunit,
              unit: this.val ? plan.monthlyPrice.unit : plan.annualPrice.unit,
              qty: plan.qty,
              from: new Date().toISOString(), // Convertir la fecha de inicio a un formato de cadena ISO
              to: endDate.toISOString(), // Convertir la fecha de fin a un formato de cadena ISO
              token_context: resp.short_token // Agregar el token a los query parameters
          };
          // Agregar los query parameters a la URL
          url += '?' + Object.keys(queryParams).map(key => key + '=' + encodeURIComponent(queryParams[key])).join('&');
           // Redirigir al usuario a la otra aplicación
           console.log("[Enterprice-saas-price.comp] => Intentamos abrir con contexto: ", url); 
           setTimeout(function (){
            window.open(url, '_blank').focus();
        }, 350);
      },
      (err: any) => {
          console.warn("[startNow] => No se pudo actualizar el contexto!");
          // Si hay un error, construir los query parameters sin el token
          const queryParams = {
              api: this.selectedProduct.name,
              time_base: plan.heading,
              mode: this.val ? 'mensual' : 'anual',
              currency: this.val ? plan.monthlyPrice.currency : plan.annualPrice.currency,
              amount: this.val ? plan.monthlyPrice.amount : plan.annualPrice.amount,
              qtyxunit: this.val ? plan.monthlyPrice.qtyxunit : plan.annualPrice.qtyxunit,
              unit: this.val ? plan.monthlyPrice.unit : plan.annualPrice.unit,
              qty: plan.qty,
              from: new Date().toISOString(), // Convertir la fecha de inicio a un formato de cadena ISO
              to: endDate.toISOString() // Convertir la fecha de fin a un formato de cadena ISO
          };
          // Agregar los query parameters a la URL
          url += '?' + Object.keys(queryParams).map(key => key + '=' + encodeURIComponent(queryParams[key])).join('&');
          // Redirigir al usuario a la otra aplicación
          console.log("[Enterprice-saas-price.comp] => Intentamos abrir sin contexto: ", url); 
          setTimeout(function (){
            window.open(url, '_blank').focus();
        }, 350);
        }
  );
}



  calculateEndDate(): Date {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    let endDate: Date;
  
    // Calcular la fecha de fin de mes
    if (today.getDate() === 1) {
      // Primer día del mes
      endDate = new Date(today.getFullYear(), today.getMonth(), lastDayOfMonth);
    } else {
      // No es el primer día del mes, calcular el resto de días del mes actual
      const remainingDaysCurrentMonth = lastDayOfMonth - today.getDate() + 1;
      // Calcular el número de días en el mes siguiente
      const daysNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
      // Sumar los días restantes del mes actual y los días del mes siguiente
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, remainingDaysCurrentMonth + daysNextMonth);
    }
  
    // Verificar si el modo anual está seleccionado y añadir un año a la fecha de fin
    if (!this.val) {
      endDate.setFullYear(endDate.getFullYear() + 1);
    }
  
    return endDate;
  }
  

  priceCarouselOptions= {
    items: 3,
    nav: false,
    dots:true,
    autoplay: false,
    center: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 1
        },
        768: {
            items: 2,
            margin: 20
        },
        992: {
            items: 3
        }
    }
  }

  price=[
    { 
      heading:"Tiny",
      monthlyPrice: { amount: 0, currency: "USD", unit: "Kg", qtyxunit: 1},
      annualPrice: { amount: 0 , currency: "USD", unit: "Kg" , qtyxunit: 1},
      qty:1,
      det1:"For Individuals",
      det2:"1 ➽ 10.000",
      det3:"/month", 
      det4:"/year", 
      features1:"",
      features2:"",
      features3:"",
      features4:"",
      features5:"",
      features6:"",
      btn:"START NOW",
      img: "assets/images/app_landing1/pricing/11.png",
      ssapiFeatures: [
        "Card Management",
        "Support via email"
      ],
      kirioFeatures: [
        "Simple customization on templates",
        "Credential Id",
        "User Management",
        "A daily message (broadcast)",
        "Support via email",
      ],
      hmsAbcBookFeatures: [
        "User Management",
        "Support via email",
        "Support via online chat",
        "Emergency Addresses, Telephones and Contacts"
      ],
      digitalCredentialsFeatures: [
        "Web App contact-center included",
        "Advanced customization (full customization)",
        "Multiple Credentials per Member (Family Group)",
        "User Management",
        "A daily message (broadcast)",
        "Support via email",
        "Emergency Addresses, Telephones and Contacts",
      ]
    },
    { 
      heading:"Small",
      monthlyPrice: { amount: 0, currency: "USD", unit: "Kg", qtyxunit: 1},
      annualPrice: { amount: 0, currency: "USD", unit: "Kg", qtyxunit: 1},
      qty:1,
      det1:"To start with SMEs",
      det2:"10.001 ➽ 30.000",
      det3:"/month", 
      det4:"/year", 
      features1:"All Tiny +",
      features2:"",
      features3:"",
      features4:"",
      features5:"",
      features6:"",
      btn:"START NOW",
      img: "assets/images/app_landing1/pricing/11.png",
      ssapiFeatures: [
        "-",
        "-",
      ],
      kirioFeatures: [
        "More than one credential per person",
        "Advanced customization (full)",
        "Unlimited messages (broadcast)",
      ],
      hmsAbcBookFeatures: [
        "Unlimited messages (broadcast)",
      ],
      digitalCredentialsFeatures: [
        "Support via online chat",
        "Unlimited messages (broadcast)"
      ] 
    },
    { 
      heading:"Medium",
      monthlyPrice: { amount: 0, currency: "USD", unit: "Kg", qtyxunit: 1},
      annualPrice: { amount: 0, currency: "USD", unit: "Kg", qtyxunit: 1},
      qty:1,
      det1:"Companies of all kinds",
      det2:"30.001 ➽ 50.000",
      det3:"/month",
      det4:"/year", 
      features1:"All Small +",
      features2:"",
      features3:"",
      features4:"",
      features5:"",
      features6:"",
      btn:"START NOW",
      img: "assets/images/app_landing1/pricing/11.png",
      ssapiFeatures: [
        "-",
        "-",
      ],
      kirioFeatures: [
        "Access to API Messaging (segmentation)",
        "Payment buttons embedded in messaging"
      ],
      hmsAbcBookFeatures: [
        "Access to Messaging API (segmentation)",
        "Payment buttons embedded in messaging",
        "HMS Security Framework API Access",
      ],
      digitalCredentialsFeatures: [
        "Access to Messaging API (segmentation)",
        "Payment buttons embedded in messaging",
        "HMS Security Framework API Access",
        "Mobile App Authorizations included"
      ] 
    },
    { 
      heading:"Large",
      monthlyPrice: { amount: "TDB", currency: "", unit: "", qtyxunit: 1},
      annualPrice: { amount: "TDB", currency: "", unit: "", qtyxunit: 1},
      qty:1,
      det1:"Large Organizations",
      det2:"50.001 ➽ ∞",
      det3:"/month", 
      det4:"/year", 
      features1:"All Medium +",
      features2:"",
      features3:"",
      features4:"",
      features5:"",
      features6:"",
      btn:"START NOW",
      img: "assets/images/app_landing1/pricing/11.png",
      ssapiFeatures: [
        "Support via online chat",
        "Corporate scale integration"
      ],
      kirioFeatures: [
        "Access to API Security Framework",
        "API Access Credentials",
        "Support via online chat",
        "Web App contact-center included",
        "Corporate scale integration",
      ],
      hmsAbcBookFeatures: [
        "Corporate scale integration",
      ],
      digitalCredentialsFeatures: [
        "API Access Credentials",
        "API Access Authorizations",
        "Web App Authorizations included",
        "Web App Medical Record included (ABC Book)",
        "Corporate scale integration",
      ] 
    }
  ]

  
 // función para cambiar el precio de mensual a anual
 monthly() {
  this.val = !this.val;
  const monthlyButton = document.querySelector('.switcher__button.monthly');
  const yearlyButton = document.querySelector('.switcher__button.yearly');

  if (this.val) {
      monthlyButton.classList.add('switcher__button--selected');
      yearlyButton.classList.remove('switcher__button--selected');
  } else {
      yearlyButton.classList.add('switcher__button--selected');
      monthlyButton.classList.remove('switcher__button--selected');
  }
}


}
