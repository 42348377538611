import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private title: Title) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
  }
  features= [
    {
      title:'Angular',
      subTitle: 'Framework',
      description: 'Angular is a robust and versatile framework for developing modern web applications. With its component-based architecture, Angular enables reactive and dynamic UI construction, ideal for single page applications (SPA).',
      img: 'assets/images/hms-tech/icon/technologies/angular-128px.png',
    },
    {
      title:'JavaScript',
      subTitle: 'Web Interactivity',
      description: 'The essential language for web interactivity, JavaScript powers front-end and back-end applications, enabling rich, asynchronous user experiences across platforms and browsers.',
      img: 'assets/images/hms-tech/icon/technologies/javaScrip-128px.png',
    },
    {
      title:'Java',
      subTitle: 'Universal Portability',
      description: 'Java stands for stability and performance across multiple platforms, ideal for enterprise, mobile and desktop applications with its vast development ecosystem',
      img: 'assets/images/hms-tech/icon/technologies/java-128px.png',
    },
    {
      title:'Smart',
      subTitle: 'Responsive',
      description: 'Responsive code that makes your landing page look good on all devices (desktops,tablets, and phones). Created with mobile specialists',
      img: 'assets/images/hms-tech/icon/technologies/javaScrip-128px.png',
    },
    {
      title:'Smart And',
      subTitle: 'optimized',
      description: 'Responsive code that makes your landing page look good on all devices (desktops,tablets, and phones). Created with mobile specialists',
      img: 'assets/images/hms-tech/icon/technologies/javaScrip-128px.png',
    },
    {
      title:'Well',
      subTitle: 'Documented',
      description: 'Responsive code that makes your landing page look good on all devices (desktops,tablets, and phones). Created with mobile specialists',
      img: 'assets/images/hms-tech/icon/technologies/javaScrip-128px.png',
    }
  ]
}
