<!-- Navbar -->
<app-modern-sass-nav></app-modern-sass-nav>
<!-- Header -->
<app-modern-sass-header></app-modern-sass-header>
<!-- Elementos home -->

<!--- company profile ---->
<app-modern-sass-feature></app-modern-sass-feature>
<!--- careers ---->
<app-modern-sass-services></app-modern-sass-services>
<!--- our work ---->
<app-modern-sass-screenshots></app-modern-sass-screenshots>

<!--- service-lines ---->
<app-enterprice-sass-header></app-enterprice-sass-header>

<!---products ---->
<app-enterprice-header></app-enterprice-header>

<!---Contacts <app-contacts></app-contacts>  ---->

<!-- footer -->
<app-hms-footer></app-hms-footer>
