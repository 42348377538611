import { Injectable } from '@angular/core';
import { CONTROL_TIME } from '../assets/config/constant-control';
import { DataToken } from '../model/token/data-token';
import { TimeService } from './utils/time.service';
import { AdminContextService } from './manager/admin-context.service';
import { Profiles } from '../assets/mocks/profiles';
import { RecoveryPerson } from '../model/context/dataConfigContext';
import { Identifier } from '../model/person/identifier.model';
import { PersonStatus } from '../model/person/personStatus';
import { AdminRolesService } from './manager/admin-roles.service';
import { ConfigService } from './config.service';
import { KEY_IDENTIFIER, KEY_PASS, KEY_REMEMBER_ID, KEY_REMEMBER_LOGIN, KEY_REMEMBER_PASS, KEY_TYPE_IDENTIFIER } from '../assets/config/config-constant.example';
import { PersonLSSSO } from '../model/SSO/context/personLSSSO';
import { PersonSSO } from '../model/SSO/person/person';
import { PersonContextSSO } from '../model/SSO/context/personContextSSO';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  readonly KEY_PEOPLE = "people";
  readonly KEY_ACTIVITY_LATEST_TIME = "timeLatestActivity";
  readonly KEY_TOKENS_CURRENTLY_ACTIVE = "tokensCurrentlyActive";
  readonly KEY_DATA_PAGES = "dataPages";
  /* readonly KEY_REMEMBER_ID = "remember_id";
  readonly KEY_REMEMBER_PASS = "remember_pass";
  readonly KEY_REMEMBER_LOGIN = "remember_login";
  readonly KEY_TYPE_IDENTIFIER = "type_identifier";
  readonly KEY_PASS = "password";
  readonly KEY_IDENTIFIER = "identifier"; */

  localStorage: any;
  dateLastestActivity: Date|null;
  dataAccesToken: DataToken|null = null;

  constructor(
    private adminContextService: AdminContextService,
    private adminRolesService: AdminRolesService,
    private configService: ConfigService,
    private timeService: TimeService
  ) { 
    this.localStorage = window.localStorage;
    this.initVarTokens();
    /* this.persons = []; */
    /* this.pagesChildren = []; */
    this.dateLastestActivity = new Date();
  }

  // Devuelve un object o un string segun corresponda o null si no existe el valor inicializado
  public getItem(key: string){
      return this.localStorage.getItem(key);
  }
  // Devuelve un object o un string segun corresponda o null si no existe el valor inicializado
  public getItemNew(key: string){
    let valueLS = this.localStorage.getItem(key);
    if(typeof(valueLS) == "string"){
      if(valueLS && (valueLS.length > 0) && (valueLS == "false" || valueLS == "true")){
        /* console.log("[getItem] == detecta boolean! => valor: ", valueLS); */
        return JSON.parse(valueLS);
      }
      /* console.log("[getItem] == tipo STRING! => key: ", key); */
      return valueLS;
    }
    else{
      /* console.log("[getItem] == tipo OBJECT! => key: ", key); */
      if(valueLS && (valueLS.length > 0)){
        return JSON.parse(valueLS);
      }
      /* console.log("[getItem] == no existe en LS => key: ", key); */
      return null;
    }
  }

  public setItem(key: string, value: any){
    if(typeof value == "string"){
      this.localStorage.setItem(key, value);
    }
    else{
      this.localStorage.setItem(key, JSON.stringify(value));
    }
  }

  // Se inicializan los tokens por perfiles
  private initVarTokens() {
    let tokens: any;
    tokens = this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE);
    /* console.log("[LocalStorage.serv] => Tokens en contexto: ",tokens); */
    if(!tokens){
      tokens = {
        operator: {token: null, expiration: null},
        affiliate: {token: null, expiration: null}
      };
      this.setItem(this.KEY_TOKENS_CURRENTLY_ACTIVE, tokens);
    }
  }

  // actualizamos el token del operator activo
  public setTokenCurrentOperator(dataToken: DataToken|any){
    /* console.log("[LocalStorage.serv] => DataToken SET", dataToken); */
    let tokensActiveLS = this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE);
    if(!tokensActiveLS){
      return;
    }
    let tokensActive = JSON.parse(this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE));
    if(!tokensActive)
      this.initVarTokens();
    tokensActive = JSON.parse(this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE));
    tokensActive.operator.token = dataToken.long_token;

    // esto para determinar si el dato recibido es un DataToken o no, ya q no nos toma el typeof
    if(dataToken.domain){
      /* console.log("[LocalStorage.serv] => DataToken type reconoce que es DataToken"); */
      // pasamos la fecha guardadad a un Date con la zona horaria por defecto
      let timeExpiredToken = Date.parse(dataToken.expiration_moment as string);
      let dateFormat = new Date(timeExpiredToken);
      tokensActive.operator.expiration = ""+dateFormat.getTime();
    }
    else{
      /* console.log("[LocalStorage.serv] => DataToken type detectamos que NO es DataToken"); */
      tokensActive.operator.expiration = dataToken.expiration_moment;
    }

    this.setItem(this.KEY_TOKENS_CURRENTLY_ACTIVE, tokensActive);
  }

  // reseteamos los datos del operador activo
  public resetTokenCurrentOperator(){
    let tokensActiveLS = this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE);
    if(!tokensActiveLS){
      return;
    }
    let tokensActive = JSON.parse(tokensActiveLS);
    if(!tokensActive){
      console.warn("[LocalStorage.serv] => No existe token de operador.");
      return;
    }
    tokensActive.operator.token = null;
    tokensActive.operator.expiration = null;
    this.setItem(this.KEY_TOKENS_CURRENTLY_ACTIVE, tokensActive);
  }

  // actualizamos el token del afiliado activo
  public setTokenCurrentAffiliate(dataToken: DataToken|any){
    let tokensActive = JSON.parse(this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE));
    tokensActive.affiliate.token = dataToken.long_token;
    // esto para determinar si el dato recibido es un DataToken o no, ya q no nos toma el typeof
    if(dataToken.domain){
      /* console.log("[LocalStorage.serv] => DataToken type reconoce que es DataToken"); */
      // pasamos la fecha guardadad a un Date con la zona horaria por defecto
      let timeExpiredToken = Date.parse(dataToken.expiration_moment as string);
      let dateFormat = new Date(timeExpiredToken);
      tokensActive.affiliate.expiration = ""+dateFormat.getTime();
    }
    else{
      /* console.log("[LocalStorage.serv] => DataToken type detectamos que NO es DataToken"); */
      tokensActive.affiliate.expiration = dataToken.expiration_moment;
    }
    this.setItem(this.KEY_TOKENS_CURRENTLY_ACTIVE, tokensActive);
  }

  // reseteamos los datos del afiliado activo
  public resetTokenCurrentAffiliate(){
    let dataTokensLS = this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE);
    if(!dataTokensLS){
      console.warn("[LocalStorage.serv] == No existen tokens activos.");
      return;
    }
    let tokensActive = JSON.parse(dataTokensLS);
    tokensActive.affiliate.token = null;
    tokensActive.affiliate.expiration = null;
    this.setItem(this.KEY_TOKENS_CURRENTLY_ACTIVE, tokensActive);
  }

  // aca deberiamos de recuperar lo existente en el LS para iniciar el array de Personas del adminContext para no perder
  // la info almacenada en el LS y evitar q la perdida de info al actualizar
  public cloneLocalStorageInContext(){
    /* console.log("[LocalStorage.serv] => (cloneLocalStorageInContext) - Personas en contexto, INICIO: ",this.adminContextService.persons); */
    let personLS = this.getAllPerson();
    this.adminContextService.persons = [];
    /* console.log("[LocalStorage.serv] => Personas en contexto: ", personLS); */
    for (let i = 0; i < personLS.length; i++) {
      this.adminContextService.persons.push((new PersonLSSSO(personLS[i])).getPersonContext());
    }
    this.adminContextService.affiliatesContext$.next(this.adminContextService.getAffiliatesContext());
    /* console.log("[LocalStorage.serv] => (cloneLocalStorageInContext): Personas en contexto: ",this.adminContextService.persons); */
  }

  public addPerson(person: PersonLSSSO){
    if(!this.existPerson(person)){
      let newPerson = this.adminContextService.addPerson(person.getPersonContext());
      /* console.log("[LocalStorage.serv] => Personas en contexto: ",this.getItem(this.KEY_PEOPLE)); */
      if(newPerson || !this.getItem(this.KEY_PEOPLE)){
        let personsInLS = this.getAllPerson();
        personsInLS.push(person);
        this.setItem(this.KEY_PEOPLE, JSON.stringify(personsInLS));
        /* this.setItem(this.KEY_PEOPLE, JSON.stringify(this.parseToPersons(this.adminContextService.persons))); */
      }
    }
    else{
      /* console.log("[LocalStorage.serv] => La persona esta en contexto del LS, se deberia de agregar al contexto.");
      console.log("[LocalStorage.serv] => Personas en contexto: ",this.adminContextService.persons); */
      this.adminContextService.addPerson(person.getPersonContext());
    }
  }

  // persiste la persona en LS y el contexto, si existe la reemplaza
  public addPersonForced(person: PersonLSSSO){
    let personsInLS = this.getAllPerson();
    let founded: boolean = false;
    /* console.log("[LocalStorage.serv] => Personas en LS: ", personsInLS); */
    for (let i = 0; i < personsInLS.length; i++) {
      let personInContextLS = new PersonLSSSO(personsInLS[i]);
      // si encontramos a la personas la reescribimos
      if(personInContextLS.equals(person)){
        founded = true;
        console.log("[LocalStorage.serv] => La persona esta en el LS. Se reemplaza.");
        personsInLS[i] = person;
      }
    }
    // si no la encontramos la agregamos
    if(!founded){
      console.log("[LocalStorage.serv] => La persona NO esta en el LS. Se agrega.");
      personsInLS.push(person);
    }
    this.setItem(this.KEY_PEOPLE, JSON.stringify(personsInLS));
    this.adminContextService.addPersonForced(person.getPersonContext());
  }

  // persistimos un afiliado en contexto con los datos agregados
  public persistAffiliateInContext(dataAffiliate: any, identifier: Identifier, functionality: string, rewrite: boolean){
    delete dataAffiliate.id;
    console.log("[LocalStorage.serv] => Datos del afiliado encontrado: ",dataAffiliate);
    // creamos el operador para tenerlo en el contexto
    let personLS = new PersonLSSSO(dataAffiliate);
    /* console.log("[LocalStorage.serv] => Afiliado a persistir: ",personLS); */
    let isRegistered: boolean|null;
    personLS.registered ? isRegistered = dataAffiliate.registered : isRegistered = null;
    personLS.addIdentifierSearch(Profiles.AFFILIATE, identifier);
    // agregamos el token del afiliado, este dato deberia de ser recuperado del findPerson
    /* personLS.token = personLS.affiliateInfo.codigo; */
    // TODO SSO: setear el cod de afi como token del mismo
    personLS.token = "Aca va el cod de afiliado!!";
    // TODO SSO: esto actualiza el estado del afiliado, ver como incorporar
    let statusAffiliate: string|null = null;
    /* personLS.affiliateInfo.estado == "A"? statusAffiliate = PersonStatus.ACTIVE: statusAffiliate = PersonStatus.INACTIVE; */
    personLS.updateProfile(Profiles.AFFILIATE, this.configService.getAppDomain(), isRegistered, statusAffiliate, null);
    personLS.function = functionality;
    personLS.activeRoles = this.adminRolesService.getRolesByFunctionality(personLS.function);
    // agregamos a la persona en LS y contexto
    (rewrite) ? this.addPersonForced(personLS): this.addPerson(personLS);
  }

  // actualiza la info de la persona en el LS como en el contexto
  public updateDataTokenPerson(person: PersonLSSSO, dataToken: DataToken){
    let personsInLS = this.getAllPerson();
    for (let i = 0; i < personsInLS.length; i++) {
      let personLS = new PersonLSSSO(personsInLS[i]);
      if(personLS.equals(person)){
        /* personLS.setDataToken(dataToken); */
        personsInLS[i].token = dataToken.long_token;
        let timeExpiredToken = Date.parse(dataToken.expiration_moment as string);
        let dateFormat = new Date(timeExpiredToken);
        personsInLS[i].expiredToken = ""+dateFormat.getTime();
      }
    }
    this.adminContextService.addTokenPerson(dataToken, person.getPersonContext())
    this.setItem(this.KEY_PEOPLE, JSON.stringify(personsInLS));
  }

  // Elimina una persona del LS y del contexto
  public deletePerson(person: PersonSSO|PersonLSSSO){
    this.adminContextService.deletePerson(person.getPersonContext());
    let personsInLS = this.getAllPerson();
    personsInLS = personsInLS?.filter((item) => !(new PersonLSSSO(item)).equals(person));
    this.setItem(this.KEY_PEOPLE, personsInLS);
  }

  // Elimina de contexto todas las personas de un operador
  public deletePersonsByOperator(tokenOperator: string){
    let persons = this.getPersonsByOperator(tokenOperator);

    if(!persons || (persons.length <= 0)){
      console.warn("[LocalStorage.serv] => Eliminacion de contexto: el operador no cuenta con personas en contexto.");
      return;
    }
    for (let i = 0; i < persons.length; i++) {
      let personToDelete: PersonLSSSO = new PersonLSSSO(persons[i]);
      this.deletePerson(personToDelete);
    }
    return;
  }
  
  // actualizamos la info del localStorage
  public updateData(localStorage: any){
    /* console.log("[LocalStorage.serv] => LS a actualizar: ", localStorage); */
    if(typeof localStorage.tokensCurrentlyActive != "undefined")
      this.setItem(this.KEY_TOKENS_CURRENTLY_ACTIVE, localStorage.tokensCurrentlyActive);
    if(typeof localStorage.people != "undefined"){
      this.setItem(this.KEY_PEOPLE, localStorage.people);
      this.filterPersonsByConfig();
    }
  }

  // Actualizamos las personas del LS dependiendo de la config de la app
  private filterPersonsByConfig(): any {
    if((this.adminContextService.getRecoveryOperators() == RecoveryPerson.ALL) && (this.adminContextService.getRecoveryAffiliate() == RecoveryPerson.ALL))
      return;
    
    let peopleContextFilter = [];
    // recuperamos el/los operador/es
    if(this.adminContextService.getRecoveryOperators() == RecoveryPerson.CURRENT){
      let operatorCurrent = this.getPersonByToken(this.getTokenOperator());
      /* console.log("[LocalStorage.serv] => (getPersonToPersist) Recuperamos el operador actual: ", operatorCurrent); */
      if(operatorCurrent)
        peopleContextFilter.push(operatorCurrent);
    }
    else{
      let operatorPersons = this.getPersonsByProfile(Profiles.OPERATOR_CC);
      /* console.log("[LocalStorage.serv] => (getPersonToPersist) Recuepramos todos los operadores: ", operatorPersons); */
      if(operatorPersons && (operatorPersons.length > 0)){
        for (let i = 0; i < operatorPersons.length; i++) {
          peopleContextFilter.push(operatorPersons[i]);
        }
      }
    }

    // recuperamos el/los afiliado/s
    if(this.adminContextService.getRecoveryAffiliate() == RecoveryPerson.ALL){
      let affiliatePersons = this.getPersonsByProfile(Profiles.AFFILIATE);
      if(affiliatePersons && (affiliatePersons.length > 0)){
        for (let i = 0; i < affiliatePersons.length; i++) {
          peopleContextFilter.push(affiliatePersons[i]);
        }
      }
      /* console.log("[LocalStorage.serv] => (getPersonToPersist) Recuperamos todos los afiliados: ", affiliatePersons); */
    }
    if(this.adminContextService.getRecoveryAffiliate() == RecoveryPerson.CURRENT){
      let affiliateCurrent = this.getPersonByToken(this.getTokenAffiliate());
      if(affiliateCurrent)
        peopleContextFilter.push(affiliateCurrent);
      /* console.log("[LocalStorage.serv] => (getPersonToPersist) Recuperamos solo el afiliado actual: ", affiliateCurrent); */
    }

    /* console.log("[LocalStorage.serv] => (getPersonToPersist) Person filtradas: ", peopleContextFilter); */
    this.setItem(this.KEY_PEOPLE, JSON.stringify(peopleContextFilter));
  }

  // devuelve la persona si existe en el contexto del LS, null en caso contrario
  public existPerson(person: PersonSSO|PersonLSSSO){
    let personInLS = this.getAllPerson();
    /* console.log("[LocalStorage.serv] => Personas en LS: ", personInLS); */
    for (let i = 0; i < personInLS.length; i++) {
      let personInContextLS = new PersonLSSSO(personInLS[i]);
      if(personInContextLS.equals(person)){
        /* console.log("[LocalStorage.serv] => La persona esta en el LS. NO se agrega al LS ni se actualiza el contexto."); */
        return personInContextLS;
      }
    }
    /* console.log("[LocalStorage.serv] => La persona NO esta en el LS. Se agrega y actualiza el contexto."); */
    return null;
  }

  public getAllPerson(){
    let personsLocalStorage = this.localStorage.getItem(this.KEY_PEOPLE);
    /* console.log("[LocalStorage.serv] => Datos de localStorage persons: ", personsLocalStorage); */
    /* let persons: PersonContext[]; */
    let persons: PersonLSSSO[];
    
    if((typeof personsLocalStorage == "undefined") || !personsLocalStorage)
      persons = [];
    else{
      persons = JSON.parse(personsLocalStorage);
    }

    return persons;
  }

  // devuelve todos los operadores de la lista de personas correspondientes a un operador
  public getPersonsByOperator(tokenOperator: string){
    let persons = this.localStorage.getItem(this.KEY_PEOPLE);
    if(!persons)
      return null;
    
    persons = JSON.parse(persons);
    let personsProfile = persons?.filter(elem => elem.tokenOperatorContext.includes(tokenOperator));

    return personsProfile;
  }

  // devuelve todos los operadores de la lista de personas
  public getPersonsByProfile(profile: string){
    let persons = this.localStorage.getItem(this.KEY_PEOPLE);
    if(!persons)
      return null;
    
    persons = JSON.parse(persons);
    let personsProfile = persons?.filter(elem => elem.profiles.includes(profile));

    return personsProfile;
  }


  public getFullData(){
    return JSON.stringify(this.localStorage);
  }

  public getPersonByToken(token: string){
    let persons = this.localStorage.getItem(this.KEY_PEOPLE);
    if(!persons)
      return null;
    
    persons = JSON.parse(persons);
    let person = persons?.find(elem => elem.token == token);

    /* return new PersonLS(person); */
    return person;
  }

  public setTimeLastestActivity(timeActivity: Date|null){
    this.dateLastestActivity = timeActivity;
    // analizar la posibilidad de no guardar esta info en el localStorage
    (timeActivity) ?
      this.setItem(this.KEY_ACTIVITY_LATEST_TIME, this.timeService.getStringDate(timeActivity)):
      this.setItem(this.KEY_ACTIVITY_LATEST_TIME, timeActivity);
  }

  public getTimeLastestActivity(){
    this.localStorage.getItem(this.KEY_ACTIVITY_LATEST_TIME);
  }

  // devuelve el token del operador actualmente activo
  public getDataTokenByPerson(operator: PersonSSO|PersonContextSSO){
    // buscamos la persona para recuperar los datos del token
    let personLS = this.getAllPerson();
    for (let i = 0; i < personLS.length; i++) {
      let person = new PersonLSSSO(personLS[i]);
      /* console.log("[LocalStorage.serv] => (getDataTokenByPerson) Personas encontrada: ", person); */
      if(person.equals(operator)){
        let dataToken = {
          long_token: person.token,
          expiration_moment: person.expiredToken
        };
        return dataToken;
      }
    }
    return null;
  }

  // devuelve el token del operador de actualmente activo
  public getTokenOperator(){
    let dataTokenOperatorLS = this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE);
    if(!dataTokenOperatorLS){
      return;
    }
    let dataToken = JSON.parse(dataTokenOperatorLS);
    if(dataToken)
      return dataToken.operator.token;
    return null;
  }
  
  // devuelve el tiempo de expiracion del token del operador actualmente activo
  public getMomentExpiredTokenOperator(){
    let dataTokensLS = this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE);
    if(!dataTokensLS){
      return null;
    }
    let dataToken = JSON.parse(dataTokensLS);
    if(dataToken)
      return dataToken.operator.expiration;
    return null;
  }

  // devuelve el token del afiliado de actualmente activo
  public getTokenAffiliate(){
    let dataToken = JSON.parse(this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE));
    if(dataToken)
      return dataToken.affiliate.token;
    return null;
  }
  
  // devuelve el tiempo de expiracion del token del afiliado actualmente activo
  public getMomentExpiredTokenAffiliate(){
    let dataToken = JSON.parse(this.getItem(this.KEY_TOKENS_CURRENTLY_ACTIVE));
    if(dataToken)
      return dataToken.affiliate.expiration;
    return null;
  }
  
  // determina si se ha cumplido el tiempo de inactividad maximo
  public idleTimeExceeded(){
    let dateCurrent = new Date();
    // armar el date a partir de lo guardo en el
    /* console.log("Date guardado: ", this.dateLastestActivity); */
    let difSecondsTotal = this.timeService.compareDatesInSeconds(this.dateLastestActivity, dateCurrent);
    /* console.log("Diferencia entre fechas en segundos: ", difSecondsTotal); */
    if((difSecondsTotal) && (difSecondsTotal >= CONTROL_TIME.INACTIVITY_LIMIT)){
      return true;
    }
    return false;
  }

  public resetData(){
    /* console.log("[LocalStorage.serv] => Se limpia el LocalStorage!!!"); */
    /* this.localStorage.clear(); */
    this.setItem(this.KEY_PEOPLE, "");
    this.setItem(this.KEY_TOKENS_CURRENTLY_ACTIVE, "");
    this.setItem(this.KEY_ACTIVITY_LATEST_TIME, "");
    this.setItem(this.KEY_DATA_PAGES, "");
  }

  public cleanAll(){
    this.localStorage.clear();
  }

  public resetDataOperatorCurrent(){
    let token = this.getTokenOperator();
    // Eliminamos las personas en contexto asociadas al operador
    this.deletePersonsByOperator(token);
    // Eliminamos el operador de la lista de personas del LS y contexto
    let operatorCurrent = this.getPersonByToken(token);
    this.deletePerson(new PersonLSSSO(operatorCurrent));
    // Limpiamos el operador y afiliado actual
    this.resetTokenCurrentOperator();
    this.resetTokenCurrentAffiliate();
  }

  // devuelve los datos de los iframe de coneccion como object
  public getDataPages(){
    let dataPageString = this.getItem(this.KEY_DATA_PAGES);
    if(!dataPageString){
      return null;
    }
    /* console.warn("[LocalStorage.serv] => getDataPages: ", dataPageString);
    console.warn("[LocalStorage.serv] => getDataPages: ", JSON.parse(dataPageString)); */
    return JSON.parse(dataPageString);
  }

  // setea los datos de las paginas
  public setDataPages(dataPages: string){
    if(!dataPages){
      console.warn("[LocalStorage.serv] => No existen datos del estado de las apps.");
      return;
    }
    this.setItem(this.KEY_DATA_PAGES, dataPages);
  }

  // resetea los datos de las paginas 
  public resetDataPages(){
    // vamos a dejar todos los datos en false para no perder rastros de las paginas abiertas
    let dataPages = this.getDataPages();
    for (let i = 0; i < dataPages.length; i++) {
      dataPages[i].logout = false;
    }
    /* console.log("[LocalStorage.serv] => DatPages reset: ", dataPages); */
    this.setItem(this.KEY_DATA_PAGES, dataPages);
    /* this.setItem(this.KEY_DATA_PAGES, null); */
  }

  // setea los datos de la pagina recibida
  public setIsLogoutPage(urlPage:string, logout:boolean){
    let dataPageString = this.getItem(this.KEY_DATA_PAGES);
    if(!dataPageString){
      return;
    }
    let dataPages = JSON.parse(dataPageString);
    if(!dataPages){
      let dataPages: any = [];
      let dataPage = {url: urlPage, logout: logout};
      dataPages.push(dataPage);
      this.setItem(this.KEY_DATA_PAGES, dataPages);
      return;
    }

    // buscamos si existe el registro de la pagina
    let founded = false;
    for (let i = 0; (i < dataPages.length) && !founded; i++) {
      if(dataPages[i].url == urlPage){
        dataPages[i].logout = logout;
        founded = true;
      }
    }
    if(!founded){     // lo agregamos
      let dataIframe = {url: urlPage, logout: logout};
      dataPages.push(dataIframe);
    }
    this.setItem(this.KEY_DATA_PAGES, dataPages);
  }

  // determina si la pagina reporto un logout
  public isReportLogout(urlPage: string){
    let dataPages = this.getDataPages();
    if(!dataPages)
      return false;
    
    // buscamos entre los datos de las paginas creados
    for (let i = 0; i < dataPages.length; i++) {
      /* console.log("[LocalStorage.serv] => (localStorage) Se compara: ", dataPages[i].url," - ", urlPage); */
      if(dataPages[i].url == urlPage){
        return dataPages[i].logout;
      }
    }

    return false;   // si no encontramos la pagina
  }

  // determina si alguna de las paginas reporta un logout
  public existReportLogout(){
    let dataPages = this.getDataPages();
    /* console.log("[LocalStorage.serv] => (existReportLogout) Paginas: ", dataPages); */
    if(!dataPages)
      return false;
    
    // buscamos entre los datos de las paginas creados
    for (let i = 0; i < dataPages.length; i++) {
      if(dataPages[i].logout){
        /* console.log("[LocalStorage.serv] => (existReportLogout) Pagina ", dataPages[i].url, " - logout: ", dataPages[i].logout); */
        return dataPages[i].logout;
      }
    }

    return false;   // si no encontramos la pagina
  }

  // #########################################################################################################
  // ########################################## NEW config LOGIN #############################################
  public cleanDataConfigLogin(){
    // el logout solo deberia destildar el login automatico
    this.setItem(KEY_REMEMBER_LOGIN, "");
    /* this.setItem(KEY_REMEMBER_ID, "");
    this.setItem(KEY_REMEMBER_PASS, "");
    this.setItem(KEY_TYPE_IDENTIFIER, "");
    this.setItem(KEY_IDENTIFIER, "");
    this.setItem(KEY_PASS, ""); */
  }

}

