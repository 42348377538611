import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hms-tech',
  templateUrl: './hms-tech.component.html',
  styleUrls: ['./hms-tech.component.scss']
})
export class HmsTechComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
