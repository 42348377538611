<app-modern-sass-nav></app-modern-sass-nav>

<!-- Sección de título -->
<section
  class="p-b-0 bg-black"
  [ngStyle]="{ background: 'linear-gradient(to top, #f5f5f8, #838383)' }"
>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title title2 title-inner">
          <div class="main-title">
            <h2
              class="font-primary borders text-center main-text text-uppercase m-b-0"
            >
              <span>{{ "Careers" | translate }}</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Sección del formulario de candidato -->
<footer
  class="event contact set-relative bg"
  id="candidate-form"
  [ngStyle]="{ 'background-image': 'url()' }"
>
  <div class="container p-b-100">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="title title3">
          <div class="main-title">
            <h2 class="text-black">{{ "Candidate Form" | translate }}</h2>
          </div>
          <div class="sub-title">
            <p class="text-black">
              {{ "Complete the candidate form below:" | translate }}
            </p>
          </div>
        </div>
      </div>
      <!-- Área del formulario de candidato -->
      <div class="col-xl-5 mx-auto col-md-6 set-z-index form-footer">
        <div class="bg-white">
          <form
            id="candidateForm"
            #candidateForm="ngForm"
            enctype="multipart/form-data"
            [formGroup]="form"
            (ngSubmit)="submitForm()"
          >
            <!-- Nombre -->
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="name">{{ "Name *" | translate }}</label>
                  <input
                    formControlName="name"
                    type="text"
                    name="form_name"
                    class="form-control"
                    id="name"
                    required
                  />
                  <!--Mensajes de error-->
                  <div *ngIf="form.get('name').invalid && (form.get('name').dirty || form.get('name').touched)" class="text-danger">
                    {{ "Name is required" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Teléfono -->
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="phone">{{ "Phone *" | translate }}</label>
                  <input
                    formControlName="phone"
                    type="number"
                    name="form_phone"
                    class="form-control"
                    id="phone"
                    required
                    autocomplete="tel"
                    pattern="[0-9]{10,15}"
                  />
                  <!-- Mensajes de error -->
                  <div *ngIf="form.get('phone').invalid && (form.get('phone').dirty || form.get('phone').touched)" class="text-danger">
                    {{ "Phone is required and must be a valid number" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Posición -->
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="position">{{ "Position *" | translate }}</label>
                  <input
                    formControlName="position"
                    type="text"
                    name="form_position"
                    class="form-control non-editable"
                    id="position"
                    required
                    readonly
                    contenteditable="false"
                    value="{{ position }}"
                  />
                </div>
              </div>
            </div>
            <!-- País -->
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="country">{{ "Country *" | translate }}</label>
                  <input
                    formControlName="country"
                    type="text"
                    name="form_country"
                    class="form-control non-editable"
                    id="country"
                    required
                    readonly
                    contenteditable="false"
                    value="{{ country }}"
                  />
                </div>
              </div>
            </div>
            <!-- CV -->
            <div class="form-group">
              <label for="cv">{{ "Attach CV *" | translate }}</label>
              <div class="input-group">
                <div class="custom-file">
                  <input
                    formControlName="cvFile"
                    type="file"
                    name="cvFile"
                    class="custom-file-input"
                    id="cv"
                    accept=".pdf, .doc, .docx"
                    required
                  />
                  <label class="custom-file-label" for="cv">{{
                    "Choose file" | translate
                  }}</label>
                  <div *ngIf="form.get('cvFile').invalid && (form.get('cvFile').dirty || form.get('cvFile').touched)" class="text-danger">
                    {{ "CV is required and must be a valid file type" | translate }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Linkedin -->
            <div class="form-group">
              <label for="linkedin">{{
                "Linkedin Profile *" | translate
              }}</label>
              <input
                formControlName="linkedin"
                type="text"
                name="form_linkedin"
                class="form-control"
                id="linkedin"
                required
              />
              <div *ngIf="form.get('linkedin').invalid && (form.get('linkedin').dirty || form.get('linkedin').touched)" class="text-danger">
                {{ "Linkedin Profile is required" | translate }}
              </div>
            </div>

            <!-- Spinner de carga con overlay -->
            <div *ngIf="isLoading" class="loading-overlay">
              <div class="spinner-container">
                <div class="spinner-border text-light" role="status">
                  <span class="sr-only">Cargando...</span>
                </div>
                <div class="loading-text">{{ "Sending..." | translate }}</div>
              </div>
            </div>

            <!-- Botón de enviar -->
            <button
              type="submit"
              class="btn btn-default primary-btn m-0-auto event-btn"
              [disabled]="form.invalid || isLoading"
              [ngStyle]="{ cursor: (form.invalid || isLoading) ? 'not-allowed' : 'pointer' }"
              [attr.title]="form.invalid && !isLoading ? 'Please complete all required fields' : null"

            >
              {{ "send" | translate }}
            </button>
          </form>
          
        </div>
      </div>
    </div>
  </div>
  <img
    src="assets/images/event/footer/2.png"
    alt=""
    class="set-abs top-0 plane2"
  />
  <img
    src="assets/images/event/footer/1.png"
    alt=""
    class="set-abs bottom-0 plane"
  />
</footer>

<!-- Pie de página -->
<!-- footer -->
<app-hms-footer></app-hms-footer>
