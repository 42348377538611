import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeTitle'
})
export class SafeTitlePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    const transformedValue = value.replace(/®/g, '<sup class="small-r">®</sup>');
    return this.sanitizer.bypassSecurityTrustHtml(transformedValue);
  }
}
