export var civilIdsByCountry: any[] = [
    { country: "AR", types:[
                /* { code:"DNI", value:"DNI", name: "Documento Nacional de Identidad", regex: "[0-9]{8}"}, */
                { code:"DNI", value:"DNI", name: "Documento Nacional de Identidad", regex: "([0-9]{8}|([0-9]{2}.[0-9]{3}.[0-9]{3}))", mask: "%1$s%2$s.%3$s%4$s%5$s.%6$s%7$s%8$s"},
                { code:"CUIT/CUIL", value:"CUIT/CUIL", name: "Codigo Unico de Identificacion Laboral", regex: "^(20|23|24|27|30|33|34)([0-9]{9}|-[0-9]{8}-[0-9]{1})", mask: "%1$s%2$s-%3$s%4$s%5$s%6$s%7$s%8$s%9$s%10$s-%11$s"},
                { code:"PAS", value:"PAS", name: "Pasaporte", regex: "([0-9]{7}|([0-9]{1}.[0-9]{3}.[0-9]{3}))", mask: "%1$s.%2$s%3$s%4$s.%5$s%6$s%7$s"}
            ]
    },
    { country: "BO", types:[
                { code:"PAS", value:"PAS", name: "Pasaporte", regex: "[0-9]{7}"},
                { code:"CI", value:"CI", name: "Cedula de Identidad", regex: "[0-9]{6}"},
                { code:"NIT", value:"NIT", name: "Nombre de NIT", regex: "[0-9]{5}"}
            ]
    },
    { country: "BR", types:[
                { code:"PAS", value:"PAS"},
                { code:"RG", value:"RG"},
                { code:"CPF_CNPJ", value:"CPF/CNPJ"}
            ]
    },
    { country: "CL", types:[
            { code:"PAS", value:"PAS"},
            { code:"CI", value:"CI"},
            { code:"RUT", value:"RUT"}
        ]
    },
    { country: "CO", types:[
            { code:"PAS", value:"PAS"},
            { code:"CC", value:"CC"},
            { code:"RUT", value:"RUT"}
        ]
    },
    { country: "CR", types:[
            { code:"PAS", value:"PAS"},
            { code:"CI", value:"CI"},
            { code:"NITE", value:"NITE"}
        ]
    },
    { country: "CU", types:[
        { code:"PAS", value:"PAS"}
        ]
    },
    { country: "EC", types:[
        { code:"PAS", value:"PAS"},
        { code:"CC", value:"CC"},
        { code:"RUC", value:"RUC"}
        ]
    },
    { country: "SV", types:[
        { code:"PAS", value:"PAS"},
        { code:"DUI", value:"DUI"},
        { code:"NIT", value:"NIT"}
        ]
    },
    { country: "GT", types:[
        { code:"PAS", value:"PAS"},
        { code:"DPI", value:"DPI"},
        { code:"NIT", value:"NIT"}
        ]
    },
    { country: "HN", types:[
        { code:"PAS", value:"PAS"},
        { code:"TDI", value:"CTDII"},
        { code:"RTN", value:"RTN"}
        ]
    },
    { country: "MX", types:[
        { code:"PAS", value:"PAS"},
        { code:"CURP", value:"CURP"},
        { code:"RFC", value:"RFC"}
        ]
    },
    { country: "NI", types:[
        { code:"PAS", value:"PAS"},
        { code:"CI", value:"CI"},
        { code:"RUC", value:"RUC"}
        ]
    },
    { country: "PA", types:[
        { code:"PAS", value:"PAS"},
        { code:"CIP", value:"CIP"},
        { code:"RUC", value:"RUC"}
        ]
    },
    { country: "PY", types:[
        { code:"PAS", value:"PAS"},
        { code:"CIC_CI", value:"CIC/CI"},
        { code:"RUC", value:"RUC"}
        ]
    },
    { country: "PE", types:[
        { code:"PAS", value:"PAS"},
        { code:"DNI", value:"DNI"},
        { code:"RUC", value:"RUC"}
        ]
    },
    { country: "DO", types:[
        { code:"PAS", value:"PAS"},
        { code:"DNI", value:"CIE"},
        { code:"RUC", value:"RNC"}
        ]
    },
    { country: "UY", types:[
        { code:"PAS", value:"PAS"},
        { code:"DNI", value:"CI"},
        { code:"RUC", value:"RUT"}
        ]
    },
    { country: "VE", types:[
        { code:"PAS", value:"PAS"},
        { code:"DNI", value:"CI"},
        { code:"RUC", value:"RIF"}
        ]
    },
]