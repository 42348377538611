// language-toggle.component.ts
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomizerService } from '../../service/customizer.service';

@Component({
    selector: 'app-language-toggle',
    templateUrl: './language-toggle.component.html',
    styleUrls: ['./language-toggle.component.scss'],
})
export class LanguageToggleComponent {
    layoutVersion: string;
    languages: { code: string; label: string }[] = [
        { code: 'en', label: 'EN' },
        { code: 'es', label: 'ES' },
    ];

    constructor(private customizerService: CustomizerService,public translate: TranslateService) {
        this.layoutVersion = customizerService.data.settings.layout_version;
    }

    toggleLanguage(event: any): void {
        const newLanguage = event.target.value;
        this.translate.use(newLanguage);
        localStorage.setItem('language', newLanguage);
      }
    
}
