import { Component, HostListener, OnInit } from '@angular/core';
import { Domain } from 'src/app/sesion/model/domain/domain';
import { DomainService } from 'src/app/sesion/services/domain.service';

@Component({
  selector: 'app-floating-buttons',
  templateUrl: './floating-buttons.component.html',
  styleUrls: ['./floating-buttons.component.scss']
})
export class FloatingButtonsComponent implements OnInit {

  supportNumber: string = '541123545047';
  isPlaying = false;
  showModal = false;
  showSupportModal = false;
  inputValue = '';
  selectedDomain: string = 'hms'; // Inicializa con el dominio por defecto
  domains = ['hms', 'scis', 'austral', ]; // Lista de dominios para mostrar en el modal
  baseUrl: string = 'https://qr.hms-tech.com/domain'; // URL base

  public showScroll: boolean = false;
  public showScrollHeight = 500;
  public hideScrollHeight = 10;


  constructor(private domainService: DomainService) { }

  ngOnInit() {
    /* this.loadDomains(); */
  }

  loadDomains() {
    /* this.domainService.getAll().subscribe(domains => {
      console.log('Dominios:', domains);
      this.domains = domains; // Guarda los dominios en la variable del componente
    }); */
  }
  
  playSound() {
    if (!this.isPlaying) {
      const audio = new Audio('assets/sounds/click_sound.mp3');
      this.isPlaying = true;
      audio.volume = 0.3; // Ajusta el volumen entre 0.0 y 1.0
      audio.playbackRate = 1.0; // Ajusta la velocidad de reproducción
      audio.play();
      audio.onended = () => {
        this.isPlaying = false;
      };
    }
  }

  getWhatsAppLink(): string {
    const message = encodeURIComponent('Hola, me gustaría saber más sobre los productos y servicios de HMS-Tech. ¿Podrían brindarme más detalles?');
    return `https://api.whatsapp.com/send?phone=${this.supportNumber}&text=${message}`;
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }
  
   processInput() {
    console.log('Input Value:', this.inputValue); // Debe mostrar lo que ingresaste
    let token = '';
    this.baseUrl = `https://qr.hms-tech.com/domain/${this.selectedDomain}`;
    if (this.inputValue.includes('token=')) { // Si la URL contiene un token
      const urlParams = new URLSearchParams(this.inputValue.split('?')[1]); // Extraemos el token de la URL
      token = urlParams.get('token'); // Obtenemos el valor del token
    } else {// Si no contiene un token, asumimos que ingresaron el token directamente
      token = this.inputValue.trim(); // Eliminamos los espacios en blanco
    }

    console.log('Extracted Token:', token);

    if (token) {
      const finalUrl = `${this.baseUrl}/info?token=${token}`;
      console.log('Final URL:', finalUrl);

      window.open(finalUrl, '_blank');
      this.closeModal();
    } else {
      console.error('Invalid token or URL.');
      alert('Por favor ingrese un token válido o una URL.');
    }
  }

  openSupportModal() {
    this.showSupportModal = true;
  }

  closeSupportModal() {
    this.showSupportModal = false; // Cierra el modal de soporte
  }

  redirectToWhatsApp() {
    window.open(this.getWhatsAppLink(), '_blank');
    this.showSupportModal = false; // Cierra el modal después de redirigir
    this.playSound(); // Reproduce un sonido al hacer clic
  }
  
  validateInput(inputField: any) {
    // Forzamos la validación para que se reflejen los errores en tiempo real.
    inputField.control.updateValueAndValidity(); 
  }
  
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (typeof window !== 'undefined') { // Verifica si el objeto window está definido
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // Obtiene la posición actual del scroll
      this.showScroll = scrollTop > this.showScrollHeight; // Muestra el botón si la posición del scroll es mayor a 500px
    }
  }
}
