import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoneSSO } from '../../model/SSO/identifiers/phone';
import { countries } from '../../assets/mocks/country-data-store';
import { CountryCode, getCountries, getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js';

@Component({
    selector: 'app-phone-id',
    templateUrl: './phone-id.component.html',
    styleUrls: ['./phone-id.component.scss',]
})
export class PhoneIdComponent implements OnInit {

    phoneIdForm: FormGroup;
    countries: any = countries;
    hideValue?: boolean = true;
    defaultCountry: string = "AR";
    countriesLib: string[] = getCountries();

    @Input() phone?: PhoneSSO|undefined;
    @Input() codeCountry?: string;
    @Input() editable?: boolean;
    @Input() placeholder: string = "";
    @Input() displayText: string = "horizontal";   // horizontal/vertical para mostrar alineados o uno arriba de otro
    @Input() displayTextInputs: boolean = true;
    @Input() countryEditable?: boolean;
    @Input() text?: string;
    @Input() textCountry?: string = "País";
    @Input() textValue?: string = "Número";
    @Input() enableInputsText: boolean = true;
    @Input() showEye?: boolean = false;
    @Input() colorText?: string;

    @Output() notifyPressEnter = new EventEmitter<boolean>();
    @Output() notifyChangeValue = new EventEmitter<any>();
    @Output() notifyChangeValueCountry = new EventEmitter<string>();
    // eventos emitidos de afuera
    @Input() updateValue: EventEmitter<any>;
    @Input() resetValue: EventEmitter<any>;
  
    ngOnInit(): void {
        /* console.log("Paises de la lib de telefono: ", this.countriesLib); */
        this.initData();
        this.phoneIdForm = new FormGroup({
            value: new FormControl({ value: this.phone?.value, disabled: !this.editable}, {
                      validators:[ Validators.required ]
                    })
          });

        // aca escuchamos la info que nos pueden enviar de afuera
        if(typeof this.updateValue != "undefined"){
            this.updateValue.subscribe((data: any) => {
            /* console.log("[PhoneId-v2.comp] => Update de input: ", data); */
            this.phone = data;
            this.phoneIdForm.get("value")?.setValue(data.value);
            })
        }
        if(typeof this.resetValue != "undefined"){
            this.resetValue.subscribe((data: any) => {
                this.phoneIdForm.get("value")?.setValue("");
                this.phone = undefined;
            })
          }
    }

    /* Controlamos el cambio del input ingresado */
    public changeInput(){
        this.initData();
        this.phone.value = this.phoneIdForm.get("value")?.value;
        /* let countryCode: CountryCode = this.countriesLib.find((elem: string) => elem == this.phone.country_code) */
        let prefix = "+" +getCountryCallingCode(this.phone?.country_code as CountryCode);
        let fullNumber = prefix + (this.phone?.value as string);
        let validNumber = isValidPhoneNumber(fullNumber);
        /* console.log("Se cambio el civilId -> " +this.civilIdForm.get("valueCivilId")?.value); */
        this.notifyChangeValue.emit({value: this.phone, valid: validNumber});
    }

    public notifyEnterPressed(): void {
        if(this.phoneIdForm.get("value")?.valid){
            this.notifyPressEnter.emit(true);
        }
    }

    public showValue(){
        this.hideValue = !this.hideValue;
    }

    private initData(){
        if(!this.phone){
            this.phone = new PhoneSSO();
            this.phone.country_code = this.defaultCountry;
        }
    }

    // ##########################################################################################
    // #################################### LISTENER ####################################

    public changeCountry(data: any){
        /* console.log("Nuevo pais seleccionado: ", data); */
        this.phone.country_code = data.code;
        this.notifyChangeValue.emit(this.phone);
    }

    private changeRegex(data: string){
        /* console.log("Nueva regex: ", data); */
        this.phoneIdForm.get('value')?.setValidators([Validators.required, Validators.pattern(data)]);
        this.phoneIdForm.get('value')?.updateValueAndValidity();
        this.notifyChangeValue.emit(this.phone);
    }

}
