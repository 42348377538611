import { Optional } from "@angular/core";
/* import { IdentifierProfile } from "../person/identifierProfile";
import { SaludSoftId } from "../person/salud.soft.id"; */
import { ProfileContext } from "../../context/profileContext";
import { IdentifierProfile } from "../../person/identifierProfile";
import { EmailSSO } from "../identifiers/email";
import { PhoneSSO } from "../identifiers/phone";
import { Ids } from "../identifiers/ids";
import { DataToken } from "../../token/data-token";
import { PersonLSSSO } from "./personLSSSO";
import { PersonSSO } from "../person/person";
import { Identifier } from "../../person/identifier.model";
import { DocumentSSO } from "../identifiers/document";

export class PersonContextSSO{
    uuid?: string;
    first_name?: string;
    last_name?: string;
    registered?: boolean;
    profiles?:string[];
    /* ids?: Ids; */
    document_ids?: DocumentSSO[];
    emails?: EmailSSO[];
    phones?: PhoneSSO[];
    external_ids?: any[];
    external_info?: any[];

    profilesContext: ProfileContext[] = [];
    selfie?: string;
    token?: string;
    tokenOperatorContext?: string[] = [];
    expiredToken?: string;
    function?: string;
    activeRoles: string[]|null = [];
    // estos 2 ultimos se deben de ignorar a la hora de enviar info al api
    id_login: IdentifierProfile[] = [];
    id_search: IdentifierProfile[] = [];

    constructor(data?: Partial<PersonContextSSO>) {
        Object.assign(this, data);
    }

    public getMailVerified(){
        return this.emails?.find((email: EmailSSO) => email.verified == true);
    }
    
    public getPhoneVerified(){
        return this.phones?.find((phone: PhoneSSO) => phone.verified == true);
    }

    // seteamos los datos del token
    public setDataToken(dataToken: DataToken){
        /* console.log("[PersonCont.ts] => Entro a setDataToken()"); */
        this.token = dataToken.long_token;
        let timeExpiredToken = Date.parse(dataToken.expiration_moment as string);
        let dateFormat = new Date(timeExpiredToken);
        this.expiredToken = ""+dateFormat.getTime();
    }

    // determina si 2 personas son iguales
    // TODO: reemplazar por un metodo mas infalible
    public equals(person: PersonSSO|PersonLSSSO|PersonContextSSO){
        if(person.document_ids && (person.document_ids.length > 0)){
          /* console.log("[Person.model] => Datos de la persona actual: ", this);
          console.log("[Person.model] => Datos de la persona: ", person); */
            if(!this.document_ids || (this.document_ids.length < 1)){
              /* console.log("[Person.model] => Equals - false 1"); */
              return false;
            }
            if(person.document_ids[0].value == this.document_ids[0].value){
              /* console.log("[Person.model] => Equals - true 1"); */
              return true;
            }
            /* console.log("[Person.model] => Equals - false 2"); */
            return false;
        }
        else{
            if(this.document_ids && (this.document_ids.length > 0)){
              /* console.log("[Person.model] => Equals - false 3"); */
              return false;
            }
            // si ninguno de los 2 tiene civilId, comparamos los nombres
            else{
                if((person.first_name == this.first_name) && (person.last_name == this.last_name)){
                  /* console.log("[Person.model] => Equals - true 2"); */
                  return true;
                }
                /* console.log("[Person.model] => Equals - false 4"); */
                return false;
            }
        }
      }

    // esto devolveria el objeto de personas minificado
    public getPerson(){
        let person = {
            uuid: this.uuid,
            first_name: this.first_name,
            last_name: this.last_name,
            /* ids: this.ids, */
            document_ids: this.document_ids,
            emails: this.emails,
            phones: this.phones,
            external_ids: this.external_ids,
            external_info: this.external_info,
            profiles: this.getProfiles(),
            registered: this.registered
        };

        return new PersonSSO(person);
    }

    private getProfiles() {
        let profiles: string[] = [];
        for (let i = 0; i < this.profilesContext.length; i++) {
            profiles.push(this.profilesContext[i].profile as string);
        }

        return profiles;
    }

    // esto devolveria el objeto de personas minificado
    public getPersonContextMinified(){

    }

    // devuelve el unico identifier del perfil recibido, null si no existe
    public getIdentifierLogin(profile: string){
        let identifierProfile = this.id_login.find(id => id.profile == profile);
        if(!identifierProfile)
            return null;
        return identifierProfile.id;
    }

    // devuelve el unico identifier del perfil recibido, null si no existe
    public getIdentifierSearch(profile: string){
        let identifierProfile = this.id_search.find(id => id.profile == profile);
        if(!identifierProfile)
            return null;
        return identifierProfile.id;
    }

    public updateProfile(profile: string, domain: string|null, isRegistered: boolean|null, status: string|null, deadline: number|null){
        for (let i = 0; i < this.profilesContext.length; i++) {
            if(this.profilesContext[i].profile == profile){
                this.profilesContext[i].domain = domain;
                this.profilesContext[i].isRegistered = isRegistered;
                this.profilesContext[i].status = status;
                this.profilesContext[i].deadline = 0;
            }
        }
    }

    // ##################################################################################################
    // ######################################## ver si borrar esto ######################################

    // Agrega el identifier segun el perfil, solo puede existir un identifier por perfil
    public addIdentifierLogin(profile: string, identifier: Identifier){
        this.deleteIdentifierLogin(profile, identifier);
        this.id_login.push(new IdentifierProfile({profile: profile, id: identifier}));
    }

    public deleteIdentifierLogin(profile: string, identifier: Identifier){
        this.id_login = this.id_login.filter(id => id.equals(new IdentifierProfile({profile: profile, id: identifier})));
    }

    // Agrega el identifier segun el perfil, solo puede existir un identifier por perfil
    public addIdentifierSearch(profile: string, identifier: Identifier){
        this.deleteIdentifierSearch(profile, identifier);
        this.id_search.push(new IdentifierProfile({profile: profile, id: identifier}));  
    }

    public deleteIdentifierSearch(profile: string, identifier: Identifier){
        this.id_search = this.id_search.filter(id => id.equals(new IdentifierProfile({profile: profile, id: identifier})));
    }
}
