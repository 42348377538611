import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-external-id',
  templateUrl: './external-id.component.html',
  styleUrls: ['./external-id.component.scss']
})
export class ExternalIdComponent implements OnInit {

  @Input() id?: string;
  @Input() displayText: string = "horizontal";        // vertical/horizontal
  @Input() text?: string;
  @Input() placeholder?: string;
  @Input() showEye: boolean = true;
  @Input() regex: string|null = null;
  @Input() colorText?: string;
  // eventos emitidos de afuera
  @Input() updateValue: EventEmitter<any>;
  @Input() resetValue: EventEmitter<any>;

  @Output() notifyChange = new EventEmitter<any>();     // evt para informar el cambio de valor

  eReset: EventEmitter<any> = new EventEmitter<any>();     // evt para informar el reset del externalId
  eUpdate: EventEmitter<any> = new EventEmitter<any>();     // evt para informar el update del externalId

  constructor() { }

  ngOnInit(): void {

    // aca escuchamos la info que nos pueden enviar de afuera
    if(typeof this.updateValue != "undefined"){
      this.updateValue.subscribe((data: any) => {
        /* console.log("[External-id.comp] => Update de input: ", data); */
        this.eUpdate.next(data);
      })
    }

    if(typeof this.resetValue != "undefined"){
      this.resetValue.subscribe((data: any) => {
        /* console.log("[External-id.comp] => Reset de input: ", data); */
        this.eReset.next(data);
       })
    }

  }

  public changeValue(data: any){
    /* console.log("[External-id.comp] == Cambio el valor: ", data); */
    this.notifyChange.emit({value: {value: data.value}, valid: data.valid});
  }

}