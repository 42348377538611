<!--feature css start-->
<section class="saas2 feature booking" id="company-profile">
  <div class="container">
      <div class="row">
          <div class="col-md-10 offset-md-1">
              <div class="title">
                  <div class="main-title">
                      <h2>{{'Company Profile' | translate }}</h2>
                  </div>
                  <div class="sub-title">
                      <p class="sub-title-para">{{"Hms tech is the technological arm of the hms group, providing solutions at the technological forefront" | translate}}.</p>
                  </div>
              </div>
          </div>
          <div class="col-xl-8 col-md-7">
              <div class="center-text justify-content-center">
                  <div class="image-container">
                      <img src="assets/images/hms-tech/company-profile/company-profile-632x565.png" alt=""
                           class="img-fluid advance-feature-img">
                  </div>
              </div>
          </div>
          <div class="col-xl-4 col-md-5">
              <div *ngFor="let data of features">
                  <div [routerLink]="[data.path]" class="center-content justify-content-start">
                      <img [src]="data.img" alt="" class="img-fluid">
                      <div class="feature-content">
                          <h5 class="feature-head">         
                            <a >{{data.title | translate }}</a>
                          </h5>
                          <p class="feature-para">{{data.description | translate }}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--feature css end-->
