export enum Identifiers {
  SALUD_SOFT_ID = "saludSoftIds",
  EXTERNAL_ID = "external_id",
  CIVIL_ID = "civilIds",
  PHONE = "phones",
  EMAIL = "emails",
  AFFILIATE_ID = "affiliate_id",
  PRESTADOR_ID = "provider_id",
  CONSULTING_ID = "consulting_id",
  SECRETARY_ID = "secretary",
  CREDENTIAL_ID = "cred_id",
  CTD_V1 = "ctd_v1",
  CTD_V2 = "ctd_v2",
  QR_ID = "qr_id",
  HMS_ID = "hms_id"
}

export enum SaludSoftAttribute {
  CONTESTADOR = "com_con",
  TELEFONO = "com_tel",
  CELULAR = "com_cel"
}

export enum Status {
  UNTRUSTED = "untrusted",
  TRUSTED = "trusted",
  VERIFIED = "verified"
}

export enum PersonType {
  AFILLIATE = "afiliado",
  PERSON = "persona",
  PROVIDER = "prestador"
}

export enum CivilIds {
  AR_DNI = "DNI",
  AR_CUIT_CUIL = "CUIT/CUIL",
  AR_PAS = "PAS",
  BO_PAS = "PAS",
  BO_CI = "CI",
  BO_NIT = "NIT",
  BR_PAS = "PAS",
  BR_RG = "RG",
  BR_CPF_CNPJ = "CPF/CNPJ",
  CL_PAS = "PAS",
  CL_CI = "CI",
  CL_RUT = "RUT",
  CO_PAS = "PAS",
  CO_CC = "CC",
  CO_RUT = "RUT",
  CR_PAS = "PAS",
  CR_CI = "CI",
  CR_NITE = "NITE",
  CU_PAS = "PAS",
  EC_PAS = "PAS",
  EC_CC = "CC",
  EC_RUC = "RUC",
  SV_PAS = "PAS",
  SV_DUI = "DUI",
  SV_NIT = "NIT",
  GT_PAS = "PAS",
  GT_DPI = "DPI",
  GT_NIT = "NIT",
  HN_PAS = "PAS",
  HN_TDI = "TDI",
  HN_RTN = "RTN",
  MX_PAS = "PAS",
  MX_CURP = "CURP",
  MX_RFC = "RFC",
  NI_PAS = "PAS",
  NI_CI = "CI",
  NI_RUC = "RUC",
  PA_PAS = "PAS",
  PA_CIP = "CIP",
  PA_RUC = "RUC",
  PY_PAS = "PAS",
  PY_CIC_CI = "CIC/CI",
  PY_RUC = "RUC",
  PE_PAS = "PAS",
  PE_DNI = "DNI",
  PE_RUC = "RUC",
  DO_PAS = "PAS",
  DO_DNI = "CIE",
  DO_RUC = "RNC",
  UY_PAS = "PAS",
  UY_DNI = "CI",
  UY_RUC = "RUT",
  VE_PAS = "PAS",
  VE_DNI = "CI",
  VE_RUC = "RIF",
}



export function getIdentifiersAsArray(){
  return Object.keys(Identifiers)
        .filter(value => isNaN(Number(value)) === false)
        .map(key => Identifiers[key]);
}
