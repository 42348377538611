<app-modern-sass-nav></app-modern-sass-nav>
<section class="saas2 services" id="careers">
  <div class="container saas2-services">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="title">
          <div class="main-title">
            <h2>{{ "Career Listings" | translate }}</h2>
          </div>
        </div>
      </div>

      <!-- Filtros -->
      <div class="col-md-10 offset-md-1">
        <div class="filters row">
          <div class="col-md-6">
            <label for="countryFilter"
              >{{ "Filter by Country" | translate }}:</label
            >
            <select
              class="form-control"
              id="countryFilter"
              [(ngModel)]="selectedCountry"
              (change)="filterByCountry($event.target.value)">
              <option value="All" >{{ "All" | translate }}</option>
              <option value="Argentina">Argentina</option>
              <option value="Colombia">Colombia</option>
              <option value="Chile">Chile</option>
              <option value="Mexico">Mexico</option>
              <option value="Peru">Peru</option>
              <option value="Uruguay">Uruguay</option>
              <option value="Latam">Latam</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="categoryFilter"
              >{{ "Filter by Category" | translate }}:</label
            >
            <select
              class="form-control"
              id="categoryFilter"
              [(ngModel)]="selectedCategory"
              (change)="filterByCategory($event.target.value)"
            >
              <option value="All">{{ "All" | translate }}</option>
              <option value="Software Development">
                {{ "Software Development" | translate }}
              </option>
              <option value="Infrastructure and Operations">{{ "Infrastructure and Operations" | translate }}</option>
              <option value="Sales and Marketing">{{ "Sales and Marketing" | translate }}</option>
              <option value="Receptionist and Commercial Support">{{ "Support" | translate }}</option>
              <option value="Project and Product Management">{{ "Management" | translate }}</option>
              <option value="Administration and Finance">{{ "Administration and Finance" | translate }}</option>
            </select>
          </div>
        </div>
      </div>

      <!-- Lista de trabajos -->
      <div class="col-md-12 scroll-container">
        <ul class="list-group">
          <ng-container *ngFor="let career of filteredCareers">
            <li [routerLink]="['candidate-form', { country: career.country, position: career.title }]"  class="list-group-item d-flex justify-content-between align-items-center" 
                *ngIf="career.status !== 'Finished'" [class.closed-career]="career.status === 'Closed'">
              <a class="career-link" [attr.aria-disabled]="career.status === 'Closed' ? true : null">
                <div class="flag-icon {{ career.flag }} margin-flag" title="{{ career.country }}"></div>
                {{ career.title | translate }} - {{ career.category | translate }}
              </a>
              <span class="status" [ngClass]="{
                'open-status': career.status.includes('Open'),
                'closed-status': career.status === 'Closed'
              }">
                {{ career.status | translate }}
              </span>              
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- footer -->
<app-hms-footer></app-hms-footer>
