<div class="modal-header">
  <h4 class="modal-title">{{"Logout Confirmation" | translate}}</h4>
</div>
<div class="modal-body">
  <p>{{"Are you sure you want to log out?" | translate}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cancel')">{{"Cancel" | translate}}</button>
  <button type="button" class="btn btn-primary confirm-button" (click)="activeModal.close('Confirm')">{{"Confirm" | translate}}</button>
</div>
