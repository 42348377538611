<!--footer1 start-->
<footer class="saas1 footer2">
  <div class="container">
      <div class="row">
        <div class="col-md-3">
            <div class="footer-title mobile-title">
                <h3 class="text-white">{{'Address' | translate }}</h3>
            </div>
            <div class="footer-contant">
                <h5 class="footer-headings">{{'Address' | translate }}<!-- <i class="fa fa-map-marker" aria-hidden="true"></i> ---></h5>
                
                <div class="contact-us">
                    <h6 class="text-white para-address">Reconquista 737</h6>
                    <h6 class="text-white para-address">Buenos Aires</h6>
                    <h6 class="text-white para-address">C1003ABO</h6>
                    <h6 class="text-white para-address">Argentina.</h6>
                </div>
            </div>
        </div>


          <div class="col-md-3">
              <div class="footer-title mobile-title">
                  <h3 class="text-white">{{'Phone' | translate }}</h3>
              </div>
              <div class="footer-contant">
                  <h5 class="footer-headings">{{'Phone' | translate }}</h5>
                  <div>
                      <ul class="footer-lists">
                        
                            <li class="list-item"><i class="fa fa-phone"></i>
                                <a href="tel:+54 11 4311 4112"> +54 11 4311 4112</a>
                            </li>
                      
                            <li class="list-item"><i class="fa-brands fa-whatsapp"></i>
                                <a [href]="getWhatsAppLink()" target="_blank">
                                  +54 11 4049 4999
                                </a>
                            </li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="col-md-3">
              <div class="footer-title mobile-title">
                  <h3 class="text-white">{{'Company' | translate }}</h3>
              </div>
              <div class="footer-contant">
                  <h5 class="footer-headings">{{'Company' | translate }}</h5>
                  <div>
                      <ul class="footer-lists">
                        <li class="list-item">
                            <i class="fa-brands fa-google-play"></i> <a href="https://play.google.com/store/apps/dev?id=9066154598995530483" target="_blank"> {{"HMS TECH in Play Store" | translate}}</a>
                        </li>
                        <li class="list-item">
                            <i class="fa-brands fa-linkedin"></i> <a href="https://www.linkedin.com/company/hms-health/mycompany/" target="_blank"> Linkedin</a>
                        </li>
                        <li class="list-item">
                          <a href="https://www.hms-health.com" target="_blank">
                            <div class="logo-container">
                                <i class="fas fa-external-link-alt"></i>
                                <img id="logo-hms-health" src="assets/images/logo/HMS_horizontal_submarca_health.png" alt="Logo HMS Health">
                                <span class="tooltip">{{"Click to go to HMS Health site" | translate}}</span>
                            </div> 
                        </a>
                        </li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="col-md-3">
              <div class="footer-title mobile-title">
                  <h3 class="text-white">{{'Support' | translate }}</h3>
              </div>
              <div class="footer-contant">
                  <h5 class="footer-headings">{{'Support' | translate }}</h5>
                  <div>
                      <ul class="footer-lists">
                          <li class="list-item" *ngIf="isLoggedIn">
                            <i class="fa fa-envelope" ></i> <a href="mailto:support@hms-tech.com"> {{'Technical Support' | translate }}: <br> support@hms-tech.com</a>
                          </li>
                          <li class="list-item" *ngIf="isLoggedIn">
                            <i class="fa fa-envelope" ></i> <a href="mailto:sales@hms-tech.com"> {{'Sales & Information' | translate }}: <br> sales@hms-tech.com</a>
                          </li>
                          <li class="list-item">
                            <div class="contact-us">
                              <i class="material-symbols-outlined">outgoing_mail </i>
                              <a class="contact-us-text" href="https://hms-tech.odoo.com/contactus" target="_blank"> {{ 'Contact Us' | translate }}</a>
                            </div>
                          </li>
                          <li class="list-item" *ngIf="isLoggedIn">
                            <i class="fa fa-support" aria-hidden="true"></i> <a href="https://hms-tech.odoo.com/help" target="_blank"> {{'Contact Support'| translate }} </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>
</footer>
<!--footer1 end-->

<!-- footer2 start -->
<footer class="saas1 footer2">
    <div class="container">
        <div class="row">
            <div class="col-6 align-self-center text-right">
                <div class="footer-left">
                    <div class="footer-logo">
                        <a href="/assets/images/ISO.pdf" target="_blank" class="footer-link">{{"ISO 9001:2015 CERTIFICATION" | translate}}</a>
                        <span class="separator">|</span>
                        <a href="/assets/images/HMS-POLÍTICA-DE-CALIDAD.pdf" target="_blank" class="footer-link">{{"QUALITY POLICY" | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="col-6 align-self-center">
                <div class="footer-right">
                    <p>&copy; {{"2024 All right reserved" | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<!--footer2 end-->
<app-floating-buttons></app-floating-buttons>
<app-tap-to-top></app-tap-to-top>