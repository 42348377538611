<app-modern-sass-nav></app-modern-sass-nav>
<!--about hms group start-->
<section class="app2 header overflow-unset" id="about-hms-tech">
  <div class="app2-header bg">
      <div class="container">
          <div class="row">
              <div class="col-xl-7 col-md-6">
                  <div class="center-text">
                      <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before text-uppercase"><span>{{"Company Profile" | translate}}</span></h6>
                        </div>
                          <div class="header-text">
                              <h1>{{"About" | translate}} <span class="bold-text">{{"Hms" | translate}}</span> {{"Group" | translate}}</h1>
                          </div>
                          <div class="header-sub-text">
                              <p class="text-white">{{"Is a company with extensive expertise that was originally consolidated in IT solutions for the health market, incorporating technological infrastructure, state-of-the-art solutions and services, which provide strategic and real-time information to optimize customer management, with recognized achievements optimizing spending and reducing costs, with an increase in the quality of care through the automation of processes and ensuring the self-management of its users." | translate}}</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-5 col-md-6">
                  <div class="img-mobile set-abs">
                      <img src="assets/images/hms-tech/about-hms-group/about-hms-group.png" alt="" class="headaer-image">
                  </div>
                  <div class="wave-orange"></div>
              </div>
          </div>
      </div>
      <div class="wave"></div>
  </div>
</section>
<!--about hms group end-->
<!-- footer -->
<app-hms-footer></app-hms-footer>