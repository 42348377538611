<app-modern-sass-nav></app-modern-sass-nav>
<!--our-people start-->
<section class="event speaker" id="our-people">
    
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title2">
                    <div class="title title2">
                        <h6 class="font-primary borders main-text"><span>{{"Company Profile" | translate}}</span></h6>
                    </div>
                    <div class="main-title">
                        <h3 class="font-primary borders main-text">{{"Our People" | translate}}</h3>
                    </div>
                    <div class="sub-title">
                        <P>{{"Our people are the most important asset of our company. We are a team of professionals with extensive experience in the health and technology sectors, who work together to provide the best solutions to our clients."| translate }}</P>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container" *ngFor="let data of experts">
                <div class="text-center">
                    <div class="team-img">
                        <img [src]="data.img" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <!--<li><a href="#"><i class="fa fa-facebook center-content" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter center-content" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-google center-content" aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/javier-malarini-106a8363/" target="_blank"><i class="fa fa-linkedin center-content" aria-hidden="true" ></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">{{data.name}}</h5>
                        <h6 class="post text-center ">{{data.designation}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</section>
<!-- footer -->
<app-hms-footer></app-hms-footer>
<!--our-people end-->
<!--borrador v2 start
<div id="your-scroll-container-id" class="horizontal-scroll-container">
    <div *ngFor="let expert of experts" class="horizontal-scroll-item">
      <img [src]="expert.img" alt="{{ expert.name }}">
      <div class="text-content">
        <h3 class="texto_b">{{ expert.name }}</h3>
        <p class="texto_c">{{ expert.designation }}</p>
      </div> 
      </div>
     <div>
    </div>
</div> -->
<!--our-people end-->