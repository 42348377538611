export const Microservices: any = {
    DOMAIN_SV: { name: "domain_server", path : "/api/domains", namev2: "domainServer"},
    MENU_SV: { name: "menu_server", path: "/api/menus", namev2: "menuServer"},
    CONTROL_SV: { name: "control_server", path: "/api/controls", namev2: "controlServer"},
    CARD_SV: { name: "data_card_viewer", path: "/api/cards", namev2: "credentialServer"},
    MINIO_SV: { name: "minio_server", path: "/api/minio", namev2: "minioServer"},
    TOKENIZER_SV: { name: "tokenizer_server", path: "/api/tokenizer", namev2: "tokenizerServer"},
    CARD2_SV: { name: "card_server", path: "/api/card_server", namev2: "cardServer"},
    AFIV10_SV: { name: "afl_v10", path: "/api/afl_v10", namev2: "afiv10Server"},
    CAV20_SV: { name: "ca_v20", path: "/api/ca_v20", namev2: "cav20Server"},
    CONFIG_SV: { name: "config_server", path: "/api/app", namev2: "configServer"},
    PEOPLE_SV: { name: "people_server", path: "/api/people", namev2: "peopleServer"},
    PEOPLE2_SV: { name: "people_server_v2", path: "/api/people_v2", namev2: "people2Server"},
    AFFILIATE_SV: { name: "affiliate_server", path: "/api/affiliate", namev2: "affiliateServer"},
    AUTH_SV: { name: "authenticator_server", path: "/api/auth", namev2: "authServer"},
    AUTH2_SV: { name: "auth_server", path: "/api/auth2", namev2: "auth2Server"},
    PLACE_SV: { name: "place_server", path: "/api/places", namev2: "placesServer"},
    PROFILE_SV: { name: "profile_server", path: "/api/profiles", namev2: "profileServer"},
    TAG_SV: { name: "tag_server", path: "/api/tags", namev2: "tagServer"},
    CARTILLA_SV: { name: "cartilla_server", path: "/api/cartilla", namev2: "cartillaServer"},
    BENEFIT_SV: { name: "benefits_server", path: "/api/benefits", namev2: "benefitServer"},
    ADMISION_SV: { name: "admissions_server", path: "/api/admissions", namev2: "admissionServer"},
    INSUMOS_SV: { name: "insumos_server", path: "/api/insumos", namev2: "insumoServer"},
    DIAGNOSIS_SV: { name: "diagnosis_server", path: "/api/diagnosis", namev2: "diagnosisServer"},
    CREDENTIAL_SV: { name: "credentials_server", path: "/api/credentials", namev2: "credentialServer"}
}
