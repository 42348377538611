import { Injectable } from '@angular/core';
import { link } from 'fs';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
  fragment?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
    //HOME
    //{ path: '/home', title: 'Home', type: 'link'},

    //HMS-TECH
	 {
      path: '/home' ,title: 'Home', type: 'sub', children: [
            { path: '/home', title: 'Company Profile', type: 'link',  fragment:'company-profile', children: [
               { path: '/home/company-profile/about-hms-group', title: 'About HMS Group', type:'link'},
               { path: '/home/company-profile/our-history', title: 'Our History', type: 'link'},
               { path: '/home/company-profile/our-vision', title: 'Our Vision', type:'link' },
               //OCULTAR OUR PEOPLE { path: '/home/company-profile/our-people', title: 'Our People', type: 'link' } //
              ] },

               { path: '/home/careers',title: 'Careers', type: 'link', fragment:'careers', children: [
                  { path: '/home/careers/candidate-form', title: 'Candidate Form', type: 'link' }, ],
                  }, 
              {
                path:'/home/our-work', title: 'Our Work', type: 'link', fragment:'our-work', children: [
                  { path: '/home/our-work/products', title: 'Products', type: 'link',fragment: 'projects' },
                ],
              },
            ],

    },

    //SERVICES LINES
    {
       path: '/service-lines' , title: 'Service lines', type: 'sub', children: [
           { path: '/service-lines/staffing', title: 'Staffing', type: 'link',fragment:'staffing',},
           { path: '/service-lines/technologies', title: 'Technologies', type: 'link',fragment:'technologies', },
           { path: '/service-lines/saas-solutions', title: 'SAAS Solutions', type: 'link',fragment:'saas-solutions',},
         ],
       },

   //PRODUCTS
    {
        path: '/products' , title: 'Product suite', type:'sub',children: [
          { path: '/products', title: 'Security Framework', fragment:'security-framework' ,type: 'link'},
          { path: '/products', title: 'Kirio', fragment:'kirio' ,type: 'link'},
          { path: '/products', title: 'SSAPI', fragment:'ssapi' ,type: 'link'},
          { path: '/products', title: 'Digital Credentials', fragment:'digital-credentials' ,type: 'link'},
          { path: '/products', title: 'Hms ABC Book',fragment:'hms-abc-book' , type: 'link'},
          { path: '/products', title: 'Hms Dam', fragment:'hms-dam' ,type: 'link'},
          { path: '/products', title: 'Hms Geo Tags',fragment:'hms-geo-tags' , type: 'link'},
          { path: '/products', title: 'Hms Web', fragment:'hms-web' ,type: 'link'},
          { path: '/products', title: 'Contact Center', fragment:'contact-center' ,type: 'link'},
       ],
    },
   
   // CONTACT US
   { path: 'https://hms-tech.odoo.com/contactus', title: 'Contact us', type: 'extLink', children: [
      { path: 'https://hms-tech.odoo.com/contactus', title: 'Contact Us', type: 'extTabLink' },
      { path: 'https://hms-tech.odoo.com/help', title: 'Tech Support', type: 'extLink',},


   ], },
  ];
    
   
  
  	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  scrollTo(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
   
}