import { Injectable } from '@angular/core';
import { ProfileContext } from '../../model/context/profileContext';
import { ProfilesLoginByApp, ProfilesLoginByAppSSO } from '../../assets/mocks/profiles';
import { ConfigService } from '../config.service';
import { Person } from '../../model/person/person.model';
import { PersonSSO } from '../../model/SSO/person/person';

@Injectable({
  providedIn: 'root'
})
export class AdminProfileService {

  private requiredProfiles: string[] = [];                   // perfiles permitidos
  /* requiredProfiles$ = new BehaviorSubject<any>(null); */

  constructor(
    private configService: ConfigService
  ) {
    /* console.log("[Adm_prof.serv] => Admin de perfiles creado."); */
  }

  // devuelve un perfil de contexto a partir de los datos recibidos
  public getProfile(profile: string, isRegistered: boolean|null, statusPerson: string){
    let dataProfile = {
      domain: this.configService.getAppDomain() as string,
      profile: profile,
      isRegistered: isRegistered,
      status: statusPerson,
      deadline: 0
    };
    
    return new ProfileContext(dataProfile);
  }

  // devuelve un perfil de contexto a partir de los datos recibidos
  public getBaseProfile(profile: string){
    let dataProfile = {
      domain: this.configService.getAppDomain() as string,
      profile: profile,
      isRegistered: null,
      status: null,
      deadline: 0
    };
    
    return new ProfileContext(dataProfile);
  }

  // devuelve el perfil requerido por la app para poder hacer login exitoso, null si no encuentra la app en su DB
  public getProfilesLoginApp(app: string){
    let dataApp = ProfilesLoginByApp.find(data => data.app == app);
    if(dataApp)
      return dataApp.profiles;
    return null;
  }

  public getProfilesLoginAppSSO(app: string){
    let dataApp = ProfilesLoginByAppSSO.find(data => data.app == app);
    if(dataApp)
      return dataApp.profiles;
    return null;
  }

  public getRequeridProfiles(){
    return this.requiredProfiles;
  }

  public setRequeridProfiles(profiles: string[]){
    this.requiredProfiles = profiles;
  }

  // ####################################################################################################################
  // #################################### control de perfiles por app/ funcionalidad ####################################

  // Determina si la persona recibida cuenta con los perfiles necesarios para hacer login en la app
  public operatorEnabled(person: Person){
    /* console.log("Perfiles del usuario CALL: ", profilesLevel); */
    /* let userProfiles = person.profiles;
    if(!userProfiles)
      return false;
    
    return this.containProfiles(this.requiredProfiles, userProfiles); */
    if(!person.profiles)
      return false;
    
    return this.containProfiles(this.requiredProfiles, person.profiles);
  }

  public operatorEnabledSOO(person: PersonSSO){
    /* console.log("Perfiles del usuario CALL: ", profilesLevel); */
    let userProfiles: any = person.profiles;
    if(!userProfiles)
      return false;
    // sacamos solo los roles
    let onlyProfile: any[] = [];

    userProfiles.forEach((profile: string) => {
      let arrayProfile = profile.split("@");
      onlyProfile.push(arrayProfile[0]);
    })
    
    return this.containProfiles(this.requiredProfiles, onlyProfile);
  }

  // determina si cada elementos de la lista profiles1 se encuentra dentro de la lista profiles2
  private containProfiles(profiles1: string[]|null, profiles2: string[]){
    if(!profiles1){
      console.warn("[Admin-profile.serv] => No contiene perfiles el array recibido.");
      return;
    }
      
    return profiles1.every(element => profiles2.indexOf(element) > -1);
  }

}

