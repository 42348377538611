<app-modern-sass-nav></app-modern-sass-nav>
<!--our vision start-->
<section class="app2 header overflow-unset" id="ourvision-hms-tech">
  <div class="app2-header bg">
      <div class="container">
          <div class="row">
              <div class="col-xl-7 col-md-6">
                  <div class="center-text">
                      <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before text-uppercase"><span>{{"Company Profile" | translate}}</span></h6>
                        </div>
                          <div class="header-text">
                              <h1>{{"Our" | translate}} <span class="bold-text">{{"Vision" | translate}}</span> </h1>
                          </div>
                          <div class="header-sub-text">
                              <p class="text-white">{{"Our vision contributes to the extension of interconnected ecosystems, providing key-pieces that are located in diverse layers and representations, equipped with algorithms and intelligence to support people and users in their daily management and decision-making. Therefore, we invest in the development of innovative technological products and solutions for organizations and their user communities." | translate}}</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-5 col-md-6">
                  <div class="img-mobile set-abs">
                      <img src="assets/images/hms-tech/our-vision/Imagen-Our Vision-542x580.png" alt="" class="headaer-image">
                  </div>
                  <div class="wave-orange"></div>
              </div>
          </div>
      </div>
      <div class="wave"></div>
  </div>
</section>
<!--our vision end-->
<!-- footer -->
<app-hms-footer></app-hms-footer>