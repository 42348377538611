import { Coordinate } from "../coordinate";
//import { LoggedUser } from "./loggedUser";

export class ContextSass{
    uuid?: string;
    app?: string;
    domain?: string;
    environment?: string;
    /* affiliate_id?: string; */
    /* operator?: string; */
    location_generation?: Coordinate;
    location_capture?: Coordinate;
    offered_profiles : any = {};                   // perfiles ofrecidos
    offered_roles : any = {};                        // roles ofrecidos
    requested_profiles: any = [];              // roles demandados, serian los -1, los q pide el site/app
    requested_roles: any = [];              // roles demandados, serian los -1, los q pide el site/app
    users_entered: any[] = [];               // tokens de cada operador
    shared_context?: string[];              // tokens de contextos compartidos
    token?: string;

    constructor(data?: Partial<ContextSass>) {
        Object.assign(this, data);
    }
}