import { Optional } from "@angular/core";
import { ProfileContext } from "../../context/profileContext";
import { IdentifierProfile } from "../../person/identifierProfile";
import { DataToken } from "../../token/data-token";
import { Identifier } from "../../person/identifier.model";
import { PersonSSO } from "../person/person";
import { Ids } from "../identifiers/ids";
import { PersonContextSSO } from "./personContextSSO";
import { EmailSSO } from "../identifiers/email";
import { PhoneSSO } from "../identifiers/phone";
import { DocumentSSO } from "../identifiers/document";

export class PersonLSSSO{
  uuid?: string;
  first_name?: string;
  last_name?: string;
  registered?: boolean;
  profiles?:string[];
  /* ids?: Ids; */
  external_info?: any[];
  document_ids?: DocumentSSO[];
  emails?: EmailSSO[];
  phones?: PhoneSSO[];
  external_ids?: any[];
  // extra de la estructura base
  selfie?: string;
  token?: string;
  tokenOperatorContext?: string[] = [];
  expiredToken?: string;
  profilesContext?: ProfileContext[];
  function?: string;
  activeRoles: string[]|null = [];
  // estos 2 ultimos se deben de ignorar a la hora de enviar info al api
  id_login: IdentifierProfile[] = [];
  id_search: IdentifierProfile[] = [];


  constructor(data?: Partial<PersonLSSSO>) {
      Object.assign(this, data);
      this.getProfilesContext();
  }

  public clone?(): PersonLSSSO {
      return new PersonLSSSO(this);
  }

  public getMailVerified(){
      return this.emails?.find((email: EmailSSO) => email.verified == true);
  }
  
  public getPhoneVerified(){
      return this.phones?.find((phone: PhoneSSO) => phone.verified == true);
  }

    // devuelve un objeto PersonContext
  public getPersonContext(){
    let personContext = {
      uuid: this.uuid,
      first_name: this.first_name,
      last_name: this.last_name,
      /* ids: this.ids, */
      document_ids: this.document_ids,
      emails: this.emails,
      phones: this.phones,
      external_ids: this.external_ids,
      external_info: this.external_info,
      profiles: this.profiles,
      profilesContext: this.getProfilesContext(),
      token: this.token,
      tokenOperatorContext: this.tokenOperatorContext,
      expiredToken: this.expiredToken,
      function: this.function,
      activeRoles: [],
      registered: this.registered,
      id_login: this.id_login,
      id_search: this.id_search
    };
    
    return new PersonContextSSO(personContext);
  }

// devuelve un objeto Person
public getPersonSSO(){
    let person = {
      firstname: this.first_name,
      lastname: this.last_name,
      uuid: this.uuid,
      first_name: this.first_name,
      last_name: this.last_name,
      document_ids: this.document_ids,
      emails: this.emails,
      phones: this.phones,
      external_ids: this.external_ids,
      external_info: this.external_info,
      profiles: this.profiles,
      profilesContext: this.getProfilesContext(),
      registered: this.registered,
    };
    
    return new PersonSSO(person);
  }

// determina si 2 personas son iguales
public equals(person: PersonSSO|PersonLSSSO|PersonContextSSO){
    if(person.document_ids && (person.document_ids.length > 0)){
      /* console.log("[Person.model] => Datos de la persona actual: ", this);
      console.log("[Person.model] => Datos de la persona: ", person); */
        if(!this.document_ids || (this.document_ids.length < 1)){
          /* console.log("[Person.model] => Equals - false 1"); */
          return false;
        }
        if(person.document_ids[0].value == this.document_ids[0].value){
          /* console.log("[Person.model] => Equals - true 1"); */
          return true;
        }
        /* console.log("[Person.model] => Equals - false 2"); */
        return false;
    }
    else{
        if(this.document_ids && (this.document_ids.length > 0)){
          /* console.log("[Person.model] => Equals - false 3"); */
          return false;
        }
        // si ninguno de los 2 tiene civilId, comparamos los nombres
        else{
            if((person.first_name == this.first_name) && (person.last_name == this.last_name)){
              /* console.log("[Person.model] => Equals - true 2"); */
              return true;
            }
            /* console.log("[Person.model] => Equals - false 4"); */
            return false;
        }
    }
  }

private getProfilesContext() {
    let profilesContext: ProfileContext[] = [];
    if(!this.profiles){
      console.warn("[PersonLSSSO.ts] => La persona no cuenta perfiles.");
      return;
    }
    for (let i = 0; i < this.profiles.length; i++) {
      let profileBaseContext = {
        domain: "",
        profile: this.profiles[i] as string,
        isRegistered: null,
        status: null,
        deadline: 0
      }
      profilesContext.push(new ProfileContext(profileBaseContext));
    }

    return profilesContext;
  }

  // seteamos los datos del token
  public setDataToken(dataToken: DataToken){
    /* console.log("[PersonCont.ts] => Entro a setDataToken()"); */
    this.token = dataToken.long_token;
    let timeExpiredToken = Date.parse(dataToken.expiration_moment as string);
    let dateFormat = new Date(timeExpiredToken);
    this.expiredToken = ""+dateFormat.getTime();
  }

  // actualizamos el 
  public updateProfile(profile: string, domain: string|null, isRegistered: boolean|null, status: string|null, deadline: number|null){
    for (let i = 0; this.profilesContext && (i < this.profilesContext.length); i++) {
        if(this.profilesContext[i].profile == profile){
            this.profilesContext[i].domain = domain;
            this.profilesContext[i].isRegistered = isRegistered;
            this.profilesContext[i].status = status;
            this.profilesContext[i].deadline = 0;
        }
    }
  }

  // Agrega si NO existe el token del operador
  public addTokenOperator(token: string){
    if(!this.tokenOperatorContext?.includes(token)){
      this.tokenOperatorContext?.push(token);
    }
  }

  // ##################################################################################################
  // ############################## id utilizados para login y busqueda ###############################

  // Agrega el identifier segun el perfil, solo puede existir un identifier por perfil
  public addIdentifierLogin(profile: string, identifier: Identifier){
    this.deleteIdentifierLogin(profile, identifier);
    this.id_login.push(new IdentifierProfile({profile: profile, id: identifier}));
  }

  public deleteIdentifierLogin(profile: string, identifier: Identifier){
      this.id_login = this.id_login.filter(id => id.equals(new IdentifierProfile({profile: profile, id: identifier})));
  }

  // Solo identificamos si la persona tiene algun id utilizado para login
  // NOTA: se podria determinar el perfil con el q se hace login (para distinguir de login de opradores con prestadores por ej)
  public existIdLogin(){
  if(this.id_login.length > 0)
      return true;
  return false;
  }

  // Agrega el identifier segun el perfil, solo puede existir un identifier por perfil
  public addIdentifierSearch(profile: string, identifier: Identifier){
      this.deleteIdentifierSearch(profile, identifier);
      this.id_search.push(new IdentifierProfile({profile: profile, id: identifier}));  
  }

  public deleteIdentifierSearch(profile: string, identifier: Identifier){
      this.id_search = this.id_search.filter(id => id.equals(new IdentifierProfile({profile: profile, id: identifier})));
  }
}
