import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-generic',
  templateUrl: './link-generic.component.html',
  styleUrls: ['./link-generic.component.scss']
})
export class LinkGenericComponent implements OnInit {

  @Input() text?: string;
  @Input() urlLink?: string;
  @Input() colorText?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
