import { Identifiers } from "../../model/person/identifiers";

export const COMPONENT_TAG = {
  input_select: "selector",
  input_mail: "input_mail",
  input_doc: "input_doc",
  input_phone: "input_phone",
  input_hmsid: "input_hmsid",
  input_externalId: "input_external_id",
  input_pass: "input_pass",
  check: "checkbox",
  code_qr: "code_qr",
  link: "link",
  captcha: "captcha",
  button: "button"
}

export const TYPE_FORM = {
  login: "formLogin",
  search: "formSearcher"
}

export const TYPE_METHOD = {
  mail: { code: "email", label: "Email"},
  document: {code: "document", label: "Documento"},
  phone: {code: "phone", label: "Télefono"},
  idExternal: {code: "external_id", label: "Id externo"},
  idHms: {code: "hms_id", label: "Identificador de HMS"},
  qr: {code: "code_qr", label: "Código QR"}
}

export const configOld: any = {
    title: { 
      show: true,
      label: "Login"
    },
    welcome: {
      show_icon: true,
      show: true,
      label: "Bienvenido al Contact Center"
    },
    subtitle: { 
      show: true,
      label: "Login de OPERADOR"
    },
    method: {
      show: true,
      label: "Seleccione el tipo de identificador",
      default: "email"
    },
    types_method: [
      {
        name: "email",
        show: true,
        name_option: "Email",
        title: "",
        placeholder: "Ingrese su email"
      },
      {
        name: "document",
        show: true,
        name_option: "Documento",
        title: "Nro. documento",
        placeholder: "Ingrese su número de documento"
      },
      {
        name: "phone",
        show: true,
        name_option: "Teléfono/Celular",
        title: "Nro. de móvil",
        placeholder: "Ingrese su número de teléfono"
      },
      {
        name: "saludsoftId",
        show: true,
        name_option: "Id Hms",
        title: "Identificador de HMS",
        placeholder: "Ingrese su número de documento"
      }
    ],
    password: {
      label: "Contraseña",
      show: true,
      show_eye: true
    },
    captcha: {
      show: true,
      label: "Ingrese el código visualizado"
    },
    options_check: [
      {
        name: "remember_id",
        label: "Recordar valor principal de identificación",
        show: true
      },
      {
        name: "remember_pass",
        label: "Recordar contraseña",
        show: true
      },
      {
        name: "remember_login",
        label: "Ingresar automaticamente",
        show: true
      }
    ],
    options_links: [
      {
        name: "problems",
        url: "https://osiris.hms-tech.com:10203/",
        label: "Tengo problemas para ingresar",
        show: true
      },
      {
        name: "no_registered",
        url: "https://osiris.hms-tech.com:10204",
        label: "No estoy registrado",
        show: true
      }
    ]
  };

export const config: any = {
  type_form: TYPE_FORM.login,
  support_profiles: true,
  title: { 
    show: true,
    label: "Login"
  },
  welcome: {
    show_icon: true,
    show: true,
    label: "HMS Login Universal"
  },
  /* subtitle: { 
    show: true,
    label: "Login de OPERADOR"
  }, */
	items: [
		{
			name: "Método",
			label: "Identificador",
			item_type: COMPONENT_TAG.input_select,
			visible: true,
			enabled: true,
			index:0,
      show_eye: false,
      default: TYPE_METHOD.mail.label,
      /* default: TYPE_METHOD.document.label, */
			subitems: [
        {
          name: TYPE_METHOD.mail.label,
					label: "Email",
          code: Identifiers.EMAIL,
					item_type: COMPONENT_TAG.input_mail,
          placeholder: "proyecto@sass.com",
					visible: true,
					enabled: true,
					index:1,
          regex: "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/",
					required:true,
          show_eye: true
				},
        {
          name: TYPE_METHOD.document.label,
          label: "Número de Documento",
          code: Identifiers.CIVIL_ID,
          item_type: COMPONENT_TAG.input_doc,
          placeholder: "12345..",
          visible: true,
          enabled: true,
          index:1,
          required:true,
          show_eye: true
        },
        {
          name: TYPE_METHOD.phone.label,
          label: "Teléfono",
          code: Identifiers.PHONE,
          item_type: COMPONENT_TAG.input_phone,
          placeholder: "12345..",
          visible: true,
          enabled: true,
          index:1,
          required:true,
          show_eye: true
        },
				/* {
					name: TYPE_METHOD.idHms.label,
					label: "Tipo id hms",
          code: Identifiers.HMS_ID,
					item_type: COMPONENT_TAG.input_hmsid,
          placeholder: "12345",
					visible: true,
					enabled: true,
					index:1,
					required:true,
          show_eye: true
				}, */
				{
					name: TYPE_METHOD.idExternal.label,
					label: "Usuario / código / valor",
          code: Identifiers.EXTERNAL_ID,
					item_type: COMPONENT_TAG.input_externalId,
          placeholder: "XXXXXX",
          regex: "^[a-zA-Z0-9]{3,15}",
					visible: true,
					enabled: true,
					index:1,
					required:true,
          show_eye: true
				},
				{
					name: TYPE_METHOD.qr.label,
					label: "Token qr",
          code: TYPE_METHOD.qr,
					item_type: COMPONENT_TAG.code_qr,
					visible: true,
					enabled: true,
					index:1,
					required: true
				}
			]
		},
		{
			name: "Contraseña",
			label: "Contraseña",
			item_type: COMPONENT_TAG.input_pass,
      placeholder: "******",
      required: true,
      /* regex: "[0-9]{3}", */
			visible: true,
			enabled: true,
			index:2,
      show_eye: true
		},
		{
			name: "Captcha",
			label: "captcha",
			item_type: COMPONENT_TAG.captcha,
      placeholder: "XXXX",
			visible: true,
			enabled: true,
			index:2,
      regex: "^[a-zA-Z0-9]{4}",
      show_eye: false
		},
    {
      name: "remember_id",
      /* label: "Recordar valor principal de identificación", */
      label: "Recordar usuario/identificador",
      item_type: COMPONENT_TAG.check,
      show: true
    },
    {
      name: "remember_pass",
      label: "Recordar contraseña",
      item_type: COMPONENT_TAG.check,
      show: true
    },
    {
      name: "remember_login",
      label: "Ingresar automaticamente",
      item_type: COMPONENT_TAG.check,
      show: true
    },
    {
      name: "Button login",
      label: "Ingresar",
      item_type: COMPONENT_TAG.button,
      visible: true,
			enabled: true,
			index:2,
      show: true,
      show_eye: false
    },
    {
      name:"forgot_password",
      label: "Olvidé mi contraseña",
      item_type: COMPONENT_TAG.link,
      url: "https://osiris.hms-tech.com:10204/",
      visible: true,
      enabled: true,
      show: true,
      index:4
    },
    {
			name:"not_registered",
			label: "Registrarse",
			item_type: COMPONENT_TAG.link,
      url: "https://osiris.hms-tech.com:10204/",
			visible: true,
			enabled: true,
      show: true,
			index:4
		}
	],
  token: {
    time_expiration: "10 h",
    time_visible: "110 s",
    period_deamon_context: "5 s",
    quantity_use: 1
  }
}

export const configSearchPerson: any = {
  type_form: TYPE_FORM.search,
  support_profiles: false,
  title: { 
    show: true,
    label: "Buscador"
  },
  welcome: {
    show_icon: false,
    show: true,
    label: "Búsqueda de personas"
  },
  /* subtitle: { 
    show: true,
    label: "Login de OPERADOR"
  }, */
	items: [
		{
			name: "Método",
			label: "Identificador",
			item_type: COMPONENT_TAG.input_select,
			visible: false,
			enabled: false,
			index:0,
      show_eye: false,
      default: TYPE_METHOD.idExternal.label,
			subitems: [
        {
          name: TYPE_METHOD.mail.label,
					label: "Email",
          code: Identifiers.EMAIL,
					item_type: COMPONENT_TAG.input_mail,
          placeholder: "proyecto@sass.com",
					visible: true,
					enabled: true,
					index:1,
          regex: "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/",
					required:true,
          show_eye: true
				},
        {
          name: TYPE_METHOD.document.label,
          label: "Número de Documento",
          code: Identifiers.CIVIL_ID,
          item_type: COMPONENT_TAG.input_doc,
          placeholder: "12345..",
          visible: true,
          enabled: true,
          index:1,
          required:true,
          show_eye: true
        },
        {
          name: TYPE_METHOD.phone.label,
          label: "Teléfono",
          code: Identifiers.PHONE,
          item_type: COMPONENT_TAG.input_phone,
          placeholder: "12345..",
          visible: true,
          enabled: true,
          index:1,
          required:true,
          show_eye: true
        },
				{
					name: TYPE_METHOD.idExternal.label,
					label: "Código de afiliado",
          code: Identifiers.EXTERNAL_ID,
					item_type: COMPONENT_TAG.input_externalId,
          placeholder: "XXXXXX",
          regex: "^[a-zA-Z0-9]{3,15}",
					visible: true,
					enabled: true,
					index: 1,
					required: true,
          show_eye: true
				},
				/* {
					name: TYPE_METHOD.qr.label,
					label: "Token qr",
          code: TYPE_METHOD.qr,
					item_type: COMPONENT_TAG.code_qr,
					visible: true,
					enabled: true,
					index:1,
					required: true
				} */
			]
		},
    {
      name: "Button search",
      label: "Buscar",
      item_type: COMPONENT_TAG.button,
      visible: true,
			enabled: true,
			index:2,
      show: true,
      show_eye: false
    }
	],
  token: {
    time_expiration: "10 m",
    time_visible: "110 s",
    period_deamon_context: "5 s",
    quantity_use: 1
  }
}