<!-- <form [formGroup]="loginForm" (ngSubmit)="informlogin()"> -->
<form [formGroup]="loginForm">
    <div class="div-box-login">
        <!-- <div style="color: white; background-color: black; text-align: center;">
            <small *ngIf="configForm?.title.show">{{configForm?.title.label}}</small>
        </div> -->
        <div class="container"> 
            <div style="text-align: center;">
                <div class="w-100" style="display: inline-flex; align-items: center; margin-top: 1%; margin-bottom: 1%;">
                    <div style="width: 15%;">
                        <img *ngIf="configForm?.welcome.show_icon" style="width: 45px; height: 45px;" src="assets/images/logo/hms.svg" alt="">
                    </div>
                    <div *ngIf="configForm?.welcome.show" style="width: 85%; padding-top: 2.5%;">
                        <h4 [ngStyle]="colorTextComponents ? {'color': colorTextComponents}: {'color': 'inherit'}"><b>{{configForm?.welcome.label}}</b></h4>
                    </div>
                </div>
                <!-- <div *ngIf="configForm?.subtitle.show" class="w-100">
                    <small>{{configForm?.subtitle.label}}</small>
                </div> -->
                <div *ngIf="configForm?.support_profiles" style="text-align: center; display: inline-flex;">
                    <img style="width: 18px; padding-right: 1%;" [ngStyle]="(colorTextComponents == 'white') ? {'filter': 'invert(100%)'}:{'filter': 'none'}" src="assets/images/arrow_right_simple_24.svg" alt="">
                    <small class="small-profile" [ngStyle]="colorTextComponents ? {'color': colorTextComponents}: {'color': 'inherit'}" type="text" ngbPopover="{{supportedProfiles}}" triggers="click" placement="right">
                        Perfiles de usuario admitidos
                    </small>
                </div>
            </div>

            <div style="padding-top: 1.5%; padding-bottom: 1.5%;">
                <div *ngFor='let option of configForm?.items; let i = index' style="padding-left: 1%">
                    <!-- <app-selector-generic *ngIf="option.item_type == components.input_select" [id]="'select_{{id}}'" [options]="option.subitems" [atrValue]="'name'"
                        [showTextSelection]=false [text]="option.label" [set]="eSetMethod" [displayText]="'vertical'" [editable]=true (notifyChangeOption)="changeTypeMethod($event, option)"></app-selector-generic> -->
                        <app-selector-generic *ngIf="option.item_type == components.input_select" [id]="'select_{{id}}'" [options]="option.subitems"
                        [atrValue]="'name'" [showTextSelection]=false [text]="option.label" [set]="eSetMethod" [displayText]="'vertical'" [colorText]="colorTextComponents"
                        [ngStyle]="option.visible ? {'display': 'block'}: {'display': 'none'}" [editable]=true (notifyChangeOption)="changeTypeMethod($event, option)"></app-selector-generic>
                        
                    <div *ngIf="option.subitems">
                        <div *ngFor='let suboption of option.subitems; let j = index'>
                            <app-selector-generic *ngIf="suboption.item_type == components.input_select" [id]="'select_{{id}}'" [options]="suboption.subitems" [atrValue]="'name'"
                                [showTextSelection]=false [editable]=true [text]="suboption.label" [displayText]="'vertical'" (notifyChangeOption)="changeTypeMethod($event, option)"></app-selector-generic>
                            <app-civil-id-v2 *ngIf="(suboption.item_type == components.input_doc)" codeCountry="AR" [editable]=true
                                [countryEditable]=true [typeCivilIdEditable]=true [placeholder]="suboption.placeholder" [showEye]=true
                                [ngStyle]="(typeMethod == methods.document.label) ? {'display': 'block'}: {'display': 'none'}" [colorText]="colorTextComponents"
                                [displayText]="'vertical'" [updateValue]="eUpdateDocument" [resetValue]="eResetDocument" (notifyChangeValue)="onChangeCivilId($event)"></app-civil-id-v2>
                            <app-email-id-v2 *ngIf="(suboption.item_type == components.input_mail)"  [text]="suboption.label" [value]="null"
                                [editable]=true [tags]="null" [displayText]="'vertical'" [placeholder]="suboption.placeholder" [showEye]=true
                                [ngStyle]="(typeMethod == methods.mail.label) ? {'display': 'block'}: {'display': 'none'}" [colorText]="colorTextComponents"
                                (notifyChangeEmail)="onChangeEmail($event)" [resetValue]="eResetEmail" [updateValue]="eUpdateEmail" [modifiable]=false></app-email-id-v2>
                            <app-phone-id *ngIf="(suboption.item_type == components.input_phone)" [phone]="null" [resetValue]="eResetPhone"
                                [editable]=true [displayText]="'vertical'" [placeholder]="suboption.placeholder" [showEye]=true [colorText]="colorTextComponents"
                                [ngStyle]="(typeMethod == methods.phone.label) ? {'display': 'block'}: {'display': 'none'}" [updateValue]="eUpdatePhone"
                                (notifyChangeValue)="onChangePhone($event)" [updateValue]="eUpdatePhone" [modifiable]=false></app-phone-id>
                            <app-external-id *ngIf="(suboption.item_type == components.input_externalId)" [displayText]="'vertical'" [id]="'input_external_id'" [updateValue]="eUpdateExternalId"
                                [ngStyle]="(typeMethod == methods.idExternal.label) ? {'display': 'block'}: {'display': 'none'}" [regex]="suboption.regex" [resetValue]="eResetExternalId" [colorText]="colorTextComponents"
                                [text]="suboption.label" [placeholder]="suboption.placeholder" (notifyChange)="onChangeInputExternalId($event)" required="required" [showEye]="suboption.show_eye"></app-external-id>
                            <app-viewer-qr *ngIf="(suboption.item_type == components.code_qr)" [data]="infoQr" [size]=220
                                [ngStyle]="(typeMethod == methods.qr.label) ? {'display': 'block'}: {'display': 'none'}"></app-viewer-qr>
                        </div>
                    </div>
        
                    <div *ngIf="(option.item_type == components.input_pass) && (userLogged)" class="form-group" style="margin-bottom: 0px; text-align: center;">
                        <p style="font-size: 0.8rem; margin-bottom: 2%;">El usuario cuenta con una sesion abierta. <a style="color: green;cursor: pointer;" (click)="redirectHome()">Continuar</a></p>
                    </div>
                    <app-input-generic-v2 *ngIf="((option.item_type == components.input_pass) && (typeMethod != methods.qr.label))" [displayText]="'vertical'"
                        [id]="'input_pass'" [updateValue]="eUpdatePass" [text]="option.name" [placeholder]="option.placeholder" [regex]="option.regex"
                        (notifyChange)="onChangePassword($event)" [colorText]="colorTextComponents" [required]="option.required" [showEye]="option.show_eye"></app-input-generic-v2>
                    <app-check-generic *ngIf="((option.item_type == components.check) && (typeMethod != methods.qr.label))" id="{{initIdCheck}}{{option.name}}" [text]="option.label"
                        [setValue]="eSetValueCheck" [colorText]="colorTextComponents" (notifyChange)="selectCheck($event, option, i)"></app-check-generic>
                    <div *ngIf="(option.item_type == components.button)" [ngClass]="(typeMethod != methods.qr.label) ? 'show-test':'hide' ">
                        <!-- <button #btnLogin type="submit" class="btn btn-primary btn-block form-control" (click)="informlogin()">{{option.label}}</button> -->
                        <!-- Para test de nuevos forms -->
                        <button #btnLogin style="background: #43DBCF;" [disabled]="!formValid" [ngStyle]="formValid ? {'opacity': '1'}: {'opacity': '0.5'}" type="submit" class="form-control" (click)="onSubmit()">
                            <label style="color: white;" [title]="!formValid ? 'existen campos vacios ó no completados correctamente': ''" [ngStyle]="formValid ? {'cursor': 'pointer'}: {'cursor': 'default'}">{{option.label}}</label>
                        </button>
                    </div>

                    <div *ngIf="option.item_type == components.link">
                        <div class="input-group">
                            <div style="width: 5%; padding-left: 1%;">
                                <img src="assets/images/up-right-24.svg" style="width: 70%; filter: invert(1);" alt="">
                            </div>
                            <div style="width: 95%; color: white;">
                                <small 
                                    style="font-size: 0.7em; cursor: pointer;" 
                                    (click)="selectLink(option)"
                                    [ngStyle]="{'color': colorTextComponents ? colorTextComponents : 'white'}"
                                >
                                    <t>{{option.label}}</t>
                                </small>
                            </div>
                        </div>
                    </div>

                    <!-- para lograr una seperacion entre la password y los checkbox -->
                    <div *ngIf="option.item_type == components.input_pass" style="height: 2vh;"><small></small></div>

                    <div *ngIf="option.item_type == components.captcha && typeMethod != methods.qr.label" style="padding-top: 2.5%; padding-bottom: 3%;">
                        <app-captcha-token [id]="'inp-captcha'" [regex]="option.regex" [placeholder]="option.placeholder" (notifyChange)="onChangeCaptcha($event)"></app-captcha-token>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<app-modal-register #modalRegister [data]="infoModal"></app-modal-register>