<!-- header section Start-->
<section class="saas1 header" id="products">
    <div class="saas1-header bg header8-content"
  [ngStyle]="{'background-image': 'url(assets/images/app_landing1/header-1-bg.png)'}">
      <div class="container">
          <div class="row">
            <div class="col-md-7">
                <div class="center-text">
                    <div>
                        <div class="header-text">
                            <div class="d-flex">
                                <h1 class="theme-color">{{'P'| translate}}{{"roduct"| translate}} 
                                    {{"Suite"| translate}}</h1>
                                <div class="center-content slider-logo">
                                    <img src="assets/images/saas1/slider-logo.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="header-sub-text">
                            <h3 class="">{{"Hms tech offers a variety of products aimed at organizations and individuals, focused on comprehensive identity management, community connectivity, document management and transactionality, both as a service mode (SaaS), and optionally, under perpetual licenses." | translate}} </h3>
                        </div> 
    
                          <div class="link-horizontal">
                              <ul>
                                  <li>
                                    <a routerLink="/products" class="icon-btn">
                                      <i class="fa-brands fa-product-hunt center-content" aria-hidden="true"></i>
                                      <h6 class="text-center text-white">{{ 'Products' | translate }}</h6>
                                    </a>
                                  </li>
                                  <li class="borders-right p-0"></li>
                                  <li>
                                      <a class="icon-btn">
                                          <i class="fa-brands fa-android center-content" aria-hidden="true"></i>
                                          <h6 class="text-center text-white">android</h6>
                                      </a>
                                  </li>
                                  <li>
                                      <a class="icon-btn">
                                          <i class="fa-brands fa-apple center-content" ></i>
                                          <h6 class="text-center text-white">ios</h6>
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="squares">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-1 set-abs">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-2 set-abs">
              </div>
              <div class="animation-circle-inverse">
                  <i></i>
                  <i></i>
                  <i></i>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- header section end-->

