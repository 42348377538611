import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-generic-v2',
  templateUrl: './input-generic-v2.component.html',
  styleUrls: ['./input-generic-v2.component.scss', '../../assets/style.scss']
})
export class InputGenericV2Component implements OnInit {

  inputForm: FormGroup;
  /* hidenValue ?: boolean = true; */
  auxValue?: string = "";

  @Input() id?: string;
  @Input() regex: string|null = null;
  @Input() value: string|null = null;
  @Input() text?: string;
  @Input() placeholder: string = "";
  @Input() displayText?: string = "vertical";     // horizontal/vertical
  @Input() showEye?: boolean = false;
  @Input() required?: boolean = false;
  @Input() hidenValue?: boolean = true;
  @Input() colorText?: string;

  /* evento para notificar el cambio del valor */
  @Output() notifyChange = new EventEmitter<any>();

  // eventos emitidos de afuera
  @Input() updateValue: EventEmitter<any>;
  @Input() resetValue: EventEmitter<any>;

  constructor() {
    this.inputForm = new FormGroup({
      inputValue: new FormControl(this.value, {
                validators:[ Validators.required]
                /* updateOn: 'blur' (controla el chequeo del dato cuando se desenfoca)*/ 
              })
    });
  }

  ngOnInit(): void {
    // actualizamos las validaciones
    this.updateValidations();
    // actualizamos el dato de entrada dado que al construir el componente no reconoce los inputs recibidos
    this.inputForm.get("inputValue")?.setValue(this.value);

    // aca escuchamos la info que nos pueden enviar de afuera
    if(typeof this.updateValue != "undefined"){
      this.updateValue.subscribe((data: any) => {
        /* console.log("[Input-generic.comp] => Update de input: ", data); */
        this.inputForm.get("inputValue")?.setValue(data);
        /* console.log("[Input-generic.comp] => Dato actualizado: "+this.inputForm.get("inputValue")?.value+ " - Form valido: ", this.inputForm.valid); */
        this.reportChanges(this.inputForm.get("inputValue")?.value, this.inputForm.valid, true);
       })
    }

    if(typeof this.resetValue != "undefined"){
      this.resetValue.subscribe((data: any) => {
        this.inputForm.get("inputValue")?.setValue("");
       })
    }
  }

  // quedamos a la escucha de los cambios de tipo idHms
  ngOnChanges(changes: SimpleChanges) {
    // buscamos los cambio de todos los formControlName
    for (let propName in changes) {  
      let change = changes[propName];
      /* console.log("[SsoftId.comp] => Se cambio la info del comp de ssoftId."); */
      
      // si cambio el idHms, recontrolamos la info
      if((propName == "regex")&&(change.currentValue != change.previousValue)){
        this.checkValue(change.currentValue);
      }
    }
  }

  public changeValue(){
    let newValue = this.inputForm.get("inputValue")?.value;
/*     if(this.valueAux)
      (newValue.toUpperCase() == this.valueAux.toUpperCase()) ? this.modified = false : this.modified = true; */
    /* console.log("Mail guardado: " +this.valueAux+ " - Mail nuevo: ", newValue+ " - Mail otro: ", this.value); */
    /* Notificamos al componente padre informando el estado de verificacion del mail */
    this.reportChanges(newValue, this.inputForm.valid, true);
  }

  public showValue(){
    this.hidenValue = !this.hidenValue;
  }

  // actualizamos los validator del input como la validacion del input
  private checkValue(newRegex: string){
    /* console.log("Nueva regex: ", data); */
    this.inputForm.get('inputValue')?.setValidators([Validators.required, Validators.pattern(newRegex)]);
    this.inputForm.get('inputValue')?.updateValueAndValidity();
    let newValue = this.inputForm.get("inputValue")?.value;
    this.reportChanges(newValue, this.inputForm.valid, true);
  }

  private updateValidations(){
    let arrayValidations = [];
    if(this.required){
      arrayValidations.push(Validators.required);
    }
    if(this.regex){
      arrayValidations.push(Validators.pattern(this.regex));
    }
    if(arrayValidations.length > 0){
      this.inputForm.get('inputValue')?.setValidators(arrayValidations);
    }
  }


  /* reportamos cambios en el form, se encapsula en otra funcion para poder llamarlo desde la vista */
  private reportChanges(newValue: string, isValid: boolean, isModified: boolean) {
    let dataForm = {value: newValue, valid: isValid, modified: isModified};
    this.notifyChange.emit(dataForm);
  }

}