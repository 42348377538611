<!--header start-->
<section class="saas2 header" id="home">
  <div class="saas2-content">
    <div class="bg saas2-bg">
      <owl-carousel-o
        [options]="carouselOptions"
        class="yoga-content overflow-hidden yoga-slider"
        #owlCar
      >
        <ng-container *ngFor="let slide of slides">
          <ng-template carouselSlide class="item">
            <div
              class="slide-content"
              [ngStyle]="{ 'background-image': 'url(' + slide.img + ')' }"
              *ngIf="!slide.video"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="center-text">
                        <div class="row">
                          <div class="col-lg-7">
                            <div class="right-way">

                             <!-- Título arriba a la izquierda -->
                              <div class="header-text" 
                              [ngClass]="{
                                'bold-subtitle': slide.title != 'Kirio®' && slide.title != 'IT',
                                'move-down': slide.title != 'Kirio®',
                                'normal-subtitle': slide.title === 'IT'
                              }">
                              <h1 class="title" [innerHTML]="slide.title === 'Suite' ? slide.title : (slide.title | translate | safeTitle)"></h1>
                              <h1 class="subtitle">
                              {{ (slide.subtitle === 'Staffing' || slide.subtitle === 'Suite') ? slide.subtitle : (slide.subtitle | translate) }}
                              </h1>
                              </div>

                              <!-- Descripciones y botones en el medio a la derecha -->
                              <div class="description-text">
                                <div class="slide-sub-text mb-2">
                                  <p class="text-sub">
                                    {{ slide.description1 | translate}}
                                  </p>
                                  <p class="text-sub">
                                    {{ slide.description2 | translate}}
                                  </p>
                                </div>
                                
                                <div
                                  class="button-group"
                                  (mouseleave)="handleMouseLeave()">
                                  
                                 <!-- Botones de imagen -->
                                 <ng-container *ngIf="slide.buttonImage1 && slide.buttonImage2">
                                  <a
                                    [href]="slide.link1"
                                    target="_blank"
                                    class="button-image"
                                    (mouseover)="handleMouseOver(slide, 'link1')"
                                    (click)="handleClick($event, slide)">
                                    <img [src]="slide.buttonImage1" alt="Google Play"/>
                                  </a>
                                  
                                  <a
                                    [href]="slide.link2"
                                    target="_blank"
                                    class="button-image"
                                    (mouseover)="handleMouseOver(slide, 'link2')"
                                    (click)="handleClick($event, slide)">
                                    <img [src]="slide.buttonImage2" alt="App Store"/>
                                  </a>
                                </ng-container>

                                <!-- Botones de texto -->
                                <ng-container *ngIf="!slide.buttonImage1 || !slide.buttonImage2">
                                  <a
                                    *ngIf="slide.link1"
                                    class="btn-default"
                                    [ngClass]="{
                                      'btn-primary': slide.buttonText1?.trim() === 'Exploralas aquí',
                                      'btn-secondary border-not': slide.buttonText1?.trim() !== 'Exploralas aquí'
                                    }"
                                    [href]="slide.link1"
                                    target="_blank"
                                    (mouseover)="handleMouseOver(slide, 'link1')"
                                    (click)="handleClick($event, slide)">
                                    {{ slide.buttonText1 | translate}}
                                  </a>
                                  
                                  <a
                                    *ngIf="slide.link2"
                                    class="btn-default btn-secondary border-not"
                                    [href]="slide.link2"
                                    target="_blank">
                                    {{ slide.buttonText2 | translate}}
                                  </a>
                                </ng-container>
                                


                                  <div *ngIf="showTooltip" class="tooltip">
                                    {{ tooltipMessage | translate}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="animation-circle-inverse">
                    <i></i>
                    <i></i>
                    <i></i>
                  </div>
                </div>
              </div>
            </div>

           <!-- video <div *ngIf="slide.video" class="video-container">
              <video [src]="slide.video" autoplay muted loop>
                Tu navegador no soporta la etiqueta de video.
              </video>
            </div> -->
          </ng-template>
        </ng-container>
      </owl-carousel-o>
      <!-- <div class="video-overlay"></div> -->
      <div class="center-content set-abs bottom-content">
        <div class="bottom">
          <a class="down">
            <img
              alt=""
              src="assets/images/hms-tech/icon/down-celeste.png"
              (click)="onRedirect($event)"
              class="img-fluid"
            />
          </a>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</section>
<!--header end-->
