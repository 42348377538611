import { Component, OnInit } from '@angular/core';
import { CareerService } from '../../hms-tech/services/career.service';

@Component({
  selector: 'app-modern-sass-services',
  templateUrl: './modern-sass-services.component.html',
  styleUrls: ['./modern-sass-services.component.scss']
})
export class ModernSassServicesComponent implements OnInit {
  totalOpenPositions: number;

  constructor(private careerService: CareerService) { }

  ngOnInit() {
    // se utiliza el servicio de careers y se aplica un filtro para obtener la cantidad de posiciones abiertas
    const careers = this.careerService.getCareers();

    // se utiliza el método reduce para obtener la cantidad total de posiciones abiertas
    this.totalOpenPositions = careers.reduce((total, career) => {
      if (career.status.includes('Open')) {
        const openPositions = parseInt(career.status.split(':')[1]) || 1;
        return total + openPositions;
      } else {
        return total;
      }
    }, 0);
    
  }

  services = [
    {
      img: 'assets/images/hms-tech/careers/Software-development-degrade.png',
      title: 'Software Development',
      description: [
        'Software Engineering',
        'Front-End Development',
        'Service Development',
        'Core Systems Development',
        'Systems Architecture',
        'Software Quality',
        'User Experience',
      ],
    },
    {
      img: 'assets/images/hms-tech/careers/Infrastructure-and-operations-degrade.png',
      title: 'Infrastructure and Operations',
      description: [
        'Server Administration and Configuration',
        'Network Administration',
        'Operations and Delivery',
        'Information Security',
      ],
    },
    {
      img: 'assets/images/hms-tech/careers/Project-and-product-degrade.png',
      title: 'Project and Product Management',
      description: [
        'Project Management',
        'Product Management',
        'Scrum Masters',
        'Systems Analysis',
      ],
    },
    {
      img: 'assets/images/hms-tech/careers/Technical-support-degrade.png',
      title: 'Receptionist and Commercial Support',
      description: [
        'Technical Support Engineering',
        'Help Desk and Support',
        'Quality Analysts and Customer Service',
      ],
    },
    {
      img: 'assets/images/hms-tech/careers/Sales-and-marketing-degrade.png',
      title: 'Sales and Marketing',
      description: [
        'Sales',
        'Business Development',
        'Digital Marketing',
        'Account Management',
      ],
    },
    {
      img: 'assets/images/hms-tech/careers/Administration-and-finance-degrade.png',
      title: 'Administration and Finance',
      description: [
        'Financial Analysis',
        'Administration',
        'Human Resources',
        'Suppliers',
        'Collections',
        'Legal and Compliance',
        'Patents and Records',
      ],
    },
  ];
}
