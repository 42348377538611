
import { Injectable } from '@angular/core';
import { Career } from '../models/Career';

@Injectable({
  providedIn: 'root',
})
export class CareerService {
  private careers: Career[] = [
    { id: 1, title: 'Java / Quarkus Developer', country: 'Argentina', category: 'Software Development', flag: 'fi fi-ar', status: 'Open position: 1' },
    { id: 2, title: 'Functional and Services Analyst', country: 'Argentina', category: 'Infrastructure and Operations', flag: 'fi fi-ar', status: 'Open position: 1' },
    { id: 3, title: 'Cross-Service and Front-Ends Securitization Developer', country: 'Argentina', category: 'Software Development', flag: 'fi fi-ar', status: 'Open position: 1' },
    { id: 4, title: 'Functional Analyst', country: 'Argentina', category: 'Infrastructure and Operations', flag: 'fi fi-ar', status: 'Open positions: 2' },
    { id: 6, title: 'Quality Assurance Specialist', country: 'Argentina', category: 'Software Development', flag: 'fi fi-ar', status: 'Open position: 1' },
    { id: 7, title: 'PL/SQL Developer', country: 'Argentina', category: 'Software Development', flag: 'fi fi-ar', status: 'Open positions: 2' },
    { id: 8, title: 'APEX Developer', country: 'Argentina', category: 'Software Development', flag: 'fi fi-ar', status: 'Open positions: 2' },

    { id: 11, title: 'Java / Quarkus Developer', country: 'Argentina', category: 'Software Development', flag: 'fi fi-la', status: 'Finished' },
    { id: 12, title: 'Functional and Services Analyst', country: 'Argentina', category: 'Infrastructure and Operations', flag: 'fi fi-ur', status: 'Finished' },

  ];

  getCareers(): Career[] {
    return this.careers;
  }

  getCareersByCountry(country: string): Career[] {
    return this.careers.filter((career) => career.country === country);
  }

  getCareersByCategory(category: string): Career[] {
    return this.careers.filter((career) => career.category === category);
  }
}