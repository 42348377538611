import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';
import { Career } from 'src/app/layouts/hms-tech/models/Career';
import { CareerService } from 'src/app/layouts/hms-tech/services/career.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
[x: string]: any;
  originalCareers: Career[] = [];
  filteredCareers: Career[] = [];
  selectedCountry: string = 'All';
  selectedCategory: string = 'All';

  constructor(private careerService: CareerService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    // suscribe a cambios en los parámetros de la ruta
    this.route.params.subscribe(params => {
      this.selectedCategory = params['category'] || 'All';  // si no hay parámetro, se usa 'All'
    });
    this.originalCareers = this.careerService.getCareers();
    this.applyFilters();
  }

  filterByCountry(country: string): void {
    this.selectedCountry = country;
    this.applyFilters();
  }

  filterByCategory(category: string): void {
    this.selectedCategory = category;
    this.applyFilters();
  }

  private applyFilters(): void {
    // Copiar la lista original
    this.filteredCareers = [...this.originalCareers];

    // Aplicar filtros
    if (this.selectedCountry !== 'All') {
      this.filteredCareers = this.filteredCareers.filter(career => career.country === this.selectedCountry);
    }

    if (this.selectedCategory !== 'All') {
      this.filteredCareers = this.filteredCareers.filter(career => career.category === this.selectedCategory);
    }
  }

  shouldShowInGray(career: Career): boolean {
    return (
      (this.selectedCountry === 'All' || career.country === this.selectedCountry) &&
      (this.selectedCategory === 'All' || career.category === this.selectedCategory)
    );
  }
 
}
