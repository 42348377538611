import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-id-v2',
  templateUrl: './email-id-v2.component.html',
  styleUrls: ['./email-id-v2.component.scss', '../../assets/style.scss']
})
export class EmailIdV2Component implements OnInit {
  
  emailForm: FormGroup;
  hideValue?: boolean = true;
  
  /* regex: string = ""; */
  @Input() text: string;
  @Input() displayText?: string = "vertical";     // horizontal/vertical
  @Input() editable: boolean;
  // se puede usar como un control dinamico del campo
  @Input() required: boolean;
  @Input() value: string;
  @Input() placeholder: string = "";
  @Input() modified: boolean = false;
  @Input() showEye?: boolean = false;
  @Input() showicon: boolean;
  @Input() action: string = "edit";  // create/edit flag para distinguir una edicion de una creacion
  @Input() colorText?: string;

  valueAux: any;
  validFormAux?: boolean;              // flag agregado para los casos de update de input dado q no verifica correctamente la validez del form

  /* evento para notificar el cambio del mail */
  @Output() notifyChangeEmail = new EventEmitter<any>();
  // eventos emitidos de afuera
  @Input() updateValue: EventEmitter<any>;
  @Input() resetValue: EventEmitter<any>;

  constructor() { }

  ngOnInit(): void {
    /* console.log("CompEmailNew editable: ", this.editable, " mostrarIcono: ", this.showicon); */
    this.emailForm = new FormGroup({
      email: new FormControl(this.value, {
                validators:[ Validators.required, Validators.email]
                /* updateOn: 'blur' (controla el chequeo del dato cuando se desenfoca)*/ 
              })
    });

    this.valueAux = this.value;
    
    // aca escuchamos la info que nos pueden enviar de afuera
    if(typeof this.updateValue != "undefined"){
      this.updateValue.subscribe((data: any) => {
        /* console.log("[Email-v2.comp] => Update de input: ", data); */
        this.emailForm.get("email").setValue(data);
        /* if(data.valid){
          this.validFormAux = true;
          this.emailForm.get("email").setValue(data.value);
        }
        else{
          this.emailForm.get("email").setValue(data);
        } */
       })
    }
    if(typeof this.resetValue != "undefined"){
      this.resetValue.subscribe((data: any) => {
        this.emailForm.get("email").setValue("");
       })
    }
  }

  /* quedamos a la escucha de cambios en el modelo para actualizar la vista */
  ngOnChanges(changes: SimpleChanges): void {
    /* console.log("Se detecto un cambio(value): ", this.value); */
    /* this.valueAux =  ""+this.value; */
    this.valueAux = this.value;
  }

  /* Controlamos el cambio del mail ingresado */
  changeInput(){
    let newValue = this.emailForm.get("email")?.value;
    if(this.valueAux && (this.action == "edit")){
      (newValue.toUpperCase() == this.valueAux.toUpperCase()) ? this.modified = false : this.modified = true;
    }
    /* console.log("Mail guardado: " +this.valueAux+ " - Mail nuevo: ", newValue+ " - Mail otro: ", this.value); */
    /* Notificamos al componente padre informando el estado de verificacion del mail */
    this.reportChanges(newValue, this.emailForm.valid, this.modified as boolean);
    
    /* let validForm: boolean;
    this.validFormAux ? validForm = true: validForm = this.emailForm.valid;
    this.reportChanges(newValue, validForm, this.modified as boolean);
    this.validFormAux = false; */
  }

  /* reportamos cambios en el form, se encapsula en otra funcion para poder llamarlo desde la vista */
  reportChanges(mailInput: string, valid: boolean, modified: boolean) {
    let infoMail = {value: {value: mailInput, status: null, tags: null}, valid: valid, modified: modified};
    this.notifyChangeEmail.emit(infoMail);
  }

  public showValue(){
    this.hideValue = !this.hideValue;
  }

}
