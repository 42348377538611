import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { MessageAppsService } from '../message-apps.service';
import { App } from '../../model/app';

@Injectable({
  providedIn: 'root'
})
export class AdminAppService {

  readonly ID_IFRAME_AUX = "ifr-aux_";

  // las propiedades de los sitemas disponibles del dominio
  apps: App[] = [
    { url: "http://osiris.hms-tech.com:10245", path_icon: "assets/images/apps/template_app.svg", name: "Template Web", enable: true, available: false, technology: "angular"},
    /* { url: "http://localhost:4200", path_icon: "assets/images/apps/template_app.svg", name: "Template Web", enable: true, available: false, technology: "angular"}, */
    /* { url: "http://localhost:4201", path_icon: "assets/images/apps/contact_center_app_icon.svg", name: "Contact Center", enable: true, available: false, technology: "angular"}, */
    { url: "http://osiris.hms-tech.com:10211", path_icon: "assets/images/apps/monitor_logs_app.svg", name: "Monitor de Logs", enable: true, available: false, technology: "angular"},
    /* { url: "http://localhost:4201", path_icon: "assets/images/apps/monitor_logs_app.svg", name: "Monitor de Logs", enable: true, available: false, technology: "angular"}, */
    { url: "http://localhost:4202", path_icon: "assets/images/apps/image_browser_app_icon.svg", name: "Explorador de Imágenes", enable: false, available: false, technology: "angular"},
    { url: "http://localhost:4203", path_icon: "assets/images/apps/medical_authorization_icon_app.svg", name: "Autorizaciones Médicas", enable: false, available: false, technology: "angular"},
    { url: "http://localhost:4204", path_icon: "assets/images/apps/manage_tags_icon_app.svg", name: "Gestión de tags", enable: true, available: false, technology: "angular"},
    { url: "https://129.213.50.15:8443/ords/r/hms-tech/project-saas105106/login", path_icon: "assets/images/apps/admin_apex_app.svg", name: "Admin APEX", enable: true, available: false, technology: "apex"}
  ];

  constructor(
    /* private localStorageService: LocalStorageService,
    private msgAppsService: MessageAppsService */
  ) { }

  // devuelve todas las apps del dominio en cuestion
  // TODO: deberiamos esta consulta al middleware al inicialmente
  public getApps(){
    return this.apps;
  }
  
  // devuelve todas las apps del dominio en cuestion
  // TODO: deberiamos esta consulta al middleware al inicialmente
  public getAppsEnabled(){
    return this.apps.filter(app => app.enable == true);
  }

  // devuelve las apps que se encuentran disponibles
  public getAppsAvailable(){
    /* console.log("[Admin-app.serv] => (filterAvailables) Datos de las apps: ", this.apps); */
    return this.apps.filter(app => app.available);
  }
  
  // // crea un ifram auxiliar para poder establecer comunicacion entre paginas
  // public initConnectionApps(){
  //   for (let i = 0; i < this.apps.length; i++) {
  //     let dataApp = this.apps[i];
  //     if(dataApp.url != document.location.origin)
  //       document.body.appendChild(this.createIframe(dataApp.url));
  //   }
  // }

  // Creamos un iframe para conectarnos con una pagina
  public createIframeConnection(urlPageSrc: string){
    console.log("[Admin-app.serv] => Creamos el iframe de la pag: ", urlPageSrc);
    const iframe =  document.createElement('IFRAME');
    iframe.id = this.ID_IFRAME_AUX + urlPageSrc;
    iframe.style.display = "none";
    (<HTMLIFrameElement>iframe).src = urlPageSrc;
    document.body.appendChild(iframe);
  }

  // analizamos que apps se encuentran disponibles
  public checkAppsAvailable(){
    // hacemos esto para de momento esquivar el pingeo a las apps disponibles
    console.warn("[Admin-app.serv] => Esquivamos el ping a las demas apps!");
    return;

    let appsAvailable = this.getAppsEnabled();

    for (let i = 0; i < appsAvailable.length; i++) {
      let dataApp = appsAvailable[i];
      if(dataApp.url != document.location.origin)
        this.urlExists(dataApp.url)
          .then(result => {
            this.setAvailabilityApps(dataApp.url, result);
            console.log("[Admin-app.serv] => "+dataApp.url+" existe:", result);
            /* console.log("[Admin-app.serv] => Iframe creado: ", this.localStorageService.isIframeCreated(dataApp.url)); */
            /* if(result && !this.localStorageService.isIframeCreated(dataApp.url)){
              this.createIframeConnection(dataApp.url);
              this.localStorageService.setDataIframeConnection(dataApp.url, true);
              this.msgAppsService.reportAvailability(dataApp.url);
            } */
            
            /* if(result){
              this.msgAppsService.reportAvailability(dataApp.url);
            } */
          })
    }

/*     this.urlExists("http://www.mozilla.org").then(result => console.log("http://www.mozilla.org exists ? ", result ))
    this.urlExists("http://www.mozillaaa.org").then(result => console.log("http://www.mozillaaa.org exists ?", result)) */
    
    /* this.urlExists("http://localhost:4202")
      .then(result => console.log("http://localhost:4202 exists ?", result))
      .catch(err => console.warn("La pagina no existe!!"))
    this.urlExists("http://localhost:4201")
      .then(result => console.log("http://localhost:4201 exists ?", result)) */

  }
  
  // Reporta su disponibilidad a las paginas disponibles hasta el momento
  /* public reportAvailabitityApps() {
    let appsAvailable = this.getAppsAvailable();
    for (let i = 0; i < appsAvailable.length; i++) {
      this.msgAppsSerive.reportAvailability(appsAvailable[i].url);
    }
  } */

  // Chequea la disponibilidad de la pagina
  private urlExists(url: string) {
    return fetch(url, {mode: "no-cors"})
      .then(res => true)
      .catch(err => false)
  }

  // Indicamos la disponibilidad de la pagina
  public setAvailabilityApps(urlPage: string, available: boolean){
    /* console.log("[Admin-app.serv] => Pagina  ", urlPage, ": disponible: ", available); */
    for (let i = 0; i < this.apps.length; i++) {
      if(this.apps[i].url == urlPage){
        this.apps[i].available = available;
      }
    }
    /* console.log("[Admin-app.serv] => Datos de las apps: ", this.apps); */
  }
  
}
