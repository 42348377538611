import { Component, OnInit, Renderer2 } from '@angular/core';
import { Menu, NavService } from '../../../service/nav.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ColorScssService } from '../../../../shared/service/color-scss.service';
import { AuthenticationV2Service } from 'src/app/sesion/services/authentication-v2.service';
import { LoginComponent } from '../../../../sesion/pages/login/login.component';
import { LogoutConfirmationComponent } from 'src/app/layouts/hms-tech/logout-confirmation/logout-confirmation.component';
import { CustomizerService } from 'src/app/shared/service/customizer.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  public openSide : boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem : string = '' 
  public overlay: boolean = false;
  isLoggedIn: boolean;
  showLoginMenu: boolean = false;
  public layoutType: string = 'dark'
  loginError: string | null = null;
  modalRef: any;  
  public loggedName = '';

  constructor( public navServices: NavService ,public customize: CustomizerService, private modalService: NgbModal, public colorPicker: ColorScssService,private authService: AuthenticationV2Service,private renderer: Renderer2) { 
    // suscribirse a cambios en el estado de autenticación
    /* this.authService.authenticationState$.subscribe((state) => {
      this.isLoggedIn = state;
    }); */
  }
  email: string;
  password: string;
  ngOnInit() {
    this.renderer.listen('window', 'click', (e: Event) => {
      const target = e.target as HTMLElement;
      if (this.openSide && !target.closest('#togglebtn') && !target.closest('.responsive-btn')) {
        this.closeOverlay();
      }
    }); // esto es para cerrar el menú lateral en dispositivos móviles al hacer clic fuera de él

    this.colorPicker.setColorScheme('inner-page');
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
    });
    this.checkLoginStatus();
    this.authService.operatorCurrent$.subscribe((operator) => {
      if (operator) {
        this.isLoggedIn = true;
        console.log('operador:' , operator);
        if (this.authService.operatorUser) {
        this.loggedName = `${this.authService.operatorUser.first_name.charAt(0)}${this.authService.operatorUser.last_name.charAt(0)}`;
        }
        else  return '';
      } else {
        this.isLoggedIn = false;
      }
    });
  }
  
  openVerticallyCentered(content) {
    this.modalService.open(content);
    console.log("login ok");
  }

  toggleSidebar() {
    this.openSide = !this.openSide;
    if (this.openSide) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }
  }

  closeOverlay() {
    this.openSide = false;
    this.enableScroll();
  }
  disableScroll() {
    this.renderer.addClass(document.documentElement, 'overlay-sidebar-header-open');
    console.log('Scroll deshabilitado');
  }

  enableScroll() {
    this.renderer.removeClass(document.documentElement, 'overlay-sidebar-header-open');
    console.log('Scroll habilitado');
  }

  //For Active Main menu in Mobile View
  setActive(menuItem){
    if (this.activeItem === menuItem) {
      this.activeItem = ''
    } else {
      this.activeItem = menuItem
    }
  }

  isActive(item){
    return this.activeItem === item 
  }

  // For Active Child Menu in Mobile View
  setChildActive(subMenu){
    if (this.activeChildItem === subMenu) {
      this.activeChildItem = ''
    } else {
      this.activeChildItem = subMenu
    }
  }

  ischildActive(subMenu){
    return this.activeChildItem === subMenu 
  }
  showCompanyProfileMessage(){
    console.log("CLICK");
  }

  openLoginModal() {
    const options = {
      backdrop: true, // Permitir cerrar el modal al hacer clic fuera de él
      centered: true // Centrar el modal verticalmente en la página
    };
    this.modalRef = this.modalService.open(LoginComponent, options);
    this.modalRef.componentInstance.closeModal.subscribe((result: string) => {
      if (result === 'success') {
        this.checkLoginStatus(); // Llamar a checkLoginStatus antes de suscribirse al evento
      
      }
      this.modalRef.close(); // Cerramos el modal
    });
  }
  
    checkLoginStatus(): void {
      //console.log('logedname function',this.loggedName = this.authService.getLoggedUserName());
      console.log('checkLoginStatus true');
    }
  
    logout() {
      this.authService.logout();
      //this.localStorageService.resetTokenCurrentOperator();
      //this.authService.authenticationState$.next(false);
      if (localStorage.getItem('people')){
        localStorage.removeItem('people');
      }
  
      // establecer remember_login como false al hacer logout momentaneamente hasta solucionar el problema de la persistencia del token
      localStorage.setItem('remember_login', 'false');
  
  
      this.customizeLayoutVersion('dark')
      // Recargar la página (idea)
      //location.reload();
    }
 

  toggleLoginMenu() {
    this.showLoginMenu = !this.showLoginMenu;
    if (this.showLoginMenu) {
      // agrega manejador de clics al documento para cerrar el menú
      document.addEventListener('click', this.closeMenuOnClickOutside);
    } else {
      // si el menú está cerrado, elimina el manejador de clics del documento
      document.removeEventListener('click', this.closeMenuOnClickOutside);
    }
  }

  closeMenuOnClickOutside = (event: Event) => {
    const target = event.target as HTMLElement;

    if (!target.closest('.login-menu-container')) {
      // El clic fue fuera del área del menú, cierra el menú
      this.showLoginMenu = false;
    }
  };

  customizeLayoutVersion(val) {
    this.customize.setLayoutVersion(val)
    this.layoutType = val
  }

  customizeLayoutType(val) {
    this.customize.setLayoutType(val)
    this.layoutType = val
  }

  openLogoutConfirmationDialog() {
    const modalRef = this.modalService.open(LogoutConfirmationComponent);

    modalRef.result.then(
      (result) => {
        if (result === 'Confirm') {
          this.logout();
        }
      },
      () => {
        // Cerrado sin confirmar (por ejemplo, haciendo clic fuera del cuadro de diálogo)
      }
    );
  }
  
}


