import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

/*                                                                              */
@Component({
  selector: 'app-modal-msg',
  templateUrl: './modal-msg.component.html',
  styleUrls: ['./modal-msg.component.scss'],
  encapsulation: ViewEncapsulation.None,      // necesario para poner personalizar el background del modal
  providers: [NgbModalConfig, NgbModal]
})

export class ModalMsgComponent implements OnInit{

  @ViewChild('myModal') myModal: any;
  /* private modalRef; */
  @Input() info: any;

  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;
  }

  ngOnInit(): void {
  }

  open(){
    /* this.modalRef = this.modalService.open(this.myModal); */
    this.modalService.open(this.myModal);
  }

}
