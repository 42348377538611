import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationV2Service } from 'src/app/sesion/services/authentication-v2.service';
import { LoginComponent } from 'src/app/sesion/pages/login/login.component';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { ContextSass } from 'src/app/sesion/model/context/contextSass';
import { AdminContextSassService } from 'src/app/sesion/services/manager/admin-context-sass.service';
import { ConfigToken } from 'src/app/sesion/assets/config/config-constant.example';
import { TokenizerService } from 'src/app/sesion/services/tokenizer.service';
import { link } from 'fs';



@Component({
  selector: 'app-modern-sass-header',
  templateUrl: './modern-sass-header.component.html',
  styleUrls: ['./modern-sass-header.component.scss']
})
export class ModernSassHeaderComponent implements OnInit {
  isLoggedIn: boolean;
  showTooltip = false;
  tooltipMessage = '';
  modalRef: any;
  isModalOpen: boolean = false; // propiedad para controlar el estado del modal y usarlo para el carrusel
  @ViewChild(CarouselComponent) owlCarousel: CarouselComponent;
  @ViewChild('backgroundVideo') backgroundVideo: ElementRef<HTMLVideoElement>;


  constructor( private authService: AuthenticationV2Service,
    private modalService: NgbModal,
    private adminContextSassService: AdminContextSassService,
    private tokenizerService: TokenizerService,
  ) { 
    this.authService.authenticationState$.subscribe((state) => {
      this.isLoggedIn = state;
    });
  }
  

  ngOnInit() { 

    /* const video = document.getElementById('backgroundVideo') as HTMLVideoElement;
    video.play();
    // listener al body para detectar cualquier clic en la página
    document.body.addEventListener('click', this.handleFirstClick);
    document.body.addEventListener('keydown', this.handleFirstClick); */

  }

  /* // función para reproducir el video después del primer clic cuando se carga la página por primera vez
  handleFirstClick() {
    // eliminar el listener después del primer clic
    document.body.removeEventListener('click', this.handleFirstClick);
    // reproducir el video después del primer clic
    const video = document.getElementById('backgroundVideo') as HTMLVideoElement;
    if (video) {
      video.play();
    }
  }
*/

  onRedirect(e) {
    e.preventDefault();
    const el = document.getElementById('company-profile');
    el.scrollIntoView({ behavior: 'smooth' });
  } 
  
  carouselOptions = {
    items: 1, // Número de elementos mostrados simultáneamente en el carrusel
    margin: 0, // Margen entre los elementos
    autoHeight: true, // Ajusta automáticamente la altura de los elementos
    nav: true, // Muestra los botones de navegación
    navText: [ // Íconos personalizados para la navegación
      '<i class="fa fa-chevron-left" aria-hidden="true"></i>',
      '<i class="fa fa-chevron-right" aria-hidden="true"></i>'
    ],
    autoplay: true, // Activa la reproducción automática
    loop: true, // Activa la reproducción en bucle 
    dots: true, // Muestra los puntos de navegación
    autoplaySpeed: 6000 // Velocidad de la reproducción automática en milisegundos
  };
  slides = [
    {
      img: 'assets/images/hms-tech/carrousel/Carrousel1-bg.jpg',
      title: 'Kirio®',
      subtitle: 'Enhanced Digital Identity',
      description1: 'Download our app, register, and start using our suite.',
      description2: 'Download it from the App Store if you have an iPhone/iPad or from Google Play if you use Android!',
      link1: 'https://play.google.com/store/apps/details?id=com.kirio.app',
      link2: 'https://apps.apple.com/us/app/kirio/id1544359941',
      buttonImage1: 'assets/images/sso/playAndroid.png',
      buttonImage2: 'assets/images/sso/playIOS.png',
      requiresLogin: true, // Indicates that this slide requires login verification
      requiresContext: true // Indicates that this slide needs to pass context
    },
    {
      img: 'assets/images/hms-tech/carrousel/Carrousel2-bg.jpg',
      title: 'Our SaaS',
      subtitle: 'Solution',
      description1: 'Innovative and scalable solutions, accessible from anywhere.',
      description2: 'Streamline business management, improve operational efficiency, and provide continuous technical support, ensuring an exceptional user experience tailored to your needs.',
      link1: 'https://solutionexplorer.hms-tech.com/',
      link2: 'https://hms-tech.odoo.com/contactus',
      buttonText1: 'Explore them here',
      buttonText2: 'Learn more...',
      requiresLogin: true, // Indicates that this slide requires login verification
      requiresContext: true // Indicates that this slide needs to pass context
    },
    {
      img: 'assets/images/hms-tech/carrousel/Carrousel3-bg.jpg',
      title: 'Health',
      subtitle: 'Suite',
      description1: 'Our Authorization Center handles over 180 million transactions annually from multiple sources.',
      description2: 'We offer an efficient and secure platform that optimizes your business, ensuring precise and reliable management of all your operations. With our solution, you can focus on growing your company while we handle your transactions.',
      link1: '/descarga-info',
      link2: 'https://hms-tech.odoo.com/contactus',
      buttonText1: 'Download information',
      buttonText2: 'Learn more...',
      requiresLogin: true, // Indicates that this slide requires login verification
    },
    {
      img: 'assets/images/hms-tech/carrousel/Carrousel4-bg.jpg',
      title: 'IT',
      subtitle: 'Staffing',
      description1: 'At HMS, we offer Staffing services as a key part of our portfolio.',
      description2: 'Through rigorous recruitment and selection processes, we specialize in finding highly qualified and adaptable profiles that seamlessly integrate into our clients\' teams. Make every hire a success, optimizing resources and time for your company.',
      link1: '/descarga-info',
      link2: 'https://hms-tech.odoo.com/contactus',
      buttonText1: 'Download information',
      buttonText2: 'Learn more...',
      requiresLogin: true, // Indicates that this slide requires login verification
    }
  ];
  
    
  handleMouseOver(slide, buttonType) {
    if (slide.requiresLogin && !this.isLoggedIn) { // Verificar si se requiere inicio de sesión
      this.showTooltip = true;
      this.tooltipMessage = 'You must log in to access this feature.';
    }
  }

  handleMouseLeave() {
    this.showTooltip = false; // Ocultar el tooltip al salir del botón
  }

  public handleClick(event: Event, slide: any): void {
    event.preventDefault(); // Prevenir el comportamiento por defecto

    if (slide.requiresLogin && !this.isLoggedIn) { // Verificar si se requiere inicio de sesión y el usuario no ha iniciado sesión
      this.openLoginComponent(); // en ese caso abre modal login
    } else {
      let urlRedirect = slide.link1; // URL del primer botón
      if (slide.requiresContext) { // Si se requiere pasar el contexto, obtenemos el contexto actual
          let newContext: ContextSass = this.adminContextSassService.getContext();
          console.log("[handleClick] => Intentamos abrir con contexto: ", urlRedirect);

          // Genera el token de contexto
          this.tokenizerService.getTokenV2({context: newContext}, ConfigToken.DURATION_LOGIN_DEFAULT, Number.parseInt(ConfigToken.LENGTH_LOGIN_DEFAULT), ConfigToken.TYPE_LOGIN_DEFAULT, 50).subscribe(
              (resp: any) => {
                  console.log("[handleClick] => Token de contexto inicializado: ", resp);
                  urlRedirect += '?token_context=' + resp.short_token;

                  // Redirige a la app seleccionada en una nueva pestaña
                  setTimeout(() => {
                      window.open(urlRedirect, '_blank').focus();
                  }, 350);
              },
              (err: any) => {
                  console.warn("[handleClick] => No se pudo actualizar el contexto!");
              }
          );
      } else { // en caso de que no se requiera contexto para abrir la app, la abre directamente
          console.log("[handleClick] => Intentamos abrir sin contexto: ", urlRedirect);
          setTimeout(() => {
              window.open(urlRedirect, '_blank').focus();
          }, 350);
      }
    }
  }

  openLoginComponent() {
    this.openLoginModal();
  }

  openLoginModal() {
    this.isModalOpen = true; // Marca que el modal está abierto
    if (this.owlCarousel) {
      this.owlCarousel.stopAutoplay(); // Detiene el autoplay
    }

    const options = {
      backdrop: true,
      centered: true
    };

    this.modalRef = this.modalService.open(LoginComponent, options);
    this.modalRef.componentInstance.closeModal.subscribe((result: string) => {
      this.modalRef.close(); // Cerramos el modal al recibir el evento de cierre
      this.isModalOpen = false; // Marca que el modal está cerrado
      if (this.owlCarousel) {
        this.owlCarousel.startAutoplay(); // Reactiva el autoplay
      }
    });
  }
  
}
