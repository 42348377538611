import { Identifier } from './identifier.model';

export class IdentifierProfile{
  profile?: string;
  id?: Identifier|null;
  
  constructor(data?: Partial<IdentifierProfile>) {
    Object.assign(this, data);
  }

  public clone?(): IdentifierProfile {
    return new IdentifierProfile(this);
  }
  

  // determina si 2 personas son iguales
  public equals(identifier: IdentifierProfile){
    if(this.profile != identifier.profile)
      return false;
    
    return this.id?.value == identifier.id?.value
  }


}