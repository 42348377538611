export class ProfileContext{
    domain?: string|null;
    profile?: string;
    isRegistered?: boolean|null;
    status?: string|null;
    deadline?: number|null;

    constructor(data?: Partial<ProfileContext>) {
        Object.assign(this, data);
    }
}