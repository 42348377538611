export enum Generic {
    // mensajes genericos
    DEFAULT_ERROR = "Ha ocurrido un error",
    REQUIRED_FIELDS = "Existen campos requeridos vacios",
    INVALID_DATA = "Los datos ingresados no son correctos",
    CONFIRM_ACTION = "¿Realmente está seguro de realizar esta operación?",
    OPERATION_SUCCES = "Se ha realizado la operación con éxito",
    OPERATION_FAILED = "Ha ocurrido un error al realizar la operación",
    OPERATION_NOT_ALLOW = "La opción no esta habilitada",
    OPERATION_NOT_ALLOW_FOR_USER = "La acción no esta habilitada para este usuario",
    EXPIRED_SESSION = "La sesión ha caducado. Debe iniciar sesión nuevamente",
    NOT_USER = "No se encuentra un usuario activo",
    DENIED_PROFILE = "El operador no cuenta con el/los perfil/es requerido/s para ejecutar esta acción"
}

export enum ErrorSaludSoft {
    SERVER_UNAVAILABLE = "Servicios de SaludSoft momentaneamente no disponibles."
}

export enum ErrorServer {
    UNAVAILABLE = "Servicios momentaneamente no disponibles."
}

export enum TitleModal {
    ERROR_AUTH = "Problemas de autenticación",
    ADMIN_SESSION = "Administración de sesión",
    EDIT_OPERATOR = "Edición de datos del operador",
    UPDATE_PASS = "Actualización de contraseñas",
    CANCEL_TRANSACTION = "Anulación de transacción",
    AUTH_BENEFIT = "Autorización de prestaciones",
    ELIGIBILITY = "Verificación de afiliados",
    AUTH_INT = "Autorización de internaciones",
    EXIT_INT = "Egreso de internaciones",
    QUERIES_INT = "Consulta integral",
    NOT_ALLOWED = "Acción no permitida"
}

// ver si crear un nuevo archivo de errores
export enum CodeError {
    HMS_UNAVAILABLE = "HMS_NOT_ENABLED",
    ERROR_SSOFT = "MSGXML"
}