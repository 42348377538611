<!-- build section start -->
<section id="staffing" class="saas1 build-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2-eg.png)'}">
    <div class="build-right-slider">
            <owl-carousel-o [options]="sync2" class="sync2" #owlCar (changed)="owlCar1.to($event.startPosition.toString())" (dragging)="owlCar1.to($event.data.startPosition.toString())"> 
                    <ng-container *ngFor="let slide of buildsync; index as i">
                        <ng-template carouselSlide [id]="i" [dotContent]="owlCar1.to(i)">
                                <img [src]="slide.img" alt="work-slider" class="img-fluid work-slide">
                        </ng-template>
                    </ng-container>
            </owl-carousel-o>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                    <owl-carousel-o [options]="sync1" class="overflow-hidden sync1"  #owlCar1 (dragging)="owlCar.to($event.data.startPosition.toString())">                    
                        <ng-container  *ngFor="let slide of navsync; index as i">
                        <ng-template carouselSlide [id]="i">
                            <div class="build-box" >
                                <div class="main-title">
                                    <h2>
                                        <img src="assets/images/hms-tech/service-lines/title-logo-amarillo.png" alt="title-logo" class="img-fluid"> 
                                        {{"Staffin"| translate}}<span class="theme-color">{{"g"| translate}}</span>
                                    </h2>
                                </div>
                                <p>{{"Hms tech provides staffing services, as an integral part of its portfolio, collaborating with clients in the identification and selection of talents, which go through meticulous recruitment and selection processes, generating hiring flexibility conditions for its clients. Through this service line, organizations access highly specialized and expert profiles, who become part of their work teams." | translate}}</p>
                                <ul>
                                    <li><img src="assets/images/hms-tech/service-lines/build-celeste.png" alt="icon">{{"We work closely with some of the most successful companies in Argentina and abroad"| translate}}. 
                                    </li>
                                    <li><img src="assets/images/hms-tech/service-lines/build-celeste.png" alt="icon">{{"Our resources are trained and retained, receiving professional assistance as part of the onboarding process with the client"| translate}}.
                                    </li>
                                    <li><img src="assets/images/hms-tech/service-lines/build-celeste.png" alt="icon">{{"We are experts in several of the most in-demand current technologies"| translate}}.
                                    </li>
                                    <li><img src="assets/images/hms-tech/service-lines/build-celeste.png" alt="icon">{{"Our software labs are staffed with leading professionals in various IT disciplines, including networking and cybersecurity, agile methodologies, architecture, cloud services, real-time transactionality, solution development, and consulting"| translate}}.
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                   </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
</section>
<!-- end build section-->
