import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-support-confirmation-modal',
  templateUrl: './support-confirmation-modal.component.html',
  styleUrls: ['./support-confirmation-modal.component.scss']
})
export class SupportConfirmationModalComponent {
  @Output() confirmed = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();

  isWithinWorkingHours(): boolean {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    
    // rango de atención
    const startHour = 9;  // 9 AM
    const endHour = 17;    // 5 PM

    return (currentHour > startHour || (currentHour === startHour && currentMinute >= 0)) &&
           (currentHour < endHour || (currentHour === endHour && currentMinute === 0));
  }
  
}
