<ng-template #myModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{info.title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{info.body}}</p>
    </div>
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button> -->
      <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Ok</button>
    </div>
</ng-template>