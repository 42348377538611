import { Component, Renderer2, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CustomizerService } from './shared/service/customizer.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationV2Service } from './sesion/services/authentication-v2.service';
import { InfoModal } from './sesion/model/info.modal';
import { AdminModalService } from './sesion/services/manager/adminModal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

    // Datos para controlar el modal
    infoMsgModal = new InfoModal("","");
    // recuperamos una referencia del modal para poder mostrarlo cuando sea necesario
    @ViewChild('modalInfoApp') modalInfo: any;
    
  public url: any;
  title = 'Unice';
  public layoutType: string = 'dark'
  isLoggedIn: boolean;
  showLoader: boolean = true;

  constructor(public customize: CustomizerService, private router: Router, private _renderer2: Renderer2,  @Inject(DOCUMENT) private _document: Document ,public translate: TranslateService,private authService: AuthenticationV2Service, private adminModalService : AdminModalService) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
    this.initializeTranslation(); 
    // suscribirse a cambios en el estado de autenticación
    this.authService.authenticationState$.subscribe((state) => {
      this.isLoggedIn = state;
    });
  }

  initializeTranslation() {
    const supportedLangs = ['en', 'es'];

    // Revisar si hay un idioma guardado en localStorage
    const savedLang = localStorage.getItem('language'); 
    const browserLang = this.translate.getBrowserLang(); // Obtener el idioma del navegador

    const defaultLang = supportedLangs.includes(savedLang) ? savedLang : // Si el idioma guardado es soportado
                        supportedLangs.includes(browserLang) ? browserLang : 'en'; // Si el idioma del navegador es soportado, usarlo, de lo contrario, usar inglés

    this.translate.setDefaultLang(defaultLang); // Establecer el idioma predeterminado
    this.translate.use(defaultLang); // Usar el idioma predeterminado
  }
   
  public ngOnInit() {
    let script = this._renderer2.createElement('script');
    script.src  = `/assets/tilt.js`;   
    this._renderer2.appendChild(this._document.body, script);
  
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Verificar si la navegación se está realizando desde la página de inicio
        if (event.url === '/') {
          this.showLoader = true;
        }
      } else if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.showLoader = false;
        }, 3100); // Ajusta el tiempo según sea necesario
      }
    });
  }

  ngAfterViewInit(): void {
    this.initGlobalModal();
  }
  
  customizeLayoutVersion(val) {
    this.customize.setLayoutVersion(val)
    this.layoutType = val
  }

  customizeLayoutType(val) {
    this.customize.setLayoutType(val)
    this.layoutType = val
  }

  // inciializa un modal para poder acceder desde cualquier componente del site
  private initGlobalModal(){
    this.adminModalService.setModalGlobal(this.modalInfo);
    this.adminModalService.setInfoModalGlobal(this.infoMsgModal);
  }
  
}
