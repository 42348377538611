import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private selectedProductSource = new BehaviorSubject<any>(null);
  selectedProduct = this.selectedProductSource.asObservable();

  // Nuevo BehaviorSubject para el precio base
  private basePriceSource = new BehaviorSubject<any>(null);
  basePrice = this.basePriceSource.asObservable();

  constructor() { }

  setSelectedProduct(product: any) {
    this.selectedProductSource.next(product);
  }

  // Método para establecer el precio base
  setBasePrice(basePrice: any) {
    this.basePriceSource.next(basePrice);
  }
}
