import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationV2Service } from 'src/app/sesion/services/authentication-v2.service';
import { LogoutConfirmationComponent } from '../../hms-tech/logout-confirmation/logout-confirmation.component';
import { CustomizerService } from '../../../shared/service/customizer.service';
import { BoxLoginComponent } from './../../../sesion/components/box-login/box-login.component';
import { LoginComponent } from 'src/app/sesion/pages/login/login.component';
import { LocalStorageService } from 'src/app/sesion/services/local-storage.service';

@Component({
  selector: 'app-modern-sass-nav',
  templateUrl: './modern-sass-nav.component.html',
  styleUrls: ['./modern-sass-nav.component.scss']
})
export class ModernSassNavComponent implements OnInit {
  isLoggedIn: boolean;
  showLoginMenu: boolean = false;
  public layoutType: string = 'dark'
  loginError: string | null = null;
  modalRef: any;  
  public loggedName = '';

  constructor(public customize: CustomizerService, private authService: AuthenticationV2Service, private modalService: NgbModal,private localStorageService: LocalStorageService) {
    // suscribirse a cambios en el estado de autenticación
   /*  this.authService.authenticationState$.subscribe((state) => {
      this.isLoggedIn = state;
    }); */
  }
  ngOnInit() {  
    this.checkLoginStatus();
    this.authService.operatorCurrent$.subscribe((operator) => {
      if (operator) {
        this.isLoggedIn = true;
        console.log('operador:' , operator);
        if (this.authService.operatorUser) {
        this.loggedName = `${this.authService.operatorUser.first_name.charAt(0)}${this.authService.operatorUser.last_name.charAt(0)}`;
        }
        else  return '';
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  openLogoutConfirmationDialog() {
    const modalRef = this.modalService.open(LogoutConfirmationComponent);

    modalRef.result.then(
      (result) => {
        if (result === 'Confirm') {
          this.logout();
        }
      },
      () => {
        // Cerrado sin confirmar (por ejemplo, haciendo clic fuera del cuadro de diálogo)
      }
    );
  }



openLoginModal() {
  const options = {
    backdrop: true, // Permitir cerrar el modal al hacer clic fuera de él
    centered: true // Centrar el modal verticalmente en la página
  };
  this.modalRef = this.modalService.open(LoginComponent, options);
  this.modalRef.componentInstance.closeModal.subscribe((result: string) => {
    /* if (result === 'success') {
      this.checkLoginStatus(); // Llamar a checkLoginStatus antes de suscribirse al evento
      this.authService.operatorCurrent$.subscribe((operator) => {
        if (operator) {
          this.isLoggedIn = true;
          this.loggedName = this.authService.getLoggedUserName();
        }
      });
    } */
    this.modalRef.close(); // Cerramos el modal
  });
}

  checkLoginStatus(): void {
    //console.log('logedname function',this.loggedName = this.authService.getLoggedUserName());
    console.log('checkLoginStatus true');
  }

  logout() {
    this.authService.logout();
    //this.localStorageService.resetTokenCurrentOperator();
    //this.authService.authenticationState$.next(false);
    if (localStorage.getItem('people')){
      localStorage.removeItem('people');
    }

    // establecer remember_login como false al hacer logout momentaneamente hasta solucionar el problema de la persistencia del token
    localStorage.setItem('remember_login', 'false');


    this.customizeLayoutVersion('dark')
    // Recargar la página (idea)
    //location.reload();
  }
 

  toggleLoginMenu() {
    this.showLoginMenu = !this.showLoginMenu;

    if (this.showLoginMenu) {
      // agrega manejador de clics al documento para cerrar el menú
      document.addEventListener('click', this.closeMenuOnClickOutside);
    } else {
      // si el menú está cerrado, elimina el manejador de clics del documento
      document.removeEventListener('click', this.closeMenuOnClickOutside);
    }
  }

  closeMenuOnClickOutside = (event: Event) => {
    const target = event.target as HTMLElement;

    if (!target.closest('.login-menu-container')) {
      // El clic fue fuera del área del menú, cierra el menú
      this.showLoginMenu = false;
    }
  };

  customizeLayoutVersion(val) {
    this.customize.setLayoutVersion(val)
    this.layoutType = val
  }

  customizeLayoutType(val) {
    this.customize.setLayoutType(val)
    this.layoutType = val
  }
}
