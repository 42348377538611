<!-- Barra de navegación para modo móvil -->
<a class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
    <div class="responsive-btn responsive_nav">

        <a>
            <!-- Selector de idioma -->
            <app-language-toggle class="language-toggle"></app-language-toggle>
        </a>
        
        <!-- Icono de login -->
        <a class="login-icon" *ngIf="!isLoggedIn" (click)="openLoginModal()">
            <span class="material-symbols-outlined">account_circle</span>
            <span class="text-login">{{ "Login" | translate }}</span>
        </a>
       
        <!-- Menú de usuario logueado -->
       <div *ngIf="isLoggedIn" class="login-menu-container" >
        <a class="login-button" (click)="toggleLoginMenu()">
          <div class="login-icon">
             <span class="material-symbols-outlined">account_circle</span>
             <span class="text-login p-info-user">{{ loggedName | translate}}</span>
          </div>
       </a>

        <ul *ngIf="showLoginMenu" class="login-options" [class.light-mode]="layoutType==='light'"> 
          <li> <a  routerLink="/personal-info"> <span><i>&#x2139;</i> {{ "Información personal" | translate }} </span>
          </a></li>  
          
          <!--<li><a routerLink="/settings">{{ "Dark / Light" | translate }}</a></li> -->

         <!-- dark/light (ocultado)  <li class="sidebar-btn dark-light-btn" *ngIf="customize.data.settings.layout_version == 'dark'" (click)="customizeLayoutVersion('light')">
                    <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version"><i>&#9728;</i> Dark / Light</span></a>
                </li>
                <li class="sidebar-btn dark-light-btn"  *ngIf="customize.data.settings.layout_version == 'light'" (click)="customizeLayoutVersion('dark')">
                    <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version"><i>&#9790;</i> Dark / Light</span></a>
                </li>
           -->           
          <!--<li><a routerLink="/settings">{{ "settings" | translate }}</a></li> -->
         <!-- <li><a (click)="openLogoutConfirmationDialog()">{{ "Sing out" | translate }} </a></li>-->
           <li><a (click)="openLogoutConfirmationDialog()" *ngIf="isLoggedIn" [class.light-mode]="layoutType==='light'"> 
            <span class="text-login"><i>&#8594;</i> {{"Logout" | translate}}</span>
          </a></li>

        </ul>
      </div>

    </div>
    
    <!-- Menú principal -->
    <ul class="main-menu">
        <!-- 1st Level Menu -->
        <li *ngFor="let menuItem of menuItems" [class]="menuItem.megaMenu ? 'mega-menu' : ''">
            <!-- Submenú -->
            <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" *ngIf="menuItem.type === 'sub'" class="dropdown" (click)="setActive(menuItem.title)">
                {{ menuItem.title | translate }}
            </a>
            <!-- Enlace -->
            <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="" *ngIf="menuItem.type === 'link'">
                {{ menuItem.title | translate }}
            </a>
            <!-- Enlace externo -->
            <a href="{{ !menuItem.type ? null : menuItem.path }} "target="_blank" class="dropdown" *ngIf="menuItem.type === 'extLink' ">
                {{ menuItem.title | translate }}
            </a>
            <!-- Enlace a pestaña externa -->
            <a href="{{ !menuItem.type ? null : menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
                {{ menuItem.title | translate }}
            </a>
            <!-- Mega menú -->
            <div class="mega-menu-container" [class.opensubmenu]="isActive(menuItem.title)"
                *ngIf="menuItem.megaMenu && menuItem.megaMenuType == 'small' || menuItem.megaMenuType == 'medium' || menuItem.megaMenuType == 'large'">
                <div class="container-fluid">
                    <div class="row">
                        <div [class]="menuItem.megaMenuType == 'small' ? 'col-lg-4' : menuItem.megaMenuType == 'medium' ? 'col-lg-3' : menuItem.megaMenuType =='large'? 'col' :'' "
                            *ngFor="let childrenItem of menuItem.children">
                            <div class="menu-container">
                                <a class="menu-head" (click)="setChildActive(childrenItem.title)">{{ childrenItem.title }}</a>
                                <ul [class.menu-icon]="menuItem.title == 'Elements'" [class.openSubChildMenu]="ischildActive(childrenItem.title)">
                                    <li *ngFor="let subChildrenItem of childrenItem.children">
                                        <a [routerLink]="!subChildrenItem.type ? null : [subChildrenItem.path]" routerLinkActive="active"
                                            *ngIf="subChildrenItem.type === 'link'">
                                            <i class="icon-{{ subChildrenItem.icon }}"></i>{{ subChildrenItem.title }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 2nd Level Menu -->
            <ul *ngIf="menuItem.children && !menuItem.megaMenu" [class.opensubmenu]="isActive(menuItem.title)">
                <li *ngFor="let childrenItem of menuItem.children" [class.sub-menu]="childrenItem.children">
                    <!-- Submenú -->
                    <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="setChildActive(childrenItem.title)">
                        {{ childrenItem.title | translate }}
                    </a>
                    <!-- Enlace -->
                    <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" [fragment]="childrenItem.fragment" routerLinkActive="active"
                        *ngIf="childrenItem.type === 'link' && !menuItem.megaMenu" (click)="showCompanyProfileMessage()">
                        {{ childrenItem.title | translate }}
                    </a>
                    <!-- Enlace externo con condición de login -->
                    <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
                        *ngIf="isLoggedIn && childrenItem.type === 'extLink' && !menuItem.megaMenu">
                        {{ childrenItem.title | translate }}
                    </a>
                    <!-- Enlace a pestaña externa -->
                    <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
                        *ngIf="childrenItem.type === 'extTabLink' && !menuItem.megaMenu">
                        {{ childrenItem.title | translate }}
                    </a>
                    <!-- Submenú de segundo nivel -->
                    <ul *ngIf="childrenItem.children" [class.openSubChildMenu]="ischildActive(childrenItem.title)">
                        <li *ngFor="let subChildrenItem of childrenItem.children">
                            <!-- Submenú -->
                            <a href="javascript:void(0)" *ngIf="subChildrenItem.type === 'sub'">
                                {{ subChildrenItem.title | translate }}
                            </a>
                            <!-- Enlace -->
                            <a [routerLink]="!subChildrenItem.type ? null : [subChildrenItem.path]" [fragment]="subChildrenItem.fragment" routerLinkActive="active"
                                *ngIf="subChildrenItem.type === 'link' && !menuItem.megaMenu">
                                {{ subChildrenItem.title | translate }}
                            </a>
                            <!-- Enlace externo -->
                            <a href="{{ !subChildrenItem.type ? null : subChildrenItem.path }}"
                                *ngIf="subChildrenItem.type === 'extLink' && !menuItem.megaMenu">
                                {{ subChildrenItem.title | translate }}
                            </a>
                            <!-- Enlace a pestaña externa -->
                            <a href="{{ !subChildrenItem.type ? null : subChildrenItem.path }}" target="_blank"
                                *ngIf="subChildrenItem.type === 'extTabLink' && !menuItem.megaMenu">
                                {{ subChildrenItem.title | translate }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
    <!-- Plantilla de inicio de sesión -->
    <ng-template #content let-modal>
        <div class="login-modal">
            <div class="modal-header">
                <button aria-label="Close" (click)="modal.dismiss('Cross click')" class="close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="nav nav-pills mb-5" id="pills-tab-login" role="tablist">
                    <li class="nav-item">
                        <a aria-controls="pills-home" aria-selected="true" class="nav-link active" data-toggle="pill"
                            href="#pills-home" id="pills-home-tab" role="tab">Login</a>
                    </li>
                    <li class="nav-item">
                        <a aria-controls="pills-profile" aria-selected="false" class="nav-link" data-toggle="pill"
                            href="#pills-profile" id="pills-profile-tab" role="tab">Sign up</a>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tab-Content">
                    <div aria-labelledby="pills-home-tab" class="tab-pane fade show active" id="pills-home"
                        role="tabpanel">
                        <!-- Formulario de inicio de sesión -->
                        <form (ngSubmit)="login($event, email, password)">
                            <div class="form-group col-md-12">
                                <label for="inputEmail">Email</label>
                                <input class="form-control" [(ngModel)]="email" name="email" placeholder="Email" type="email">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="inputPassword05">Password</label>
                                <input class="form-control" [(ngModel)]="password" name="password" placeholder="Password" type="password">
                            </div>
                            <button class="btn primary-btn btn-default text-uppercase" type="submit">Sign up</button>
                        </form>
                        <!-- Fin del formulario de inicio de sesión -->
                    </div>
                    <div aria-labelledby="pills-profile-tab" class="tab-pane fade" id="pills-profile"
                        role="tabpanel">
                        <!-- Formulario de registro -->
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputEmail05">Email</label>
                                    <input class="form-control" id="inputEmail05" placeholder="Email" type="email">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputPassword04">Password</label>
                                    <input class="form-control" id="inputPassword04" placeholder="Password"
                                        type="password">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputPassword4">Confirm Password</label>
                                    <input class="form-control" id="inputPassword4" placeholder="Password"
                                        type="password">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputAddress">Address</label>
                                <input class="form-control" id="inputAddress" placeholder="1234 Main St" type="text">
                            </div>
                            <button class="btn btn-default primary-btn text-uppercase">log in</button>
                        </form>
                        <!-- Fin del formulario de registro -->
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
