<app-modern-sass-nav></app-modern-sass-nav>
<section class="p-b-0 bg-black" [ngStyle]="{'background': 'white'}">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="title title2 title-inner">
                  <div class="main-title">
                      <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>{{'contact' | translate}}</span></h2>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<footer class="event contact set-relative bg " id="contact"  [ngStyle]="{'background-image': 'url()'}">
  <div class="container p-b-100">
      <div class="row">
          <div class="col-md-10 offset-md-1">
              <div class="title title3">
                  <div class="main-title">
                      <h2 class=" text-black">our schedule</h2>
                  </div>
                  <div class="sub-title">
                      <P class="text-black">Register now and reserve your seat for this
                          <span>year's unice,</span> the largest <span>web development</span> and online marketing
                          <span>conference in UK,</span> covering everything you need to know in order to develop a
                          successful online business.</P>
                  </div>
              </div>
          </div>
          <div class="col-xl-5 offset-xl-1 col-md-6 p-r-0 map">
              <div class="iframe-container">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31242.699836185682!2d-58.38779556433094!3d-34.60455884237685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccacb44b2742d%3A0x21d3d5c153ef88e1!2sHealth%20Management%20Solutions!5e0!3m2!1ses-419!2sar!4v1701702187156!5m2!1ses-419!2sar" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
          </div>
          <div class="col-xl-5 p-l-0 col-md-6 set-z-index form-footer">
              <div class="bg-white">

                  <form ngNoForm action="https://formsubmit.co/jsisti.hms@gmail.com" method="POST" enctype="multipart/form-data">
                      <div class="row">
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label for="name">{{'Name *' | translate}}</label>
                                  <input type="text" name="name" class="form-control" id="name">
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label for="Phone">{{'Phone *' | translate}}</label>
                                  <input type="text" name="phone" class="form-control" id="Phone">
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label for="Email">{{'Email *' | translate}}</label>
                                  <input type="text" name="email" class="form-control" id="Email">
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label for="Subject">{{'Subject *' | translate}}</label>
                                  <input type="text" name="subject" class="form-control" id="Subject">
                              </div>
                          </div>
                      </div>
                      <div class="form-group">
                          <label for="Message">{{'Message *' | translate}}</label>
                          <input type="text" name="message" class="form-control" id="Message">
                      </div>

                      <button type="submit" class="btn btn-default primary-btn m-0-auto event-btn">
                        {{ 'send' | translate }}
                    </button>
                  </form>
              </div>
          </div>
      </div>
  </div>
  <img src="assets/images/event/footer/2.png" alt="" class="set-abs top-0 plane2">
  <img src="assets/images/event/footer/1.png" alt="" class="set-abs bottom-0 plane">
</footer>
<app-hms-footer></app-hms-footer>