import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Identifiers } from '../../model/person/identifiers';

@Injectable({
  providedIn: 'root'
})
export class AdminOptionsHmsIdService {

  idSelected: string = Identifiers.HMS_ID;
  idSelected$: Subject<string> = new Subject<string>();

  constructor() { }

  // es la opcion para mostrar todos los HmsId
  public showAll(){
    this.idSelected = Identifiers.HMS_ID;
    this.idSelected$.next(this.idSelected);
  }

  // se setea un id por default
  public setId(id: string){
    this.idSelected = id;
    this.idSelected$.next(this.idSelected);
  }
}
