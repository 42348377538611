import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CivilId } from '../../model/person/civil.id';
import { ParseCodeService } from '../../services/utils/parse-code.service';
import { civilIdsByCountry } from '../../assets/mocks/civilIdCountry';
import { countries } from '../../assets/mocks/country-data-store';

@Component({
  selector: 'app-civil-id-v2',
  templateUrl: './civil-id-v2.component.html',
  styleUrls: ['./civil-id-v2.component.scss', '../../assets/style.scss']
})
export class CivilIdV2Component implements OnInit {
  
  civilIdForm: FormGroup;
  countries: any = countries;
  optionsDocumentType: any[] = [];
  hideValue?: boolean = true;

  @Input() civilId?: CivilId|undefined;
  @Input() codeCountry?: string;
  @Input() editable?: boolean;
  @Input() placeholder: string = "";
  @Input() displayText: string = "horizontal";   // horizontal/vertical para mostrar alineados o uno arriba de otro
  @Input() countryEditable?: boolean;
  @Input() typeCivilIdEditable?: boolean;
  @Input() text?: string;
  @Input() showEye?: boolean = false;
  @Input() colorText?: string;
  
  @Output() notifyPressEnter = new EventEmitter<boolean>();
  @Output() notifyChangeValue = new EventEmitter<any>();
  @Output() notifyChangeValueCountry = new EventEmitter<string>();
  @Output() notifyChangeValueTypeCivilId = new EventEmitter<string>();
  // eventos emitidos de afuera
  @Input() updateValue: EventEmitter<any>;
  @Input() resetValue: EventEmitter<any>;

  /* valueCivilId?: string; */
  /* typeCivilId:string = CivilIds.AR_DNI; */
  typeCivilId:any|null = null;

  // FLAGS
  valueValid: boolean = false;
  
  constructor(
    private parseCodeService: ParseCodeService
  ) { 
    /* this.civilIdForm = new FormGroup({
      valueCivilId: new FormControl({ value: "", disabled: this.inputEditable}, {
                //validators:[ Validators.required, Validators.pattern('[0-9]{8}')]
                validators:[]
                //updateOn: 'blur' (controla el chequeo del dato cuando se desenfoca) 
              })
    }); */
   }

  ngOnInit() {
    let civilIdFormatted;
    if(this.civilId){
      civilIdFormatted = this.civilIdFormatted();
    }
    else{
      civilIdFormatted = "";
    }
      
    this.civilIdForm = new FormGroup({
      valueCivilId: new FormControl({ value: civilIdFormatted, disabled: this.civilId?.readOnly}, {
                validators:[ Validators.required ]
              })
    });
    
    if(this.civilId){
      this.typeCivilId = this.civilId.codeId;
    }

    // aca escuchamos la info que nos pueden enviar de afuera
    if(typeof this.updateValue != "undefined"){
      this.updateValue.subscribe((data: any) => {
        /* console.log("[CivilId-v2.comp] => Update de input: ", data); */
        this.civilIdForm.get("valueCivilId")?.setValue(data.value);
        this.codeCountry = data.issuer;
        this.typeCivilId = data.codeId;
        /* this.changeInput(); */
       })
    }
    if(typeof this.resetValue != "undefined"){
      this.resetValue.subscribe((data: any) => {
        this.civilIdForm.get("valueCivilId").setValue("");
       })
    }

    /* console.log("CivilId type recibido: ", this.typeCivilId); */
    this.updateOptionTypeCivilIds();
  }

  private civilIdFormatted(){
    let codeCountry = (this.civilId?.issuer)?.toLocaleUpperCase();
    let codeId = this.civilId?.codeId;
    
    let formatCountry:any = civilIdsByCountry.find(civilId => civilId.country == codeCountry);
    /* console.log("Format encontrado: ", formatCountry); */
    let formatCivilId:any = (formatCountry.types).find((type: any) => type.code == codeId);

    if((typeof this.civilId?.value == "undefined")||(this.civilId?.value == ""))
      return "";
    return this.parseCodeService.formatCivilId(formatCivilId.mask, this.civilId?.value);
  }

  /* Controlamos el cambio del input ingresado */
  public changeInput(){
    /* console.log("Se cambio el civilId -> " +this.civilIdForm.get("valueCivilId")?.value); */
    let dataForm = this.createCivilId();
    this.notifyChangeValue.emit(dataForm);
  }

  private createCivilId(){
    let civilIdInput = this.civilIdForm.get("valueCivilId")?.value;
    let isValid = this.civilIdForm.get("valueCivilId")?.valid;
    /* console.log("[createCivilId] == formControl civilId valid => ", isValid);
    console.log("[createCivilId] == form invalido => ", this.civilIdForm.invalid); */
    return {value: {value: civilIdInput, issuer: (this.codeCountry)?.toLowerCase(), codeId: this.typeCivilId, status: null, tags: null}, valid: isValid};
  }

  public notifyEnterPressed(): void {
    if(this.civilIdForm.get("valueCivilId")?.valid)
      this.notifyPressEnter.emit(true);
  }

  public showValue(){
    this.hideValue = !this.hideValue;
  }

  // ##########################################################################################
  // #################################### LISTENER ####################################

  public changeCountry(data: any){
    /* console.log("Nuevo pais seleccionado: ", data); */
    this.codeCountry = data.code;
    /* this.notifyChangeValueCountry.emit(this.codeCountry); */
    let dataForm = this.createCivilId();
    this.updateOptionTypeCivilIds();
    this.notifyChangeValue.emit(dataForm);
  }

  public changeTypeCivilId(data: any){
    this.typeCivilId = data;
    /* console.log("[Civil-id-v2.comp] => tipo doc: ", data); */
    /* this.notifyChangeValueTypeCivilId.emit(this.typeCivilId); */
    this.changeRegex(this.typeCivilId.regex);
    let dataForm = this.createCivilId();
    this.notifyChangeValue.emit(dataForm);
  }

  private changeRegex(data: string){
    /* console.log("Nueva regex: ", data); */
    this.civilIdForm.get('valueCivilId')?.setValidators([Validators.required, Validators.pattern(data)]);
    let dataForm = this.createCivilId();
    this.notifyChangeValue.emit(dataForm);
  }

  private updateOptionTypeCivilIds() {
    let code: string;
    this.codeCountry ? code = this.codeCountry: code = this.civilId?.issuer;

    /* console.log("UpdateTypeCivilIds: ", this.codeCountry); */
    let civilIdsCountry:any = civilIdsByCountry.find(civilId => civilId.country == code);
    if(!civilIdsCountry){
      console.warn("[CivilId-v2] => No se encuentran los tipos del pais ingresado. ", code);  
      return;
    }

    this.optionsDocumentType = civilIdsCountry.types;
    this.typeCivilId = this.optionsDocumentType[0];
    /* console.log("[CivilId-v2] => tipo doc actualizado: ", this.typeCivilId); */
    this.changeRegex(this.typeCivilId.regex);
  }

}
