import { Injectable } from '@angular/core';
import { ContextSass } from '../../model/context/contextSass';
import { ContextSassSpanish } from '../../model/context/contextSassSpanish';
import { ConfigService } from '../config.service';
import { TimeService } from '../utils/time.service';
import { DateTime } from 'luxon';
import { ContextManagerService } from '../context-manager.service';
import { Profile } from '../../model/profiler/profile';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { TokenizerService } from '../tokenizer.service';
import { config } from '../../assets/mocks/login';
import { Person } from '../../model/person/person.model';
import * as uuid from 'uuid';
import { PersonSSO } from '../../model/SSO/person/person';
import { AdminRolesService } from './admin-roles.service';
import { ConfigToken } from '../../assets/config/config-constant.example';

@Injectable({
  providedIn: 'root'
})
export class AdminContextSassService {

  context?: ContextSass;
  /* requestedTime?: string = undefined; */
  expirationTime?: string = undefined;

  tokenVisitorContext?: string;                 // el token del contexto que comparte su contexto para poder loguearse en el sitio 
  tokenVisitorContext$ = new BehaviorSubject<string|undefined>(undefined);
  token?: string;                 // el token del contexto que comparte su contexto para poder loguearse en el sitio 
  token$ = new BehaviorSubject<string|undefined>(undefined);

  // initializedContext: boolean = false;
  context$ = new BehaviorSubject<ContextSass|undefined>(undefined);

  constructor(
    private configService: ConfigService,
    private timeService: TimeService,
    private contextManagerService: ContextManagerService,
    private adminRolesService: AdminRolesService,
    private tokenizerService: TokenizerService
  ) {
    // this.configService.targetServices$.subscribe((value: any) => {      
    //   if(value){
    //     /* console.log("[Admin-context-sass.serv] => Lb4 services actualizado: ", value); */
    //     this.initContext();
    //   }
    // });

    this.context$.subscribe(
      (resp: any) => {
        if(resp){
          /* console.log("[const] => cambio el contexto: ", resp); */
          this.context = resp;
        }
      }
    )

    this.initContext();
  }

  private initContext(){
    // en lugar de ir a buscar la info al viejo mock vamos a buscar el archivo de roles por funcionalidad
    let fullRolesApp: any = this.adminRolesService.getFullRoles()
    
    let dataInitial: any = {
      app: this.configService.getApp(),
      domain: this.configService.getAppDomain(),
      environment: this.configService.getTypeEnvironment(),
      requested_roles: fullRolesApp,
      uuid: uuid.v4()
    }
    /* this.context = new ContextSass(dataInitial); */
    let newContext = new ContextSass(dataInitial);
    this.context$.next(newContext);
    /* console.log("[initContext] => Contexto incializado: ", newContext); */
  }

  public async updateWithUserLogged(tokenUserLogged: string){
    if(!this.context){
      console.warn("[updateWithUserLogged] => No se ha inicializado el contexto. No es posible actualizarlo.");
      return null;
    }
    let respStartContext = await this.contextManagerService.startContext(this.context, tokenUserLogged);
    /* console.log("[updateWithUserLogged] => Resp startcontext: ", respStartContext); */
    if(respStartContext){
      this.context = respStartContext;
      this.context$.next(this.context);
      /* console.log("[updateWithUserLogged] => Contexto inicializado: ", this.context); */
      // this.initializedContext = true;
    }
  }

  public async addPerson(personToken: string, isOperator: boolean){
    return await this.contextManagerService.addPersonToContextApi(this.context, personToken, isOperator);
  }

  public updateCounterRoles(user: PersonSSO){
    // TODO: ver si se hace todo esto con algunas de las funciones nuevas
    this.contextManagerService.updateRoles(this.context, user);
  }

  public supportedRol(role: string){
    return this.contextManagerService.hasRole(this.context, role);
  }

  // Determina si se soporta alguno de los roles recibidos
  public supportedSomeRole(roles: string[]|null){
    if(!roles){
      console.warn("[supportedSomeRole] => No se recibieron roles!!");
      return;
    }
    /* console.log("[supportedSomeRole] => Contexto inicializado: ", this.context);
    console.log("[supportedSomeRole] => roles recibidos: ", roles); */
    return this.contextManagerService.hasSomeRole(this.context, roles);;
  }
  
  // determina si el contexto cumple con alguno de los roles requeridos por la funcionalidad recibida
  public verifyAccesByFuncionallity(funcionallity: string){
    let rolesByFuncionallity = this.adminRolesService.getRolesByFunctionality(funcionallity);
    let allowed = this.contextManagerService.verifyAccess(rolesByFuncionallity, this.context);
    /* console.log("[userAllowedByFuncionallity] => resp de user permitido: ", allowed); */
    if(allowed.hasAccess){
      this.context$.next(allowed.context);
    }
    return allowed.hasAccess;
  }

  // determina si el contexto cumple con alguno de los roles recibidos
  public verifyAccesByRoles(roles: string[]){
    let allowed = this.contextManagerService.verifyAccess(roles, this.context);
    if(allowed.hasAccess){
      this.context$.next(allowed.context);
    }
    return allowed.hasAccess;
  }
  
  // Buscamos un token de contexto
  public generateTokenContext(){
    /* this.tokenizerService.getTokenTime({context: this.context}, config.token.time_expiration).subscribe( */
    this.tokenizerService.getTokenV2({context: this.context}, config.token.time_expiration, Number.parseInt(ConfigToken.LENGTH_LOGIN_DEFAULT), ConfigToken.TYPE_LOGIN_DEFAULT, Number.parseInt(ConfigToken.NUMBER_USSAGE_LOGIN_DEFAULT))
      .subscribe(
        (resp: any) => {
          console.log("[Admin-context-sass.serv] => Resp de la generacion de token de contexto generado: ", resp);
          /* this.context.token = resp.short_token;              // NOTA: el login con la app CD solo funca con el short_token */
          this.token = resp.short_token;              // NOTA: el login con la app CD solo funca con el short_token
          /* this.context.token = resp.long_token; */         // este es para probar el login en la app con user logueado en site angular
          this.setTimeExpired(resp.expiration_moment);
          /* console.log("[Admin-context-sass.serv] => Generado token de contexto: ", this.context); */
        }
    )
  }

  /* public setLocationGeneration(coord: Coordinate){
    this.context.locationGeneration = coord;
  } */

  public getContext(): ContextSass|undefined{
    /* console.log("[getContext] => Se inicalizo el contexto: ", this.initializedContext); */
    return this.context$.getValue();
  }

  public setContext(context: ContextSass){
    this.context = context;
  }

  public removeUserLogged(){
    this.context?.users_entered.shift();
  }

  public getTokenContext(): string|undefined{
    /* return this.context.token; */
    return this.token;
  }

  public setTokenVisitorContext(newToken: string|undefined): void{
    this.tokenVisitorContext = newToken;
    this.tokenVisitorContext$.next(this.tokenVisitorContext);
  }

  public getTokenVisitorContext(): string|undefined{
    return this.tokenVisitorContext;
  }

  public setTokenLocal(token: string){
    this.context.token = token;
  }

  /* public setTimeRequested(time: string){
    this.requestedTime = time;
  } */

  public setTimeExpired(time: string){
    this.expirationTime = time;
  }

  // agregamos los datos de los usuarios recibidos y actualizamos los roles
  public async updateUsersEntered(dataUsers: any[]){
    for (let dataUser of dataUsers) {
      let dataFind = this.context?.users_entered.find((data: any) => data.user == dataUser.user);
      if(!dataFind){
        this.context?.users_entered.push(dataUser);
        // si agrego un nuevo usuario actualizo los roles segun el perfil del user
        let profiles = dataUser.profiles;
        for(let nameProfile of profiles){
          let arrayProfile = nameProfile.split("@");
          await this.updateCounterRoles(arrayProfile[0]);
        }
      }
    }
  }

  public expiredToken(){
    if(!this.expirationTime){
      console.log("[Admin-context-sass.serv] => Aun no se ha seteado el tiempo de expiracion.");
      return true;
    }
    let fecha = DateTime.fromISO(this.expirationTime);
    let fechaActual = DateTime.now();
    /* console.log("[Admin-context-sass.serv] => Hora de expiracion: ", fecha, " - Hora actual: ", fechaActual); */
    if (fecha > fechaActual){
      /* console.log("[Admin-context-sass.serv] => NO expiro la hora!"); */
      return false;
    }
    else{
      /* console.log("[Admin-context-sass.serv] => Expiro la hora!"); */
      return true;
    }
  }

  public userEnabled(user: PersonSSO){
    this.contextManagerService.updateRoles
  }

  // determina si el contexto ha sufrido algun cambio
  public changedContext(context: ContextSass){
    /* console.log("[Admin-context-sass.serv] => Contexto actual: ", this.context);
    console.log("[Admin-context-sass.serv] => Contexto nuevo: ", context); */
    let currentContext = JSON.stringify(this.context);
    let newContext = JSON.stringify(context);
    if(currentContext != newContext){
      return true;
    }
    return false;
  }

}

