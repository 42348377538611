import { Injectable } from '@angular/core';
import { Context } from '../../model/context/context';
import { PersonContext } from '../../model/context/personContext';
import { Person } from '../../model/person/person.model';
import { Daedline } from '../../model/deadline';
import { Location } from '../../model/location';
import { DataToken } from '../../model/token/data-token';
import { ConfigService } from '../config.service';
import { ConfigContext } from '../../assets/config/config-context';
import { BehaviorSubject } from 'rxjs';
import { PersonContextSSO } from '../../model/SSO/context/personContextSSO';
import { PersonSSO } from '../../model/SSO/person/person';

@Injectable({
  providedIn: 'root'
})
export class AdminContextService {

  deadline?: Daedline;
  persons?: PersonContextSSO[];
  recoveryOperators: any;
  recoveryAffiliates: any;

  // afiliados en contexto
  affiliatesContext$ = new BehaviorSubject<PersonSSO[]|null>(null);

  constructor(
    private configService: ConfigService
  ) {
    this.persons = [];
    this.recoveryOperators = ConfigContext.RECOVERY_OPERATORS;
    this.recoveryAffiliates = ConfigContext.RECOVERY_AFFILIATES;
  }

  // Agrega una persona al contexto
  public addPerson(person: PersonContextSSO){
    /* console.log("[Admin-cont.serv] => Entra a addPerson() con person: ", person); */
    if(!this.existPerson(person)){
      /* console.log("[Admin-cont.serv] => Push de la persona: aun NO esta en contexto."); */
      this.persons?.push(person);
      // si es un afiliado notificamos la actualizacion
      let infoAffi = person.external_info?.find((external: any) => (external.agent.id == "SALUDSOFT_AFFILIATE"));
      if(infoAffi){
        this.affiliatesContext$.next(this.getAffiliatesContext());
      }
      return person;
    }
    /* console.log("[Admin-cont.serv] => Persona en contexto. NO se sobreescribe."); */
    return null;
  }
  
  // Agrega una persona al contexto, si existe la reemplaza
  public addPersonForced(person: PersonContextSSO){
    /* let userExist = this.persons?.find(elem => (new PersonContextSSO(elem)).equals(person)); */
    let founded: boolean = false;

    for (let i = 0; i < this.persons.length; i++) {
      // si encontramos a la personas la reescribimos
      if(this.persons[i].equals(person)){
        founded = true;
        console.log("[Adm-context.serv] => La persona esta en el contexto. Se reemplaza.");
        this.persons[i] = person;
      }
    }
    
    if(!founded){
      console.log("[Adm-context.serv] => La persona NO esta en el contexto. Se agrega.");
      this.persons?.push(person);
    }
    // si es un afiliado notificamos la actualizacion
    // TODO SSO: luego ver como como corregir esto con el nuevo modelo
    /* if(person.affiliateInfo){
      this.affiliatesContext$.next(this.getAffiliatesContext());
    } */
  }

  // Agrega un token a la persona indicada
  public addTokenPerson(dataToken: DataToken, person: PersonContextSSO){
    /* console.log("[Admin-cont.serv] => Entra a addTokenPerson()."); */
    for (let i = 0; i < this.persons.length; i++) {
      if(this.persons[i].equals(person)){
        this.persons[i].token = dataToken.long_token;
        this.persons[i].expiredToken = dataToken.expiration_moment;
      }
    }
  }
  
  // Agrega una persona al contexto
  public deletePerson(person: PersonContextSSO|PersonSSO){
    this.persons = this.persons?.filter((item) => !item.equals(person));
    return this.persons;
    /* console.log("[Admin-cont.serv] => datos de contexto: ", this.getFullData()); */
  }

  // devuelve la persona persistida en contexto, null en caso de no estarlo
  public existPerson(person: PersonSSO|PersonContextSSO){
    /* console.log("[Admin-cont.serv] => Personas en contexto: ", this.persons);
    console.log("[Admin-cont.serv] => Persona a buscar: ", person); */
    let userExist = this.persons?.find(elem => (new PersonContextSSO(elem)).equals(person));
    
    if(userExist){
      return userExist;
    }
    /* console.log("[Admin-cont.serv] => userExist: ", userExist, " - mailRecibido: ",person.emails[0].value); */
    return null;
  }

  // aca deberiamos de mostrar toda la info del contexto que queres comunicar a otras apps
  public getFullData(){
    this.updateDataDeadline();
    let dataContext = {
      domain: this.configService.getAppDomain() as string,
      app: this.configService.getApp() as string,
      version: this.configService.getAppVersion() as string,
      deadline: this.deadline/* ,
      persons: this.persons */  // ignoramos las personas dado que lo enviamos como datos del localStorage
    }
    
    return new Context(dataContext);
  }

  public getFullDataString(){
    return JSON.stringify(this.getFullData());
  }

  public getRecoveryOperators(){
    return this.recoveryOperators;
  }
  public setRecoveryOperators(option: string){
    this.recoveryOperators = option;
  }

  public getRecoveryAffiliate(){
    return this.recoveryAffiliates;
  }
  public setRecoveryAffiliate(option: string){
    this.recoveryAffiliates = option;
  }

  public getAffiliatesContext(){
    if(!this.persons)
      return null;

    let affiliates: PersonSSO[] = [];
    for (let i = 0; i < this.persons.length; i++) {
      // para distinguir afiliados de operadores
      // TODO: ver como devolver los afiliados en contexto
      /* if(this.persons[i].affiliateInfo){
        affiliates.push(this.persons[i].getPerson());
      } */
    }
    return affiliates;
  }
  // actualizamos los datos
  private updateDataDeadline(){
    this.deadline = new Daedline();
    // recuperamos las coordenadas (puede que haya que mejorar precision)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // Seteamos la ubicacion
        this.deadline.location = new Location({latitude: ""+position.coords.latitude, longitude: ""+position.coords.longitude});
      });
    }
    else {
      console.warn("[Admin-context.serv] => El usuario no concedio los permisos para determinar la ubicacion.");
    }

    // recuperamos la hora actual
    this.deadline.moment = new Date().getTime();
    /* console.log("[Admin-context.serv] => Data contexto deadline: ",this.deadline); */
  }

}

