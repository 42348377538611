import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-selector-generic',
  templateUrl: './selector-generic.component.html',
  styleUrls: ['./selector-generic.component.scss']
})
/* export class SelectorGenericComponent implements OnInit, AfterViewChecked, AfterContentChecked { */
export class SelectorGenericComponent implements OnInit{

  // debemos tener una referencia de cada subscripcion para q luego se puede desubscribir sin error
  private subscriptionReset: Subscription;
  private subscriptionSet: Subscription;

  @Input() id?: string;
  @Input() editable?: boolean;
  @Input() options: any[];
  @Input() inputOption?: string|null = null;
  @Input() showTextSelection?: boolean = true;
  @Input() text?: string;
  @Input() marginBottom?: boolean = true;
  @Input() displayText?: string = "vertical";     // horizontal/vertical
  @Input() atrValue?: string;     // atributo del objeto a mostrar como dato y por el cual se busca
  @Input() colorText?: string;
  // eventos emitidos de afuera
  @Input() updateOptionsList: EventEmitter<any>;
  @Input() set: EventEmitter<any>;
  @Input() reset: EventEmitter<any>;

  @Output() notifyChangeOption = new EventEmitter<any>();

  /* private eventsSubscription: Subscription;
  @Input() events: Observable<any>|undefined; */

  // opcion default
  optionCodeSelected: string|null = null;
  // muestra la primera opcion de seleccionar un elemento
  showtext: boolean = false;
  /* isReset: boolean = false; */

/*   constructor(private globalEventsService: GlobalEventsService) {
    this.globalEventsService.showCheckoutHeader = true;
} */
  constructor() { }
  
  ngOnInit(): void {
    // if(typeof this.events != "undefined"){
    //   this.eventsSubscription = this.events.subscribe((dataEvent) => {
    //   console.log("[Selector-generic.comp] => Recibi un evento desde el padre: ", dataEvent, " - input: ", this.inputOption);
    //   if((dataEvent.id == this.id) && (dataEvent.code == "reset")){
    //     console.log("[Selector-generic.comp] => Select reseteado("+this.id+")!!");
    //     /* this.showtext = dataEvent.value; */
    //     this.optionCodeSelected = null;
    //     /* this.ref.markForCheck()
    //     this.refresh(); */
    //   }
    // });}    

    if(typeof this.reset != "undefined"){
      this.subscriptionReset = this.reset.subscribe((data: any) => {
        /* console.log("[Select-autosearch.comp] => Evento reset input: ", data); */
        this.inputOption = "";
        this.optionCodeSelected = null;
        this.options = [];      // para no dejar desplegadas las opciones, si es que se encontraban abiertas
       })
    }
    if(typeof this.set != "undefined"){
      this.subscriptionReset = this.set.subscribe((data: any) => {
        /* console.log("[Select-autosearch.comp] => id(",this.id,") Evento set input: ", data);   */
        // if(data.id == this.id){
        //   /* console.log("[Select-autosearch.comp] => SON IGUALES "); */
        //   this.setValue(data.value);
        // }
        // else{
        //   console.log("[Select-autosearch.comp] => NO son iguales ");
        // }
        this.setValue(data);
      })
    }

    this.init();

    // aca escuchamos la info que nos pueden enviar de afuera
    if(typeof this.updateOptionsList != "undefined"){
      this.updateOptionsList.subscribe((data: any) => {
        /* console.log("[Select-generic.comp] => Update de opciones: ", data); */
        this.options = data;
       })
    }

  }

  public setValue(data: any){
    this.optionCodeSelected = data;
    /* console.log("[Selector-generic.comp] => Opciones actuales: ", this.options); */
    // TODO: buscar la opcion en la lista de opciones actuales
    let option = this.options.find((op: any) => op[this.atrValue] == this.optionCodeSelected);
    /* console.log("[Selector-generic.comp] => Se selecciono la opcion: ", option); */
    this.notifyChangeOption.emit(option);
  }

  onChange(optionSelected: any){
    /* console.log("[Selector-generic.comp] => Se selecciono la opcion: ", optionSelected); */
    this.optionCodeSelected = optionSelected;
    // buscamos la opcion en la lista disponible
    /* let option = this.options.find(op => op[atrValue] == optionSelected); */
    let option = this.options.find((op: any) => op[this.atrValue] == optionSelected);
    /* console.log("[Selector-generic.comp] => Se selecciono la opcion: ", option); */
    this.notifyChangeOption.emit(option);
    // aca podria ir a buscar el objeto tag de la lista para devolverlo
  }

  public init(){
    if(!this.options){
      console.warn("[Selector-generic.comp] => No se recibieron opciones para mostrar.");
      return;
    }
    
    let optionFound: any;
    /* console.log("[Selector-generic.comp] => Opcion recibida: ", this.inputOption); */
    // para los casos en que no se brinda un valor
    if(!this.inputOption){
      optionFound = this.options.find(option => option[this.atrValue] == "other");
    }
    else{
      optionFound = this.options.find(option => option[this.atrValue] == this.inputOption);
      // en el caso de brindarse una calificacion no comtemplada
      if((typeof optionFound == "undefined") && (typeof this.inputOption == "string") && this.inputOption.length > 0){
        optionFound = {code: this.inputOption, value: this.inputOption};
        console.warn("[Selector-generic.comp] => Se agrega la opcion: ", optionFound);
        this.options.push(optionFound);
      }
      
    }
    if(!optionFound){
      console.warn("[Selector-generic.comp] => No se encontro la opcion del select.");
      return;
    }
    this.optionCodeSelected = optionFound[this.atrValue];
    /* console.log("[Selector-generic.comp] => Opcion encontrada: ", optionFound); */
  }

  /* ngOnDestroy() {
    if(this.eventsSubscription)
      this.eventsSubscription.unsubscribe();
  } */
  ngOnDestroy() {
    if(this.subscriptionSet)
      this.subscriptionSet.unsubscribe();
    if(this.subscriptionReset)
      this.subscriptionReset.unsubscribe();
  }

}
