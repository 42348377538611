import { Injectable } from '@angular/core';
import { InfoModal } from '../../model/info.modal';

@Injectable({
  providedIn: 'root'
})
export class AdminModalService {

  refModal: any;
  refInfoModal?: InfoModal;

  constructor() {}

  public setModalGlobal(modal: any){
    /* console.warn("[setModalGlobal] => Modal recibido: ", modal); */
    this.refModal = modal;
  }

  public setInfoModalGlobal(infoModal: InfoModal){
    this.refInfoModal = infoModal;
  }

  public showModalGlobal(title: string, body: string){
    if(!this.refInfoModal){
      console.warn("[showModalGlobal] => La info del modal global no ha sido incializado.");
      return;
    }
    this.refInfoModal.title = title;
    this.refInfoModal.body = body;
    this.refModal.open();
  }

}
