import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriceComponent } from './enterprice/enterprice.component';
import { EnterpriceSassComponent } from './enterprice-sass/enterprice-sass.component';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { RegisterComponent } from '../pages/register/register.component';
import { HmsTechComponent } from './hms-tech/hms-tech.component';
import { HmsTechProjectsComponent } from './hms-tech/hms-tech-projects/hms-tech-projects.component';
import { CandidateFormComponent } from './hms-tech/candidate-form/candidate-form.component';
import { AboutHmsGroupComponent } from './hms-tech/company-profile/about-hms-group/about-hms-group.component';
import { OurHistoryComponent } from './hms-tech/company-profile/our-history/our-history.component';
import { OurVisionComponent } from './hms-tech/company-profile/our-vision/our-vision.component';
import { OurPeopleComponent } from './hms-tech/company-profile/our-people/our-people.component';
//FOLDER HMS
import { TechnologiesComponent } from './../layouts/hms-tech/service-lines/technologies/technologies.component'
import { StaffingComponent } from './../layouts/hms-tech/service-lines/staffing/staffing.component'
import { SaasSolutionsComponent } from './../layouts/hms-tech/service-lines/saas-solutions/saas-solutions.component'
import { CareersComponent } from './../layouts/hms-tech/careers/careers.component'
import { ContactUsComponent } from './hms-tech/contact-us/contact-us.component';
//LOGIN
import { LoginComponent } from '../sesion/pages/login/login.component';

const routes: Routes = [
  //Home
  
  {
    path: 'home',
    component: HmsTechComponent,
    data: {
      title: "Home | HMS Group",
      breadcrumb: "Home"
    },
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'company-profile', component: HmsTechComponent },
    { path: 'careers', component: HmsTechComponent, },
    { path: 'our-work', component: HmsTechComponent, },
    ],
  },

  //Company Profile
  {
    path: 'home/company-profile/about-hms-group',
    component: AboutHmsGroupComponent,
    data: {
      title: 'Company Profile | HMS Group',
      breadcrumb: 'home/about-hms-group',
    },
  },
  //our-history
  {
    path: 'home/company-profile/our-history',
    component: OurHistoryComponent,
    data: {
      title: 'Company Profile | HMS Group',
      breadcrumb: 'home/our-history',
    },
  },
  //our-vision
  {
    path: 'home/company-profile/our-vision',
    component: OurVisionComponent,
    data: {
      title: 'Company Profile | HMS Group',
      breadcrumb: 'home/our-vision',
    },
  },
  //people
  {
    path: 'home/company-profile/our-people',
    component: OurPeopleComponent,
    data: {
      title: 'Company Profile | HMS Group',
      breadcrumb: 'home/our-people',
    },
  },
  //Careers
  {
    path: 'home/careers2',
    component: CareersComponent,
    data: {
      title: 'Careers | HMS Group',
      breadcrumb: 'careers',
    },
  },
  //Candidate Form
  {
    path: 'home/careers/candidate-form',
    component: CareersComponent,
    data: {
      title: 'Candidate Form | HMS Group',
      breadcrumb: 'home/careers/candidate-form',
    },
  },
  {
    path: 'home/careers2/candidate-form',
    component: CandidateFormComponent,
    data: {
      title: 'Candidate Form | HMS Group',
      breadcrumb: 'home/careers/candidate-form',
    },
  },
  {
    path: 'home/careers/candidate-form/candidate-form',
    component: CandidateFormComponent,
    data: {
      title: 'Candidate Form | HMS Group',
      breadcrumb: 'home/careers/candidate-form',
    },
  },
  {
    path: 'home/careers2/candidate-form/candidate-form',
    component: CandidateFormComponent,
    data: {
      title: 'Candidate Form | HMS Group',
      breadcrumb: 'home/careers/candidate-form',
    },
  },

  //Our Work
  {
    path: 'home/our-work/products',
    component: HmsTechProjectsComponent,
    data: {
      title: 'Company Profile | HMS Group',
      breadcrumb: 'home/our-work',
    },
  },
 
  //Products
  {
    path: 'products',
    component: EnterpriceComponent,
    data: {
      title: 'Products | HMS Group',
      breadcrumb: 'Products',
    }
  },
  {
    path: 'products/products',
    component: HmsTechProjectsComponent,
    data: {
      title: 'Products | HMS Group',
      breadcrumb: 'products',
    }
  },
  //Technologies
  {
    path: 'products/technologies',
    component: TechnologiesComponent,
    data: {
      title: 'Technologies | HMS Group',
      breadcrumb: 'technologies',
    }
  },
  //Service-Lines
  {
    path: 'service-lines',
    component: EnterpriceSassComponent,
    data: {
      title: 'Service-Lines | HMS Group',
      breadcrumb: 'Service-Lines',
    },
  },
  {
    path: 'service-lines/staffing', 
    component: EnterpriceSassComponent,
    data: {
      title: 'Staffing | HMS Group',
      breadcrumb: 'Staffing',
    },
  },
  { 
    path: 'service-lines/technologies', 
    component: EnterpriceSassComponent ,
    data: {
      title: 'Technologies | HMS Group',
      breadcrumb: 'Technologies',
    },
  },
  { 
    path: 'service-lines/saas-solutions', 
    component: EnterpriceSassComponent,
    data: {
      title: 'Saas-Solutions | HMS Group',
      breadcrumb: 'Saas-Solutions',
    }, 
  },
  //Contact Us
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: {
      title: 'Contact | HMS Group',
      breadcrumb: 'Contact',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: "404 - Not Found",
      breadcrumb: "Page Not Found"
    }
  },

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
