<app-loader></app-loader>
<app-loading></app-loading>

<router-outlet *ngIf="!showLoader"></router-outlet>
<!--
<div class="theme-pannel-main" *ngIf="isLoggedIn">
    <ul>
        <li class="sidebar-btn dark-light-btn" *ngIf="customize.data.settings.layout_version == 'dark'" (click)="customizeLayoutVersion('light')">
            <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version">&#9728;</span></a>
        </li>
        <li class="sidebar-btn dark-light-btn"  *ngIf="customize.data.settings.layout_version == 'light'" (click)="customizeLayoutVersion('dark')">
            <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version">&#9790;</span></a>
        </li>
    </ul>
</div>-->

<!-- Modal para mostrar los mjes de informacion al usuario -->
<app-modal-msg #modalInfoApp [info]="infoMsgModal"></app-modal-msg>