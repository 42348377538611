import { Injectable } from '@angular/core';
import { ROLES_FUNCTION } from '../../model/functionality-roles';
import { ConfigService } from '../config.service';
import { ProfileService } from '../profile.service';
import { PersonSSO } from '../../model/SSO/person/person';

@Injectable({
  providedIn: 'root'
})
export class AdminRolesService {

  constructor(
    private configService: ConfigService,
    private profileService: ProfileService,
    /* private contextManagerService: ContextManagerService, */
  ) {
    /* console.log("[Adm_roles.serv] => Admin de roles creado."); */
  }

  // determina si el usuario cumple con alguno de los roles requeridos por la funcionalidad recibida
  public async userAllowed(user: PersonSSO, funcionallity: string){
    let rolesForLogin = this.getRolesByFunctionality(funcionallity);
    return await this.userHasSomeRole(user, rolesForLogin);
  }

  // devuelve el conjunto de roles requeridos por todas las funcionalidades del site
  public getFullRoles(){
    let rolesSite: any[] = [];
    for(let rolFunction of ROLES_FUNCTION){
      rolesSite = rolesSite.concat(rolFunction.roles);
    }
    return rolesSite;
  }

  // devuelve los roles necesarios para una funcionalidad determinada, null en el caso de tratarse de una funcionalidad no contemplada
  public getRolesByFunctionality(funcionality: string){
    let objFuncRol = ROLES_FUNCTION.find(elem => elem.funcionality == funcionality);
    if(!objFuncRol)
      return null;
    return objFuncRol.roles;
  }

  // determina si el usuario recibido satisface al menos un rol de los recibidos
  public async userHasSomeRole(user: PersonSSO, roles: string[]|null): Promise<boolean>{
    if(!roles){
      console.warn("[userHasSomeRole] => No se recibieron roles!");
      return false;
    }
    let domainCurrent = this.configService.getAppDomain() as string;
    // recupero los perfiles del usuario a partir de los perfiles que tiene del dominio en el q estoy
    let profilesUser = this.getProfilesOfDomain(user, domainCurrent);
    let respAllProfiles: any = await this.profileService.allProfilesByDomain(domainCurrent).toPromise();
    let rolesUser: string[] = [];
    if(respAllProfiles.length > 0){
      for(let profile of profilesUser){
        let profileFounded = null;
        for (let i = 0; (i < respAllProfiles.length) && (!profileFounded); i++) {
          let dataProfile = respAllProfiles[i];
          let arrayDataProfile = dataProfile.name.split("@");
          if(arrayDataProfile[0] == profile){
            profileFounded = dataProfile;
          }
        }
        if(profileFounded){
          rolesUser = rolesUser.concat(profileFounded.roles);
        }
      }
      /* console.log("[userHasSomeRole] => roles econtrados: ", rolesUser); */
    }

    // controlamos si el usuario cuenta con al menos uno de los roles recibidos 
    for(let rol of roles){
      let rolFounded = rolesUser.find((data: string) => data == rol);
      if(rolFounded){
        /* console.log("[userHasSomeRole] => Satisface el rol!!"); */
        return true;
      }
    }
    
    return false;
  }

  private getProfilesOfDomain(user: PersonSSO, domain: string): string[]{
    let profiles: string[] = [];
    // Ajuste para extraer y usar nombres de perfil y dominios
    user.profiles?.map((profile: any) => {
      let typeProfile = typeof(profile);
      /* console.log("perfil del tipo: ", typeProfile); */
      let profileParts: any = [];
      (typeProfile == "string") ? profileParts = profile.split('@'): profileParts = profile.name.split('@');
      /* let profileParts = profile.split('@'); */
      /* console.log("[getProfilesOfDomain] => partes del perfil: ", profileParts); */
      
      if (profileParts.length === 2) {
          let profileName = profileParts[0];
          let profileDomain = profileParts[1].split('_')[0]; // Extrae la parte del dominio
          if(profileDomain.toUpperCase() == domain.toUpperCase()){
            profiles.push(profileName);
          }
      }
    });
    return profiles;
  }

  // Devuelve los perfiles que cumplen con alguno de los roles recibidos
  public async getProfilesByRoles(roles: string[]){
    let domain: string = this.configService.getAppDomain() as string;
    let respGetProfiles: any = await this.profileService.allProfilesByDomain(domain).toPromise();
    /* console.log("[getProfilesByRoles] => Resp de get de perfiles: ", respGetProfiles); */
    
    if (!respGetProfiles || respGetProfiles.error) {
        return Promise.reject(respGetProfiles);
    }
    let profilesRol: any = [];
    respGetProfiles.forEach((profile: any) => {
        const profileContainRol = profile.roles.some((rol: any) => roles.includes(rol));
        if(profileContainRol) {
            profilesRol.push(profile);
        }
    });
    // Transformar el array de perfiles a un array de nombres
    const nameProfiles = profilesRol.map((profile: any) => profile.name);
    /* let arrProfiles = [];
    for(let prof of nameProfiles){
        let arrProf = prof.split("@");
        arrProfiles.push(arrProf[0]);
    }
    return Promise.resolve(arrProfiles); */

    return Promise.resolve(nameProfiles);

  }
}

