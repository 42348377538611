import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-feature',
  templateUrl: './modern-sass-feature.component.html',
  styleUrls: ['./modern-sass-feature.component.scss']
})
export class ModernSassFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

features = [
  {
    img:'assets/images/hms-tech/company-profile/About-hms-degrade.png',
    title: 'About HMS Group',
    description: 'Is a company with extensive expertise that was originally consolidated in IT solutions for the health market.',
    path: 'company-profile/about-hms-group'
  },
  {
    img:'assets/images/hms-tech/company-profile/Our-history-degrade.png',
    title: 'Our History',
    description: 'HMS formed in 1995 from Binary and Systemas, offering healthcare services and IT software.',
    path: 'company-profile/our-history'
  },
  {
    img:'assets/images/hms-tech/company-profile/Our-vision-degrade.png',
    title: 'Our Vision',
    description: 'Interconnected ecosystems, providing key-pieces that are located in diverse layers and representations.',
    path: 'company-profile/our-vision'
  },
  {
    img:'assets/images/hms-tech/company-profile/Our-people-degrade.png',
    title: 'Our People',
    description: 'We are a team of professionals with extensive experience in the health and technology sectors, who work together to provide the best solutions to our clients.',
    path: '',
    // OCULTAR OUR PEOPLE path: 'company-profile/our-people' //
  }
]
}
