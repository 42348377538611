import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SesionModule } from './sesion/sesion.module';
import { LocationService } from './sesion/services/utils/location.service';
import { LayoutsRoutingModule } from './layouts/layouts-routing.module';
import { InterceptorService } from '../app/layouts/hms-tech/services/interceptor.service';

// AOT compilation support
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    LayoutsRoutingModule,
    BrowserAnimationsModule,
    PagesModule,
    // PortfolioModule,
    HttpClientModule,
    CarouselModule,
    SharedModule,
    SesionModule,
    RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [TranslateService,
     LocationService,
     { // se puede crear un modulo con todos los interceptores en el caso de que haya
      provide: HTTP_INTERCEPTORS, // se usa para interceptar las peticiones http 
      useClass: InterceptorService, // indica que servicio se va a usar como interceptor
      multi: true   // para escuchar todas las peticiones, sin esto se sobreescriben
    },
     /* CONTROL DE CACHE A TRAVÉS DE HEADERS HTTP INTERCEPTORS{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    } */],
  bootstrap: [AppComponent]
})
export class AppModule { }
