import { Optional } from "@angular/core";
import { Ids } from "../identifiers/ids";
import { EmailSSO } from "../identifiers/email";
import { PhoneSSO } from "../identifiers/phone";
import { ProfileContext } from "../../context/profileContext";
import { PersonContextSSO } from "../context/personContextSSO";
import { DocumentSSO } from "../identifiers/document";

export class PersonSSO{
    /* @Optional() uuid?: string; */
    uuid?: string;
    first_name?: string;
    last_name?: string;
    registered?: boolean;
    profiles?:string[];
    /* ids?: Ids; */
    document_ids?: DocumentSSO[];
    emails?: EmailSSO[];
    phones?: PhoneSSO[];
    external_ids?: any[];
    external_info?: any[];
    personOldModel?: any;

    selfie?: string;
    // para el fake de la resp del login de cav20
    userSaludSoft: boolean = false;

    constructor(data?: Partial<PersonSSO>) {
        Object.assign(this, data);
    }

    public clone?(): PersonSSO {
        return new PersonSSO(this);
    }

    public equals(person: PersonSSO): boolean{
        if(!person)
            return false;
        return (this.uuid == person.uuid);
    }

    public getMailVerified(){
        /* return this.ids?.emails?.find((email: EmailSSO) => email.verified == true); */
        return this.emails?.find((email: EmailSSO) => email.verified == true);
    }

    public getPhoneVerified(){
        /* return this.ids?.phones?.find((phone: PhoneSSO) => phone.verified == true); */
        return this.phones?.find((phone: PhoneSSO) => phone.verified == true);
    }

    // devuelve un objeto PersonContext a partir de un object Person
    public getPersonContext(){
        let personContext = {
            uuid: this.uuid,
            first_name: this.first_name,
            last_name: this.last_name,
            /* ids: this.ids, */
            emails: this.emails,
            document_ids: this.document_ids,
            phones: this.phones,
            external_ids: this.external_ids,
            external_info: this.external_info,
            profiles: this.profiles,
            profilesContext: this.getProfilesContext(),
            token: "",
            tokenOperatorContext: [],
            function: "",
            activeRoles: [],
            isRegistered: this.registered
        };
    
        return new PersonContextSSO(personContext);
    }

    private getProfilesContext(): ProfileContext[] {
        if (!this.profiles) {
          return []; // Retorna un array vacío si no hay perfiles
        }
      
        let profilesContext: ProfileContext[] = [];
      
        for (let profile of this.profiles) {
          let profileBaseContext: Partial<ProfileContext> = {
            domain: "",
            profile: profile, // Aquí se infiere que 'profile' es una 'string'
            isRegistered: null,
            status: null,
            deadline: 0
          };
          profilesContext.push(new ProfileContext(profileBaseContext));
        }
      
        return profilesContext;
      }
      

    // recibe una persona con el modelo de Person anterior y devuelve un objeto con el nuevo modelo de Person
    public parse(person: any){
        this.personOldModel = person;
        // TODO: devolver un PersonSSO
        this.first_name = person.firstname;
        this.last_name = person.lastname;
        this.registered = person.isRegistered;
        this.profiles = [];
        this.external_info = person.affiliateInfo;
        this.document_ids = [];
        // recreamos los ids con el nuevo modelo
        for (const element of person.civilIds) {
            let civilId = element;
            let newDocument: DocumentSSO = {
                country_code: civilId.issuer,
                type: civilId.codeId,
                value: civilId.value
            }
            this.document_ids.push(newDocument);
        }
        this.emails = [];
        for (const element of person.emails) {
            let email = element;
            let newEmail: EmailSSO = {
                value: email.value,
                label: email.label
            }
            this.emails.push(newEmail);
        }
        this.phones = [];
        for (const element of person.phones) {
            let phone = element;
            let newPhone = new PhoneSSO();
            newPhone.country_code =phone.countryCode;
            newPhone.value = phone.phoneNumber;
            this.phones.push(newPhone);
        }
        // esto para agregar los saludSoftId
        /* this.phones = [];
        for (const element of person.phones) {
            let phone = element;
            let newPhone = new PhoneSSO();
            newPhone.country_code =phone.countryCode;
            newPhone.value = phone.phoneNumber;
            this.phones.push(newPhone);
        } */
    }

    public getOldModel(){
        return this.personOldModel;
    }
}
