import { Injectable } from '@angular/core';
import { ContextSass } from '../model/context/contextSass';
import { Profile } from '../model/profiler/profile';
import { Role } from '../model/profiler/role';
import { ConfigService } from './config.service';
import { ProfileService } from './profile.service';
import { Microservices } from '../assets/mocks/microservices';
import { PersonSSO } from '../model/SSO/person/person';


@Injectable({
    providedIn: 'root'
  })
  export class ContextManagerService {
      private urlBaseProfileServ?: string;
      private urlBaseTokenizerServ?: string;
      private urlBaseSrv?: string;
      private apikey?: string;
      private allowedRoles?: string[];
  
      private consoleLogLibrary: boolean = true;
  
    constructor(
      private profileService: ProfileService,
      private configService: ConfigService
    ) {
      this.configService.targetServices$.subscribe((value: any) => {      
          if(value){
            let baseUrlProfile: any = this.configService.getBaseUrlMicroservice(Microservices.PROFILE_SV.name);
            let baseUrlTokenizer: any = this.configService.getBaseUrlMicroservice(Microservices.TOKENIZER_SV.name);
            if(!baseUrlProfile){
              console.warn("[Context-manager.serv] => No se encontraron los datos de base del profile_sv.");
              return;
            }
            if(!baseUrlTokenizer){
              console.warn("[Context-manager.serv] => No se encontraron los datos de base del tokenizer_sv.");
              return;
            }
            this.urlBaseProfileServ = baseUrlProfile;
            this.urlBaseTokenizerServ = baseUrlTokenizer;
            this.apikey = this.configService.getApiKey() as string;
            /* console.log("[Context-manager.serv] => Url SRV_PROFILE: "+this.urlBaseProfileServ); */
          }
        });
    }
  
    // ############################################# VERSION NUEVA #############################################
  
    /* public async startContext(initialContext: any) { */
    public async startContext(initialContext: ContextSass, tokenUserLogged: string) {
      let allowedRoles = initialContext.requested_roles;
  
      try { 
          const userTokenData = await this.verifyUserToken(initialContext.domain as string, tokenUserLogged);
          // Determinar si los perfiles están directamente bajo payload o dentro de un objeto context
          // Primero, determina la ubicación correcta de los perfiles
          let profilesPath;
          if (userTokenData.payload.profiles) {
              profilesPath = userTokenData.payload.profiles;
          } else if (userTokenData.payload.context && userTokenData.payload.context.perfiles) {
              profilesPath = userTokenData.payload.context.perfiles;
          } else if (userTokenData.payload.user && userTokenData.payload.user.profiles) {
              profilesPath = userTokenData.payload.user.profiles;
          } else {
              profilesPath = []; // Por defecto, si no se encuentra ninguna ruta conocida
          }
  
          /* const responseData = await fetch(`https://osiris.hms-tech.com/api/profiles/domain/${initialContext.domain}?apikey=kO2Ntii7I7d8SzxnYZGsckNmTUZAdZ1b`); */
          const responseData = await fetch(this.urlBaseProfileServ+"/domain/"+initialContext.domain?.toUpperCase()+"?apikey="+this.apikey);
          /* console.log("[startContext] => Perfiles del dominio -> ", responseData); */
          const dataProfileByDomain = await responseData.json();
          let context: ContextSass = initialContext;
          /* context.operator = tokenUserLogged; */
          context.requested_profiles = this.updateRequestedProfile(context, dataProfileByDomain);
          context.offered_profiles = this.updateOfferedProfile(userTokenData.payload.profiles, context);
          // agregamos al operador a la lista de usuarios ingresados
          context.users_entered.push(this.getDataUserEntered(tokenUserLogged, userTokenData.payload.profiles));
          context.offered_roles = this.updateOfferedRoles(context.offered_profiles, dataProfileByDomain);
          /* console.log( "Contexto actualizado:  ", context); */
          return Promise.resolve(context);
      } catch (error) {
          console.error('[startContext] => Error al obtener los perfiles:', error);
          return null;
      }
  }
  
  //Verificación de TOKEN
  //Verificamos que el token pasado sea valido y retornamos todos sus datos.
  private async verifyUserToken(domain: string, token: string) {
      /* const url = `https://osiris.hms-tech.com/api/tokenizer/domain/${domain}/ctd/${token}`; */
      const url = this.urlBaseTokenizerServ+"/domain/"+domain+"/ctd/"+token+"?apikey="+this.apikey;
      try {
          const response = await fetch(url);
          const jsonData = await response.json();
          if (this.consoleLogLibrary) {
              /* console.log("[verifyUserToken] => verifyUserToken Response:", response); */
              /* console.log("[verifyUserToken] => verifyUserToken Data:", jsonData); */
          }
          return jsonData;
      } catch (error) {
          console.error("[verifyUserToken] => Error in verifyUserToken:", error);
          throw error; // Propagar el error para manejarlo en la llamada a esta función.
      }
  }
  
  // Actualizando contadores contexto:
  public actualizarRolesYContadores(contexto: ContextSass, roles: any) {
      // Buscar si el perfil ya existe en `perfiles`
      let perfilExistente = contexto.offered_profiles.find((perfil: any) => perfil.nombre === roles.name);
   
      // Si el perfil no existe, lo añade al arreglo `perfiles`.
      if (!perfilExistente) {
          contexto.offered_profiles.push(roles.name);
      }
  
      // Actualizar roles en el objeto `roles` y `requested_roles` si coinciden.
      for (let role of roles.roles) {
          if (contexto.offered_roles.hasOwnProperty(role)) {
              contexto.offered_roles[role] += 1;  // Incrementa el valor si el rol ya existe en `roles`.
          } else {
              contexto.offered_roles[role] = 1;  // Agregamos el ROL si no existe en `roles` y lo inicializamos a 1.
          }
  
          // También actualiza en `requested_roles` si el rol está en `allowedRoles`.
          if (contexto.requested_roles.hasOwnProperty(role)) {
              contexto.requested_roles[role] += 1;  // Incrementa el valor si el rol ya existe en `requested_roles`.
          }
      }
  }
  
  //AGREGAR PERSONA AL CONTEXTO
  public async addPersonToContextApi(context: ContextSass, tokenUsersEntered: string, isOperator: boolean){
      //context.users_entered = context.users_entered.concat(tokenUsersEntered);
      let resp = await this.expandirContexto(context, tokenUsersEntered, isOperator); //Agregamos el token de la persona adicional en el contexto
      if(resp){
          context = resp;
      }
      else{
          console.warn("[addPersonToContextApi] => Ha ocurrido un error al actualizar el contexto ", context);
      }
      return context;
  }
  
  //AGREGANDO PERSONA AL CONTEXTO (new versión de expandir contexto)
  public async expandirContexto(context: ContextSass, tokenUser: string, isOperador: boolean) {
      //const url = `https://osiris.hms-tech.com/api/tokenizer/domain/HMS/ctd/${tokenUsersEntered}`;
      try {
          //const domain ="hms" 
          const userTokenData = await this.verifyUserToken(this.configService.getAppDomain() as string, tokenUser);//Primero revisamos el token sea valido
          //Agregamos perfiles al contexto
          /* console.log("[expandirContexto] => ", userTokenData); */
          if (userTokenData) {
              //console.log("expandiendo contexto", userTokenData);
              const userProfileInfo = {
                  user: tokenUser,
                  profiles: new Array()
              };
              /* console.log("[expandirContexto] => dataUserIngresado: ", userProfileInfo); */
              userTokenData.payload.profiles.forEach((profile: any) => {
                  if (typeof profile === 'string') {
                      //Modelo viejo de perfil
                      userProfileInfo.profiles.push(profile);
                  } else if (typeof profile === 'object' && profile.name) {
                      //modelo nuevo de perfil
                      userProfileInfo.profiles.push(profile.name); 
                  }
              });
  
              if(!isOperador){
                  context.users_entered.push(userProfileInfo);
              }
              else{
                  context.users_entered = context.users_entered.splice(0, 0, userProfileInfo);
              }
  
              const profileResult = await this.updateOfferedProfile(userTokenData, context); // Usar 'await' para esperar el resultado
              context.offered_profiles = profileResult;
  
              //actualizando roles:
             //const contextUpdate = await updateRoles(context, userTokenData);
            //  actualizarRolesYContadores(context, )
              return context;
          } else {
              console.warn("Error al expandir el contexto:", userTokenData);
              return null;
          }
      } catch (error) {
          console.warn("Error al hacer la solicitud:", error);
          return null;
      }
  }
  
  /* public async updateRoles(context: ContextSass, userTokenData: any){ */
  public async updateRoles(context: ContextSass, user: PersonSSO){
      /* console.log("[updateRoles] => user ", user);
      console.log("[updateRoles] => context UPDATE ROLES ", context); */
      let domain = this.configService.getAppDomain();
      
      // Ajuste para extraer y usar nombres de perfil y dominios
      const allProfilesResponse = user.profiles?.map((profile: any) => {
          let profileParts = profile.split('@');
          if (profileParts.length === 2) {
              let profileName = profileParts[0];
              let profileDomain = profileParts[1].split('_')[0]; // Extrae la parte del dominio
              return { name: profileName, domain: profileDomain }; // Retorna objeto con nombre y dominio
          }
          return { name: profile, domain: "" }; // Para perfiles sin '@', retorna el nombre y dominio vacío
      });
      
      /* const responseData = await fetch(`https://osiris.hms-tech.com/api/profiles/domain/${userTokenData.domain}?apikey=kO2Ntii7I7d8SzxnYZGsckNmTUZAdZ1b`); */
      /* const responseData = await fetch(this.urlBaseProfileServ+"/domain/"+userTokenData.domain+"?apikey="+this.apikey); */
      const responseData = await fetch(this.urlBaseProfileServ+"/domain/"+domain+"?apikey="+this.apikey);
      const data = await responseData.json();
      
      // Arreglo para recoger los roles de los perfiles coincidentes
      let matchingRoles: any[] = [];
  
      // Itera sobre todos los perfiles del usuario
      allProfilesResponse?.forEach((userProfile: any) => {
          // Busca coincidencias en la data de la API
          data.forEach((apiProfile: any) => {
              if (apiProfile.name === userProfile.name) {
                  // Verifica si el dominio del perfil coincide con el dominio esperado antes de agregar roles
                  if (userProfile.domain.toUpperCase() === domain?.toUpperCase()) {
                      matchingRoles.push(...apiProfile.roles);
                  }
              }
          });
      });
  
      /* console.log("[updateRoles] => Roles de perfiles coincidentes:", matchingRoles); */
      matchingRoles.forEach(role => {
          if (context.offered_roles.hasOwnProperty(role)) {
              context.offered_roles[role] += 1;
          } else {
              context.offered_roles[role] = 1;
          }
      });
      /* console.log("[updateRoles] => Contexto actualizado con roles:", context); */
      return context; // Retorna el contexto actualizado
  }
   
  // Recibe los roles a cumplir y determina si el contexto satisface esos roles
  public hasRole(context: ContextSass, role: string){
      let offeredRoles = context.offered_roles;
      /* console.warn("[hasSomeRole] => Contexto recibido: ", context);
      console.warn("[hasSomeRole] => Roles ofrecidos de contexto recibido: ", offeredRoles);
      console.warn("[hasSomeRole] => Contador del rol solicitado: ", offeredRoles[role]); */
      if(!offeredRoles){
          console.warn("[hasRole] => el contexto recibido no ofrece ningun rol.");
          return false;
      }
      // NOTA: no se controla si existe el rol xq inicialmente el contexto deberia cargar todos los roles demandados por toda la funcionalidad del sitio
      return (offeredRoles[role] > 0);
  }
  
    // Recibe los roles a cumplir y determina si el contexto satisface esos roles
    public hasSomeRole(context: ContextSass, roles: string[]){
      for(let rol of roles){
          if(this.hasRole(context, rol)){
              return true;
          }
      }
      return false;
    }
  
    // ############################################# LO NUEVO #############################################
    
    // Convertir contexto
    public objectAsString(context: ContextSass) { 
      return JSON.stringify(context);
    }
  
    public stringAsObject(context: string){
      return JSON.parse(context);
    }
  
    private updateRequestedProfile(context: ContextSass, profiles: string[]) { // Asegúrate de pasar allowedRoles como argumento
      let allowedRoles = context.requested_roles;
      /* console.log("Actualizando perfil requerido según roles requeridos", allowedRoles);
      console.log("Perfiles del dominion: ", profiles); */
      let requestedProfiles: string[] = [];
  
      profiles.forEach((profile: any) => {
          // Verificar si el perfil tiene alguno de los roles permitidos
          const profileHasAllowedRole = profile.roles.some((role: any) => allowedRoles.includes(role));
          
          if (profileHasAllowedRole) {
              // Si el perfil tiene alguno de los roles permitidos, agregar al array
              // requestedProfiles.push(profile.name + "@" + profile.domain); // Aquí puedes agregar el environment como necesites
              requestedProfiles.push(profile.name); // Aquí puedes agregar el environment como necesites
          }
      });
  
      return requestedProfiles;
    }
  
    private updateOfferedProfile(profilesUser: any[], context: ContextSass) {
      let offeredProfilesResult:any = [];
      /* let allOfferedProfiles = userTokenData.payload.profiles.map(profile => {
          if(typeof profile === 'string'){
              //formato viejo
              return profile;
          } else if (typeof profile === 'object'){
              //Formato nuevo
              return profile.name;
          }
      }).flat(); */
      for(let profile of profilesUser){
          if(typeof profile === 'string'){
              //formato viejo
              offeredProfilesResult.push(profile);
          } else if (typeof profile === 'object'){
              //Formato nuevo
              offeredProfilesResult.push(profile.name);
          }
      }
      
      let allOfferedProfiles = profilesUser;
      let allRequestedProfiles = context.requested_profiles;
      // Ajustar los perfiles ofrecidos por el entorno
      let adjustedOfferedProfiles = allOfferedProfiles.map(profile => this.adjustProfileEnvironment(profile));
      // Inicializar el resultado de los perfiles ofrecidos
      // Contar cuántas veces cada perfil aparece
      adjustedOfferedProfiles.forEach(profile => {
          if (allRequestedProfiles.includes(profile)) {
              // Si el perfil ya está en el resultado, incrementarlo
              if (offeredProfilesResult.hasOwnProperty(profile)) {
                  offeredProfilesResult[profile] += 1;
                  /* console.log("Incrementando existente:", profile, "Total:", offeredProfilesResult[profile]); */
              } else {
                  // Si no, inicializarlo a 1
                  offeredProfilesResult[profile] = 1;
                  /* console.log("Agregando nuevo perfil:", profile); */
              }
          } else {
              /* console.log("Perfil no solicitado:", profile); */
          }
      });
  
      return offeredProfilesResult;
  }
  
  // private adjustProfileEnvironment(nameProfile: string) {
  //     //console.log("QUITANDO AMBIENTE AL PERFIL", profile);
  //      return nameProfile.replace(/(@[^_]+)_.*$/, "$1");
  // }
  private adjustProfileEnvironment(nameProfile: any) {
       return (typeof(nameProfile) == "string") ? nameProfile.replace(/(@[^_]+)_.*$/, "$1"): nameProfile.name.replace(/(@[^_]+)_.*$/, "$1");
  }
  
   private updateOfferedRoles(userProfiles: string[], profilesDomain: string[]) {
      // console.log("PERFIL 0.2", userProfiles); // Los perfiles del usuario
      let rolesCount: any = {}; // Objeto para almacenar los roles y cuántas veces cada uno aparece
  
      // Iterar sobre todos los perfiles de 'data'
      profilesDomain.forEach((profile: any) => {
          // console.log("PERFIL 0.4", profile.name); // Todos los perfiles disponibles
          // Verificar si el nombre del perfil actual está en los resultados del perfil del usuario
          if (userProfiles.hasOwnProperty(profile.name)) {
              // Si es así, contar cada uno de los roles de este perfil
              profile.roles.forEach((role: string) => {
                  if (rolesCount.hasOwnProperty(role)) {
                      rolesCount[role] += 1; // Incrementar el contador para este rol
                  } else {
                      rolesCount[role] = 1; // Inicializar el contador para este rol
                  }
              });
          }
      });
  
      // Retornar el objeto con los contadores de roles
      return rolesCount;
  }
  
  private getDataUserEntered(tokenUser: string, profilesUser: any){
      //console.log("expandiendo contexto", userTokenData);
      const userProfileInfo = {
          user: tokenUser,
          profiles: profilesUser
      };
      
       // Revisar cada perfil y procesar según el tipo de datos (string u objeto).
       profilesUser.forEach((profile: any) => {
          if (typeof profile === 'string') {
              // Formato antiguo, simplemente agregar el string.
              userProfileInfo.profiles.push(profile);
          } else if (typeof profile === 'object' && profile.name) {
              // Formato nuevo, extraer el nombre del perfil.
              userProfileInfo.profiles.push(profile.name);
          }
      });
  
   return userProfileInfo;
  }
  
  
  //VERIFICAR ACCESO A FUNCIONALIDAD
  // Verificar acceso a funcionalidad y devolver un objeto con el contexto actualizado y un booleano de autorización
  public verifyAccess(authorizedRoles: string[], context: ContextSass) {
      console.log("[verifyAccess] => roles autorizados: ", authorizedRoles); 
      /* console.log("context", context); */
  
      let hasAccess = false; // Indicador de si se tiene acceso o no
      let allRolesOffered = context.offered_roles;
  
      // Crea una copia del contexto para no modificar el original directamente
      let updatedContext = { ...context };
  
      // Verifica si algún rol autorizado está ofrecido en el contexto
      for (const rol of authorizedRoles) {
          if (allRolesOffered.hasOwnProperty(rol) && allRolesOffered[rol] > 0) {
              console.log("[verifyAccess] => Rol INCLUIDO ", rol);
              hasAccess = true; // Cambia el indicador a verdadero si el rol está presente
          } else {
              console.log("[verifyAccess] => Rol NO incluido ", rol);
          }
      }
  
      // Si se tiene acceso, actualiza la propiedad requested_roles con los roles autorizados
      if (hasAccess) {
          updatedContext.requested_roles = authorizedRoles;
      }
  
      // Devuelve un objeto con el contexto actualizado y el indicador de acceso
      return { context: updatedContext, hasAccess: hasAccess };
  }
  
  
  }
  
  