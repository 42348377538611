import { Injectable } from '@angular/core';
import { AdminContextSassService } from './manager/admin-context-sass.service';
/* import { AdminContextService } from './manager/admin-context.service'; */

@Injectable({
  providedIn: 'root'
})
export class MessageAppsService {

  readonly ID_IFRAME_AUX = "ifr-aux_";

  constructor(
    private adminContextService: AdminContextSassService
  ) {}

  // crea un ifram auxiliar para poder establecer comunicacion entre paginas
  private createIframeConnection(urlPageSrc: string){
    /* console.log("[Mesg-app.serv] => Creamos el iframe de la pag: ", urlPageSrc); */
    const iframe =  document.createElement('IFRAME');
    iframe.id = this.ID_IFRAME_AUX + urlPageSrc;
    iframe.style.display = "none";
    (<HTMLIFrameElement>iframe).src = urlPageSrc;
    document.body.appendChild(iframe);
  }

  // // se envia toda la info del localStorage de una pagina
  // public sendLocalStorageToPage(urlPageReceiver: string, data: string){
  //   let dataContext = this.adminContextService.getContext();
  //   console.log("[Mesg-app.serv] => Datos del contexto recuperados: ", dataContext);
  //   let idIframe = this.ID_IFRAME_AUX + urlPageReceiver;
  //   const iframe = document.getElementById(idIframe);
  //   if(iframe == null)
  //     return;
    
  //   const iWindow = (iframe as HTMLIFrameElement).contentWindow;

  //   var postMsg = {"localStorage": data, "type": "data"};
  //   /* console.log("[Mesg-app.serv] => Data enviada: ", postMsg); */

  //   // esperamos a q se cargue el iframe para enviar la info
  //   setTimeout(function (){
  //     iWindow?.postMessage(postMsg, urlPageReceiver)
  //   }, 650);

  //   // se envia el mje cuando se carga el iframe
  //   /* iWindow.onload = () =>  {
  //     iWindow?.postMessage(postMsg, urlPageReceiver)
  //   }; */
  // }

  public async reportLogin(urlPages: any[], localStorage: string){
    /* let pagesEnabledDomain = this.adminAppsService.getAppsAvailable(); */
    /* console.log("[Mesg-app.serv] => Entra a reportar el login(cant pageAvailable): ", urlPages.length); */
    for (let i = 0; i < urlPages.length; i++) {
      const dataPage = urlPages[i];
      console.log("[Mesg-app.serv] => Url page(Login): ", dataPage.url);
      let iframeConnection = this.getIframePage(dataPage.url);
      if(!iframeConnection){
        this.createIframeConnection(dataPage.url);
        await this.delay(300);
      }
      await this.delay(100);
      this.sendMsgLogin(dataPage.url, localStorage);
    }
  }

/* public async reportLogout(urlPages: any[], dataLocalStorage: string){ */
  public async reportLogout(urlPages: any[], dataPageLS: string, dataOperatorLS: string){
    /* let dataLS = ""+dataLocalStorage; */
    /* let pagesEnabledDomain = this.adminAppsService.getAppsAvailable(); */
    /* console.log("[Mesg-app.serv] => Entra a reportar el logout(cant pageEnabled): ", urlPages.length); */
    for (let i = 0; i < urlPages.length; i++) {
      const dataPage = urlPages[i];
      console.log("[Mesg-app.serv] => Url page(Logout): ", dataPage.url);
      let iframeConnection = this.getIframePage(dataPage.url);
      if(!iframeConnection){
        this.createIframeConnection(dataPage.url);
        await this.delay(300);
      }
      await this.delay(100);
      /* this.sendMsgLogout(dataPage.url, dataLS); */
      this.sendMsgLogout(dataPage.url, ""+dataPageLS, ""+dataOperatorLS);
    }
  }

  // Informa que la pagina esta disponible
  // public reportAvailability(urlReceive: string){
  //   let idIframe = this.ID_IFRAME_AUX + urlReceive;
  //   const iframe = document.getElementById(idIframe);
  //   console.log("[Mesg-app.serv] => (reportAvailability): iframe encontrado: ", iframe);
  //   if(iframe == null){
  //     console.warn("[Mesg-app.serv] => (reportAvailability): No existe conexion con la pagina: ", urlReceive);
  //     return;
  //   }
  //   const iWindow = (iframe as HTMLIFrameElement).contentWindow;

  //   console.log("[Mesg-app.serv] => Url page destino: ", urlReceive);
  //   var postMsg = {"available": true, "type": "control"};

  //   setTimeout(function (){
  //     iWindow?.postMessage(postMsg, urlReceive)
  //   }, 50);
  // }
  public reportAvailability(urlReceive: string){
    let iframeConnection = this.getIframePage(urlReceive);
      if(!iframeConnection)
        this.createIframeConnection(urlReceive);

    let idIframe = this.ID_IFRAME_AUX + urlReceive;
    const iframe = document.getElementById(idIframe);
    console.log("[Mesg-app.serv] => (reportAvailability): iframe encontrado: ", iframe);
    if(iframe == null){
      console.warn("[Mesg-app.serv] => (reportAvailability): No existe conexion con la pagina: ", urlReceive);
      return;
    }
    const iWindow = (iframe as HTMLIFrameElement).contentWindow;

    console.log("[Mesg-app.serv] => (reportAvailability)Url page destino: ", urlReceive);
    var postMsg = {"available": true, "type": "control"};

    setTimeout(function (){
      iWindow?.postMessage(postMsg, urlReceive)
    }, 50);
  }

  // ########################################################################################
  // ############################ METODOS PRIVADOS ##########################################

  // funciona auxiliar para esperar
  private delay(ms: any){
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  private getIframePage(url: string){
    let idIframe = this.ID_IFRAME_AUX + url;
    const iframe = document.getElementById(idIframe);
    console.log("[Mesg-app.serv] => (getIframePage) Const iframe: ", iframe);
    return iframe;
  }

  // crea y devuelve un iframe para entablecer comunicacion con una pagina
  // private createIframe(url: string){
  //   const iframe =  document.createElement('IFRAME');
  //   /* let iframe =  document.createElement('IFRAME'); */
  //   iframe.id = this.ID_IFRAME_AUX + url;
  //   console.log("[Mesg-app.serv] => Iframe creado: ", iframe.id);
    
  //   iframe.style.display = "none";
  //   (<HTMLIFrameElement>iframe).src = url;
  //   return iframe;
  // }

  /* private sendMsgLogout(urlReceive: string, data: string){ */
  private sendMsgLogout(urlReceive: string, dataPage: string, dataOperator: string){
    let idIframe = this.ID_IFRAME_AUX + urlReceive;
    const iframe = document.getElementById(idIframe);
    if(iframe == null){
      console.warn("[Mesg-app.serv] => (MsgLogout): No existe conexion con la pagina: ", urlReceive);
      return;
    }
    
    const iWindow = (iframe as HTMLIFrameElement).contentWindow;
    /* console.log("[Mesg-app.serv] => Url page destino: ", urlReceive); */
    /* var postMsg = {"logout": true, "type": "session-end", "pages": data}; */
    var postMsg = {"type": "session-end", "pages": dataPage, "operator": dataOperator};

    setTimeout(function (){
      iWindow?.postMessage(postMsg, urlReceive)
    }, 250);
  }
  

  private sendMsgLogin(urlReceive: string, localStorage: string){
    let idIframe = this.ID_IFRAME_AUX + urlReceive;
    const iframe = document.getElementById(idIframe);
    /* let iframe = document.getElementById(idIframe); */
    if(iframe == null){
      console.warn("[Mesg-app.serv] => (MsgLogin): No existe conexion con la pagina: ", urlReceive);
      /* this.createIframeConnection(urlReceive); */
      return;
    }
    console.log("[Mesg-app.serv] => iframe page destino encontrado: ", iframe);
    const iWindow = (iframe as HTMLIFrameElement).contentWindow;

    /* console.log("[Mesg-app.serv] => Url page destino: ", urlReceive); */
    var postMsg = {"login": true, "type": "session-init", "localStorage": localStorage};

    setTimeout(function (){
      iWindow?.postMessage(postMsg, urlReceive)
    }, 250);
  }

}