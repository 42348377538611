import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-autosearcher',
  templateUrl: './select-autosearcher.component.html',
  styleUrls: ['./select-autosearcher.component.scss']
})
export class SelectAutosearcherComponent implements OnInit {

  // debemos tener una referencia de cada subscripcion para q luego se puede desubscribir sin error
  private subscriptionReset: Subscription = new Subscription;
  private subscriptionSet: Subscription = new Subscription;

  @Input() id?: string;
  @Input() value?: string;
  @Input() placeholder?: string;
  @Input() options?: any[];
  @Input() atrValue?: string;     // atributo del objeto a mostrar como dato y por el cual se busca
  @Input() required?: boolean = false;
  @Input() enable?: boolean = true;
  @Input() size?: string;         // small/large    con esto cambio el tamaño de la lista
  // eventos emitidos de afuera
  @Input() set: EventEmitter<any> |undefined;
  @Input() reset: EventEmitter<any>|undefined;

  @Output() notifyChangeValue = new EventEmitter<any>();

  itemSelected?: any;    // el valor seleccionado
  valueInput?: string;
  valueInputAux?: string;   // poner como auxiliar del dato ingresado para filtrar y usarlo en onFocus para actualizar la lista de opciones
  optionCodeSelected?: string;

  /* viewOptions: boolean = false; */
  viewListOptions: boolean = false;

  items?: any[];

  constructor() { }

  ngOnInit(): void {
    if(!this.options){
      console.warn("[Select-autosearch.comp] => No se recibio la lista de opciones.");
      return;
    }
    this.items = this.options;

    // aca escuchamos la info que nos pueden enviar de afuera
    if(typeof this.reset != "undefined"){
      this.subscriptionReset = this.reset.subscribe((data: any) => {
        /* console.log("[Select-autosearch.comp] => Evento reset input: ", data); */
        this.valueInput = "";
        this.viewListOptions = false;
        this.items = [];      // para no dejar desplegadas las opciones, si es que se encontraban abiertas
       })
    }
    if(typeof this.set != "undefined"){
      this.subscriptionReset = this.set.subscribe((data: any) => {
        console.log("[Select-autosearch.comp] => id(",this.id,") Evento set input: ", data);
        // if(data.id == this.id){
        //   /* console.log("[Select-autosearch.comp] => SON IGUALES "); */
        //   this.setValue(data.value);
        // }
        // else{
        //   console.log("[Select-autosearch.comp] => NO son iguales ");
        // }
        this.setValue(data);
      })
    }
    if(this.value){
      this.valueInput = this.value;
    }
  }

  public search(data: string){
    this.viewListOptions = true;
    /* console.log("[Select-autosearch.comp] => Input modificado: ", data, " - opciones: ", this.options); */
    let value = data.toUpperCase();
    if(!this.atrValue){
      this.items = this.options?.filter((option: any) => ((option.value).toUpperCase()).includes(value));
    }
    else{
      this.items = this.options?.filter((option: any) => ((option[this.atrValue as string]).toUpperCase()).includes(value));
    }


    /* console.log("[Select-autosearch.comp] => Opciones recuperadas: ", this.items); */
  }

  public setValue(dataItem: any){
    // lo ultimo agregado
    if(!dataItem){
      this.itemSelected = undefined;
      this.valueInput = "";
      return;
    }
    /* console.log("[Select-autosearch.comp] => Valor seleccionado: ", dataItem); */
    this.viewListOptions = false;
    if(this.itemSelected == dataItem)
      return;
    this.itemSelected = dataItem;
    /* console.log("[Select-autosearch.comp] => setValue: se setea input"); */
    if(!this.atrValue)
      this.valueInput = this.itemSelected.value;
    else
      this.valueInput = this.itemSelected[this.atrValue];

    this.items = this.options;
    this.notifyChangeValue.emit(this.itemSelected);
  }

  public viewAllOptions(){
    if(!this.options || (this.options.length == 0))
      return;

    this.viewListOptions = !this.viewListOptions;
    this.items = this.options;

    /* console.log("[Select-autosearch.comp] => item selected: ", this.itemSelected); */
    if(!this.atrValue){
      this.itemSelected ? this.valueInput = this.itemSelected.value : this.valueInput = "";
    }
    else{
      this.itemSelected ? this.valueInput = this.itemSelected[this.atrValue] : this.valueInput = "";
    }
    /* console.log("[Select-autosearch.comp] => Opciones: ", this.items); */
  }

  public setOption(data: any){
    /* console.log("[Select-autosearch.comp] => Toma la tecla: ", data); */
    if(data.key == "Enter"){
      /* console.log("[Select-autosearch.comp] => Presiona ENTER. Dato ingresado: ", this.valueInput);
      console.log("[Select-autosearch.comp] => Opciones: ", this.options); */
      let optionFound = null;
      if(!this.atrValue){
        optionFound = this.options?.find((option: any) => option.value == this.valueInput?.toUpperCase());
      }
      else{
        optionFound = this.options?.find((option: any) => option[this.atrValue!] == this.valueInput?.toUpperCase());
      }

      /* console.log("[Select-autosearch.comp] => Opcion encontrada: ", optionFound); */
      if(optionFound){
        this.setValue(optionFound);
      }
      /* else{
        console.log("[Select-autosearch.comp] => Item seleccionado: ", this.itemSelected);
        if(this.itemSelected){
          this.valueInput = this.itemSelected.city_ascii;
        }
      } */
      /* this.notifyPressEnter.emit(true); */
    }
    // if(data.key == "ArrowDown"){
    //   this.active = document.querySelector(".hover") || document.querySelector(".ul-custom li");
    //   /* console.log("[Select-autosearch.comp] => Opcion elegida: ", this.active); */
    //   this.active = this.active.nextElementSibling || this.active;
    // }
  }

  public onFocus() {
    this.search("");
    this.viewListOptions = true;
  }

  public onBlur(){
  }
  
  ngOnDestroy(): void {
    /* if(this.reset)
      this.reset.unsubscribe(); */
      if(this.reset)
        this.subscriptionReset.unsubscribe();
  }

}
