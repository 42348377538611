import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-feature',
  templateUrl: './enterprice-sass-feature.component.html',
  styleUrls: ['./enterprice-sass-feature.component.scss']
})
export class EnterpriceSassFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toggleCollapse(data: any) {
    data.isCollapsed = !data.isCollapsed;
  }

  features= [
    {
      title:'Node.js',
      subTitle: '& Loopback 4',
      description: 'HMS early adopted Node.js, the open-source, high-performance JavaScript execution environment used for developing server applications, becoming a benchmark in the field. having opted for the Loopback 4 framework based on Typescript and originally created by IBM for the development of RESTful APIs. In this context, HMS has implemented high-performance and scalable APIS to provide critical services in real time.',
      img: 'assets/images/hms-tech/icon/technologies/angular-128px.png',
      isCollapsed: true
    },
    {
      title:'Java',
      subTitle: '& Quarkus',
      description: 'HMS has implemented numerous services using Quarkus, a modern framework for the development of cloud services that supports Kubernates and Red Hat OpenShift and is complemented by a broad ecosystem of extensions. Quarkus offers fast startup times, low resource consumption, support for microservices and reactive architectures, and a wide range of integrations and extensions that increase development productivity.',
      img: 'assets/images/hms-tech/icon/technologies/javaScrip-128px.png',
      isCollapsed: true
    },
    {
      title:'Angular',
      subTitle: '& Ionic',
      description: 'HMS Tech has vast experience in the development of Web Apps and Mobile Apps based on the Angular framework maintained by Google, based on Typescript, it is characterized by an architecture of reusable components that encapsulate logic, styles and behaviors. Among its notable features are Two-Way Data Binding, Dependency Injection, Routing and Navigation, Directives and extensive facilities for testing and delivery. Together with Ionic, a framework for the development of hybrid applications, it makes it possible to recreate a user experience equivalent to native applications on mobile devices based on common development for Android & IOS.',
      img: 'assets/images/hms-tech/icon/technologies/java-128px.png',
      isCollapsed: true
    },
    {
      title:'Oracle',
      subTitle: 'Technologies',
      description: 'With more than two decades of experience in developing applications on Oracle technologies, HMS has mastery in managing Oracle infrastructure in the cloud, administration and programming of Databases, PL/SQL, and the development of applications based on its Oracle APEX (Oracle Application Express) framework, implementing interoperable architectures between Oracle technology and Open Source solutions through its security infrastructure (HMS Security Infrastructure) and its service architecture code that makes your landing page look good on all devices (desktops,tablets, and phones). Created with mobile specialists.',
      img: 'assets/images/hms-tech/icon/technologies/javaScrip-128px.png',
      isCollapsed: true
    },
    
  ]
}
