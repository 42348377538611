import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-check-generic',
  templateUrl: './check-generic.component.html',
  styleUrls: ['./check-generic.component.scss']
})
export class CheckGenericComponent implements OnInit, AfterViewInit {

  tagHtml?: any;

  @Input() id?: string;
  @Input() text?: string;
  @Input() colorText?: string;

  // eventos emitidos de afuera
  @Input() setValue: EventEmitter<any>;

  /* evento para notificar el cambio del valor */
  @Output() notifyChange = new EventEmitter<boolean>();

  constructor() { }

  ngAfterViewInit(): void {
    this.tagHtml = document.getElementById(this.id as string);
    /* console.log("[Check-generic.comp] => Tag checkbox: ", this.tagHtml); */
  }

  ngOnInit(): void {
    // aca escuchamos la info que nos pueden enviar de afuera
    if(typeof this.setValue != "undefined"){
      this.setValue.subscribe((data: any) => {
        /* console.log("[Check-generic.comp] => Set check: ", data); */
        if(data.id == this.id){
          this.tagHtml.checked = data.checked;
          // me aseguro de notificar q se tildo el check
          this.notifyChange.emit(data.checked);
        }
       })
    }
  }

  public select(data: any){
    /* console.log("[Check-generic.comp] => Check seleccionado: ", data.target.checked); */
    this.notifyChange.emit(data.target.checked);
  }

}
