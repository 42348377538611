export enum UrlCore {
    LOGIN = "init/auth/login",
    LOGOUT = "init/auth/logout",
    REGISTER = "init/auth/register",
    CHANGE_SESION = "init/auth/change",
    ABOUT = "about",
    HOME = "/home",
    USER_SELFIE = "init/user/selfie",
    USER_PASS = "init/user/recovery-pass",
    USER_EDIT_INFO = "init/user/edit-info",
    USER_EDIT = "init/user/edit"
  }