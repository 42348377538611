import { Injectable } from '@angular/core';
import SHA3 from 'sha3';
import * as NodeRSA from 'node-rsa';
import { ConfigService } from '../config.service';
import { DomainService } from '../domain.service';
import { PersonSSO } from '../../model/SSO/person/person';
import { Person } from '../../model/person/person.model';

@Injectable({
  providedIn: 'root'
})
export class EncryptatorService {

  private public_key?: string;
  private private_key?: string;

  constructor(
    private configService: ConfigService,
    private domainService: DomainService
  ) {
    // // esperamos a obtener la baseUrl del domain_sv
    // this.configService.hostServer$.subscribe((value) => {
    //   /* console.log("Cambia la data del domain: ", value); */
    //   if(value){
    //     /* this.urlBaseSrv = this.configService.getUrlServerByName(this.NAME_SERVER); */
    //     /* console.log("Url SRV_AUTH: "+this.urlBaseSrv); */
    //     this.domainService.getKeysEncrypted("hms").subscribe(
    //       (data: any) => {
    //         /* console.log("Keys: ", data); */
    //         this.public_key = "-----BEGIN PUBLIC KEY-----" +data.keys.public+ "-----END PUBLIC KEY-----";
    //         this.private_key = "-----BEGIN PRIVATE KEY-----" +data.keys.private+ "-----END PRIVATE KEY-----";
    //       },
    //       (err: any) => {
    //         console.warn("No se lograron recuperar las keys de encriptacion: ", value);
    //       })
    //   }
    // });

    this.public_key = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwFt0gwidIq1VL0f4KOMZghS0Nq6a4leajz7AQjNK/J3ILdf0k840afihFami0qXQ90W3qZ3qnxbHwb6ugXSMT4iMxMjHTwmBMGteK46Hr/4J3CR6npSV8sFNHr5Cbc3s95SVBNJB0O4uv6Nrl4nMJ5gjX6DlO0bojAvqB6TZ4l40SZiSLJ+ZPEGlyqzf/5utMZIBwxgBSd/5GgtfwlSnBA49zZeLoetktNVWYKAczFm3ThYZZVBUg5iyYJRjh+VfzkOPt50bF2TxXGv73eoHJ9nLPCtRxef1h4x5aJkWgfisrvvtOyigVDVrgaDgADdr0XAJAsEbTcHDxURvO9v1RwIDAQAB-----END PUBLIC KEY-----";
    this.private_key = "-----BEGIN PRIVATE KEY-----MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDAW3SDCJ0irVUvR/go4xmCFLQ2rpriV5qPPsBCM0r8ncgt1/STzjRp+KEVqaLSpdD3RbepneqfFsfBvq6BdIxPiIzEyMdPCYEwa14rjoev/gncJHqelJXywU0evkJtzez3lJUE0kHQ7i6/o2uXicwnmCNfoOU7RuiMC+oHpNniXjRJmJIsn5k8QaXKrN//m60xkgHDGAFJ3/kaC1/CVKcEDj3Nl4uh62S01VZgoBzMWbdOFhllUFSDmLJglGOH5V/OQ4+3nRsXZPFca/vd6gcn2cs8K1HF5/WHjHlomRaB+Kyu++07KKBUNWuBoOAAN2vRcAkCwRtNwcPFRG872/VHAgMBAAECggEAJtuSutGnpJStS6LXP7M4uO1jxlQZV7XotB0yC21/LW7WPGSOtLkIH4wpRk4BZVo3z2vXAT20YrTgX4Qcjmwz1/E2tp30nUPHRDe8S0I8qNHBviXkW1qau/JOmPJISxQ5VX4/aDztLL6g1Pe3SWJ9ylXSd4PyKrpVSc0yVp73oOGbJc0OfALMI1+CLgaw4l1NNjoyEZnscZaBX18ONQQdiddN4IW5bIlursXb13K28st/paJw9jFiq2odH0I4ZMbpHy+Ej95Mp+QgMeishM8gaX2A5M6EOYJzuYVimN+laBd0v1suCcpCu2r+y2GKWb/z4fPbKoVs2ObCeKaG32kVKQKBgQDnC8/kGePz95uCB/hq/x4u/iFp2I7YhbX0F5AZi9h4+4uIOquPNlvDsku39qrMQizVyOwwG3tqmunM2lzJd7DfaK79XxVl0TMuYXyEfGik7PHX6VAbM9oUZAEWP26sLYhRAh5K3yxooQDVI0zHc48np9Qg9d916zBAGIYHr4xPYwKBgQDVIe9ZGx6lq8J6DOcaFESmpLAIIa87iExhwFxX8XwwPQ0+XagYdBdjcAMZn0JTUHwnPwIQ4L+vPrKPaQgFSTRAi3fXkGqJvkytQ6nnT/RWNcOb9DbKq66Gbi60KF5AfXR16z9prwfx5n3tgx62UBaqzdxkdL9Q6mDgBPpHzFsBzQKBgQCtEu8fOrnEtrXZ+SiA1i0BHMNg66VN0xPTDpeWW2jEcAI9AV4kgJ7LANOxseiN7AB0APfUrbGM0RDUsLqS/MMVB/EVwdVgmQ6S7tn80Mj+yrP7fj3Qss3pOmSKCuhXuCAGNuUERxuYIQtjnfbU7d7N4O2J50UGqzBQw04bkcg0awKBgAKBDZDccrW7mONaN3Tk/BGBKdhOnQJ0DYGwguU9nxnp/bfX3du7sXKnpEGuz1avQWYspVeRbG8gq3Rn5YI6Y/TGLrhHqBjKNZhrjRl8pQBGTXANpH8pGTnXHZFcUA8t6ipggzQ4rD05Wg1qVvgJZNrI5GgBvyWyO0UmidJCImeZAoGBAI8Y1zFRkKcZ0THYNgIbh0WANJvxoF0ATaMYyamTbiQdBTfSWGdtpzPCLRgkh7B9c6FIBMKwZ/T+cQrhAyFeizKGy+XLhoQz1Z9tNRpU4YObtTrLhZ7fdSXVRyBPrhlMXnbfYmpGaW3dI2/6qfDe4g+PIGyIAVKaqD0BnyatNS1o-----END PRIVATE KEY-----";
  }

  public hashingSha3_256(value: string){
    const hash = new SHA3(256);
    hash.update(value);
    let newHash = hash.digest('hex');
    /* console.log("[encrypt.serv] => Hash(",value,"): ", newHash); */
    return newHash;
  }

  public hashingSha3_512(value: string){
    const hash = new SHA3(512);
    hash.update(value);
    let newHash = hash.digest('hex');
    /* console.log("[encrypt.serv] => Hash(",value,"): ", newHash); */
    return newHash;
  }


  public encrypt_private_RSA(dataPlain: string){
    /* console.log("Valor a encriptar(private): ", dataPlain); */
    try {
      let key_private = new NodeRSA();
      key_private.importKey(this.private_key as string, 'pkcs8-private-pem');
      let encrypted = key_private.encryptPrivate(dataPlain, 'base64')
      return encrypted
    }
    catch (error) {
      /* console.log("error: " + error); */
      return null;
    }
  }

  public decrypt_private_RSA(key: string, data: string) {
    try {
      /* console.log("Decrypt RSA Private Key"); */
      let key_private = new NodeRSA();
      key_private.importKey(this.private_key as string, 'pkcs8-private-pem');
      let decrypted = key_private.decrypt(data, 'utf8');
      return decrypted;
    }
    catch (error) {
      /* console.log("error: " + error); */
      return null;
    }
  }

  public encrypt_public_RSA(dataPlain: string){
    /* console.log("Valor a encriptar(public): ", dataPlain); */
    try {
      let key_public = new NodeRSA();
      key_public.importKey(this.public_key as string, 'pkcs8-public-pem');
      let encrypted = key_public.encrypt(dataPlain, 'base64')
      return encrypted
    }
    catch (error) {
      /* console.log("error: " + error); */
      return null;
    }
  }

  public decrypt_public_RSA(key: string, data: string) {
    try {
      let key_public = new NodeRSA();
      key_public.importKey(this.public_key as string, 'pkcs8-public-pem');
      let decrypted = key_public.decryptPublic(data, 'utf8');
      return decrypted;
    }
    catch (error) {
      /* console.log("error: " + error); */
      return null;
    }
  }

  // devuelve el modelo de Person con los datos desencriptados
  // NOTA: dejamos esto comentado dado q el servicio de login aun responde con el modelo viejo de persona
  public getIdPersonDecrypt(person?: PersonSSO){
    if(!person){
      console.log("[Login.comp] => No se recibieron datos de un operador.");
      return;  
    }
    let personDecrypt: any = Object.assign({}, person);    // clonamos el objeto
    /* console.log("[Login.comp] => Persona clonada: ", personDecrypt); */
    // recuperamos los valores de los identificadores desencriptados
    if(person.document_ids){     // copiamos los documentos
      for (let i = 0; i < person.document_ids.length; i++) {
        let docDecrypt = this.decrypt_public_RSA("", person.document_ids[i].value as string);
        /* console.log("[Login.comp] => Doc desencriptado: ", docDecrypt); */
        personDecrypt.document_ids[i].value = docDecrypt;
      }
    }
    if(person.emails){     // copiamos los emails
      for (let i = 0; i < person.emails.length; i++) {
        personDecrypt.emails[i].value = this.decrypt_public_RSA("", person.emails[i].value as string);
      }
    }
    if(person.phones){     // copiamos los telefonos
      for (let i = 0; i < person.phones.length; i++) {
        personDecrypt.phones[i].value = this.decrypt_public_RSA("", person.phones[i].value as string);
      }
    }
    delete personDecrypt.password;
    return personDecrypt;
  }
  
}
