<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-1.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Vicky Smith</h5>
                        <h6 class="post text-center ">UI/UX Designer - Little Big</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-2.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-3.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Mark Tucker</h5>
                        <h6 class="post text-center ">App Developer - Jumpster</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-4.png" alt="" class="img-fluid">

                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-1.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Vicky Smith</h5>
                        <h6 class="post text-center ">UI/UX Designer - Little Big</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-2.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-3.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Mark Tucker</h5>
                        <h6 class="post text-center ">App Developer - Jumpster</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-4.png" alt="" class="img-fluid">

                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
