import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Menu } from '../model/menu/menu';
import { ConfigService } from './config.service';
import { Microservices } from '../assets/mocks/microservices';


@Injectable({
  providedIn: 'root'
})
export class MenuOptionsService {

  private urlBaseSrv?: string;
  private apikey?: string;

  menuOption?: Menu|null;
  observable = new BehaviorSubject<Menu|null>(null);

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
    // esperamos a obtener la baseUrl del domain_sv
    this.configService.targetServices$.subscribe((value: any) => {      
      if(value){
        /* let dataMicroservices: any = value[Microservices.MENU_SV.namev2]; */
        let baseUrl: any = this.configService.getBaseUrlMicroservice(Microservices.MENU_SV.name);
        if(!baseUrl){
          console.warn("[Menu.serv] => No se encontraron los datos de base del menu_sv.");
          return;
        }
        this.urlBaseSrv = baseUrl;
        this.apikey = this.configService.getApiKey() as string;
        /* console.log("Url SRV_MENU: "+this.urlBaseSrv); */
      }
    });
  }

  public getMenu(){
    this.findMenuApp().subscribe(
      value => {
        /* console.log("[Menu.serv] => resp getMenu: ", value); */
        // en el caso de encontrar el menu en el server
        if(value["error"])
          this.menuOption = null;
        else
          this.menuOption = value;
        this.observable.next(this.menuOption);
      },
      error => {
        console.warn("[Menu.serv] => ERROR: no se pudo cargar el menu. ", error);
      }
    )
  }

  
  // recupera el menu de la aplicacion
  private findMenuApp(){
    /* let urlRequest = `${this.urlBaseSrv}/domain/${this.configService.getAppDomain()}/app/${this.configService.getApp()}/version/${this.configService.getAppVersion()}?apikey=${this.configService.getApiKey()}`; */
    let urlRequest = `${this.urlBaseSrv}/domain/${this.configService.getAppDomain()}/app/${this.configService.getApp()}/version/${this.configService.getAppVersion()}?apikey=${this.apikey}`;
    return this.httpClient.get(urlRequest);
  }

}

