export const LOGIN_BY_TOKEN_ENABLED = true;
// funcionalidades de LS
export const AFFILIATE_IN_LOCAL_ENABLED = true;
export const TIME_SESSION_ENABLED = true;

// parametros para llamadas de servicios
export const SESSION_TOKEN_EXPIRATION = "PT3M";         // tiempo de expiracion del token de sesion obtenido en login OK

// config de tokens
export enum ConfigToken {
    NUMBER_USSAGE_LOGIN_DEFAULT = "10",
    TYPE_LOGIN_DEFAULT = "CTD",
    DURATION_LOGIN_DEFAULT = "PT10M",
    LENGTH_LOGIN_DEFAULT = "5"
}

// VIEW
export const THEME_LIGHT = "light";
export const THEME_DARK = "dark";

// Keys local storage
export const KEY_REMEMBER_ID = "remember_id";
export const KEY_REMEMBER_PASS = "remember_pass";
export const KEY_REMEMBER_LOGIN = "remember_login";
export const KEY_TYPE_IDENTIFIER = "type_identifier";
export const KEY_PASS = "password";
export const KEY_IDENTIFIER = "identifier";
export const KEY_VALID_INPUT = "valid_input";           // se agrega para reconfirmar la validacion de los inputs guardados
export const VALID_INIT_LOGIN = "initial_login";