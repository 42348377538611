import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Identifier } from '../model/person/identifier.model';
import { Message } from '../model/message';
import { ConfigService} from './config.service';
import { Identifiers } from '../model/person/identifiers';
import { LocalStorageService } from './local-storage.service';
/* import { MessageAppsService } from './message-apps.service'; */
import { Profiles } from '../assets/mocks/profiles';
import { AdminProfileService } from './manager/admin-profile.service';
import { DomainService } from './domain.service';
import { EncryptatorService } from './utils/encryptator.service';
import { AdminContextSassService } from './manager/admin-context-sass.service';
import { PersonSSO } from '../model/SSO/person/person';
import { Microservices } from '../assets/mocks/microservices';
import { PeopleService } from './people.service';
import { SESSION_TOKEN_EXPIRATION } from '../assets/config/config-constant.example';
import { MessageAppsService } from './message-apps.service';
import { AdminAppService } from './manager/admin-app.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationV2Service {

  private urlBaseSrv?: string;
  private apikey?: string;

  authenticationState$ = new BehaviorSubject(false);
  
  usersLogged: PersonSSO[] = [];
  userLoggedList$: Subject<PersonSSO[]> = new Subject<PersonSSO[]>();

  operatorUser: PersonSSO | null = null;
  // el observable del operador
  operatorCurrent$ = new BehaviorSubject<PersonSSO|null>(null);

  // se declara todo aca dado que ngOnInit son para componentes y directivas
  constructor(
    private httpClient: HttpClient,
    private configService:ConfigService,
    private messageAppsService: MessageAppsService,
    private adminAppsSerice: AdminAppService,
    private localStorageService: LocalStorageService,
    private adminProfileService: AdminProfileService,
    private adminContextSassService: AdminContextSassService,
    private encriptService: EncryptatorService) {

      // esperamos a obtener la baseUrl del domain_sv
      this.configService.targetServices$.subscribe((value: any) => {      
        if(value){
          /* let dataMicroservices: any = value[Microservices.AUTH2_SV.namev2]; */
          let baseUrl: any = this.configService.getBaseUrlMicroservice(Microservices.AUTH2_SV.name);
          if(!baseUrl){
            console.warn("[Auth2.serv] => No se encontraron los datos de base del auth2_sv.");
            return;
          }
          this.urlBaseSrv = baseUrl;
          this.apikey = this.configService.getApiKey() as string;
          /* console.log("[Auth2.serv] => Url SRV_AUTH2: "+this.urlBaseSrv); */
        }
      });
   }

  async init() {
    this.operatorCurrent$.subscribe((value => {
      this.operatorUser = value;
    }));
  }

  /* Agregamos un usuario a la lista de usuarios logeados */
  public reportAddUser(user: PersonSSO){
    let isLogged = this.usersLogged.find(userLogged => userLogged.equals(user));
    // si la persona no esta registrada como operador
    if(!isLogged){
      this.usersLogged.push(user);
      this.userLoggedList$.next(this.usersLogged);
    }
  }

  /* Eliminamos un usuario de la lista de usuarios logeados */
  public reportDeleteUser(userDelete: PersonSSO){
    this.usersLogged = this.usersLogged.filter(user => user.first_name != userDelete.first_name);
    this.userLoggedList$.next(this.usersLogged);
  }

  public registerUsersListActive(dataUser:any, identifier: Identifier){
    delete dataUser["id"];
    let person = new PersonSSO(dataUser);
    this.reportAddUser(person);
    this.operatorUser = person;
    /* console.log("[registerUsersListActive] => Datos del operador: ", this.operatorUser); */
    this.operatorCurrent$.next(this.operatorUser);
  }

  // TODO: probar esto!!!!!!!!!
  public authenticateByIdentifierHashEncrypt(identifier: Identifier, password: string){
    let env = this.configService.getTypeEnvironment()?.toUpperCase();
    /* console.log("[AuthV2.serv] => Datos para login:: id:  ",identifier, " - pass: ", password); */
    let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()?.toUpperCase()+"/environment/"+env+"/authenticate/identifier?apikey="+this.apikey;
    let bodyAuthV2 = this.getBody(identifier, password, true, true, SESSION_TOKEN_EXPIRATION);
    /* console.log("[AuthV2.serv] => Body SSO: ", bodyAuthV2); */
    return this.httpClient.post(url, bodyAuthV2);
  }

  /* Crear un nuevo usuario */
  public createPersonSSO(person: PersonSSO, identifiers: Identifier[], password: string){
    const request: Message = {
      type: "person_registration",
      payload: {
        identifiers: identifiers,
        person: person,
        password: password,
        saludSoftPassword: password
      }
    }

    let url = this.urlBaseSrv+`/registrate/identifiers?apikey=${this.apikey}`;
    /* console.log("Url generado createPersonSSO: ", url); */

    return this.httpClient.post(url, request);
  }

  // Servicio que permite restaurar password de un afiliado en particular
  public deleteIdentifier(identifier: Identifier) {
    let url = this.urlBaseSrv+`/authenticate/identifier/delete?apikey=${this.apikey}`;
    /* console.log("Url authServ: "+url); */
    let bodyRequest = this.getBodyResetPassById(identifier);
    /* console.log("MESSAJE " + JSON.stringify(request)); */

    return this.httpClient.post(url, bodyRequest);
  }

  // actualiza la selfie de un usuario de la lista de usuarios logeados
  public updateSelfieUserListLogged(user: PersonSSO, selfie: string){
    var userFounded = this.usersLogged.find(u => u.uuid == user.uuid);
    if (userFounded){
      userFounded.selfie = selfie;
      /* console.log("Se encontro al usuario logeado!!"); */
    }
    else{
      console.warn("No se encontro el usuario logeado.");
    }
  }

  /* Devuelve true si el usuario ya tiene sesion activa, false en caso contrario */
  public isLoggedUser(identifier: Identifier){
    let userFound = null;
    /* console.log("[Auth.ser] => Identifier userLogged: " , identifier); */
    let typeIdentifier = identifier.type;

    switch (typeIdentifier) {
      case Identifiers.CIVIL_ID:
        userFound = this.findUserByCivilId(identifier.value.value);
        break;
      case Identifiers.EMAIL:
        userFound = this.findUserByEmail(identifier.value.value);
        break;
      case Identifiers.PHONE:
        
        break;
    }

    return userFound;
  }

  public setAuth(dataPersonSSO: any, identifier: Identifier){
    this.registerUsersListActive(dataPersonSSO, identifier);
  }

  public logout() {
    /* console.log("[Auth.ser] => (logout): users ", this.usersLogged); */
    var index:any = null;
    // Elimino el usuario operador de la lista de loggeados
    for(let i=0; i < this.usersLogged.length ; i++){
      let user = this.usersLogged[i];
      if(user.first_name == this.operatorUser?.first_name){
        index = i;
      }
    }
    if(index == null){
      console.warn("[Auth.ser] => Ha ocurrido un problema al encontrar el usuario. Host: ", document.location.origin);
      return;
    }
    this.localStorageService.setTimeLastestActivity(null);
    // analizamos si debemos de mandar un mje a otras paginas abiertas desde esta pagina
    this.localStorageService.setIsLogoutPage(document.location.origin, true);
    let tokenOperator = ""+this.localStorageService.getTokenOperator();
    if(this.adminAppsSerice.getAppsAvailable()){
      let appsAvailable = this.adminAppsSerice.getAppsAvailable();
      appsAvailable = appsAvailable.filter(app => !this.localStorageService.isReportLogout(app.url))
      /* console.warn("[Auth.ser] => Apps disponibles q no reportaron el logout: ", appsAvailable); */
      this.messageAppsService.reportLogout(appsAvailable, JSON.stringify(this.localStorageService.getDataPages()), tokenOperator);
    }
    this.reportDeleteUser(this.usersLogged[index]);
    // Cambio el estado de sesion (deja de estar activo)
    this.authenticationState$.next(false);
    this.operatorCurrent$.next(null);
  }

  public changeOperator(newOperator: PersonSSO) {
    var userFounded = this.usersLogged.find(u => u.uuid == newOperator.uuid);
    if (userFounded) {
      this.operatorUser = newOperator;
      this.operatorCurrent$.next(this.operatorUser);
    } else {
      // esto se deberia reportar como un error
      console.warn("[Auth.ser] => El operador no fue encontrado entre los usuarios logeados.");;
    }
    /* El cambio de operador siempre deja activo el estado */
    this.authenticationState$.next(true);
  }

  public isAuthenticated() {
    return this.authenticationState$.getValue();
  }


  private findUserByEmail(mail: string){
    let user = null;
    let operator: PersonSSO;
    /* console.log("[Auth.ser] => Cant de user logeados: ", this.usersLogged.length, " - mail buscado: ", mail); */
    this.usersLogged.forEach( function(valor, indice, array) {
      operator = valor as PersonSSO;
      let mailOperator = operator.getMailVerified()?.value;
      /* console.log("Compara "+mail+" con "+mailOperator); */
      if (mail.toLowerCase() == mailOperator?.toLowerCase()){
        /* console.log("Se encontro al usuario en la lista de logeados."); */
        user = valor;
      }
    });
    return user;
  }

  private findUserByCivilId(value: string){
    let user = null;
    this.usersLogged.forEach( function(valor, indice, array) {
      if (value == valor.document_ids[0].value){
        user = valor;
      }
    });
    return user;
  }

  // Devuelve el body indciado para cada tipo de identifier
  // TODO: cuando se actualize el service probablemente desaparezca
  private getBodyResetPassById(identifier: Identifier) {
    /* console.log("IDENTIFIER en auth service: ", identifier); */
    let typeIdentifier = identifier.type;
    let body: any;

    switch (typeIdentifier) {
      case Identifiers.AFFILIATE_ID:
        body = {
          saludSoftIds: [
            {
              type: Identifiers.AFFILIATE_ID,
              issuer: this.configService.getAppDomain(),
              id: identifier.value
            }
          ],
          profiles: [Profiles.AFFILIATE]
        }
        break;
      case Identifiers.CIVIL_ID:
        body = {
          civilIds: [
              {		
                  codeId: identifier.value.codeId,
                  value: identifier.value.value,
                  issuer: identifier.value.issuer
              }
          ],
          profiles: [Profiles.AFFILIATE]
        }
        break;
      case Identifiers.EMAIL:
/*         let mail = identifier.value.value;
        body = {
          "emails":[
            {
              value: mail,
              oldPassword: oldPass,
              newPassword: newPass
            }
          ],
          profiles: ["AFFILIATE"]
        } */
        break;
      case Identifiers.PHONE:
        
        break;
    }
    
    return body;
  }

  // Recupera el/los perfiles necesarios para hacer el login
  // private getProfileApp(){
  //   let profiles = this.adminProfileService.getProfilesLoginApp(this.configService.getApp());
  //   if(profiles)
  //     return profiles[0];
  //   return null;
  // }

  // ######################################################################################################
  // ################################## FUNCIONES AUTH V2 #################################################
  // devuelve el body acorde al tipo de identificador
  private getBody(identifier: Identifier, password: string, hashing: boolean, encrypted: boolean, durationToken: string){
    let pass: any;
    if(hashing){
      pass = this.encriptService.hashingSha3_512(password)
    }
    else{
      pass = password;
    }

    let bodyRequest: any = {
      person: this.getObjPersonSSOByIdentifier(identifier, encrypted),
      person_domain: this.configService.getAppDomain(),
      password: pass,
      token: {
        expiration_duration: durationToken
      }
    };
    return bodyRequest;
  }

  // devuelve un objeto de person distinto segun el identificador
  private getObjPersonSSOByIdentifier(identifier: Identifier, encrypted: boolean){
    /* console.log("[DEBUG] => ID recibido: ", identifier); */
    
    let bodyId: any;
    let dataValue: any;
    if(encrypted){
      dataValue = this.encriptService.encrypt_public_RSA(identifier.value.value);
    }
    else{
      dataValue = identifier.value.value;
    }

    switch (identifier.type) {
      case Identifiers.CIVIL_ID:
        bodyId = {
          document_ids: {
              country_code: identifier.value.issuer,
              type: identifier.value.codeId.code,
              value: dataValue
          }
        }
        break;
      case Identifiers.EMAIL:
        bodyId = {
          emails: {
              value: dataValue
            }
        }
        break;
      case Identifiers.PHONE:
        bodyId = {
          phones: {
            country_code: identifier.value.country_code,
            value: dataValue
          }
        }
        break;
      case Identifiers.EXTERNAL_ID:
        bodyId = {
          external_ids: {
            type: "back_office",      // WARN: esto se harcodea para poder loguear en el cav20, se debe cambiar para q funcione para cualquier idExterno
            value: dataValue
          }
        }
        break;
    }

    let bodyByIdentifier = {
      ids: bodyId
    };

    return bodyByIdentifier;
  }

}

