import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { finalize, timeout } from "rxjs/operators";
import { catchError } from 'rxjs/operators';
import { LoadingService } from 'src/app/sesion/services/loading.service';
import { LocalStorageService } from 'src/app/sesion/services/local-storage.service';
import { TokenizerService } from 'src/app/sesion/services/tokenizer.service';
import { TimeService } from 'src/app/sesion/services/utils/time.service';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private readonly TIMEOUT_DEFAULT = 4000;

  constructor(
    private localStorageService: LocalStorageService,
    private tokenizerService: TokenizerService,
    private loadingService: LoadingService,
    private timeService: TimeService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { // esta funcion se ejecuta antes de que se haga la peticion al servidor
    /* console.log("[INTERCEPTOR.serv] => Request captado"); */
    // aca deberiamos de validar que el token sea valido antes de asignarlo
    const token = this.localStorageService.getTokenOperator(); // obtenemos el token del localstorage
    this.tokenExpired();  // verificamos si el token esta expirado

    let tokenToPersist: string;
    
    let headers = new HttpHeaders({
      'token': token
    })

    // #############################################################################
    if((req.headers.get("skip") == "true") || (req.responseType == "blob")){ // si el header tiene el skip, no se le asigna el token
      /* console.log("Tiene el skip!!"); */

      /* return next.handle(req); */
      return next.handle(req).pipe(timeout(this.TIMEOUT_DEFAULT)); // para meter un timeout en las peticiones
    }
    else{
      /* console.log("NO Tiene el skip!!"); */
      this.loadingService.show(); // mostramos el loading
      
      /* return next.handle(req).pipe(
        finalize(()=> this.loadingService.hide())
      ); */
      return next.handle(req).pipe( // interceptamos la peticion
        finalize(()=> this.loadingService.hide()) // cuando se complete la peticion, se oculta el loading
      ).pipe(timeout(this.TIMEOUT_DEFAULT)); // para meter un timeout en las peticiones
      // para meter un timeout en las peticiones => SEGUIR
      /* return next.handle(req).pipe(
        timeout(4000),
        finalize(()=> this.loadingService.hide())
      ); */
    }


  }

  private updateRequest(token: string, req: HttpRequest<any>){
    let headers = new HttpHeaders({
      'token': token
    });

    const reqClone = req.clone({
      headers
    })

    return reqClone;
  }
  
  // esta funciona tmb podria hacer un save en un log
  manageError( error: HttpErrorResponse){ // funcion que se ejecuta cuando hay un error
    console.log("Manejo de errores personalizados.");
    return throwError("Admins error interceptor.");
  }


  private tokenExpired(){ // funcion que verifica si el token esta expirado
    /* console.log("Time expiracion token: ", this.localStorageService.getMomentExpiredTokenOperator()); */
    
    // pasamos la fecha guardad a un Date con la zona horaria por defecto
    let timeExpiredToken = Date.parse(this.localStorageService.getMomentExpiredTokenOperator() as string);
    let dateFormat = new Date(timeExpiredToken);
    
    let segundosDif = this.timeService.compare(dateFormat, new Date());
    /* console.log("Segundos de diferencia: ", segundosDif, " Date1: ", dateFormat, " - date2: ", new Date()); */
    
    if( segundosDif < 0){
      /* console.log("[INTERCEPTOR.serv] => Token EXPIRADO"); */
      return false;
    }
    /* console.log("[INTERCEPTOR.serv] => Token NO_EXPIRADO"); */
    return true;
  }
}
