  <form [formGroup]="phoneIdForm" (ngSubmit)="notifyEnterPressed()">
    <div class="form-group" [ngStyle]="(displayText == 'horizontal') ? {'display': 'inline-flex'}: {'display': 'block'}" style="width: 100%;">
      <div *ngIf="text">
        <label for="" style="line-height: initial; margin-bottom: 0.3rem;">
          <small [ngStyle]="(displayText == 'horizontal') ? {'width': '33%'}: {'width': '100%'}">{{text}}</small>
        </label>
      </div>
  
      <div style="display: inline-flex; width: 100%;" class="input-group">
        <app-selector-generic style="width: 35%; padding-right: 1%;" [editable]=false [atrValue]="'name'" [options]="countries" [inputOption]="'Argentina'"
          [showTextSelection]=false [text]="textCountry" [colorText]="colorText" [displayText]="'vertical'" (notifyChangeOption)="changeCountry($event)"></app-selector-generic>
    
        <div style="width: 65%;">
          <label for="" style="line-height: initial; margin-bottom: 0.3rem;">
            <small *ngIf="displayTextInputs" [ngStyle]="colorText ? {'color': colorText}: {'color': 'inherit'}">{{textValue}}</small>
          </label>
          <input *ngIf="!editable" style="width: 100%;" class="form-control form-control-sm form-control-inp" type="text" formControlName="value" [readonly]=true>
          <input *ngIf="editable" style="width: 100%;" (ngModelChange)="changeInput()" class="form-control form-control-sm" type="text" [readonly]=false
            formControlName="value" [placeholder]="placeholder" [type]="hideValue ? 'password': 'text'" [ngClass]="phoneIdForm.valid? 'inp-valid': 'inp-invalid' ">
          <div *ngIf="showEye" class="div-eye">
            <img *ngIf="!hideValue" src="app/sesion/assets/icon/eye_close48.svg" title="Ocultar contraseña" class="w3-hide" style="width:80%;" class="w3-hover-opacity" alt="" (click)="showValue()">
            <img *ngIf="hideValue" src="app/sesion/assets/icon/eye_open48.svg" title="Mostrar contraseña" class="w3-hide" style="width:80%;" class="w3-hover-opacity" alt="" (click)="showValue()">
          </div>
        </div>
      </div>
    </div>
  </form>