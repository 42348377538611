import { Daedline } from "../deadline";
import { PersonContext } from "./personContext";

export class Context{
    domain?: string;
    app?: string;
    version?: string;
    deadline?: Daedline;
    persons?: PersonContext[] = [];

    constructor(data?: Partial<Context>) {
        Object.assign(this, data);
    }
}