<app-modern-sass-nav></app-modern-sass-nav>
<!--- service-lines ---->
<app-enterprice-sass-header></app-enterprice-sass-header>
<!--- staffing ---->
<app-enterprice-sass-build></app-enterprice-sass-build>
<!--- technologies ---->
<app-enterprice-sass-feature></app-enterprice-sass-feature>

<!--- saas-solutions ---->
<app-enterprice-sass-work></app-enterprice-sass-work>
<!--- price ---->
<app-enterprice-sass-price></app-enterprice-sass-price>

<!--- contacts ---->
<app-enterprice-sass-client></app-enterprice-sass-client>
<!-- footer -->
<app-hms-footer></app-hms-footer>
