<form [formGroup]="inputForm" [ngStyle]="(displayText == 'horizontal') ? {'display': 'inline-flex', 'width': '100%'}: {'display': 'block'}">
    <label *ngIf="text" for="" style="line-height: initial; margin-bottom: 0.3rem;" [ngStyle]="colorText ? {'color': colorText}: {'color': 'inherit'}">
        <small [ngStyle]="(displayText == 'horizontal') ? {'width': '30%'}: {'width': '100%'}">{{text}}</small>
    </label>
    <div [ngStyle]="showEye ? {'width': '100%'}: {'width': '100%'}" class="input-group mb-2">
        <!-- <input id="input_{{id}}" (ngModelChange)="changeValue()" class="form-control form-control-sm" [type]="hidenValue ? 'password': 'text'"
            formControlName="inputValue" [placeholder]="placeholder" [ngClass]="inputForm.valid ? 'inp-valid': 'inp-invalid' "> -->
        <input id="input_{{id}}" (ngModelChange)="changeValue()" class="form-control form-control-sm" [type]="hidenValue ? 'password': 'text'"
            formControlName="inputValue" [placeholder]="placeholder" [ngClass]="{'inp-invalid': inputForm.get('inputValue').touched && inputForm.get('inputValue').invalid }">
        <div *ngIf="showEye" class="div-eye">
            <img *ngIf="!hidenValue" src="assets/images/eye_close48.svg" title="Ocultar" class="w3-hide" style="width:80%;" class="w3-hover-opacity" alt="" (click)="showValue()">
            <img *ngIf="hidenValue" src="assets/images/eye_open48.svg" title="Mostrar" class="w3-hide" style="width:80%;" class="w3-hover-opacity" alt="" (click)="showValue()">
        </div>
    </div>
</form>