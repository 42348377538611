<span (click)="toggleOptionsMenu($event)">
  <img src="assets/images/hms-tech/icon/nav/apps.png" alt="Apps" class="apps-icon">
</span>

<ul *ngIf="showOptionsMenu" class="app-menu" (clickOutside)="closeOptionsMenu()">
  <li class="app-column" *ngFor="let app of apps">
    <a (click)="onChangeApp(app)" class="app-link"> <!-- llama a onChangeApp() -->
      <div class="app-item">
        <img [src]="app.image" alt="{{ app.name }}" class="app-icon">
        <span>{{ app.name }}</span>
      </div>
    </a>
  </li>
</ul>
