<app-modern-sass-nav></app-modern-sass-nav>
<!-- about section Start-->
<section class="event about bg bg-img bg-about">
  <div class="container">
      <div class="row">
          <div class="col-md-10 offset-md-1">
              <div class="title1">
                <h1><span class="theme-color">T</span>echnologies <span class="small-text">
                </span></h1>
              </div>
          </div>
          <div class="col-lg-4 offset-lg-1 col-md-6">
              <div class="text-right">
                  <div class="announcer-img">
                      <img src="assets/images/event/1.jpg" alt="" class="img-fluid">
                      <img src="assets/images/event/1.jpg" alt="" class="img-fluid">
                      <img src="assets/images/event/l3.jpg" alt="" class="img-fluid" aTilt data-tilt data-tilt-max="3"
                           data-tilt-speed="400" data-tilt-perspective="500">
                  </div>
              </div>
          </div>
          <div class="col-xl-5 offset-xl-1 col-lg-7 col-md-6">
              <div class="abouts center-text">
                  <div class="format">
                      <div class="format-small-text">
                          <h6 class="text-white borders-before text-uppercase"><span>Embracing Technology in Our Solutions</span></h6>
                      </div>
                      <div class="format-head-text sub-tittle">
                          <h3 class="about-font-header text-white">Technology Highlights</h3>
                      </div>
                      <div class="format-sub-text sub-tittle">
                          <p class="about-para p-light">HMS TECH is the technological arm of the hms group where highly specialized computer solutions are researched and developed based on the most current technologies.</p>
                          <p class="about-para p-light">In recent years we have witnessed the growing momentum and acceleration with which new technologies emerge and the expansion of research fields as varied as artificial intelligence, telecommunications and cryptography, which converge towards concrete solutions at the service of society.</p>
                      </div>
                      <a class="btn btn-default " href="#">read more</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- about section end-->

<!--schedule section start-->
<section class="yoga format bg img-1">
  <div class="container">
      <div class="row">
          <div class="col-md-8 offset-md-2">
              <div class="title title2">
                  <h2 class="text-white text-dark">Software Solutions Series</h2>
                  <img src="assets/images/yoga-img/logo/bottom-curve.png" alt="" class="img-fluid">
              </div>
          </div>
          <div class="col-lg-5 col-md-6 set-order-2">
              <div class=" center-text">
                  <div>
                      <div class="format-head-text sub-title">
                        <h3 class="about-font-header text-white text-dark">#1 <span> Pioneering Tech Services</span></h3>
                    </div>
                      <div class="format-sub-text">
                          <p class="about-para text-white text-dark">HMS TECH focuses on a varied service proposal marketed through four service lines and whose common denominator is the application of current technologies and the participation of highly trained professionals of excellence in various branches of computing, from service provision from applications directly self-managed by their users to the supply of consultants in the staffing modality.</p>
                          <p class="about-para text-white text-dark">HMS TECH has developed high expertise in the implementation of turnkey solutions based on frameworks for the development of web-apps and mobile apps (Angular, Ionic, Apex), corporate-level services on architectures focused on node.js or java , loopback or quarqus frameworks respectively, and the use of a wide variety of technical resources, APIs and databases such as mongodb, oracle, redis, dessertsq, minio, etc., deployed in a variety of infrastructures and cloud services.</p>
                      </div>
                     <!-- <ul class="icon-collection"> ICONOS
                          <li class="about-icon">
                              <a href="#" class="center-content"><img src="assets/images/yoga-img/icon/1.png" alt="" class="img-fluid"></a>
                          </li>
                          <li class="about-icon">
                              <a href="#" class="center-content"><img src="assets/images/yoga-img/icon/2.png" alt="" class="img-fluid"></a>
                          </li>
                          <li class="about-icon">
                              <a href="#" class="center-content"><img src="assets/images/yoga-img/icon/3.png" alt="" class="img-fluid"></a>
                          </li>
                      </ul>-->
                      <!--<a class="btn btn-default back-white m-t-45">learn more</a>-->
                  </div>
              </div>
          </div>
          <div class="col-lg-7 col-md-6">
              <div class="girl-img-container">
                  <img src="assets/images/yoga-img/sechdule/img.png" class="img-fluid girl-yoga" alt="">
              </div>
          </div>
      </div>
  </div>
</section>
<!--schedule section end-->

<!-- technologies section-->
<section id="technologies" class="saas1 service">
  <div class="about-chat">
      <div class="container">
          <div class="row">
              <div class="col-md-12 text-center">
                  <div class="title">
                      <img src="assets/images/hms-tech/service-lines/title-logo-amarillo.png" alt="title-logo" class="img-fluid">
                      <div class="main-title">
                          <h2>
                              <span class="theme-color">T</span>echnologies

                          </h2>
                      </div>
                      <hr>
                      <div class="sub-title">
                          <p class="p-padding">Product development as well as the execution of its implementation. Tailor-made & turnkey solution development.</p>
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of features">
                  <div class="chat-box">
                      <img [src]="data.img" alt="stay-connected">
                      <div class="feature-content">
                          <h4 class="service-heading"> {{data.title}} <span
                                  class="theme-color">{{data.subTitle}}</span>
                          </h4>
                          <hr>
                          <p>{{data.description}}</p>
                      </div>
                  </div>
              </div>

          </div>
      </div>
  </div>
</section>
<!-- end technologies section -->
<!-- footer -->
<app-hms-footer></app-hms-footer>