import { Component, OnInit, HostListener  } from '@angular/core';

@Component({
  selector: 'app-our-people',
  templateUrl: './our-people.component.html',
  styleUrls: ['./our-people.component.scss']
})
export class OurPeopleComponent implements OnInit {
  private scrollContainer: HTMLElement;
  constructor() { }

  ngAfterViewInit() {
    this.scrollContainer = document.getElementById('your-scroll-container-id');
  }

  ngOnInit() {
  }
  
  experts = [
    { 
      img:"assets/images/hms-tech/developers/jmalanari_1.png",
      name:"Sam Rowling",
      designation:"Team Leader - otstrab"
     },
     { 
      img:"assets/images/hms-tech/our-people/people_v1_fernando.png",
      name:"Fernando Draier",
      designation:"App Developer - Jumpster"
     },
     { 
      img:"assets/images/hms-tech/developers/jsisti_1.png",
      name:"Sam Rowling",
      designation:"Team Leader - otstrab"
     },
     { 
      img:"assets/images/hms-tech/developers/hfranco_1.png",
      name:"Sam Rowling",
      designation:"Team Leader - otstrab"
     },{ 
      img:"assets/images/event/l3-1.png",
      name:"Vicky Smith",
      designation:"UI/UX Designer - Little Big"
     }
  ]
  @HostListener('wheel', ['$event'])
onScroll(event: WheelEvent) {
  if (this.scrollContainer && this.scrollContainer.contains(event.target as Node)) {
    // Calcula si el contenedor está al principio o al final de su desplazamiento horizontal.
    const atStart = this.scrollContainer.scrollLeft <= 0;
    const atEnd = this.scrollContainer.scrollLeft >= this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth;

    // Ajusta la cantidad de scroll basada en la dirección del evento.
    const scrollAmount = event.deltaY;

    // Decide si se debe permitir el desplazamiento vertical.
    if (scrollAmount < 0 && !atStart || scrollAmount > 0 && !atEnd) {
      // El usuario está intentando desplazarse hacia arriba pero no está al principio,
      // o hacia abajo pero no está al final: prevenir el desplazamiento vertical.
      event.preventDefault();
      this.scrollContainer.scrollLeft += scrollAmount;
    }
    // Si el usuario está en el inicio y se desplaza hacia arriba, o al final y se desplaza hacia abajo,
    // permite el desplazamiento vertical (no llamar a preventDefault).
  }
}





}