import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConfigToken } from 'src/app/sesion/assets/config/config-constant.example';
import { ContextSass } from 'src/app/sesion/model/context/contextSass';
import { TokenizerService } from 'src/app/sesion/services/tokenizer.service';
import { AdminContextSassService } from 'src/app/sesion/services/manager/admin-context-sass.service';
import { AdminModalService } from 'src/app/sesion/services/manager/adminModal.service';


@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {
  showOptionsMenu = false;
  apps: any; // Define el tipo de la variable apps según tus necesidades
  context: ContextSass; // Variable para almacenar el contexto

  constructor(
    private http: HttpClient, 
    private tokenizerService: TokenizerService,
    private adminContextSassService: AdminContextSassService,
    private adminModalService: AdminModalService,
    ) { }

  ngOnInit() {
    this.loadAppsData();
    // Carga el contexto al iniciar el componente
    //this.loadContext();
  }

  loadAppsData() {
    this.http.get<any>('assets/data/apps.json').subscribe(data => {
      this.apps = data;
    });
  }

  toggleOptionsMenu(event: Event) {
    event.stopPropagation();
    this.showOptionsMenu = !this.showOptionsMenu;
    console.log("probando showOptionsMenu", this.showOptionsMenu);
  
    if (this.showOptionsMenu) {
      // agrega manejador de clics al documento para cerrar el menú
      document.addEventListener('click', this.closeMenuOnClickOutside);
    } else {
      // si el menú está cerrado, elimina el manejador de clics del documento
      document.removeEventListener('click', this.closeMenuOnClickOutside);
    }
  }
  

  closeMenuOnClickOutside = (event: Event) => {
    const target = event.target as HTMLElement;

    if (!target.closest('.app-menu')) {
      // El clic fue fuera del área del menú, cierra el menú
      this.showOptionsMenu = false;
    }
  };

  public onChangeApp(appSelected: any){
    if(!appSelected.enable){
      console.warn("[onChangeApp] => El sitio aun no esta habilitado.");
      this.adminModalService.showModalGlobal("Conmutador de apps", "El sitio no se encuentra disponible por el momento.");
      return;
    }

    let urlRedirect = appSelected.url;

    if (appSelected.context) {
        let newContext: ContextSass = this.adminContextSassService.getContext();
        console.log("[App-menu.comp] => Intentamos abrir con contexto: ", urlRedirect); 
        this.tokenizerService.getTokenV2({context: newContext}, ConfigToken.DURATION_LOGIN_DEFAULT, Number.parseInt(ConfigToken.LENGTH_LOGIN_DEFAULT), ConfigToken.TYPE_LOGIN_DEFAULT, 50).subscribe(
            (resp: any) => {
                console.log("[onChangeApp] => Token de contexto inicializado:  ", resp); 
                urlRedirect += '?token_context=' + resp.short_token;
                // abrimos la app seleccionada en un nueva pestaña
                setTimeout(function (){
                    window.open(urlRedirect, '_blank').focus();
                }, 350);
            },
            (err: any) => {
                console.warn("[onChangeApp] => No se pudo actualizar el contexto!");
            }
        );
    } else {
        console.log("[App-menu.comp] => Intentamos abrir sin contexto: ", urlRedirect); 
        // abrimos la app seleccionada en un nueva pestaña sin pasar un contexto
        setTimeout(function (){
            window.open(urlRedirect, '_blank').focus();
        }, 350);
    }
}



}