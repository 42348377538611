import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalMsgComponent } from './components/modal-msg/modal-msg.component';
import { FieldModifiedComponent } from './components/field-modified/field-modified.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SelectorGenericComponent } from './components/selector-generic/selector-generic.component';
import { SelectAutosearcherComponent } from './components/select-autosearcher/select-autosearcher.component';
import { InputGenericV2Component } from './components/input-generic-v2/input-generic-v2.component';
import { CheckGenericComponent } from './components/check-generic/check-generic.component';
import { LinkGenericComponent } from './components/link-generic/link-generic.component';
import { CivilIdV2Component } from './components/civil-id-v2/civil-id-v2.component';
import { EmailIdV2Component } from './components/email-id-v2/email-id-v2.component';
import { CaptchaTokenComponent } from './components/captcha-token/captcha-token.component';
import { ViewerQrComponent } from './components/viewer-qr/viewer-qr.component';
import { ExternalIdComponent } from './components/external-id/external-id.component';
import { PhoneIdComponent } from './components/phone-id/phone-id.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { BoxLoginComponent } from './components/box-login/box-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './pages/login/login.component';
import { AppInitService } from './services/app-init.service';
import { ModalRegisterComponent } from './components/modal-register/modal-register.component';


export function init(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.init();
  }
}

@NgModule({
  declarations: [
    ModalMsgComponent,
    FieldModifiedComponent,
    LoadingComponent,
    SelectorGenericComponent,
    SelectAutosearcherComponent,
    InputGenericV2Component,
    CheckGenericComponent,
    LinkGenericComponent,
    CivilIdV2Component,
    EmailIdV2Component,
    CaptchaTokenComponent,
    ViewerQrComponent,
    ExternalIdComponent,
    PhoneIdComponent,
    BoxLoginComponent,
    LoginComponent,
    ModalRegisterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    NgbModule,
  ],
  providers: [
    /* AuthGuard, */
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: init, deps: [AppInitService], multi: true}
            ],
  exports: [
    ModalMsgComponent,
    BoxLoginComponent,
    LoadingComponent, 
  ]
})
export class SesionModule { }
