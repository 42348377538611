import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Profile } from '../model/SSO/profile';
import { Microservices } from '../assets/mocks/microservices';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private urlBaseSrv?: string;
  private apikey?: string;

  constructor(
    private httpClient: HttpClient,
    private configService:ConfigService
  ) {
      // esperamos a obtener la baseUrl del domain_sv
      this.configService.targetServices$.subscribe((value: any) => {      
        if(value){
          /* let dataMicroservices: any = value[Microservices.PROFILE_SV.namev2]; */
          let baseUrl: any = this.configService.getBaseUrlMicroservice(Microservices.PROFILE_SV.name);
          if(!baseUrl){
            console.warn("[Profile.serv] => No se encontraron los datos de base del profile_sv.");
            return;
          }
          this.urlBaseSrv = baseUrl;
          this.apikey = this.configService.getApiKey() as string;
        }
      });
    }

    // recupera todos los perfiles del dominio recibido
    public allProfilesByDomain(domain: string){
      let url = this.urlBaseSrv+"/domain/"+domain.toUpperCase()+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);
  
      return this.httpClient.get(url);
    }

    // recupera un perfil junto a sus roles segun el nombre
    public getProfileByName(name: string){
      let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/name/"+name+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);
  
      return this.httpClient.get(url);
    }

    // crea un perfil con los roles recibidos
    public create(dataProfile: Profile){
      let url = this.urlBaseSrv+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);
  
      return this.httpClient.post(url, dataProfile);
    }

    // actusliza los roles de un determinadoperfil
    public updateRoles(nameProfile: string, roles: string[]){
      let body = { roles: roles};
      let url = this.urlBaseSrv+"/domains/"+this.configService.getAppDomain()+"/name/"+nameProfile+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);
  
      return this.httpClient.patch(url, body);
    }

    // crea un rol en el dominio recibido
    public createRol(rol: string, domain: string){
      let body = { name: rol, domain: domain};
      let url = this.urlBaseSrv+"/roles?apikey="+this.apikey;
      //console.log("Url people services - findById: " + url);
  
      return this.httpClient.post(url, body);
    }

    // recupera todos los perfiles junto a sus roles del dominio actual
    public getAllProfiles(){
      let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);
  
      return this.httpClient.get(url);
    }

    // elimina un perfil segun el nombre
    public deleteProfile(name: string){
      let url = this.urlBaseSrv+"/domain/"+this.configService.getAppDomain()+"/name/"+name+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);
  
      return this.httpClient.delete(url);
    }

    // elimina un rol segun el nombre
    public deleteRol(name: string){
      let url = this.urlBaseSrv+"/roles/name/"+name+"domain/"+this.configService.getAppDomain()+"?apikey="+this.apikey;
    //console.log("Url people services - findById: " + url);
  
      return this.httpClient.delete(url);
    }
}
