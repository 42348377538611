<!-- products section -->
<section id="saas-solutions" class="saas1 howitwork" [ngStyle]="{'background-image': 'url(assets/images/saas1/work-bg.png)'}">
    <div class="container">
        <!-- Título y subtítulo -->
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="title">
                    <img src="assets/images/hms-tech/service-lines/title-logo-amarillo.png" alt="title-logo" class="img-fluid">
                    <div class="main-title">
                        <h2>
                            <span class="theme-color">S</span>{{"aas"| translate}} <span class="theme-color">S</span>{{"olutions"| translate}}
                        </h2>
                    </div>
                    <hr>
                    <div class="sub-title">
                        <p class="p-padding">{{"A range of products delivered as services hosted on HMS TECH's own infrastructure and offered on a convenient subscription model" | translate}}.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Pestañas -->
        <div class="col-md-12 work-tab">
            <ul class="nav nav-pills justify-content-center " id="pills-tab" role="tablist">
                <li class="nav-item  text-center" *ngFor="let data of nav">
                    <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work == data.title }"
                                 (click)="openWorkSlide(data.title)">
                        <img [src]="data.img" alt="tab-image-1">
                        <h6 class="">{{data.title| translate}}</h6>
                        <span></span>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Contenido de las pestañas -->
        <div class="work-tab-bg work-content p-t-50">
            <div class="tab-content text-center" id="pills-tabContent">
                <div class="tab-pane fade show active" *ngFor="let data of content" [ngClass]="{'active show': work == data.title }" id="pills-{{data.title}}" role="tabpanel">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="work-content-title">
                                <h3>{{data.title| translate}}</h3>
                            </div>
                        </div>
                    </div>
                    <!-- Lista de productos -->
                    <div class="row">
                        <div class="col-md-12">
                            <!-- Lista de productos -->
                            <div class="work-content-products">
                                <ul>
                                    <li *ngFor="let product of data.products" [ngClass]="{'active': selectedProduct === product}" (click)="selectProduct(product)">
                                        {{product.name}}
                                    </li>
                                </ul>
                            </div>
                   
                        </div>
                    </div>
                     <!-- Detalle del producto seleccionado -->
                <div class="row" *ngIf="selectedProduct && !hasValidPrice(selectedProduct)">
                    <div class="col-md-12">
                        <div class="selected-product-details">
                            <a href="https://hms-tech.odoo.com/contactus" target="_blank" class="btn btn-default">{{"Contact" | translate}}</a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end products section -->
