import { Component, ElementRef, OnInit } from '@angular/core';
import { ProductService } from '../../hms-tech/services/product.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-enterprice-sass-work',
  templateUrl: './enterprice-sass-work.component.html',
  styleUrls: ['./enterprice-sass-work.component.scss']
})
export class EnterpriceSassWorkComponent implements OnInit {
  public work: any = 'WEB APIs';
  public selectedProduct: any;
  public selectedProductCategory: any;

  constructor(private productService: ProductService,private el: ElementRef,private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.selectedProductCategory = params['product']; // Obtiene el producto seleccionado de la URL
      this.findCategoryForSelectedProduct(); // Llama a la función para encontrar la categoría del producto seleccionado
      console.log(this.selectedProduct); // Aquí puedes hacer lo que necesites con el producto seleccionado
    });
  }

  openWorkSlide(val) {
    this.work = val;
  }

  selectProduct(product) {
    this.selectedProduct = product;
    this.productService.setSelectedProduct(product);
    this.productService.setBasePrice(product.basePrice); // Establece el precio base del producto seleccionado
    setTimeout(() => {
      const el = document.getElementById('plan');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error('Element "plan" not found in the DOM.');
      }
    }, 100); // Espera 100 milisegundos (0.1 segundo) antes de hacer scroll
  } 

  // Función para verificar si un producto tiene un precio mensual válido
  hasValidPrice(product: any): boolean {
    return  (product && product.monthlyPrice.tiny) ? true : false;
  } 
  
   // Función para encontrar la categoría del producto seleccionado
   findCategoryForSelectedProduct() {
    for (const category of this.content) {
      if (category.products.some(product => product.name === this.selectedProductCategory)) {
        this.work = category.title; // Establece la categoría actual como la categoría que contiene el producto seleccionado
        break; // Rompe el bucle una vez que se encuentra la categoría
      }
    }
  }
  nav = [
    {
      img: 'assets/images/hms-tech/saas-solutions/Initializing.png',
      title: 'WEB APIs'
    },
    {
      img: 'assets/images/hms-tech/saas-solutions/Add-Instances.png',
      title: 'HEALTH SOLUTIONS'
    },
    {
      img: 'assets/images/hms-tech/saas-solutions/Security.png',
      title: 'SECURITY INFRASTRUCTURE'
    },
    {
      img: 'assets/images/hms-tech/saas-solutions/Configuration.png',
      title: 'EVERYTHING ELSE'
    }
  ];

  content = [
    {
      title: 'WEB APIs',
      products: [
        { 
          name: 'SSAPI', 
          description: "SSAPI description", 
          monthlyPrice: { tiny: 0.032, small: 0.031, medium: 0.022 },
          annualPrice: { tiny: 0.400, small: 0.300, medium: 0.200 },
          unit: 'Hit',
        },
        { name: 'API DE NEGOCIO', 
          description: "" ,
          monthlyPrice: "",
          annualPrice: "",
        },
        { name: 'API DE SEGURIDAD', description: "",
        monthlyPrice: "",
        annualPrice: "",
        },
      ]
    },
    {
      title: 'HEALTH SOLUTIONS',
      products: [
        { name: 'HMS ABC Book', description: "HMS ABC Book description" ,
        monthlyPrice: { tiny: 39.00, small: 49.00, medium: 99.00 },
        annualPrice: { tiny: 429.00 , small: 539.00, medium: 1089.00 },
        unit: 'Usr Admin',

        },
        { name: 'HMS Web Mobile Authorization Apps', description:  "",
        monthlyPrice: "",
        annualPrice: "",
        },
        { name: 'HMS Authorization Apps ', description:  "",
        monthlyPrice: "",
        annualPrice: "",
        },
      ]
    },
    {
      title: 'SECURITY INFRASTRUCTURE',
      products: [
        { name: 'Security Framework', description: "" ,
        monthlyPrice: "",
        annualPrice: "",
        },
        //{ name: 'Product 8', description: "" },
        //{ name: 'Product 9', description: "" }
      ]
    },
    {
      title: 'EVERYTHING ELSE',
      products: [
        { name: 'Kirio', description: "Mobile First Solution. Implements comprehensive user identity management. It operates as a HUB for activities and communications that relate the user community to the organization." ,
        monthlyPrice: { tiny: 199.00, small: 599.00, medium: 999.00 },
        annualPrice: { tiny: 2190.00, small: 6290.00, medium: 9990.00 },
        unit: 'Range',

        },
        { name: 'Digital Credentials', description: "Solution composed of a Mobile App for identification of Health Systems Affiliates, includes Web App for the Contact Center, Clinical History (ABC Book) and integration with ERP." ,
        monthlyPrice: { tiny: 0.19, small: 0.18, medium: 0.17},
        annualPrice: { tiny: 2.10, small: 2.00, medium: 1.90 },
        unit: 'Range',
        },
        { name: 'HMS DAM', description: "" ,
        monthlyPrice: "",
        annualPrice: "",
        },
        { name: 'HMS Geo-Tags', description: "",
        monthlyPrice: "",
        annualPrice:  "",
        },
        { name: 'HMS Contact Center', description: "" ,
        monthlyPrice: "",
        annualPrice: "",
        },
      ]
    },
  ];
}
