import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { domains } from '../assets/mocks/domains';
import { Domain } from '../model/domain/domain';
import { RespDomain } from '../model/domain/respDomain';
import { ConfigService } from './config.service';
import { LocalStorageService } from './local-storage.service';
import { ControlFormsService } from './control-forms.service';
import { Place } from '../model/place';
import { Company } from '../model/domain/company';
import { Address } from '../model/domain/address';
import { RespDomainEnv } from '../model/domain/respDomainEnv';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  domains: Domain[] =  domains;
  // data: RespDomain|null = null;
  dataDomain$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private configService: ConfigService,
    private controlFormsService: ControlFormsService,
    private httpClient: HttpClient
  ) {}
   
  // esto funciona ok pero falta el update del url al inicio
  public async updateDataDomain(domain: string, env: string){
    let respGetDataDomain: any = await this.getDataDomainEnv(domain, env).toPromise();
   // / console.log("[updateDataDomain] => Resp getDataDomainEnv: ", respGetDataDomain); /
    if(!respGetDataDomain.error){
     // / respGetDataDomain = respGetDataDomain as RespDomainEnv; /
      this.configService.setAppDomain(domain);
      this.configService.setApiKey(respGetDataDomain.api_key);
      this.configService.setBaseUrlServer(this.getBaseUrl(respGetDataDomain.addresses));
      this.configService.setMicrositesDomain(respGetDataDomain.misc.micro_sites);
      this.configService.setTargetServices(respGetDataDomain.misc.micro_services);
      this.configService.setTypeEnvironment(env);
      this.controlFormsService.setControlForms();
      this.dataDomain$.next(respGetDataDomain);
    }
    else{
      let errorMsg = "Ha ocurrido un error al recuperar los datos del dominio";
      if(respGetDataDomain.error.error){
        errorMsg = respGetDataDomain.error.error;
      }
      console.warn("[updateDataDomain] => Error: ", errorMsg, ". El sitio puede no funcionar correctamente.");
    }
    return respGetDataDomain;
  }

  // #####################################################################################################################
  // ######################################################## API ########################################################

    public getKeysEncrypted(domain: string, env: string){
      let urlRequest = this.configService.getCentralServer() + "/api/domains/environment/keys/domain/" + domain.toUpperCase() + "/environment/"+env.toUpperCase()+"?apikey=" + this.configService.getApiKeyCentralServer();
      /// console.log("url domian: ", urlRequest); /
      
      return this.httpClient.get(urlRequest);
    }

    // recupera todas las apps del dominio y env recibido
    public getAllApps(domain: string, env: string){
      let urlRequest = this.configService.getCentralServer() + "/api/domains/apps/domain/" + domains + "environment/"+env+"?apikey=" + this.configService.getApiKeyCentralServer();
      /// console.log("url domian: ", urlRequest); /
      
      return this.httpClient.get(urlRequest);
    }

    // recupera todos los dominios del dominio actual
    public getAll(): Observable<string[]> {
      let urlRequest = this.configService.getCentralServer() + "/api/domains/companies?apikey=" + this.configService.getApiKeyCentralServer();
      console.log("url domain: ", urlRequest); 
      
      return this.httpClient.get<any[]>(urlRequest).pipe(
        map(response => response.map(item => item.domain)) // Extraer solo los dominios
      );
    }
    
    

    private getDataDomain(domain: Domain|undefined){
      let codDomain: string = domain?.codeMenu as string;

      let urlRequest = this.configService.getCentralServer() + "/api/domains/domain/" + codDomain + "?apikey=" + this.configService.getApiKeyCentralServer();
      /// console.log("url domian: ", urlRequest); /
      
      return this.httpClient.get(urlRequest);
    }

    // Recupera los datos del dominio y el ambiente
    private getDataDomainEnv(domain: string, env: string){
      let urlRequest = this.configService.getCentralServer() + "/api/domains/environment/domain/" +domain.toUpperCase()+ "/environment/" +env.toUpperCase()+ "?apikey=" + this.configService.getApiKeyCentralServer();
     // / console.log("[getDataDomainEnv] => url domain: ", urlRequest); /
      
      return this.httpClient.get(urlRequest);
    }

    // con el modelo de compañia
    public getDataDomainV3(domain: string){
      let urlRequest = this.configService.getCentralServer() + "/api/domains/company/domain/"+domain.toUpperCase()+"?apikey=" + this.configService.getApiKeyCentralServer();
      /// console.log("url domian: ", urlRequest); /
      
      return this.httpClient.get(urlRequest);
    }

    public updateLocation(place: Place){
      let url= this.configService.getCentralServer() + "/api/domains/company/location/domain/" + this.configService.getAppDomain()?.toUpperCase() +"?apikey=" + this.configService.getApiKeyCentralServer();

      return this.httpClient.patch(url, place);
    }

    // Obtener datos de una compania (reemplazaria getDataDomainV3)
  public getCompany(domain: string): Observable<any> {
    let url = this.configService.getCentralServer()+"/api/domains/company/domain/"+domain.toUpperCase()+"?apikey="+this.configService.getApiKeyCentralServer();
    return this.httpClient.get(url);
  }


  // Agregar un nuevo dominio
  public addCompany(newCompany: Company): Observable<any> {
    let url = this.configService.getCentralServer()+"/api/domains/company?apikey="+this.configService.getApiKeyCentralServer();
    return this.httpClient.post(url, newCompany);
  }

  // Actualizar una empresa por su nombre de dominio
  public updateCompany(updateCompanyData: Company): Observable<any> { // domainName: string, environment: string
    let url = this.configService.getCentralServer()+"/api/domains/company/domain/"+updateCompanyData.domain+"?apikey="+this.configService.getApiKeyCentralServer();
    return this.httpClient.patch(url, updateCompanyData);
  }


  // Elimina un dominio identificandolo por su nombre y su ambiente
  public deleteCompany(domainName: string): Observable<any> {
    let url = this.configService.getCentralServer()+"/api/domains/company/domain/"+domainName+"?apikey="+this.configService.getApiKeyCentralServer();
    return this.httpClient.delete(url);
  }

  public dataMicroservices(domain: string, env: string){
    let url = this.configService.getCentralServer()+"/api/domains/environment/microservices/domain/"+domain.toUpperCase()+"/environment/"+env.toUpperCase()+"?apikey="+this.configService.getApiKeyCentralServer();
    return this.httpClient.get(url);
  }

  // Recupera las imagenes del dominio recibido
  public getImagesDomain(domain: string){
    let url = this.configService.getCentralServer()+"/api/domains/company/domain/"+domain.toUpperCase()+"?apikey="+this.configService.getApiKeyCentralServer();
    return this.httpClient.get(url);
  }

  public getAllImagesDomain(){
    let url = this.configService.getCentralServer()+"/api/domains/company/images?apikey="+this.configService.getApiKeyCentralServer();
    return this.httpClient.get(url);
  }

  // Devuelve la base url de un dominio, null si no lo encuentra
  private getBaseUrl(addressess: Address[]){
    let principalAddress: Address|undefined = addressess.find((data: any) => data.tag == "principal");
    if(!principalAddress){
      console.warn("[getBaseUrl] => No se pudo obtener la url base del dominio actual!");
      return null;
    }
    let port = "";
   // / if(addressess[0].services_base_protocol != "https"){ /
    if(principalAddress.base_port != "443"){
      port = ":"+principalAddress.base_port;
    }
   // / console.log("[getBaseUrl] => Base url armada: ", principalAddress.base_protocol +"://"+ principalAddress.base_host + port); /
    
    return principalAddress.base_protocol +"://"+ principalAddress.base_host + port;
  }
}
