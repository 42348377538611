import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-captcha-token',
  templateUrl: './captcha-token.component.html',
  styleUrls: ['./captcha-token.component.scss', '../../assets/style.scss']
})
export class CaptchaTokenComponent implements OnInit {

  captchaForm: FormGroup;

  rightCode: string = "";
  inputCode?: string;
  validToken: boolean = false;
  
  @Input() id: string = "";
  @Input() regex: string = "";
  @Input() showEye: boolean = false;
  @Input() placeholder: string = "";
  @Input() colorText?: string;
  @Output() notifyChange = new EventEmitter<any>();

  eReset: EventEmitter<any> = new EventEmitter<any>();       // setea el valor del input generico

  constructor() { }

  ngOnInit(): void {
    this.captchaForm = new FormGroup({
      captcha: new FormControl("", {
                validators:[ Validators.required]
              })
    });
    this.getImgValiCode();
    this.updateValidations();
  }

  public changeInput(){
    this.inputCode = this.captchaForm.get("captcha")?.value;
    /* console.log('[changeInput] => cambio el captcha: ', this.inputCode); */
    this.verifyToken();
    /* this.captchaForm.get('captcha')?.updateValueAndValidity(); */
  }
  /* public changeValue(data: any){
    this.inputCode = data;
    this.verifyToken();
  } */
  
  public refreshToken(){
    this.inputCode = "";
    this.captchaForm.get("captcha")?.setValue("");
    this.validToken = false;
    // aca va el reset del input-v2
    this.getImgValiCode();
  }

  public verifyToken(){
    if(this.inputCode == this.rightCode){
      /* console.log('[Captcha-token] => token CORRECTO'); */
      this.validToken = true;
    }
    else{
      /* console.log('[Captcha-token] => token INCORRECTO'); */
      this.validToken = false;
    };
    this.notifyChange.emit({value: this.inputCode, valid: this.validToken});
  }
  

  private getImgValiCode() {
    let showNum = [];
    let canvasWinth = 150;
    let canvasHeight = 30;
    let canvas: any = document.getElementById('valicode');
    let context = canvas.getContext('2d');
    canvas.width = canvasWinth;
    canvas.height = canvasHeight;
    let sCode = 'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,0,1,2,3,4,5,6,7,8,9,!,@,#,$,%,^,&,*,(,)';
    let saCode = sCode.split(',');
    let saCodeLen = saCode.length;
    for (let i = 0; i <= 3; i++) {
      let sIndex = Math.floor(Math.random()*saCodeLen);
      let sDeg = (Math.random()*30*Math.PI) / 180;
      let cTxt = saCode[sIndex];
      /* showNum[i] = cTxt.toLowerCase(); */
      showNum[i] = cTxt;
      let x = 10 + i*20;
      let y = 20 + Math.random()*8;
      context.font = 'bold 23px 微软雅黑';
      context.translate(x, y);
      context.rotate(sDeg);

      context.fillStyle = this.randomColor();
      context.fillText(cTxt, 0, 0);

      context.rotate(-sDeg);
      context.translate(-x, -y);
    }
    for (let i = 0; i <= 5; i++) {
      context.strokeStyle = this.randomColor();
      context.beginPath();
      context.moveTo(
        Math.random() * canvasWinth,
        Math.random() * canvasHeight
      );
      context.lineTo(
        Math.random() * canvasWinth,
        Math.random() * canvasHeight
      );
      context.stroke();
    }
    for (let i = 0; i < 30; i++) {
      context.strokeStyle = this.randomColor();
      context.beginPath();
      let x = Math.random() * canvasWinth;
      let y = Math.random() * canvasHeight;
      context.moveTo(x,y);
      context.lineTo(x+1, y+1);
      context.stroke();
    }
    this.rightCode = showNum.join('');
    /* console.log('[Captcha-token] => Codigo correcto: ', codeOriginal); */
  }

  private randomColor () {
    let r = Math.floor(Math.random()*256);
    let g = Math.floor(Math.random()*256);
    let b = Math.floor(Math.random()*256);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  public pressKey(event: any){
    if(event.key == "Enter"){
      event.preventDefault();
      /* console.log("[Captcha-token] => Se presiono el ENTER. Esquivo el refresh del token!"); */
    }
  }

  private updateValidations(){
    let arrayValidations = [Validators.required];
    if(this.regex){
      arrayValidations.push(Validators.pattern(this.regex));
    }
    if(arrayValidations.length > 0){
      this.captchaForm.get('captcha')?.setValidators(arrayValidations);
    }
  }

}
