export enum CodeApps {
  WEB_TEMPLATE = "web_template",
  CONTACT_CENTER = "contact_center",
  WEB_PRESTADORES = "web_prestadores_2.0",
  EXPLORER_DOCUMENTS = "people_explorer",
  VIEWER_DOCUMENTS = "image_viewer",
  MANAGER_TAGS = "manager_tag",
  CARTILLA = "portal_cartilla",
  MANAGER_DOMAINS = "management_profiles",
  MONITOR_LOGS = "monitor_logs"
}

export const Apps = [
    {
      app: CodeApps.WEB_TEMPLATE,
      name: "Template web",
      codeTag: "WT",
      /* roles: ["desarrollar", "solicitar", "identificar persona"] */
      roles: ["AUTENTICAR_PERSONAS"]
    },
    {
      app: CodeApps.CONTACT_CENTER,
      name: "Contac Center",
      codeTag: "CC",
      /* roles: ["crear", "identificar persona"] */
      roles: ["AUTENTICAR_PERSONAS"]
    },
    {
      app: CodeApps.WEB_PRESTADORES,
      name: "Web Prestadores",
      codeTag: "WP",
      roles: ["AUTENTICAR_PERSONAS"]
    },
    {
      app: CodeApps.EXPLORER_DOCUMENTS,
      name: "Explorador de Documentos",
      codeTag: "PE",
      roles: ["AUTENTICAR_PERSONAS"]
    },
    {
      app: CodeApps.VIEWER_DOCUMENTS,
      name: "Visualizador de Imagenes",
      codeTag: "VI",
      roles: ["AUTENTICAR_PERSONAS"]
    },
    {
      app: CodeApps.MANAGER_TAGS,
      name: "Gestion de Tags",
      codeTag: "MT",
      roles: ["AUTENTICAR_PERSONAS"]
    },
    {
      app: CodeApps.CARTILLA,
      name: "Portal de Cartila",
      codeTag: "PC",
      roles: ["AUTENTICAR_PERSONAS"]
    },
    {
      app: CodeApps.MANAGER_DOMAINS,
      name: "Gestión de perfiles",
      codeTag: "GP",
      roles: ["AUTENTICAR_PERSONAS"]
    },
    {
      app: CodeApps.MONITOR_LOGS,
      name: "Monitor de logs",
      codeTag: "ML",
      roles: ["AUTENTICAR_PERSONAS"]
    }
]