import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodeError, ErrorSaludSoft, Generic } from '../../assets/source/message';

@Injectable({
  providedIn: 'root'
})
export class AdminMsgResponseService {

  constructor() { }

  // Devuelve un string en base al tipo de error recibido
  // Deberia de recibir HttpErrorResponse pero para esto se debe resolver el hecho de crear una var de este tipo para cubrir casos
  // en los que el error no viene de la respuesta de un service
  /* public getMsg(error: HttpErrorResponse): string{ */
  public getMsg(error: any): string{
    let msg = Generic.DEFAULT_ERROR;
    switch(error.status){
      case HttpStatusCode.BadRequest: msg = Generic.INVALID_DATA; break;
    }

    return msg;
  }

  // Devuelve un string en base al tipo de error recibido
  // Deberia de recibir HttpErrorResponse pero para esto se debe resolver el hecho de crear una var de este tipo para cubrir casos
  // en los que el error no viene de la respuesta de un service
  /* public getMsg(error: HttpErrorResponse): string{ */
  public getMsgInError(error: any): string{
    console.log("[AdminMs.serv] => error recibido: ", error);
    let msg: string = Generic.DEFAULT_ERROR;
    switch(error.status){
      case HttpStatusCode.BadRequest:
        msg = Generic.INVALID_DATA;
        break;
    }

    return msg;
  }

  public getMsgInSuccess(dataError: any): string{
    let msg: string = Generic.OPERATION_FAILED;
    if(dataError.data?.error.code == CodeError.HMS_UNAVAILABLE){
      msg = ErrorSaludSoft.SERVER_UNAVAILABLE;
    }
    if(dataError.error?.code && dataError.error.code.includes("TOK")){
      msg = dataError.error.message;
    }
    return msg;
  }

}
