<!-- <div [ngStyle]="(displayText == 'horizontal') ? {'display': 'inline-flex', 'width': '100%'}: {'display': 'block'}">
    <small *ngIf="text" [ngStyle]="(displayText == 'horizontal') ? {'width': '32%'}: {'width': '100%'}">{{text}}</small>
 -->
 <form [formGroup]="emailForm">
    <div class="form-group" [ngStyle]="(displayText == 'horizontal') ? {'display': 'inline-flex', 'width': '100%'}: {'display': 'block'}">
        <!-- <div *ngIf="showicon">
            <img class="idType-icon" src="assets/icon/bootstrap/envelope-fill.svg">
            <label position="floating">{{label}}</label>
        </div> -->
        <label for="" style="line-height: initial; margin-bottom: 0.3rem;" [ngStyle]="colorText ? {'color': colorText}: {'color': 'inherit'}">
            <small *ngIf="text" [ngStyle]="(displayText == 'horizontal') ? {'width': '33%'}: {'width': '100%'}">{{text}}</small>
        </label>
        <app-field-modified *ngIf="(!showicon && modified)"></app-field-modified>
        <div class="input-group mb-2">
            <input *ngIf="!editable" class="form-control form-control-sm form-control-inp" formControlName="email" disabled>
            <!-- <input *ngIf="editable" class="form-control form-control-sm form-control-inp" formControlName="email" [type]="hideValue ? 'password': 'text'"
              (ngModelChange)="changeInput()" [placeholder]="placeholder" [ngClass]="emailForm.valid? 'inp-valid': 'inp-invalid' "> -->
            <input *ngIf="editable" class="form-control form-control-sm form-control-inp" formControlName="email" [type]="hideValue ? 'password': 'text'"
              (ngModelChange)="changeInput()" [placeholder]="placeholder" [ngClass]="{'inp-invalid': emailForm.get('email').touched && emailForm.get('email').invalid }">
            <div *ngIf="showEye" class="div-eye">
                <img *ngIf="!hideValue" src="app/sesion/assets/icon/eye_close48.svg" title="Ocultar contraseña" class="w3-hide" style="width:80%;" class="w3-hover-opacity" alt="" (click)="showValue()">
                <img *ngIf="hideValue" src="app/sesion/assets/icon/eye_open48.svg" title="Mostrar contraseña" class="w3-hide" style="width:80%;" class="w3-hover-opacity" alt="" (click)="showValue()">
            </div>
        </div>
    </div>
</form>