<app-modern-sass-nav></app-modern-sass-nav>

<!--our-history start-->

<section class="app2 about format" id="our-history">
    <div class="animated-bg"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
          <div class="img our-history">
            <img src="assets/images/hms-tech/our-history/Imagen-Our-History-542x580.png" alt="" class="img-fluid mobile1">
          </div>
        </div>
        <div class="col-md-7">
          <div class="abouts center-text">
            <div>
              <div class="format-small-text">
                <h6 class="font-primary borders-before text-uppercase"><span>{{"Company Profile" | translate}}</span></h6>
              </div>
              <div class="format-head-text">
                <h3 class="about-font-header font-secondary">{{"Our history" | translate}}</h3>
              </div>
              <div class="format-sub-text">
                <p class="about-para">{{"HMS began in 1995 as the joint of two companies: Binary, dedicated to service in the health vertical, audits of medical benefits for heath organizations and medication processing for the Argentine Federation of Chambers of Pharmacies (FACAF), and Systemas , specialized in IT, focused to the development of cutting-edge software. Binary's know-how on the health business combined with that of Systemas, produces the Authorizing Center for Medical Benefits with which health organizations achieve expense control and financing in real time. Both companies developed the foundations of what is known today as SaludSoft, HMS's flagship product. In 1998 it obtained its first two clients: Provincia Salud and AMSA, integrating Oracle Financials and SaludSoft, later Medicus SA, where the SaludSoft software was fully implemented for the first time. From these completely successful implementations, the creation of countless software and technology projects and solutions was triggered. In 2001 the company incorporated investors to expand its products in LATAM, completing the process in 2002, strengthening its position in the Argentine market and establishing the company in Mexico, where during the years 2002 and 2003 great advances and business were made. HMS is presented to the market with a mature product recognized as the undisputed leader. In 2015 she was hired by the largest Social and Prepaid Project in Argentina, OSDE. In 2023, HMS is consolidated as a corporate group with two divisions, HMS Health, which serves the health market, and HMS Tech, focused on newest technological developments for all markets." | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!--our-history-->

<!-- footer -->
<app-hms-footer></app-hms-footer>