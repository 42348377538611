<!-- header section Start-->
<section class="saas1 header" id="service-lines">
    <div class="saas1-header bg header8-content"  [ngStyle]="{'background-image': 'url(assets/images/saas1/slider-banner.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="center-text">
                        <div>
                            <div class="header-text">
                                <div class="d-flex">
                                    <h1 class="theme-color">{{'S'| translate}}{{"ervice"| translate}} 
                                        {{'L'| translate}}{{"ines"| translate}}</h1>
                                    <div class="center-content slider-logo">
                                        <img src="assets/images/saas1/slider-logo.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="header-sub-text">
                                <h3 class="">{{"HMS TECH organizes its offer through three well defined" | translate}} <span> {{"service lines" | translate}} </span>{{"that meet the market needs" | translate}} </h3>
                            </div> 
                            <div class="link-horizontal">
                                <ul>
                        
                                    <li>
                                        <!-- OCULTAR BOTON <a class="btn btn-default primary-btn transparent">{{"start now"| translate}}</a> -->
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="center-text slider-banner">
                        <img class="img-banner"src="assets/images/hms-tech/service-lines/07-service-lines.png" alt="slid-banner" style="filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/saas1/background2.png" class="img-fluid set-abs background" alt="">
    <img src="assets/images/saas1/dot.png" class="img-fluid set-abs dot" alt="">
</section>
<!-- header section end-->
<!-- event section start-->
<section class="yoga event bg img-1" [ngStyle]="{'background-image': 'url()'}"
>
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h2 class="text-white">HMS TECH organizes its offer through three perfectly defined service lines that satisfy market needs:</h2>
                    <img src="assets/images/yoga-img/logo/bottom-curve.png" alt="" class="img-fluid">
                </div>
            </div>-->
            
            <div class="col-md-4">
                <a routerLink="/service-lines" fragment="staffing" class="event-container d-flex">
                    <div class="event-info center-content">
                        <h4 class="text-black address">{{"Staffing" | translate}}</h4>
                        <h5 class="text-black time">{{"Supply of consultants in various disciplines and senioritis to reinforce our customer's staff." | translate}}</h5>
                    </div>
                </a>
            </div>
            <div class="col-md-4">
                <a routerLink="/service-lines" fragment="technologies" class="event-container d-flex">
                  <div class="event-info center-content">
                    <h4 class="text-black address">{{ "Technologies" | translate }}</h4>
                    <h5 class="text-black time">{{ "Product development as well as the execution of its implementation. Tailor-made & turnkey solution development." | translate }}</h5>
                  </div>
                </a>
            </div>
            <div class="col-md-4">
                <a routerLink="/service-lines" fragment="saas-solutions" class="event-container d-flex">
                    <div class="event-info center-content">
                        <h4 class="text-black address">Saas</h4>
                        <h5 class="text-black time">{{"A range of products delivered as services hosted on HMS TECH's own infrastructure and offered on a convenient subscription model." | translate}}</h5>
                    </div>
                </a>
            </div> 
        </div>
    </div>
</section>
<!-- event section end-->
